import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import { selectAvgAnnualSavings, selectCashInHandTotal, selectFinanceOption } from './selectors';
import { selectIsLoanOption } from '../../../../../common/Proposals/FinanceOptions/selectors';
import { State } from '@solvana/state-domain';
import AveMonthlyCashFlow from './AveMonthlyCashflow';
import { FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import FirstYearCashFlow from './FirstYearCashFlow';
import { LineItem, LineItemTotal } from '../../../../../../components/Accounting';

type Props = {
  proposalId: number;
  financeOptionId: number;
  state: State;
};

const CashFlow: React.FC<Props> = ({ proposalId, financeOptionId, state }) => {
  const classes = useStyles();
  const isLoanOption = useSelector((state: RootState) => selectIsLoanOption(state, proposalId, financeOptionId));
  const financeOption = useSelector((state: RootState) => selectFinanceOption(state, proposalId, financeOptionId));
  const annualSavings = useSelector((state: RootState) =>
    selectAvgAnnualSavings(state, proposalId, financeOptionId, 25),
  );
  const cashInHandTotal = useSelector((state: RootState) =>
    selectCashInHandTotal(state, proposalId, financeOptionId, 25),
  );

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Cash Flow
      </Typography>
      <Grid container spacing={3} className={classes.cardContainer}>
        <Grid item xs={12} lg={6}>
          {FinanceOptionType.Cash === financeOption?.type ? (
            <FirstYearCashFlow proposalId={proposalId} state={state} />
          ) : (
            <AveMonthlyCashFlow proposalId={proposalId} financeOptionId={financeOptionId} state={state} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card elevation={0} className={classes.card}>
            <div>
              <Typography className={classes.cardTitle} variant="h4">
                Average Annual Savings
              </Typography>

              {isLoanOption ? (
                <>
                  <LineItem title="Average Annual Savings During Loan Term" value={annualSavings.duringFinancingTerm} />
                  <LineItem title="Average Annual Savings After Loan Term" value={annualSavings.afterFinancingTerm} />
                </>
              ) : (
                <LineItem title="Average Annual Savings" value={annualSavings.afterFinancingTerm} />
              )}
            </div>

            <LineItemTotal title="Total Savings Over 25 Years" value={cashInHandTotal} />
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default CashFlow;
