import { Groups } from '../Group';

export interface CognitoUser {
  'cognito:groups': Groups[];
  sub: string;
}

export type UserSession = {
  groups: Groups[];
  sub: string;
};

export const toUserSession = (cognito: CognitoUser): UserSession => ({
  groups: cognito['cognito:groups'],
  sub: cognito.sub,
});
