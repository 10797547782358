import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProposal } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { GoSolarPageParams, toChooseAddersPath } from '../../routes';
import { selectIsFetchingProposal } from '../../selectors';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../common/Spinner';
import { AppBar, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { HomePageRoute } from '../../../pg-home';
import HorizontalLogo from '../../../common/Logos/HorizontalLogo';
import ProgressBar from '../../../common/ProgressBar';
import UserMenu from '../../../common/Layout/Header/UserMenu';
import { selectProposal } from '../../../common/Proposals/selectors';
import { RootState } from '../../../../RootState';
import Header from '../../../common/Layout/Header';
import { fetchCustomer } from '../../../common/Customers/actions';
import Cart from '../Cart';
import { fetchAdders } from '../../../common/Catalog/Adders/actions';
import { fetchBatteries } from '../../../common/Catalog/Batteries/actions';
import { fetchInverters } from '../../../common/Catalog/Inverters/actions';
import { fetchPanels } from '../../../common/Catalog/Panels/actions';
import { fetchAdvocate } from 'containers/common/Advocates/actions';
import LearnMore from '../LearnMore';
import { useStyles } from './styles';
import ElevationScroll from '../../../../components/ElevationScroll';
import json2mq from 'json2mq';
import { selectAdvocate } from '../../../common/Advocates/selectors';
import {
  GoSolarRoutes,
  toCheckoutPath,
  toChooseSolarOptionPath,
  toChooseBatteriesPath,
  toChooseFinancingPath,
} from '../../routes';
import Advocate from './Advocate';
import { selectProposalHasBatterOptions } from '../../pg-choose-your-batteries/selectors';
import UtilityModal from '../UtilityModal';

const toPages = (hasBatteries: boolean) => {
  const pages = [
    {
      title: 'Solar',
      path: GoSolarRoutes.ChooseYourSolarOption,
      pathHandler: toChooseSolarOptionPath,
    },
    {
      title: 'Add-ons',
      path: GoSolarRoutes.ChooseYourAdders,
      pathHandler: toChooseAddersPath,
    },
    {
      title: 'Financing',
      path: GoSolarRoutes.ChooseYourFinancing,
      pathHandler: toChooseFinancingPath,
    },
    {
      title: 'Batteries',
      path: GoSolarRoutes.ChooseYourBatteries,
      pathHandler: toChooseBatteriesPath,
    },
    {
      title: 'Checkout',
      path: GoSolarRoutes.Checkout,
      pathHandler: toCheckoutPath,
    },
  ];
  const batteryIndex = 2;
  return hasBatteries ? pages : [...pages.slice(0, batteryIndex), ...pages.slice(batteryIndex + 1)];
};

type Props = {
  hideProgressBar?: boolean;
  hideAdvocateContactInfo?: boolean;
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, hideProgressBar, hideAdvocateContactInfo }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isUnderMdView = useMediaQuery(json2mq({ maxWidth: theme.breakpoints.values.md }));
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const hasBatteryOptions = useSelector((state: RootState) => selectProposalHasBatterOptions(state, proposalId));
  const pages = toPages(hasBatteryOptions);

  useEffect(() => {
    id && fetchProposal(id, dispatch);
    fetchAdders(dispatch);
    fetchBatteries(dispatch);
    fetchInverters(dispatch);
    fetchPanels(dispatch);
  }, [id]);

  useEffect(() => {
    if (proposal) {
      fetchCustomer(proposal.customerId, dispatch);
      fetchAdvocate(proposal.advocateId, dispatch);
    }
  }, [proposal]);

  const isFetching = useSelector(selectIsFetchingProposal);
  const advocate = useSelector(
    (state: RootState) => proposal && proposal.advocateId && selectAdvocate(state, proposal.advocateId),
  );

  if (!proposal && isFetching) return <Spinner fullPage />;

  if (!proposal) {
    return (
      <div className={classes.notFoundContainer}>
        <Header />
        <Typography color="primary" variant="subtitle1">
          Proposal not found
        </Typography>
      </div>
    );
  }

  return (
    <>
      <section>
        {isUnderMdView && (
          <>
            <AppBar color="default" elevation={0} position="static" className={classes.smallHeaderContainer}>
              <Link className={classes.brand} onClick={() => history.push(HomePageRoute.Path)}>
                <HorizontalLogo height="42px" />
              </Link>
              <UserMenu className={classes.userMenu} />
            </AppBar>
            <ElevationScroll threshold={60}>
              <AppBar color="default" elevation={0} position="sticky" className={classes.smallProgressBarContainer}>
                {!hideProgressBar && <ProgressBar pathHandlerId={proposal.id} pages={pages} />}
              </AppBar>
            </ElevationScroll>
          </>
        )}
        {!isUnderMdView && (
          <ElevationScroll>
            <AppBar color="default" elevation={0} position="fixed" className={classes.headerContainer}>
              <Link className={classes.brand} onClick={() => history.push(HomePageRoute.Path)}>
                <HorizontalLogo height="42px" />
              </Link>
              {!hideProgressBar && <ProgressBar pathHandlerId={proposal.id} pages={pages} />}
              <UserMenu className={classes.userMenu} />
            </AppBar>
          </ElevationScroll>
        )}
        <UtilityModal />
        <section className={classes.bodyContainer}>
          {!isUnderMdView && !hideAdvocateContactInfo && advocate && (
            <div className={classes.contactInfoWidgetContainer}>
              <Advocate advocate={advocate} />
            </div>
          )}
          {children}
          {isUnderMdView && !hideAdvocateContactInfo && advocate && (
            <div className={classes.contactInfoWidgetContainer}>
              <Advocate advocate={advocate} />
            </div>
          )}
        </section>
      </section>
      <Cart />
      <LearnMore />
    </>
  );
};

export default Layout;
