import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      flexGrow: 1,
      maxWidth: '650px',
      margin: theme.spacing(2, 'auto', 6),
      '& h5': {
        margin: theme.spacing(1, 'auto', 0),
        maxWidth: '475px',
      },
    },
    formContainer: {
      marginBottom: theme.spacing(4),
    },
    subtitle: {
      color: theme.palette.type === 'dark' ? Colors.GreyThree : Colors.GreyTwo,
    },
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(1),
    },
    answerOptionsContainer: {
      margin: theme.spacing(3, 0),
      width: '100%',
    },
    button: {
      width: '100%',
    },
    error: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);
