import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { TextField as MUITextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import { solarSupportedStates } from '../../../Address/solarSupportedStates';

const stateOptions = solarSupportedStates.map((stateValue) => ({ value: stateValue, label: stateValue }));

const Address: React.FC = () => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Address
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <TextField name={'address.address1'} disabled={isSubmitting} label="Address1" />
        <TextField name={'address.address2'} disabled={isSubmitting} label="Address2" />
        <TextField name={'address.city'} disabled={isSubmitting} label="City" />

        <Field
          component={MUITextField}
          className={classes.input}
          type="text"
          name="address.state"
          label="State"
          select
          variant="standard"
          helperText="Please select A State"
          margin="normal"
          InputLabelProps={{ shrink: true }}
        >
          {stateOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>

        <TextField name={'address.zip'} disabled={isSubmitting} label="Zip" />
      </AccordionDetails>
    </Accordion>
  );
};

export default Address;
