import React from 'react';
import ModalView from '../../../common/ModalView';
import { FormikHelpers } from 'formik';
import CustomerForm from '../../../common/Customers/CustomerForm';
import { proposalToolApi } from '../../../../lib/api/proposalToolApi';
import { setOneCustomer } from '../../../common/Customers/actions';
import { Dispatch } from 'redux';
import { curry } from 'ramda';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

type Props = {
  customer: Customer;
  open: boolean;
  handleClose: () => void;
};

export const updateCustomer = curry(
  (dispatch: Dispatch, customer, { setSubmitting, setValues, setStatus }: FormikHelpers<Customer>) => {
    setSubmitting(true);
    proposalToolApi
      .put(`/v1/customers/${customer.id}`, { ...customer })
      .then((response) => {
        dispatch(setOneCustomer(response.data));
        setValues(response.data);
        setStatus({ success: 'Success.' });
        setSubmitting(false);
      })
      .catch((e) => {
        setStatus({ error: e.error });
        setSubmitting(false);
      });
  },
);

const EditCustomer: React.FC<Props> = ({ handleClose, open, customer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <ModalView handleClose={handleClose} open={open}>
      <div className={classes.root}>
        <CustomerForm customer={customer} submitHandler={updateCustomer(dispatch)} />
      </div>
    </ModalView>
  );
};

export default EditCustomer;
