import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(6),
    },
    title: {
      margin: theme.spacing(0, 0, 3),
    },
    listHeaderItem: {
      marginBottom: theme.spacing(3),
    },
    questionList: {
      margin: theme.spacing(3, 0, 0),
      padding: 0,
      '& li': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: theme.spacing(0, 0, 3),
        padding: 0,
      },
    },
    answerList: {
      margin: theme.spacing(0, 0, 0, 5),
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      '& li': {
        margin: 0,
        padding: 0,
      },
    },
    formContainer: {
      margin: theme.spacing(3, 'auto', 0),
      maxWidth: '600px',
    },
  }),
);
