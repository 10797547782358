import { FAQItem } from './types';

export const commercialFAQs: FAQItem[] = [
  {
    summary: 'We are selling our business, what happens to the loan?',
    details: 'The loan transfers to the new owner of the business.',
  },
  {
    summary: 'We are moving/selling our facility, can the loan be transferred?',
    details:
      'Yes! The loan can be moved to the new facility owner, though technically it is not a transfer. The new owner will need to complete a loan application be approved by Sunstone. Assuming they are, a new loan will be created that mimics the remaining terms on the old loan. If the new owner cannot be approved, the loan will need to paid before the purchase is finalized.',
  },
  {
    summary: 'How pay my bill?',
    details: 'The quoted rates assume that you enroll in via ACH. Sunstone will bill you monthly on behalf of our financing partners.',
  },
  {
    summary: 'What is the special payment and when is it due?',
    details: 'The special payment is a one-time payment made to reinvest the tax credit you receive into your system. Making this payment keeps your monthly loan payments as low as possible from day 1. It is due 18 months after the beginning of the loan but can be made at any time before that period. The loan begins at substantial completion, i.e. when the system is fully installed but perhaps not yet turned on.',
  },
];
