import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from 'theme/Colors';

export const useStyles = () =>
  makeStyles((_theme: Theme) =>
    createStyles({
      title: {
        color: Colors.GreyFour,
        lineHeight: 1.25,
        '& strong': {
          color: '#fff',
        },
      },
    }),
  );
