import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: Colors.GreyThree,
      width: '100%',
      borderTop: `solid 1px ${theme.palette.type === 'dark' ? Colors.GreyTwo : Colors.GreyThree}`,
      marginTop: theme.spacing(3),
    },
  }),
);
