import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unsubscribe();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
