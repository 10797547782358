import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';

export const selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const selectedAdder = proposal.cart.selectedAdders.find((sa) => sa.adderId === adderId);
  if (!selectedAdder) return 0;

  const proposalWithOnlyAdderInCart = {
    ...proposal,
    cart: { financeOption: proposal.cart.financeOption, selectedAdders: [selectedAdder] },
  };

  return Pricing.avgMonthlyPaymentNetAllIncentives(proposalWithOnlyAdderInCart);
};
