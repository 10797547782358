import React, { useCallback, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import HomePage, { HomePageRoute as HomeRoutes } from 'containers/pg-home';
import store from './store';
import { Amplify, Auth } from 'aws-amplify';
import { makeTheme } from './theme/materialUI/makeTheme';
import { selectLayoutUI } from './containers/common/Layout/selectors';
import CustomersPage, { CustomersRoute } from './containers/pg-customers';
import { AuthenticatedRoute, EmployeesOnlyRoute, UnauthenticatedRoute } from './containers/Auth/Routes';
import { Route as SignInRoute } from './containers/Auth/pg-sign-in/route';
import { Route as ForgotPasswordRoute } from './containers/Auth/pg-forgot-password/route';
import { Route as ResetPasswordRoute } from './containers/Auth/pg-reset-password/route';
import { Route as ConfirmSignUpRoute } from './containers/Auth/pg-confirm-signup/route';
import { Route as SignUpRoute } from './containers/Auth/pg-sign-up/route';
import AdvocatesPage, { Routes as AdvocatesRoute } from './containers/pg-advocates';
import TeamsPage, { Routes as TeamsRoute } from './containers/pg-teams';
import CustomerPage, { Routes as CustomerRoute } from './containers/pg-customer';
import SignInPage from './containers/Auth/pg-sign-in';
import { setGroups, setHasResolvedAuth, setIDTokenClaims, setIsAuthenticated } from './containers/Auth/actions';
import { CognitoUser, toUserSession } from './containers/common/UserSession';
import SignUpPage from './containers/Auth/pg-sign-up';
import ForgotPasswordPage from './containers/Auth/pg-forgot-password';
import ResetPasswordPage from './containers/Auth/pg-reset-password';
import ConfirmSignUpPage from './containers/Auth/pg-confirm-signup';
import { selectHasResolvedAuth } from './containers/Auth/selectors';
import ChooseYourSolarOptionPage from './containers/CheckoutPages/pg-choose-your-solar-option';
import ReinvestYourSavings from './containers/CheckoutPages/pg-choose-your-batteries';
import Checkout from './containers/CheckoutPages/pg-checkout';
import Complete from './containers/CheckoutPages/pg-complete';
import { GoSolarRoutes } from './containers/CheckoutPages/routes';
import ChooseYourSavingsPage from './containers/CheckoutPages/pg-choose-your-financing';
import usePageTrack from './utils/analytics';
import RFAPages from './containers/pg-rfa';
import { ALL_RFA_ROUTES, RFAUtilityAPIRedirectPage } from './containers/pg-rfa/routes';
import UtilityAPIRedirectIFramePage from './containers/pg-rfa/UtilityAPIRedirectIFramePage';
import { RFADetailsRoute } from './containers/pg-rfa-details/routes';
import RFADetailsPage from './containers/pg-rfa-details';
import ChooseYourAddersPage from './containers/CheckoutPages/pg-choose-your-adders';
import { segmentAnalytics } from './utils/analytics/segmentAnalytics';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

const RouterWrapper: React.FC = () => {
  usePageTrack();

  return (
    <Switch>
      <UnauthenticatedRoute path={SignInRoute.Path} exact component={SignInPage} />
      <UnauthenticatedRoute path={SignUpRoute.Path} exact component={SignUpPage} />
      <UnauthenticatedRoute path={ConfirmSignUpRoute.Path} exact component={ConfirmSignUpPage} />
      <UnauthenticatedRoute path={ForgotPasswordRoute.Path} exact component={ForgotPasswordPage} />
      <UnauthenticatedRoute path={ResetPasswordRoute.Path} exact component={ResetPasswordPage} />

      <Route path={RFAUtilityAPIRedirectPage.Path} exact component={UtilityAPIRedirectIFramePage} />
      <AuthenticatedRoute path={ALL_RFA_ROUTES} exact component={RFAPages} />

      <AuthenticatedRoute path={HomeRoutes.Path} exact component={HomePage} />
      <AuthenticatedRoute path={GoSolarRoutes.ChooseYourSolarOption} component={ChooseYourSolarOptionPage} />
      <Redirect from={GoSolarRoutes.RedirectChooseYourSolarOption} exact to={GoSolarRoutes.ChooseYourSolarOption} />
      <AuthenticatedRoute path={GoSolarRoutes.ChooseYourAdders} component={ChooseYourAddersPage} />
      <AuthenticatedRoute path={GoSolarRoutes.ChooseYourFinancing} component={ChooseYourSavingsPage} />
      <AuthenticatedRoute path={GoSolarRoutes.ChooseYourBatteries} component={ReinvestYourSavings} />
      <AuthenticatedRoute path={GoSolarRoutes.Checkout} component={Checkout} />
      <AuthenticatedRoute path={GoSolarRoutes.Complete} component={Complete} />

      <EmployeesOnlyRoute path={RFADetailsRoute.Path} exact component={RFADetailsPage} />
      <EmployeesOnlyRoute path={CustomersRoute.Path} exact component={CustomersPage} />
      <EmployeesOnlyRoute path={CustomerRoute.Path} exact component={CustomerPage} />
      <EmployeesOnlyRoute path={AdvocatesRoute.Path} component={AdvocatesPage} />
      <EmployeesOnlyRoute path={TeamsRoute.Path} component={TeamsPage} />
    </Switch>
  );
};

const ThemeWrapper: React.FC = () => {
  const { isDark } = useSelector(selectLayoutUI);
  const hasResolvedAuthentication = useSelector(selectHasResolvedAuth);
  const theme = makeTheme(isDark);
  const dispatch = useDispatch();

  const initLoad = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      dispatch(setIsAuthenticated(true));
      dispatch(setGroups(toUserSession(session.getAccessToken().payload as CognitoUser)));
      dispatch(setIDTokenClaims(session.getIdToken().payload));
      dispatch(setHasResolvedAuth(true));
    } catch (e) {
      dispatch(setIsAuthenticated(false));
      dispatch(setHasResolvedAuth(true));
    }
  }, []);

  useEffect(() => {
    initLoad().catch();
  }, [initLoad]);

  if (!hasResolvedAuthentication) {
    return null;
  }

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
        {'production' === process.env.NODE_ENV && <script>{!(window as any).analytics && segmentAnalytics()}</script>}
      </Helmet>

      <Router>
        <RouterWrapper />
      </Router>
    </MUIThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeWrapper />
    </Provider>
  );
};

export default App;
