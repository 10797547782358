import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { PartnerLogoProps } from '../types';

const defaultWidth = '52px';

const Mosaic: React.FC<PartnerLogoProps> = ({ className, width }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width || defaultWidth} viewBox="0 0 486 93">
      <path d="M31,31H62V62H31Z" fill="#fff" />
      <path d="M0,31H31V62H0Z" fill="#eb874f" />
      <path d="M31.1,0h31V31h-31Z" fill="#f6cd80" />
      <path d="M62.1,31h31V62h-31Z" fill="#3bbfbb" />
      <path d="M31.1,62h31V93h-31Z" fill="#4e8eaa" />
      <path
        d="M154.1,49.5,131.2,15.2h-7.1V78h6.8V27.2l22.9,33.6h.4L177,27.2V78h7.1V15.2h-7.2Zm77.1-35.3c-19.1,0-32.1,15.1-32.1,32.4v.2c0,17.3,12.8,32.3,31.9,32.3S263.1,64,263.1,46.7v-.2c0-17.4-12.9-32.3-31.9-32.3Zm24.5,32.6c0,14.3-10.2,25.7-24.6,25.7s-24.7-11.7-24.7-25.9v-.2c0-14.2,10.2-25.7,24.6-25.7s24.7,11.6,24.7,25.9Zm44.8-3.6c-13.8-3-17-6.3-17-12.2v-.2c0-5.6,5.2-10.1,13.4-10.1,6.5,0,12.3,2.1,18.1,6.8l4.1-5.5a32.79,32.79,0,0,0-22-7.7c-11.9,0-20.6,7.3-20.6,17.1v.2c0,10.3,6.6,15,21.1,18.1,13.2,2.8,16.3,6.1,16.3,11.9v.2c0,6.2-5.6,10.7-14,10.7-8.7,0-15-3-21.5-8.9L274,68.8c7.5,6.7,15.7,10,25.6,10,12.5,0,21.4-7,21.4-17.7v-.2c0-9.5-6.5-14.7-20.5-17.7Zm58.1-28.4L330,78h7.3l7.4-16.6h34.1L386.1,78h7.6L365.1,14.8ZM347.5,55l14.3-32,14.3,32Zm59.3-39.8h7.1V77.9h-7.1Zm54.3,57.3c-13.8,0-24.3-11.4-24.3-25.9v-.2c0-14.4,10.4-25.7,24.3-25.7,8.5,0,14.4,3.6,19.7,8.6l4.8-5.2c-6.3-5.9-13.1-9.9-24.5-9.9-18.5,0-31.7,14.7-31.7,32.4v.2c0,18,13.4,32.3,31.5,32.3,11.4,0,18.5-4.4,25.1-11l-4.7-4.6C475.4,69.2,469.7,72.5,461.1,72.5Z"
        fill={isDarkMode ? '#fff' : '#4b4846'}
      />
    </svg>
  );
};

export default Mosaic;
