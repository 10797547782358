import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import ModalView from 'containers/common/ModalView';
import { useDispatch, useSelector } from 'react-redux';
import { selectProposal } from 'containers/common/Proposals/selectors';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from 'containers/CheckoutPages/routes';
import { RootState } from 'RootState';
import { selectCustomerForId } from 'containers/common/Customers/selectors';
import { selectShowUtilityModal } from 'containers/CheckoutPages/selectors';
import { setShowUtilityModal } from 'containers/CheckoutPages/actions';
import { toFormattedPrice } from 'utils/helpers';
import { selectTotalUtilityBill } from 'containers/CheckoutPages/pg-choose-your-financing/Financing/selectors';
import StyledButton from 'containers/common/StyledButton';
import { selectAnnualUtilityBills } from '../LearnMore/BenefitsSummary/WithWithoutSolar/SavingsChart/selectors';

const UtilityModal: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const showModal = useSelector(selectShowUtilityModal);
  const twentyFiveYearBill = useSelector((state: RootState) => selectTotalUtilityBill(state, proposalId, 25));
  const annualBills = useSelector((state: RootState) => selectAnnualUtilityBills(state, proposalId, 25));
  const dispatch = useDispatch();
  const customer = useSelector((state: RootState) =>
    proposal ? selectCustomerForId(proposal.customerId, state) : null,
  );

  const bills: { [k: string]: number } = useMemo(() => {
    return {
      ...{ '1': annualBills[1] || 0 },
      ...{ '7': annualBills[7] || 0 },
      ...{ '25': annualBills[25] || 0 },
    };
  }, [annualBills]);

  if (!proposal || !customer) return null;

  return (
    <ModalView open={showModal} handleClose={() => dispatch(setShowUtilityModal(false))}>
      <div className={classes.root}>
        <Typography variant="h5">
          Hi {customer.firstName}, Let&apos;s first review your current utility plan.
        </Typography>
        <Typography variant="body2" className={classes.p}>
          Without solar you will spend <b className={classes.negative}>{toFormattedPrice(twentyFiveYearBill)}</b> over
          the next 25 years.
        </Typography>

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="Annual costs table">
            <TableHead>
              <TableRow>
                <TableCell>Years</TableCell>
                <TableCell align="right">Annual Bill</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(bills).map((year: string) => (
                <TableRow key={bills[year]}>
                  <TableCell>{year === '1' ? 'Next Year' : `Year ${year}`}</TableCell>
                  <TableCell align="right" className={classes.negative}>
                    {toFormattedPrice(bills[parseInt(year)])}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" className={classes.note}>
          * Utility bills increase at an estimated rate of 2.9% per year
        </Typography>

        <StyledButton className={classes.btn} variant="contained" onClick={() => dispatch(setShowUtilityModal(false))}>
          Ok, got it!
        </StyledButton>
      </div>
    </ModalView>
  );
};

export default UtilityModal;
