import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext } from 'formik';

const Utility: React.FC = () => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Utility
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <TextField name={'utility.utilityType'} disabled={isSubmitting} label="Utility Type" />
        <TextField
          name={'utility.annualConsumption'}
          disabled={isSubmitting}
          label="Annual Consumption"
          type="number"
        />
        <TextField
          name={'utility.annualFixedCharge'}
          disabled={isSubmitting}
          label="Annual Fixed Charge"
          type="number"
        />
        <TextField
          name={'utility.annualInflationPercentage'}
          disabled={isSubmitting}
          label="Annual Inflation Percentage"
          type="number"
        />
        <TextField name={'utility.valueOfSolar'} disabled={isSubmitting} label="Value of Solar" type="number" />
        <TextField name={'utility.rate'} disabled={isSubmitting} label="Rate" type="number" />
      </AccordionDetails>
    </Accordion>
  );
};

export default Utility;
