import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectNJTotalBenefits,
  selectNJRecCount,
  selectNJFirstYearBenefits,
  selectAvgSRECValue,
  selectSRECCount,
} from './selectors';
import { RootState } from '../../../../../../../RootState';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import BenefitCard from '../common/BenefitCard';
import NJIcon from '../../../../../../../components/SolvanaIcons/StateIcons/NJIcon';
import { selectCartSolarOption } from '../../../../../../common/Proposals/Cart/selectors';

type Props = {
  proposalId: number;
};

const NJBenefits: React.FC<Props> = ({ proposalId }) => {
  const solarOption = useSelector((state: RootState) => selectCartSolarOption(state, proposalId));
  const totalValue = useSelector((state: RootState) => selectNJTotalBenefits(state, proposalId));
  const firstYearRecValue = useSelector((state: RootState) => selectNJFirstYearBenefits(state, proposalId));
  const recCount = useSelector((state: RootState) => selectNJRecCount(state, proposalId));
  const avgSRECValue = useSelector((state: RootState) => selectAvgSRECValue(state, proposalId));
  const srecCount = useSelector((state: RootState) => selectSRECCount(state, proposalId));

  if (!solarOption) return null;

  return (
    <BenefitCard
      title={`+${toFormattedPrice(totalValue)}`}
      subtitle="NJ State Incentives"
      Description={() => (
        <div>
          <div>
            New Jersey awards monetary incentives to people generating solar electricity through Renewable Energy
            Credits (RECs). RECs can put as much as <strong>{toFormattedPrice(firstYearRecValue)}</strong> in your
            pocket in the first year alone! For every 1,000 kWh that your system produces you receive a REC that is
            automatically exchanged for money and deposited into your bank account. For the first 15 years you are
            eligible for a special type of REC called a Solar Renewable Energy Certificate (SREC). You will receive{' '}
            <strong>{srecCount} SRECs</strong> with an average value of{' '}
            <strong>{toFormattedPrice(avgSRECValue)}</strong> each. SREC values are fixed and will not fluctuate. Over
            25 years your system will generate a total of <strong>{recCount} RECs</strong> and will continue to generate
            RECs as long as your system produces energy.
          </div>
        </div>
      )}
      Icon={() => <NJIcon size={65} />}
    />
  );
};

export default NJBenefits;
