import React from 'react';
import { FinanceOption, FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Mosaic from '../SolvanaIcons/Partners/Mosaic';
import SunlightFinancial from '../SolvanaIcons/Partners/SunlightFinancial';
import SunstoneCredit from '../SolvanaIcons/Partners/SunstoneCredit';

export const useStyles = () =>
  makeStyles((_theme) =>
    createStyles({
      title: {
        fontSize: '28px',
        fontWeight: 900,
      },
      partnerLogo: {
        width: '50%',
        fontSize: '28px',
      },
      sunlightFinancial: {
        width: '90%',
      },
    }),
  );

type Props = {
  financeOption: FinanceOption;
};

const FinancePartnerLogo: React.FC<Props> = ({ financeOption }) => {
  const classes = useStyles()();

  switch (financeOption.type) {
    case FinanceOptionType.Mosaic:
      return <Mosaic width="50%" className={classes.partnerLogo} />;

    case FinanceOptionType.SunlightFinancial:
      return <SunlightFinancial width="75%" className={classes.sunlightFinancial} />;

    case FinanceOptionType.SunstoneCredit:
      return <SunstoneCredit width="75%" className={classes.partnerLogo} />;

    case FinanceOptionType.CapitalLease:
      return (
        <Typography variant="h2" className={classes.title}>
          Capital Lease
        </Typography>
      );

    case FinanceOptionType.Cash:
      return (
        <Typography variant="h2" className={classes.title}>
          Self Finance
        </Typography>
      );

    default:
      return null;
  }
};

export default FinancePartnerLogo;
