import React, { useMemo } from 'react';
import Layout from 'containers/common/Layout';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import HomeProposals from './HomeProposals';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectCurrentUserProfile } from '../common/CurrentUserProfile/selectors';
import { selectIDTokenClaims } from '../Auth/selectors';
import NextUp from './NextUp';

const HomePage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const idTokenClaims = useSelector(selectIDTokenClaims);
  const { firstName } = idTokenClaims || {};

  const currentUserProfile = useSelector(selectCurrentUserProfile);
  const userProfile = currentUserProfile && currentUserProfile.entity;
  const day = useMemo(() => moment(new Date()).format('dddd'), []);

  return (
    <Layout title="Solvana | Buy & Finance Solar 100% Online | Contact-Free at Home Delivery & Installation">
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.heroContainer}>
          <div className={classes.leftHeroContainer}>
            {userProfile ? (
              <Typography variant="h1">
                Happy {day}, <br /> {userProfile.firstName}.
              </Typography>
            ) : (
              <Typography variant="h1">
                Happy {day}
                {firstName ? `, ${firstName}` : ''}.
              </Typography>
            )}
            <NextUp />
          </div>
          <div className={classes.rightHeroContainer}>
            <img
              src="https://solvana-catalog-resources.s3.amazonaws.com/images/solvana-solar-roof-2b.jpg"
              alt="Solvana solar installation on house"
            />
          </div>
        </div>
        <Grid className={classes.contentContainer} container spacing={isSmallerThanMediumView ? 0 : 5}>
          <Grid item lg={6} xs={12}>
            <div className={classes.gridItem}>
              <Typography variant="h4">Your Solar Proposals</Typography>
              <HomeProposals />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default HomePage;
