import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../RootState';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import BenefitCard from '../common/BenefitCard';
import ElectricIcon from '../../../../../../../components/SolvanaIcons/ElectricIcon';
import { selectTotalSolarSavings } from '../../../../../../common/Proposals/SolarOptions/selectors';
import ElectricBillBlurb from './ElectricBillBlurb';

type Props = {
  proposalId: number;
};
const ElectricBillSavingsSummary: React.FC<Props> = ({ proposalId }) => {
  const totalSolarSavings = useSelector((state: RootState) => selectTotalSolarSavings(state, proposalId, 1));

  return (
    <BenefitCard
      title={`+${toFormattedPrice(totalSolarSavings)}`}
      subtitle="Electric Bill Savings"
      Description={() => <ElectricBillBlurb proposalId={proposalId} />}
      Icon={() => <ElectricIcon size={65} />}
    />
  );
};

export default ElectricBillSavingsSummary;
