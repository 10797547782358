import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';
import AddressLinkToGoogleMaps from 'containers/common/AddressLinkToGoogleMaps';

type Props = {
  customer: Customer;
};

const CustomerProfile: React.FC<Props> = ({ customer }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <hgroup>
        <Typography className={classes.title} variant={'h5'}>
          Contact Information
        </Typography>

        <Typography className={classes.detail} variant={'subtitle1'}>
          {customer.phoneNumber}
        </Typography>

        <Typography className={classes.detail} variant={'subtitle1'}>
          {customer.email}
        </Typography>
      </hgroup>

      <hgroup>
        <Typography className={classes.title} variant={'h5'}>
          Address
        </Typography>

        <AddressLinkToGoogleMaps address={customer.address} />
      </hgroup>
    </div>
  );
};

export default CustomerProfile;
