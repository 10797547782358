import { Reducer } from 'redux';
import { Actions, removeCustomer, setCustomers, setError, setIsFetching, setOneCustomer } from './actions';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

type CustomerActions = ReturnType<typeof setCustomers> &
  ReturnType<typeof setIsFetching> &
  ReturnType<typeof setError> &
  ReturnType<typeof removeCustomer> &
  ReturnType<typeof setOneCustomer>;

export type CustomerState = {
  isFetching: boolean;
  error: string | null;
  entities: Customer[];
};

export type SelectCustomerState = {
  customers: CustomerState;
};

const DEFAULT_STATE: CustomerState = {
  isFetching: false,
  error: null,
  entities: [] as Customer[],
};

const toUpdateCustomerState = (state: CustomerState, customer: Customer): CustomerState => {
  const index = state.entities.findIndex((c) => c.id === customer.id);
  if (index < 0) {
    return { ...state, entities: [...state.entities, customer] };
  }

  return { ...state, entities: [...state.entities.slice(0, index), ...state.entities.slice(index + 1), customer] };
};

const toRemoveCustomerState = (state: CustomerState, id: string): CustomerState => {
  const index = state.entities.findIndex((c) => c.id === id);
  if (index < 0) {
    return state;
  }

  return { ...state, entities: [...state.entities.slice(0, index), ...state.entities.slice(index + 1)] };
};

const customers: Reducer<CustomerState, CustomerActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetCustomers:
      return { ...state, entities: action.customers };

    case Actions.SetOneCustomer:
      return toUpdateCustomerState(state, action.customer);

    case Actions.SetIsFetching:
      return { ...state, isFetching: action.isFetching };

    case Actions.RemoveCustomer:
      return toRemoveCustomerState(state, action.id);

    case Actions.SetError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default customers;
