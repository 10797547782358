import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(6),
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', 6, 'auto'),
      color: '#fff',
      fontSize: '28px',
    },
    cardContainer: {},
    card: {
      padding: theme.spacing(4),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardTitle: {
      fontSize: '20px',
      marginBottom: theme.spacing(1),
    },
    savings: {
      color: theme.palette.success.main,
    },
    loss: {
      color: theme.palette.primary.main,
    },
    cardStatsTotal: {
      borderColor: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      borderTop: 'solid 1px',
      marginTop: theme.spacing(3),
      fontWeight: 900,
      justifySelf: 'flex-end',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
