import { selectProposal, SelectProposalState } from '../../../../common/Proposals/selectors';
import { selectFinanceOption } from '../../../../common/Proposals/FinanceOptions/selectors';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { getFinancingLegalText } from '../../../common/LearnMore/BenefitsSummary/FinanceOption/FinancingLegal/getFinancingLegalText';

export const selectLoanPaymentLegalText = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): string | null => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const financeOption = selectFinanceOption(state, proposalId);
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return null;
  const loan = financeOption as LoanFinanceOption;

  const essAdder = proposal.essAdders.find((ess) => ess.id === essAdderId);
  if (!essAdder) return null;

  const itcPercentage = ITC.percentage(proposal);

  return getFinancingLegalText(loan, essAdder.price, itcPercentage);
};
