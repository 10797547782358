import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
      strokeWidth: 2.21,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    highlight: {
      stroke: theme.palette.primary.main,
      strokeWidth: 3,
    },
  }),
);

const defaultWidth = 50;

const CartPortCanopy: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 62">
      <title>{title || 'Car Port Canopy'}</title>
      <path d="M31 8L2 30" />
      <path d="M54 30v30" />
      <path d="M8 30v30" />
      <path d="M31 8l29 22" />
      <path d="M60 19l-5 6" />
      <path d="M53 14l-5 6" />
      <path d="M48 10l-5 6" />
      <path d="M41.7 5.4L37 11" />
      <path className={classes.highlight} d="M64 22L38 2" />
      <path d="M17.7 42.9h26.6l-2.4-4.6c-.8-1.1-2-1.7-3.3-1.7H23.7c-1.2 0-2.4.6-3.2 1.6 0-.1-2.8 4.7-2.8 4.7zm-3.6 13.5h33.8V49c0-1.6-.6-3.1-1.8-4.3l-1.8-1.8H17.7l-1.8 1.8c-1.1 1.1-1.8 2.7-1.8 4.3v7.4z" />
      <path d="M14.2 48.1l3.2.6c1.3.3 2.7-.1 3.7-1.1l.3-.3" />
      <path d="M15.2 45.4s6.6.2 7.5 3.8H39c.9-3.6 7.5-3.8 7.5-3.8" />
      <path d="M47.5 48.1l-3.2.6c-1.3.3-2.7-.1-3.7-1.1l-.3-.3M18.2 60h-2.4c-1 0-1.7-.8-1.7-1.7v-1.9H20v1.9c0 .9-.8 1.7-1.8 1.7zm28 0h-2.4c-1 0-1.7-.8-1.7-1.7v-1.9H48v1.9c-.1.9-.9 1.7-1.8 1.7zM16.8 43.3h-3.4c-.3 0-.6-.3-.6-.6 0-.1 0-.2.1-.3l1-1.5c.3-.5.9-.8 1.5-.8h.9c.5 0 .9.4 1 .8l.3 1.4m27.6 1h3.4c.3 0 .6-.3.6-.6 0-.1 0-.2-.1-.3l-1-1.5c-.3-.5-.9-.8-1.5-.8h-.9c-.5 0-.9.4-1 .8l-.3 1.4" />
    </svg>
  );
};

export default CartPortCanopy;
