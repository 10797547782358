import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const SelfFinance: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 41">
      <title>{title || 'Self Finance'}</title>
      <g strokeWidth="2.21" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M6.059 7.808L2.5 8.5M38.5 1.5L22.194 5.081" />
        <path className={classes.highlight} d="M6.102 9V5.08H42V9" />
        <path d="M46 19.829V9H2v30h44V28.223" />
        <path d="M29 20h22v8H29z" />
      </g>
    </svg>
  );
};

export default SelfFinance;
