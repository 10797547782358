import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  size?: number;
};

const defaultSize = 50;

const TaxCredit: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67 67"
    >
      <title>Tax Credit</title>
      <path
        d="M65.09 33.07a32 32 0 1 1-32-32 32 32 0 0 1 32 32z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.1"
      />
      <path
        d="M39.14 42.68V46h-2.48v-3.3a9.77 9.77 0 0 1-5.56-2.8l1.9-2.33a7.5 7.5 0 0 0 5 2.17c1.63 0 2.75-.79 2.75-2.09 0-1.49-1.14-2.07-3.49-2.79-3.07-1-5.82-2-5.82-5.37 0-2.77 1.87-4.85 5.14-5.14v-3.46h2.48v3.59a11.43 11.43 0 0 1 4.69 2.11L42 28.93a7.82 7.82 0 0 0-4.39-1.57c-1.59 0-2.6.69-2.6 1.92s1.21 1.84 3.3 2.51c3.57 1.1 6.06 2 6.06 5.62 0 2.91-2 4.88-5.19 5.27"
        fillRule="evenodd"
      />
      <path d="M21.72 34.37H18a.48.48 0 0 1-.46-.46v-1a.48.48 0 0 1 .46-.47h3.75v-3.79a.45.45 0 0 1 .46-.44h1a.45.45 0 0 1 .46.44v3.77h3.75a.46.46 0 0 1 .44.47v1a.46.46 0 0 1-.44.46h-3.75v3.75a.45.45 0 0 1-.46.43h-1a.45.45 0 0 1-.46-.43z" />
    </svg>
  );
};

export default TaxCredit;
