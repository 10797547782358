import React from 'react';
import { useStyles } from './styles';
import Category from './Category';
import { Grid } from '@material-ui/core';
import { FinanceCategory, setFinanceOptionsCategory } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectHasFinanceOptions } from '../FinanceOptions/selectors';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../../routes';
import { not } from 'ramda';

const FinanceCategories: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const hasFinanceOptions = useSelector((state: RootState) => selectHasFinanceOptions(state, proposalId));
  React.useEffect(() => {
    if (not(hasFinanceOptions)) {
      dispatch(setFinanceOptionsCategory(FinanceCategory.SaveMost));
    }
  }, [hasFinanceOptions]);

  return (
    <section className={classes.root}>
      <Grid container className={classes.root} spacing={5}>
        {hasFinanceOptions && (
          <Grid item xs={12} md={6}>
            <Category category={FinanceCategory.SaveMore} label="Most popular" title="Save" subtitle="Solar Loan" />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Category category={FinanceCategory.SaveMost} title="Save Most" subtitle="Pay Cash / Self-Finance" />
        </Grid>
      </Grid>
    </section>
  );
};

export default FinanceCategories;
