import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& svg': {
          fill: '#fff',
          width: '46px',
          height: '46px',
          [theme.breakpoints.down('xs')]: {
            width: '42px',
            height: '42px',
          },
        },
      },
    }),
  );
