import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js';
import { Container, Typography, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import { Colors } from '../../../../../../../theme/Colors';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../RootState';
import { selectAnnualFinancingNetIncentives, selectAnnualUtilityBills, selectRemainingBills } from './selectors';
import { selectIsLoanOption } from '../../../../../../common/Proposals/FinanceOptions/selectors';

type Props = {
  proposalId: number;
  financeOptionId: number;
};

const SavingsChart: React.FC<Props> = ({ proposalId, financeOptionId }) => {
  const classes = useStyles();
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const horizonLength = 25;
  const annualUtilityBills = useSelector((state: RootState) =>
    selectAnnualUtilityBills(state, proposalId, horizonLength),
  );
  const annualFinancingNetIncentives = useSelector(
    (state: RootState) => selectAnnualFinancingNetIncentives(state, proposalId, financeOptionId) || {},
  );
  const remainingBills = useSelector((state: RootState) => selectRemainingBills(state, proposalId, horizonLength));
  const labels = Object.keys(annualUtilityBills).map((n) => `Year ${n}`);
  const isLoanOption = useSelector((state: RootState) => selectIsLoanOption(state, proposalId, financeOptionId));
  const theme = useTheme();

  const datasets: {}[] = [
    {
      label: 'Without Solar',
      data: Object.values(annualUtilityBills),
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      type: 'line',
      fill: false,
    },
  ];

  if (isLoanOption) {
    datasets.push({
      label: 'Solar Financing (Net Incentives)',
      data: Object.values(annualFinancingNetIncentives),
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    });
  }

  datasets.push({
    label: 'Remaining Utility Bill With Solar',
    data: Object.values(remainingBills),
    backgroundColor: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    borderWidth: 2,
  });

  const chartConfig: ChartConfiguration = {
    type: 'bar',
    data: { labels, datasets },
    options: {
      legend: { onClick: (e) => e.stopPropagation() },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: (value) => toFormattedPrice(parseInt(value as string), 0),
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              callback: (_, index) => `Year ${index + 1}`,
            },
          },
        ],
      },
      tooltips: {
        backgroundColor: Colors.SecondaryDark,
        xPadding: 16,
        yPadding: 16,
        callbacks: {
          label: (item) => `${toFormattedPrice(parseInt(item.value || '0'))}`,
        },
      },
    },
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
  }, [chartContainer, chartConfig]);

  return (
    <Container className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Annual Cash Flow
      </Typography>
      <Typography className={classes.subtitle} variant="h4">
        Save money by replacing your utility bill with solar financing.
      </Typography>
      <canvas ref={chartContainer} />
    </Container>
  );
};

export default SavingsChart;
