import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const GroundMount: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 58">
      <title>{title || 'Solar Ground Mount'}</title>
      <g strokeWidth="2.1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          className={classes.highlight}
          d="M7.393 18.644h62.41M29.448 1.829L25.49 39.415M47.798 1.829l3.958 37.586"
        />
        <path d="M33.614 56.327h9.815V39.373h-9.815zM75.106 39.373H1.936L10.86 1.896h55.323z" />
      </g>
    </svg>
  );
};

export default GroundMount;
