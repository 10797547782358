import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import NewJersey from '@solvana/proposal-tool-domain/dist/lib/StateIncentives/NewJersey';

export const selectNJTotalBenefits = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return NewJersey.totalIncentives(proposal, 25);
};

export const selectNJFirstYearBenefits = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return NewJersey.totalIncentives(proposal, 1);
};

export const selectNJRecCount = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  const totalProduction = System.totalProduction(solarOption, 25);

  return Math.round(NewJersey.recCount(totalProduction));
};

export const selectAvgSRECValue = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  const srecCount = NewJersey.incentivePlanRECTotalCount(solarOption, 15);
  const srecTotalValue = NewJersey.incentivePlanRECValueTotal(solarOption, 15);

  return srecTotalValue / srecCount;
};

export const selectSRECCount = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  return Math.floor(NewJersey.incentivePlanRECTotalCount(solarOption, 15));
};
