import React from 'react';
import { useSelector } from 'react-redux';
import { selectTaxCredit } from './selectors';
import { RootState } from '../../../../../../../RootState';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import BenefitCard from '../common/BenefitCard';
import { selectProposal } from '../../../../../../common/Proposals/selectors';
import TaxCredit from '../../../../../../../components/SolvanaIcons/TaxCredit';

type Props = {
  proposalId: number;
};

const StateTaxCredit: React.FC<Props> = ({ proposalId }) => {
  const taxCredit = useSelector((state: RootState) => selectTaxCredit(state, proposalId));
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));

  if (!proposal || taxCredit <= 0) return null;
  const { address } = proposal;

  return (
    <BenefitCard
      title={`+${toFormattedPrice(taxCredit)}`}
      subtitle={`${address.state} Tax Credit`}
      Description={() => (
        <p>
          With this system you will receive a tax credit of <strong>{toFormattedPrice(taxCredit)}</strong> in the first
          year.
        </p>
      )}
      Icon={() => <TaxCredit size={65} />}
    />
  );
};

export default StateTaxCredit;
