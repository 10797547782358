import React from 'react';
import { useStyles } from './styles';
import SelectButton from '../../../SelectButton';
import { Card, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUpdatingCart } from '../../../../../common/Proposals/selectors';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import FinancePartnerLogo from '../../../../../../components/FinancePartnerLogo';
import { RootState } from '../../../../../../RootState';
import { selectMonthlyPaymentNetIncentives } from '../../../../../common/Proposals/FinanceOptions/selectors';
import {
  FinanceOptionType,
  isLoanFinanceOption,
  LoanFinanceOption,
} from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { updateCart } from '../../../../../common/Proposals/actions';
import { selectSolarOptionsPriceNetFirstYear } from '../../../../pg-choose-your-financing/Financing/FinanceOptions/common/selectors';
import { selectHasStateIncentives } from '../selectors';
import { selectCartMonthlyPaymentForLoanOption } from '../../../../pg-choose-your-financing/Financing/FinanceOptions/LoanOption/selectors';
import { selectCartTotal } from '../CashFlow/FirstYearCashFlow/selectors';
import FinancingLegal from './FinancingLegal';

type Props = {
  financeOptionId: number;
  proposal: Proposal;
};

const FinanceOption: React.FC<Props> = ({ financeOptionId, proposal }) => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const hasStateIncentives = useSelector((state: RootState) => selectHasStateIncentives(state, proposal.id));
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  const isSelected = proposal.cart?.financeOption === financeOptionId;
  const cashPrice = useSelector((state: RootState) => {
    return financeOption?.type === FinanceOptionType.Cash ? selectCartTotal(state, proposal.id) : 0;
  });
  const cashPriceNetFirstYear = useSelector((state: RootState) => {
    return financeOption?.type === FinanceOptionType.Cash ? selectSolarOptionsPriceNetFirstYear(state, proposal.id) : 0;
  });
  const monthlyPayment = useSelector((state: RootState) =>
    selectCartMonthlyPaymentForLoanOption(state, proposal.id, financeOptionId),
  );
  const monthlyPaymentNetIncentives = useSelector((state: RootState) =>
    selectMonthlyPaymentNetIncentives(state, proposal.id, financeOptionId),
  );

  if (!financeOption) return null;

  const onSubmit = () => {
    updateCart(dispatch, proposal.id, { ...proposal.cart, financeOption: financeOptionId });
  };

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.firstRow}>
        <FinancePartnerLogo financeOption={financeOption} />

        {FinanceOptionType.Cash === financeOption.type && (
          <>
            <hgroup>
              <Typography variant="h5">{toFormattedPrice(cashPrice)}</Typography>
              <Typography variant="body2">
                {`${toFormattedPrice(cashPriceNetFirstYear)} net first year incentives`}
              </Typography>
            </hgroup>
          </>
        )}

        {isLoanFinanceOption(financeOption.type) && (
          <>
            <hgroup>
              <Typography variant="h6">{(financeOption?.termInMonths || 0) / 12} Year Loan</Typography>

              <Typography className={classes.subtitle} variant="subtitle2">
                {financeOption?.rate || '--'}% APR
              </Typography>
            </hgroup>

            <hgroup>
              <Typography className={classes.monthlyPayment} variant="h5">
                {`${toFormattedPrice(monthlyPayment)}/mo.`}
                <sup>*</sup>
              </Typography>
              {hasStateIncentives && (
                <Typography className={classes.subtitle} variant="h4">
                  {`${toFormattedPrice(monthlyPaymentNetIncentives)}/mo. net incentives`}
                </Typography>
              )}
            </hgroup>
          </>
        )}

        <div className={classes.buttonContainer}>
          <SelectButton
            disabled={isUpdatingCart}
            isSelected={isSelected}
            buttonClassName={classes.button}
            onClick={onSubmit}
          >
            Select
          </SelectButton>
        </div>
      </div>
      {isLoanFinanceOption(financeOption.type) && (
        <FinancingLegal loan={financeOption as LoanFinanceOption} proposal={proposal} />
      )}
    </Card>
  );
};

export default FinanceOption;
