export const FACTORS = [
  'Historical electrical consumption',
  'Fire safety requirements',
  'Azimuth',
  'Module orientation',
  'Pitch of array',
  'Array type: (Carport, Rooftop Canopy, Flush Mount, Ground Mount)',
  'Number of sub arrays',
  'Location (Latitude & Longitude)',
  'Soiling',
  'Shading',
  'Annual snow',
  'Module mismatch',
  'Wiring inefficiency',
  'Conduit sizing',
  'Connections',
  'Light-Induced Degradation',
  'Nameplate Rating',
  'Age of system',
  'System availability',
  'System size',
  'Roof size',
  'NEC 2017',
  'Module warranty',
  'Module efficiency',
  'Module color',
  'Racking manufacturer installation guidelines',
  'Module manufacturer installation guidelines',
  'Inverter manufacturer installation guidelines',
  'Inverter location (outdoor vs. indoor)',
  'Industry best practices as they evolve',
  'DC to AC Size Ratio',
  'Inverter clipping',
  'Inverter efficiency',
  'Electrical rate ($/kWh)',
  'Tariff class',
  'Rule 21 compliance (where applicable)',
  'Property setbacks',
  'Electrical engineering',
  'Structural engineering',
];
