import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../RootState';
import { selectCartAddersData, selectCartESSAdderData, selectCartSolarOptionData } from '../../common/Cart/selectors';
import ProductCard from './ProductCard';
import { toFormattedPrice } from '../../../../utils/helpers';
import {
  selectCartMonthlyPaymentNetITC,
  selectCartTotalFinancedPrice,
  selectHasMonthlyPayment,
} from '../../../common/Proposals/Cart/selectors';
import { selectProposal } from '../../../common/Proposals/selectors';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { selectFinanceOption } from '../../../common/Proposals/FinanceOptions/selectors';
import { LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import ReAmortizationExplanation from '../../pg-choose-your-financing/Financing/FinanceOptions/LoanOption/ReAmortizationExplanation';
import IncentivesDisclaimer from '../../common/LearnMore/BenefitsSummary/IncentivesDisclaimer';
import SimpleDagger from '../../../../components/SimpleDagger';

type Props = {
  proposalId: number;
};

const Summary: React.FC<Props> = ({ proposalId }) => {
  const classes = useStyles()();
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const financeOption = useSelector((state: RootState) => selectFinanceOption(state, proposalId));
  const solarOption = useSelector((state: RootState) => selectCartSolarOptionData(state, proposalId));
  const adders = useSelector((state: RootState) => selectCartAddersData(state, proposalId));
  const essAdder = useSelector((state: RootState) => selectCartESSAdderData(state, proposalId));
  const hasMonthlyPayment = useSelector((state: RootState) => selectHasMonthlyPayment(state, proposalId));
  const cartTotal = useSelector((state: RootState) => selectCartTotalFinancedPrice(state, proposalId));
  const cartMonthlyPayment = useSelector((state: RootState) =>
    selectCartMonthlyPaymentNetITC(state, proposalId),
  );

  if (!proposal || !financeOption) return null;

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant="h6">
        Summary
      </Typography>

      <List className={classes.list}>
        {solarOption && <ProductCard product={solarOption} />}

        {adders.map((adder) => (
          <ProductCard product={adder} key={`adder-${adder.id}`} />
        ))}

        {essAdder && <ProductCard product={essAdder} />}
      </List>

      <div className={classes.summaryContainer}>
        <Typography className={classes.totalTitle} variant="h6">
          Total
        </Typography>

        <Typography className={classes.totalPrice} variant="h5">
          {toFormattedPrice(cartTotal)}
        </Typography>

        {hasMonthlyPayment ? (
          <>
            <Typography className={classes.monthlyPayment} variant="body1">
              {toFormattedPrice(cartMonthlyPayment)}/mo.<sup>*</sup>
            </Typography>

            {hasMonthlyPayment && (
              <ReAmortizationExplanation proposal={proposal} loanOption={financeOption as LoanFinanceOption} sup="*" />
            )}

            <List className={classes.footerList}>
              <ListItem>
                <IncentivesDisclaimer />
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  <SimpleDagger double /> Payment for the first 17 months. Payment amount will remain the same if a{' '}
                  {ITC.percentage(proposal) * 100}% voluntary payment is applied before month 17. Please see the summary
                  total for total monthly payments.
                </Typography>
              </ListItem>
            </List>
          </>
        ) : (
          <List className={classes.footerList}>
            <ListItem>
              <IncentivesDisclaimer />
            </ListItem>
          </List>
        )}
      </div>
    </section>
  );
};

export default Summary;
