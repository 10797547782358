import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { State } from '@solvana/state-domain';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import { selectAddressState, selectESSStateIncentivesTotal } from './selectors';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import { selectSMARTTerm } from '../../BenefitsSummary/SolarIncentives/MassSMARTBenefits/selectors';

type Props = {
  essAdderId: number;
  proposalId: number;
};

const ESSStateIncentives: React.FC<Props> = ({ proposalId, essAdderId }) => {
  const classes = useStyles()();
  const state = useSelector((state: RootState) => selectAddressState(state, proposalId));
  const totalIncentives = useSelector((state: RootState) =>
    selectESSStateIncentivesTotal(state, proposalId, essAdderId, 25),
  );

  const smartTerm = useSelector((state: RootState) => selectSMARTTerm(state, proposalId));

  switch (state) {
    case State.MA:
      return (
        <Typography variant="subtitle1" className={classes.title}>
          SMART Incentives ({smartTerm}-Year Total): <strong>-{toFormattedPrice(totalIncentives)}</strong>
        </Typography>
      );
    default:
      return null;
  }
};

export default ESSStateIncentives;
