import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
    tree: {
      stroke: theme.palette.primary.main,
      fill: theme.palette.background.paper,
    },
  }),
);

const defaultWidth = 50;

const SomeShading: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
      <title>{title || 'Some Shading'}</title>

      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g className={classes.highlight} strokeWidth="2.94">
          <path d="M84.32 21.236c-4.865 4.867-12.83 4.79-17.79-.168-4.96-4.96-5.035-12.924-.168-17.791M58.261 14.699L51 15.85M72.899 29.338l-1.15 7.26M59.963 3.95L56.139 2M63.202 24.397l-3.035 3.035M83.649 27.635l1.948 3.825" />
        </g>
        <g strokeWidth="2.1">
          <path d="M20.716 52L2 66.082M35.92 66.93v19.547H5.512V66.931" />
          <path className={classes.detail} d="M20.716 56.723l-7.151 5.38" />
          <path d="M20.716 52l18.716 14.082" />
          <path className={classes.detail} d="M20.716 56.723l7.151 5.38" />
        </g>
        <path
          d="M46 32l9.474 19.343H51.13l7.5 15.314-3.947-.001L61 79.552H49.157V86h-5.525l-.001-6.448H31l6.316-12.896h-3.948l7.499-15.313h-4.34L46 32z"
          className={classes.tree}
          strokeWidth="2.352"
        />
      </g>
    </svg>
  );
};

export default SomeShading;
