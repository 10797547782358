import React from 'react';
import { LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { useDispatch } from 'react-redux';
import { Link, Typography } from '@material-ui/core';
import { setLearnMoreItemForFinancingFAQ } from '../../../../../common/LearnMore/actions';
import { useStyles } from './styles';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { getFinancingLegalText } from '../../../../../common/LearnMore/BenefitsSummary/FinanceOption/FinancingLegal/getFinancingLegalText';

type Props = {
  proposal: Proposal;
  loanOption: LoanFinanceOption;
  sup?: string;
};

const ReAmortizationExplanation: React.FC<Props> = ({ loanOption, proposal, sup }) => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const principle = Pricing.cartTotal(proposal) - loanOption.downPayment;
  const itcPercentage = ITC.percentage(proposal);
  const legalText = getFinancingLegalText(loanOption, principle, itcPercentage);

  return (
    <Typography className={classes.root} variant="subtitle2">
      {sup && <sup>{sup}</sup>} {legalText}
      <Link
        className={classes.link}
        onClick={() =>
          dispatch(
            setLearnMoreItemForFinancingFAQ({
              proposalId: proposal.id,
              id: proposal.id,
            }),
          )
        }
      >
        Learn More
      </Link>
    </Typography>
  );
};

export default ReAmortizationExplanation;
