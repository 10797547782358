import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { isDarkMode } from '../../../../theme/helpers';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 'auto', 6),
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : Colors.GreyFive,
      border: `solid 1px ${Colors.GreyThree}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      maxWidth: '600px',
    },
    totalContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left',
      },
    },
    cartTotal: {
      borderTop: `solid 1px ${isDarkMode(theme) ? Colors.GreyFive : Colors.Dark}`,
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    incentive: {
      color: theme.palette.success.main,
    },
  }),
);
