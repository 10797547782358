import React from 'react';
import { Card } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../RootState';
import { selectCartTotal } from '../../common/LearnMore/BenefitsSummary/CashFlow/FirstYearCashFlow/selectors';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { selectStateIncentivesTotal } from '../../common/LearnMore/BenefitsSummary/CashFlow/selectors';
import { MACRS } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/MACRS';
import { selectCartTotalNetFirstYearIncentives } from '../../../common/Proposals/Cart/selectors';
import { LineItem, LineItemTotal } from '../../../../components/Accounting';
import SimpleDagger from '../../../../components/SimpleDagger';

type Props = {
  proposal: Proposal;
};

const InstallPriceSummary: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const total = useSelector((state: RootState) => selectCartTotal(state, proposal.id));
  const itcTotal = ITC.value(total, proposal);
  const itcRate = ITC.percentage(proposal);
  const macrsCashValue = MACRS.value(total, proposal);
  const firstYearStateIncentives = useSelector((state: RootState) => selectStateIncentivesTotal(state, proposal.id, 1));
  const totalPriceNetFirstYearIncentives = useSelector((state: RootState) =>
    selectCartTotalNetFirstYearIncentives(state, proposal.id),
  );

  return (
    <Card elevation={0} className={classes.root}>
      <div className={classes.totalContainer}>
        <LineItem title="Total Installation Price" ignoreSign value={total} />
        <LineItem
          title={`${itcRate * 100}% Federal Tax Credit in First Year`}
          flipSavingsSign
          value={itcTotal}
          Sup={SimpleDagger}
        />

        {macrsCashValue > 0 && (
          <LineItem title="MACRS Cash Value" flipSavingsSign value={macrsCashValue} Sup={SimpleDagger} />
        )}

        {firstYearStateIncentives > 0 && (
          <LineItem
            title="First Year State Incentives"
            flipSavingsSign
            value={firstYearStateIncentives}
            Sup={SimpleDagger}
          />
        )}
        <LineItemTotal title="Price Net First Year Incentives" ignoreSign value={totalPriceNetFirstYearIncentives} />
      </div>
    </Card>
  );
};

export default InstallPriceSummary;
