import { proposalToolApi } from '../../lib/api/proposalToolApi';
import { Dispatch } from 'redux';
import { setOneProposal } from '../common/Proposals/actions';

export enum Actions {
  SetIsFetching = 'goSolarPageUI/SetIsFetching',
  SetError = 'goSolarPageUI/SetError',
  SetShowUtilityModal = 'goSolarPageUI/SetShowUtilityModal',
}

export const setIsFetching = (isFetching: boolean) => ({
  type: Actions.SetIsFetching,
  isFetching,
});

export const setError = (error: null | string) => ({
  type: Actions.SetError,
  error,
});

export const setShowUtilityModal = (showModal: boolean) => ({
  type: Actions.SetShowUtilityModal,
  showModal,
});

export const fetchProposal = (id: string, dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get(`/v1/proposals/${id}`)
    .then((p) => {
      dispatch(setOneProposal(p.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};
