import { FinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { selectProposal, SelectProposalState } from '../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { isLoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { MonthlyPaymentsResult } from '@solvana/proposal-tool-domain/dist/lib/Financing/monthlyPayments';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { selectCheckoutPrice } from '../../../CheckoutPages/pg-checkout/StripeCheckoutForm/CheckoutForm/selectors';
import { MACRS } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/MACRS';

export interface SelectMonthlyPaymentNetIncentives {
  (state: SelectProposalState, proposalId: number, financeOptionId: number): number;
}

export const selectMonthlyPaymentNetIncentives: SelectMonthlyPaymentNetIncentives = (
  state,
  proposalId,
  financeOptionId,
) => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return Pricing.avgMonthlyPaymentNetAllIncentives({
    ...proposal,
    cart: { ...proposal.cart, financeOption: financeOptionId },
  });
};

export const selectMonthlyMacrValue: SelectMonthlyPaymentNetIncentives = (state, proposalId, financeOptionId) => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return 0;

  const price = Pricing.cartTotal(proposal);
  const totalMacrValue = MACRS.value(price, proposal);

  return totalMacrValue / (financeOption.termInMonths || 1);
};

export interface SelectMonthlyPaymentsForSolarOptions {
  (state: SelectProposalState, proposalId: number, financeOptionId: number): MonthlyPaymentsResult;
}

export const selectMonthlyPaymentsForSolarOptions: SelectMonthlyPaymentsForSolarOptions = (
  state,
  proposalId,
  financeOptionId,
) => {
  const defaultResult = { initialPayment: 0, reAmortizedPayment: 0 } as MonthlyPaymentsResult;
  const checkoutPrice = selectCheckoutPrice(state, proposalId);

  const proposal = selectProposal(state, proposalId);
  if (!proposal) return defaultResult;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return defaultResult;
  const loan = financeOption as LoanFinanceOption;

  const principal = Pricing.cartTotal(proposal);
  const principleMinusDownPayment = principal - checkoutPrice;

  return Financing.monthlyPayments(principleMinusDownPayment, loan);
};

export const selectIsLoanOption = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption) return false;
  return isLoanFinanceOption(financeOption.type);
};

export const selectSolarOptionsMonthlyPaymentNetITC: SelectMonthlyPaymentNetIncentives = (
  state,
  proposalId,
  financeOptionId,
) => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption) return 0;
  if (!isLoanFinanceOption(financeOption.type)) return 0;

  const proposalWithFinanceOption = {
    ...proposal,
    cart: { ...proposal.cart, financeOption: financeOptionId },
  };

  return Pricing.monthlyPaymentForCartNetITC(proposalWithFinanceOption);
};

export const selectFinanceOption = (state: SelectProposalState, proposalId: number): FinanceOption | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return proposal.financeOptions.find((fo) => fo.id === proposal.cart.financeOption);
};
