import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';

type PathHandlerFunc = {
  (pathHandlerId: string): string;
};

export type ProgressSlice = {
  title?: string;
  path: string;
  pathHandler: PathHandlerFunc;
};

type Steps = {
  count: number;
  title?: string;
  path: string;
};

type Props = {
  pathHandlerId?: number;
  pages: ProgressSlice[];
};

const ProgressBar: React.FC<Props> = ({ pathHandlerId, pages }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();

  const getSteps = (): Steps[] => {
    return pages.map(({ title, path }, index) => ({
      count: index + 1,
      title,
      path,
    }));
  };

  const getCurrentStep = (): number => {
    for (let i = 0; i < pages.length; i += 1) {
      if (match && match.path === pages[i].path) {
        return i + 1;
      }
    }
    return 0;
  };

  const steps = getSteps();
  const currentStep = getCurrentStep();

  return (
    <ul className={classes.root}>
      {steps.map((step, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li
          className={clsx({
            [classes.progress]: true,
            [classes.completedProgressItem]: step.count < currentStep,
            [classes.incompleteProgressItem]: step.count >= currentStep,
          })}
          onClick={() => {
            if (step.count < currentStep) {
              const id = (pathHandlerId === undefined ? -1 : pathHandlerId).toString();
              history.push(pages[i].pathHandler(id));
            }
          }}
          key={step.count}
        >
          {step.count === currentStep && (
            <div
              className={clsx({
                [classes.currentStep]: currentStep !== 1,
                [classes.firstCurrentStep]: currentStep === 1,
              })}
            >
              {step.title && (
                <Typography
                  variant="subtitle1"
                  className={clsx({
                    [classes.subtitles]: currentStep !== step.count,
                    [classes.currentStepSubtitle]: currentStep === step.count,
                  })}
                >
                  {step.title}
                </Typography>
              )}
            </div>
          )}

          {step.count !== currentStep && step.title && (
            <Typography
              variant="subtitle1"
              className={clsx(classes.subtitles, {
                [classes.futureStepSubtitle]: currentStep < step.count,
              })}
            >
              {step.title}
            </Typography>
          )}
        </li>
      ))}
    </ul>
  );
};

export default React.memo(ProgressBar);
