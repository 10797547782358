import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(6),
      width: '100%',
      '& hgroup': {
        marginBottom: theme.spacing(2),
      },
    },
    title: {
      margin: 0,
    },
    detail: {
      margin: 0,
    },
    card: {
      padding: theme.spacing(2),
    },
  }),
);
