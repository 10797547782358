import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    highlight: {
      stroke: theme.palette.primary.main,
      fill: 'none',
    },
  }),
);

const defaultWidth = 50;

const Snowfall: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 83">
      <title>{title || 'Snowfall'}</title>
      <g strokeWidth="2.1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M55.299 15.063c.221-.88.351-1.797.351-2.746 0-6.222-5.044-11.267-11.267-11.267-4.406 0-8.212 2.536-10.063 6.222a11.213 11.213 0 0 0-4.67-1.022c-5.326 0-9.778 3.7-10.954 8.667h-5.08c-6.94 0-12.566 5.626-12.566 12.566S6.676 40.05 13.616 40.05h39.868c6.94 0 12.566-5.626 12.566-12.567 0-6.321-4.674-11.538-10.751-12.42z" />
        <path
          className={classes.highlight}
          d="M16.05 48.05v19M13.55 65.05l2.5-2.5 2.5 2.5M13.55 50.55l2.5 2.5 2.5-2.5M25.55 57.55h-19M8.55 55.05l2.5 2.5-2.5 2.5M23.05 55.05l-2.5 2.5 2.5 2.5M51.414 48.774l7.271 17.553M55.61 65.436l1.354-3.267 3.266 1.353M50.06 52.04l3.268 1.353 1.353-3.267M63.826 53.914l-17.553 7.272M47.164 58.11l3.267 1.355-1.353 3.266M60.56 52.561l-1.353 3.267 3.267 1.353M42.363 64.036l-8.644 16.92M32.403 78.037l3.363-1.089 1.09 3.363M39 65.124l1.09 3.364 3.362-1.089M46.501 76.818l-16.92-8.644M32.5 66.858l1.09 3.363-3.365 1.089M45.412 73.453l-3.363 1.09 1.09 3.363"
        />
      </g>
    </svg>
  );
};

export default Snowfall;
