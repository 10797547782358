import { Reducer } from 'redux';
import {
  Actions,
  appendRFAEntity,
  resetDefaultState,
  setAddressField,
  setAddressState,
  setCustomerProfileSubmitError,
  setHasResolvedRFAFetch,
  setIsSubmittingProfile,
  setIsSubmittingRFA,
  setMailingAddressField,
  setMailingAddressState,
  setRFAAddressIsMailingAddress,
  setRFAEntities,
  setRFASubmitError,
  setRFASubmitSuccess,
  setRFASurveySingleAnswer,
  setShowUtilityAPIIFrame,
  toggleRFASurveyMultiAnswer,
} from './actions';
import { RFAQuestion } from './common/rfaSurveyQuestions';
import { Address, RFA } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain/dist';

type RFAActions = ReturnType<typeof setRFASurveySingleAnswer> &
  ReturnType<typeof setAddressField> &
  ReturnType<typeof setAddressState> &
  ReturnType<typeof setCustomerProfileSubmitError> &
  ReturnType<typeof setIsSubmittingProfile> &
  ReturnType<typeof setMailingAddressField> &
  ReturnType<typeof setMailingAddressState> &
  ReturnType<typeof setHasResolvedRFAFetch> &
  ReturnType<typeof setIsSubmittingRFA> &
  ReturnType<typeof setRFASubmitError> &
  ReturnType<typeof setRFAAddressIsMailingAddress> &
  ReturnType<typeof setShowUtilityAPIIFrame> &
  ReturnType<typeof setRFAEntities> &
  ReturnType<typeof appendRFAEntity> &
  ReturnType<typeof resetDefaultState> &
  ReturnType<typeof setRFASubmitSuccess> &
  ReturnType<typeof toggleRFASurveyMultiAnswer>;

type Survey = {
  [key in RFAQuestion]?: string[];
};

type RFAState = {
  survey: Survey;
  address: Address;
  rfaAddressIsMailingAddress: boolean;
  mailingAddress: Address;
  isSubmittingProfile: boolean;
  isSubmittingRFA: boolean;
  submitSuccess: boolean;
  showUtilityAPIIFrame: boolean;
  hasResolvedFetch: boolean;
  customerSubmitProfileError: string | null;
  rfaSubmitError: string | null;
  entities: RFA[];
};

export type SelectRFAState = {
  rfa: RFAState;
};

const DEFAULT_STATE: RFAState = {
  mailingAddress: {
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: State.NJ,
  },
  survey: {},
  isSubmittingProfile: false,
  isSubmittingRFA: false,
  hasResolvedFetch: false,
  showUtilityAPIIFrame: false,
  submitSuccess: false,
  customerSubmitProfileError: null,
  rfaSubmitError: null,
  rfaAddressIsMailingAddress: false,
  entities: [],
  address: {
    address1: '',
    address2: '',
    city: '',
    state: State.NJ,
    zip: '',
  },
};

const handleAddSurveySingleAnswer = (state: RFAState, action: RFAActions): RFAState => ({
  ...state,
  survey: {
    ...state.survey,
    [action.question]: [action.answer],
  },
});

const handleToggleSurveyMultiAnswer = (state: RFAState, action: RFAActions): RFAState => {
  const answers = state.survey[action.question] || [];
  const index = answers.findIndex((a) => a === action.answer);
  const hasAnswer = index >= 0;

  if (hasAnswer) {
    return {
      ...state,
      survey: {
        ...state.survey,
        [action.question]: [...answers.slice(0, index), ...answers.slice(index + 1)],
      },
    };
  }

  return {
    ...state,
    survey: {
      ...state.survey,
      [action.question]: [...answers, action.answer],
    },
  };
};

const rfa: Reducer<RFAState, RFAActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetRFASurveySingleAnswer:
      return handleAddSurveySingleAnswer(state, action);

    case Actions.ToggleRFASurveyMultiAnswer:
      return handleToggleSurveyMultiAnswer(state, action);

    case Actions.ResetDefaultState:
      return DEFAULT_STATE;

    case Actions.SetCustomerProfileSubmitError:
      return {
        ...state,
        customerSubmitProfileError: action.customerProfileSubmitError,
      };

    case Actions.SetRFASubmitSuccess:
      return {
        ...state,
        submitSuccess: action.submitSuccess,
      };

    case Actions.SetShowUtilityAPIIFrame:
      return {
        ...state,
        showUtilityAPIIFrame: action.showUtilityAPIIFrame,
      };

    case Actions.SetRFASubmitError:
      return {
        ...state,
        rfaSubmitError: action.rfaSubmitError,
      };

    case Actions.SetRFAAddressIsMailingAddress:
      return {
        ...state,
        rfaAddressIsMailingAddress: action.rfaAddressIsMailingAddress,
      };

    case Actions.SetRFAEntities:
      return {
        ...state,
        entities: action.entities,
      };

    case Actions.AppendRFAEntity:
      return {
        ...state,
        entities: [...state.entities, action.rfa],
      };

    case Actions.SetAddressState:
      return {
        ...state,
        address: {
          ...state.address,
          state: action.state,
        },
      };

    case Actions.SetAddressField:
      return {
        ...state,
        address: {
          ...state.address,
          [action.field]: action.value,
        },
      };

    case Actions.SetMailingAddressState:
      return {
        ...state,
        mailingAddress: {
          ...state.mailingAddress,
          state: action.state,
        },
      };

    case Actions.SetIsSubmittingProfile:
      return {
        ...state,
        isSubmittingProfile: action.isSubmittingProfile,
      };

    case Actions.SetIsSubmittingRFA:
      return {
        ...state,
        isSubmittingRFA: action.isSubmittingRFA,
      };

    case Actions.SetHasResolvedRFAFetch:
      return {
        ...state,
        hasResolvedFetch: action.hasResolvedFetch,
      };

    case Actions.SetMailingAddressField:
      return {
        ...state,
        mailingAddress: {
          ...state.mailingAddress,
          [action.field]: action.value,
        },
      };

    default:
      return state;
  }
};

export default rfa;
