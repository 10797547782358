import {
  FinanceOption,
  FinanceOptionType,
  isLoanFinanceOption,
  LoanFinanceOption,
} from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { PanelCatalogState } from 'containers/common/Catalog/Panels/reducer';
import { ProposalsState } from 'containers/common/Proposals/reducer';
import { selectLoanFinanceOptions, selectProposal, SelectProposalState } from 'containers/common/Proposals/selectors';
import { toFormattedPrice } from 'utils/helpers';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { AdderCatalogState } from '../../../common/Catalog/Adders/reducer';
import { BatteryCatalogState } from '../../../common/Catalog/Batteries/reducer';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { IDWithPrice } from '@solvana/proposal-tool-domain/dist/lib/Pricing/types';
import {
  selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartESSAdderMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartESSAdderPriceNetFirstYearIncentives,
  selectCartFinanceOption,
  selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartSolarOptionPriceNetFirstYearIncentives,
} from '../../../common/Proposals/Cart/selectors';
import { selectAdderPriceNetFirstYearIncentives } from '../../../common/Proposals/Adders/selectors';
import {
  selectESSAdderITCValue,
  selectESSAdderMACRSValue,
  selectESSAdderTotalStateIncentives,
} from '../../../common/Proposals/ESSAdders/selectors';
import {
  selectSolarOptionITCValue,
  selectSolarOptionMACRSValue,
} from '../../../common/Proposals/SolarOptions/selectors';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { selectStateIncentives } from '../LearnMore/BenefitsSummary/CashFlow/selectors';
import { selectSolarOptionTotalPBIs } from '../../../common/Proposals/SolarOptions/selectors/selectSolarOptionTotalPBIs';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { MACRS } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/MACRS';
import { MonthlyPaymentsResult } from '@solvana/proposal-tool-domain/dist/lib/Financing/monthlyPayments';
import { selectCartSolarOptionMonthlyPayment } from '../../../common/Proposals/Cart/selectors/selectCartSolarOptionMonthlyPayment';
import { selectCartAdderMonthlyPayment } from '../../../common/Proposals/Cart/selectors/selectCartAdderMonthlyPrice';
import { selectCartESSAdderMonthlyPayment } from '../../../common/Proposals/Cart/selectors/selectCartESSAdderMonthlyPrice';
import { InverterCatalogState } from 'containers/common/Catalog/Inverters/reducer';

export interface ProductSummary {
  id: number;
  firstYearStateIncentives: number;
  stateTaxCredit?: number;
  imageUrl: string;
  itcValue: number;
  macrsValue: number;
  monthlyPayment: MonthlyPaymentsResult | null;
  monthlyPaymentNetIncentivesOverTerm: number;
  monthlyPaymentTitle?: string;
  price: number;
  priceNetFirstYearIncentives: number;
  title: string;
  subtitle?: string;
}

const getAdderMonthlyPaymentTitle = (
  adderId: number,
  quantity: number,
  adders: IDWithPrice[],
  selectedFinanceOption: FinanceOption | undefined,
  loanFinanceOptions: LoanFinanceOption[],
): string | undefined => {
  if (!selectedFinanceOption) {
    const lowestPayment = Pricing.lowestMonthlyPaymentForAdder(adderId, quantity, adders, loanFinanceOptions);
    if (!lowestPayment) return undefined;
    return `*As low as ${toFormattedPrice(lowestPayment.monthlyPayment)}/mo.`;
  }

  const principle = Pricing.adderPrice(adderId, quantity, adders);

  if (isLoanFinanceOption(selectedFinanceOption.type)) {
    const loanOption = selectedFinanceOption as LoanFinanceOption;
    const payment = Financing.monthlyPaymentNetITC(principle, loanOption);
    return `${toFormattedPrice(payment)}/mo.`;
  }

  return undefined;
};

const getESSAdderMonthlyPaymentTitle = (proposal: {
  cart: {
    financeOption?: number | null;
    essAdder?: number | null;
  };
  tax: {
    entity: TaxEntity;
    installationYear: number;
    rate?: number;
  };
  essAdders: {
    id: number;
    price: number;
  }[];
  financeOptions: FinanceOption[];
}): string | undefined => {
  const selectedFinanceOption = proposal.financeOptions.find((fo) => fo.id === proposal.cart.financeOption);

  if (!selectedFinanceOption) {
    const lowestPayment = Pricing.lowestMonthlyPaymentForESSAdder(proposal);
    if (!lowestPayment) return undefined;
    return `*As low as ${toFormattedPrice(lowestPayment.monthlyPayment)}/mo.`;
  }

  if (isLoanFinanceOption(selectedFinanceOption.type)) {
    const financedPrice = Pricing.essAdderPrice(proposal);
    const loanOption = selectedFinanceOption as LoanFinanceOption;
    const payment = Financing.monthlyPaymentNetFedIncentives(financedPrice, loanOption, proposal);
    return `${toFormattedPrice(payment)}/mo.`;
  }

  return undefined;
};

const getSolarOptionMonthlyPaymentTitle = (proposal: {
  cart: {
    financeOption?: number | null;
    solarOption?: number | null;
  };
  tax: {
    entity: TaxEntity;
    installationYear: number;
    rate?: number;
  };
  solarOptions: {
    id: number;
    price: number;
  }[];
  financeOptions: FinanceOption[];
}): string | undefined => {
  const selectedFinanceOption = proposal.financeOptions.find((fo) => fo.id === proposal.cart.financeOption);

  if (!selectedFinanceOption) {
    const lowestPayment = Pricing.lowestMonthlyPaymentForSolarOption(proposal);
    if (!lowestPayment) return undefined;
    return `*As low as ${toFormattedPrice(lowestPayment.monthlyPayment)}/mo.`;
  }

  if (isLoanFinanceOption(selectedFinanceOption.type)) {
    const payment = Pricing.solarOptionMonthlyPaymentNetFedIncentives(proposal);
    return `${toFormattedPrice(payment)}/mo.`;
  }

  return undefined;
};

interface SelectSolarOptionState {
  proposals: ProposalsState;
  panelCatalog: PanelCatalogState;
  inverterCatalog: InverterCatalogState;
}

export const selectCartSolarOptionData = (
  state: SelectSolarOptionState,
  proposalId: number,
): ProductSummary | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  const { cart } = proposal;

  const solarOption = proposal.solarOptions.find((so) => so.id === cart.solarOption);
  if (!solarOption) return undefined;
  const panelCount = solarOption.subArrays.reduce((acc, a) => (acc += a.panelCount), 0);

  const inverterCount = solarOption.inverters.reduce((acc, i) => (acc += i.quantity), 0);

  const financeOption = proposal.financeOptions.find((fo) => fo.id === cart.financeOption);
  if (!financeOption) {
    const cashOption = proposal.financeOptions.find((fo) => fo.type === FinanceOptionType.Cash);
    if (!cashOption) return undefined;
  }

  const price = Pricing.solarOptionPrice(proposal);

  const panelId = solarOption.subArrays.length ? solarOption.subArrays[0].productId : null;
  const solarOptionCatalog = state.panelCatalog.entities.find((p) => p.id === panelId);
  if (!solarOptionCatalog) return undefined;

  const inverterId = solarOption.inverters.length ? solarOption.inverters[0].productId : null;
  const inverterOptionCatalog = state.inverterCatalog.entities.find((i) => i.id === inverterId);
  if (!inverterOptionCatalog) return undefined;

  return {
    id: solarOption.id,
    firstYearStateIncentives: selectSolarOptionTotalPBIs(state, proposalId, solarOption.id, 1),
    stateTaxCredit: selectStateIncentives(state, proposalId, 1).taxCredit,
    imageUrl: solarOptionCatalog.photoUrls.length ? solarOptionCatalog.photoUrls[0] : '',
    itcValue: selectSolarOptionITCValue(state, proposalId, solarOption.id),
    macrsValue: selectSolarOptionMACRSValue(state, proposalId, solarOption.id),
    monthlyPayment: selectCartSolarOptionMonthlyPayment(state, proposalId),
    monthlyPaymentNetIncentivesOverTerm: selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm(state, proposalId),
    monthlyPaymentTitle: getSolarOptionMonthlyPaymentTitle(proposal),
    price,
    priceNetFirstYearIncentives: selectCartSolarOptionPriceNetFirstYearIncentives(state, proposalId),
    title: `${panelCount} x ${solarOptionCatalog.brand} - ${solarOptionCatalog.model}`,
    subtitle: `${inverterCount} x ${inverterOptionCatalog.brand} - ${inverterOptionCatalog.model}`,
  };
};

type SelectAddersState = { adderCatalog: AdderCatalogState } & SelectProposalState;

interface SelectCartAddersData {
  (state: SelectAddersState, proposalId: number): ProductSummary[];
}

export const selectCartAddersData: SelectCartAddersData = (state, proposalId: number) => {
  const result = [] as ProductSummary[];
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return result;

  let selectedFinanceOption = selectCartFinanceOption(state, proposalId);
  if (!selectedFinanceOption) {
    if (!selectedFinanceOption) {
      const cashOption = proposal.financeOptions.find((fo) => fo.type === FinanceOptionType.Cash);
      if (!cashOption) return result;

      selectedFinanceOption = cashOption;
    }
  }

  const loanFinanceOptions = selectLoanFinanceOptions(state, proposalId);

  for (let i = 0; i < proposal.cart.selectedAdders.length; i += 1) {
    const selectedAdder = proposal.cart.selectedAdders[i];
    const adder = proposal.adders.find((a) => a.id === selectedAdder.adderId);
    if (!adder) continue;

    const adderCatalog = state.adderCatalog.entities.find((e) => e.id === adder.productId);
    if (!adderCatalog) continue;

    const price = Pricing.adderPrice(adder.id, selectedAdder.quantity, proposal.adders);

    result.push({
      id: adder.id,
      itcValue: adder.isITCEligible ? ITC.value(price, proposal) : 0,
      macrsValue: adder.isITCEligible ? MACRS.value(price, proposal) : 0,
      firstYearStateIncentives: 0,
      monthlyPayment: selectCartAdderMonthlyPayment(state, proposalId, adder.id),
      monthlyPaymentNetIncentivesOverTerm: selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm(
        state,
        proposalId,
        adder.id,
      ),
      monthlyPaymentTitle: getAdderMonthlyPaymentTitle(
        adder.id,
        selectedAdder.quantity,
        proposal.adders,
        selectedFinanceOption,
        loanFinanceOptions,
      ),
      price,
      priceNetFirstYearIncentives: selectAdderPriceNetFirstYearIncentives(state, proposalId, adder.id, 1),
      title: `${adderCatalog.brand} - ${adderCatalog.model}`,
      imageUrl: adderCatalog.photoUrls.length ? adderCatalog.photoUrls[0] : '',
    });
  }

  return result;
};

type SelectESSAdderState = { batteryCatalog: BatteryCatalogState } & SelectProposalState;

interface SelectCartESSAdderData {
  (state: SelectESSAdderState, proposalId: number): ProductSummary | null;
}

export const selectCartESSAdderData: SelectCartESSAdderData = (state, proposalId: number) => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const essAdder = proposal.essAdders.find((ess) => ess.id === proposal.cart.essAdder);
  if (!essAdder) return null;

  const price = Pricing.essAdderPrice(proposal);
  const batteryCatalog = state.batteryCatalog.entities.find((e) => e.id === essAdder.productId);
  if (!batteryCatalog) return null;

  return {
    id: essAdder.id,
    firstYearStateIncentives: selectESSAdderTotalStateIncentives(state, proposalId, essAdder.id, 1),
    imageUrl: batteryCatalog.photoUrls.length ? batteryCatalog.photoUrls[0] : '',
    itcValue: selectESSAdderITCValue(state, proposalId, essAdder.id),
    macrsValue: selectESSAdderMACRSValue(state, proposalId, essAdder.id),
    monthlyPayment: selectCartESSAdderMonthlyPayment(state, proposalId),
    monthlyPaymentNetIncentivesOverTerm: selectCartESSAdderMonthlyPaymentNetAllIncentivesOverTerm(state, proposalId),
    monthlyPaymentTitle: getESSAdderMonthlyPaymentTitle(proposal),
    price,
    priceNetFirstYearIncentives: selectCartESSAdderPriceNetFirstYearIncentives(state, proposalId),
    title: `${batteryCatalog.brand} - ${batteryCatalog.model}`,
  };
};
