import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        fontSize: '13px',
        whiteSpace: 'pre-wrap',
        marginTop: theme.spacing(2),
        color: Colors.GreyFive,
      },
      link: {
        paddingLeft: theme.spacing(0.5),
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }),
  );
