import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext } from 'formik';
import FinanceOptionTypeSelect from './FinanceOptionTypeSelect';
import { newFinanceOption } from './defaults';
import { FinanceOptionDTO, FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';

const shouldDisableTermInMonths = (o: FinanceOptionDTO): boolean => o.type === FinanceOptionType.Cash;
const shouldDisablePaymentFactorFields = (o: FinanceOptionDTO): boolean => o.type === FinanceOptionType.Cash;

const FinanceOptions: React.FC = () => {
  const classes = useStyles()();
  const { setFieldValue, values, isSubmitting } = useFormikContext<ProposalDTO>();

  const handleRemoveClick = (index: number) => {
    if (!values || !values.financeOptions) {
      return;
    }

    setFieldValue('financeOptions', [
      ...values.financeOptions.slice(0, index),
      ...values.financeOptions.slice(index + 1),
    ]);
  };

  const handleAddClick = () => {
    if (!values) {
      return;
    }
    setFieldValue('financeOptions', [...(values.financeOptions || []), newFinanceOption]);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Finance Options
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2}>
          {values?.financeOptions?.map((o, i) => (
            <Grid item lg={3} md={4} sm={6} key={`financeOption-${values?.tax?.entity}-${i}`}>
              <Card elevation={3}>
                <CardHeader
                  action={
                    <IconButton onClick={() => handleRemoveClick(i)} size="small">
                      <Close />
                    </IconButton>
                  }
                />
                <CardContent className={classes.option}>
                  <FinanceOptionTypeSelect name={`financeOptions[${i}].type`} />
                  <TextField name={`financeOptions[${i}].downPayment`} disabled={isSubmitting} label="Down Payment" />
                  <TextField
                    name={`financeOptions[${i}].termInMonths`}
                    disabled={isSubmitting || shouldDisableTermInMonths(o)}
                    label="Term In Months"
                    type="number"
                  />
                  <TextField
                    name={`financeOptions[${i}].rate`}
                    disabled={isSubmitting || o.type === FinanceOptionType.Cash}
                    label="Rate"
                    type="number"
                  />
                  <TextField
                    name={`financeOptions[${i}].initialPaymentFactor`}
                    disabled={isSubmitting || shouldDisablePaymentFactorFields(o)}
                    label="Initial Payment Factor"
                    type="number"
                  />
                  <TextField
                    name={`financeOptions[${i}].secondPaymentFactor`}
                    disabled={isSubmitting || shouldDisablePaymentFactorFields(o)}
                    label="Second Payment Factor"
                    type="number"
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item md={3} sm={6} className={classes.addOption}>
            <Button startIcon={<Add />} onClick={handleAddClick} size="medium">
              Add Finance Option
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FinanceOptions;
