import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Link, useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { HomePageRoute } from '../../../pg-home';
import HorizontalLogo from '../../Logos/HorizontalLogo';
import UserMenu from './UserMenu';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../Auth/selectors';
import DarkModeToggleButton from './DarkModeToggleButton';
import ElevationScroll from '../../../../components/ElevationScroll';
import { useTheme } from '@material-ui/core/styles';
import CircleLogo from '../../Logos/CircleLogo';

type Props = {
  hideLogo?: boolean;
  invertedMenu?: boolean;
};

const Header: React.FC<Props> = ({ hideLogo, invertedMenu }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isSmOrLower = useMediaQuery(theme.breakpoints.down('sm'));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <ElevationScroll>
      <AppBar position="fixed" color="default" elevation={0} className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {!hideLogo && (
            <Link className={classes.brand} onClick={() => history.push(HomePageRoute.Path)}>
              {isSmOrLower ? <CircleLogo size="42px" /> : <HorizontalLogo height="42px" />}
            </Link>
          )}

          <Box className={classes.rightContainer}>
            {isAuthenticated ? <UserMenu invertedMenu={invertedMenu} /> : <DarkModeToggleButton />}
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

export default Header;
