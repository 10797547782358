import { Reducer } from 'redux';
import {
  Actions,
  setSelectedAdder,
  setCartUpdateError,
  setError,
  setIsFetching,
  setIsUpdatingCart,
  setOneProposal,
  setProposals,
  removeSelectedAdder,
  updateProposalSharedOnDate,
} from './actions';
import { Proposal, ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';
import { SelectedAdder } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';
import { find } from 'ramda';

type ProposalActions = ReturnType<typeof setOneProposal> &
  ReturnType<typeof setProposals> &
  ReturnType<typeof setIsFetching> &
  ReturnType<typeof setIsUpdatingCart> &
  ReturnType<typeof setCartUpdateError> &
  ReturnType<typeof setSelectedAdder> &
  ReturnType<typeof removeSelectedAdder> &
  ReturnType<typeof setError> &
  ReturnType<typeof updateProposalSharedOnDate>;

export type ProposalsState = {
  cartUpdateError: string | null;
  entities: Proposal[];
  error: string | null;
  index: ProposalIndexItem[];
  isFetching: boolean;
  isUpdatingCart: boolean;
};

const DEFAULT_STATE: ProposalsState = {
  index: [],
  isFetching: false,
  isUpdatingCart: false,
  error: null,
  entities: [],
  cartUpdateError: null,
};

const toUpdateProposalState = (state: ProposalsState, proposal: Proposal): ProposalsState => {
  const index = state.entities.findIndex((p) => p.id === proposal.id);
  if (index < 0) {
    return { ...state, entities: [...state.entities, proposal] };
  }

  return { ...state, entities: [...state.entities.slice(0, index), ...state.entities.slice(index + 1), proposal] };
};

const toUpdateProposalSharedOnDate = (state: ProposalsState, proposal: Proposal): ProposalsState => {
  const stateWithNewProposal = toUpdateProposalState(state, proposal);

  const index = state.index.findIndex((p) => p.id === proposal.id);
  const newProposalIndexState =
    index < 0
      ? [...state.index, proposal]
      : [...state.index.slice(0, index), ...state.index.slice(index + 1), proposal];

  return { ...stateWithNewProposal, index: [...newProposalIndexState] };
};

const toUpdateSelectedAdderState = (
  state: ProposalsState,
  proposalId: string,
  selectedAdder: SelectedAdder,
): ProposalsState => {
  const proposal = find((p) => p.id.toString() === proposalId, state.entities);
  if (!proposal) {
    return state;
  }

  const index = proposal.cart.selectedAdders.findIndex((a) => a.id === selectedAdder.id);
  let proposalUpdate;

  if (index < 0) {
    proposalUpdate = {
      ...proposal,
      cart: {
        ...proposal.cart,
        selectedAdders: [...proposal.cart.selectedAdders, selectedAdder],
      },
    };

    return toUpdateProposalState(state, proposalUpdate);
  }

  proposalUpdate = {
    ...proposal,
    cart: {
      ...proposal.cart,
      selectedAdders: [
        ...proposal.cart.selectedAdders.slice(0, index),
        ...proposal.cart.selectedAdders.slice(index + 1),
        selectedAdder,
      ],
    },
  };

  return toUpdateProposalState(state, proposalUpdate);
};

const toRemovedSelectedAdderState = (
  state: ProposalsState,
  proposalId: string,
  selectedAdderId: number,
): ProposalsState => {
  const proposal = find((p) => p.id.toString() === proposalId, state.entities);
  if (!proposal) {
    return state;
  }

  const index = proposal.cart.selectedAdders.findIndex((a) => a.id === selectedAdderId);
  if (index < 0) {
    return state;
  }

  const proposalUpdate = {
    ...proposal,
    cart: {
      ...proposal.cart,
      selectedAdders: [
        ...proposal.cart.selectedAdders.slice(0, index),
        ...proposal.cart.selectedAdders.slice(index + 1),
      ],
    },
  };

  return toUpdateProposalState(state, proposalUpdate);
};

const proposals: Reducer<ProposalsState, ProposalActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetOneProposal:
      return toUpdateProposalState(state, action.proposal);

    case Actions.SetSelectedAdder:
      return toUpdateSelectedAdderState(state, action.proposalId, action.selectedAdder);

    case Actions.RemoveSelectedAdder:
      return toRemovedSelectedAdderState(state, action.proposalId, action.selectedAdderId);

    case Actions.SetProposals:
      return { ...state, index: action.proposals };

    case Actions.UpdateProposalSharedOnDate:
      return toUpdateProposalSharedOnDate(state, action.proposal);

    case Actions.SetIsFetching:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetIsUpdatingCart:
      return { ...state, isUpdatingCart: action.isUpdatingCart };

    case Actions.SetError:
      return { ...state, error: action.error };

    case Actions.SetCartUpdateError:
      return { ...state, cartUpdateError: action.cartUpdateError };

    default:
      return state;
  }
};

export default proposals;
