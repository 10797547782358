import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext, Field } from 'formik';
import AdderOptionSelect from './AdderOptionSelect';
import { useSelector } from 'react-redux';
import Spinner from '../../../Spinner';
import { defaultAdder } from './defaults';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectAdderCatalog } from '../../../Catalog/Adders/selectors';

const AdderOptions: React.FC = () => {
  const classes = useStyles()();
  const { setFieldValue, values, isSubmitting } = useFormikContext<ProposalDTO>();
  const adders = useSelector(selectAdderCatalog);

  const handleRemoveClick = (index: number) => {
    if (!values || !values.adders) {
      return;
    }

    setFieldValue('adders', [...values.adders.slice(0, index), ...values.adders.slice(index + 1)]);
  };

  const handleAddClick = () => {
    if (!values) {
      return;
    }
    setFieldValue('adders', [...(values.adders || []), defaultAdder]);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Adders
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2}>
          {values?.adders?.map((a, i) => (
            <Grid item lg={3} md={4} sm={6} key={`${a.toString()}-${i}`}>
              <Card elevation={3}>
                <CardHeader
                  action={
                    <IconButton onClick={() => handleRemoveClick(i)} size="small">
                      <Close />
                    </IconButton>
                  }
                />
                <CardContent className={classes.option}>
                  {adders.isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      <AdderOptionSelect name={`adders[${i}]`} />

                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label className={classes.itcCheckbox} htmlFor="itc-checkbox">
                        ITC Eligible
                        <Field
                          id="itc-checkbox"
                          name={`adders[${i}].isITCEligible`}
                          disabled={isSubmitting}
                          type="checkbox"
                          label="ITC Eligible"
                        />
                      </label>
                      <TextField
                        name={`adders[${i}].maxQuantity`}
                        disabled={isSubmitting}
                        type="number"
                        label="Max Quantity"
                      />
                      <TextField
                        name={`adders[${i}].price`}
                        variant="filled"
                        disabled={isSubmitting}
                        type="number"
                        label="Price (Per Unit)"
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item md={3} sm={6} className={classes.addOption}>
            <Button startIcon={<Add />} onClick={handleAddClick} size="medium">
              Add Adder Option
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdderOptions;
