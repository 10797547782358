import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(1),
    },
    smallHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(2, 1, 0, 2.5),
      height: '80px',
    },
    brand: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '168px',
      '& svg': {
        height: '42px',
      },
      [theme.breakpoints.down('md')]: {
        '& svg': {
          height: '36px',
        },
      },
    },
    userMenu: {
      cursor: 'pointer',
      paddingRight: theme.spacing(2.5),
      width: '168px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(1, 3),
      width: '100%',
      height: '80px',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 2.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
  }),
);
