import { Reducer } from 'redux';
import { Actions, setIsFetchingBatteries, setBatteries, setBatteriesFetchError } from './actions';
import { Battery } from '@solvana/catalog-domain/dist/Battery';

type BatteryCatalogActions = ReturnType<typeof setIsFetchingBatteries> &
  ReturnType<typeof setBatteriesFetchError> &
  ReturnType<typeof setBatteries>;

export type BatteryCatalogState = {
  isFetching: boolean;
  error: string | null;
  entities: Battery[];
};

const DEFAULT_STATE: BatteryCatalogState = {
  isFetching: false,
  error: null,
  entities: [],
};

const batteryCatalog: Reducer<BatteryCatalogState, BatteryCatalogActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetBatteries:
      return { ...state, entities: action.batteries };

    case Actions.SetIsFetchingBatteries:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetBatteriesFetchError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default batteryCatalog;
