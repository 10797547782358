import { Advocate } from '@solvana/proposal-tool-domain/dist/Advocate';
import { RootState } from '../../../RootState';
import { State } from './reducer';

export const selectAdvocates = (state: RootState): State => state.advocates;

export const selectAdvocate = (state: RootState, advocateId: string): Advocate | undefined =>
  state.advocates.entities.find((advocate) => {
    return advocate.id === advocateId;
  });
