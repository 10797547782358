import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      margin: '30px 0',
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);
