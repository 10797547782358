import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'center',
    },
    title: {
      '& sup': {
        fontSize: theme.spacing(2),
        fontWeight: 500,
      },
    },
    button: {
      maxWidth: '280px',
    },
    circle: {
      borderRadius: '999px',
      border: theme.palette.type === 'dark' ? 'none' : `solid 1px ${Colors.SecondaryDark}`,
      backgroundColor: theme.palette.type === 'dark' ? Colors.GreyOne : 'none',
      width: theme.spacing(3),
      height: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: theme.spacing(0, 2, 0, 0),
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1.5),
      },
    },
    iconList: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      textAlign: 'center',
      margin: theme.spacing(5, 'auto'),
      maxWidth: '460px',
      width: '100%',
      '& li': {
        '& h5': {
          width: '100%',
        },
        margin: theme.spacing(0, 0, 1.5),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 'unset',
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& li:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
);
