import React, { useEffect } from 'react';
import { Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import Layout from 'containers/common/Layout';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerFetchError, selectCustomerForId } from '../common/Customers/selectors';
import { RootState } from '../../RootState';
import { fetchCustomer } from '../common/Customers/actions';
import { selectIsAdmin } from '../Auth/selectors';
import { isProposalCreator } from '../common/Group';
import TabPanel from './TabPanel';
import CustomerDetails from './CustomerDetails';
import CreateProposal from './CreateProposal';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import { fetchProposalsForCustomer } from '../common/Proposals/actions';

interface Params {
  id: string;
}

const CustomerPage: React.FC = () => {
  const { id } = useParams<Params>();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomer(id, dispatch);
    fetchProposalsForCustomer(dispatch, id);
  }, [id]);

  const error = useSelector(selectCustomerFetchError);
  const customer = useSelector((state: RootState) => selectCustomerForId(id, state));
  const isAdmin = useSelector(selectIsAdmin);
  const classes = useStyles();

  const canCreateProposals = isAdmin || isProposalCreator;

  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (!customer) {
    return (
      <Layout title="Solvana | Customer">
        <div className={classes.root}>
          <Container>
            <Typography className={classes.error} variant={'subtitle1'}>
              Customer not found.
            </Typography>
          </Container>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Solvana | Customer">
      <div className={classes.root}>
        <Container>
          <Typography className={classes.title} variant={'h3'}>
            {toFullName(customer)}
          </Typography>

          <Paper>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
              <Tab label="Details" />
              {canCreateProposals && <Tab label="Create Proposal" />}
            </Tabs>
          </Paper>
        </Container>

        <TabPanel value={value} index={0}>
          <CustomerDetails customer={customer} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CreateProposal />
        </TabPanel>

        {error && (
          <Typography className={classes.error} variant={'subtitle1'}>
            {error}
          </Typography>
        )}
      </div>
    </Layout>
  );
};

export default CustomerPage;
