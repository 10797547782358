import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '../../../styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectPanelCatalog } from '../../../../../Catalog/Panels/selectors';

type Props = {
  productIdName: string;
  annualDegradationName: string;
};

const PanelOptionSelect: React.FC<Props> = ({ productIdName, annualDegradationName }) => {
  const classes = useStyles()();
  const { setFieldValue, isSubmitting } = useFormikContext<ProposalDTO>();
  const panelCatalog = useSelector(selectPanelCatalog);

  const selectOptions = panelCatalog.entities.map((panel) => ({
    label: `${panel.brand} - ${panel.model}`,
    value: panel.id,
  }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPanel = panelCatalog.entities.find((p) => p.id === parseInt(e.target.value));
    if (selectedPanel) {
      setFieldValue(productIdName, selectedPanel.id);
      setFieldValue(annualDegradationName, selectedPanel.annualDeg);
    }
  };

  return (
    <Field
      component={TextField}
      className={classes.input}
      disabled={isSubmitting}
      onChange={handleChange}
      type="text"
      name={productIdName}
      label="Solar Panel"
      select
      variant="standard"
      helperText="Please select a solar panel"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default PanelOptionSelect;
