import { Reducer } from 'redux';
import { Actions, setIsFetchingPanels, setPanels, setPanelsFetchError } from './actions';
import { Panel } from '@solvana/catalog-domain/dist/Panel';

type PanelCatalogActions = ReturnType<typeof setIsFetchingPanels> &
  ReturnType<typeof setPanelsFetchError> &
  ReturnType<typeof setPanels>;

export type PanelCatalogState = {
  isFetching: boolean;
  error: string | null;
  entities: Panel[];
};

const DEFAULT_STATE: PanelCatalogState = {
  isFetching: false,
  error: null,
  entities: [],
};

const panelCatalog: Reducer<PanelCatalogState, PanelCatalogActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetPanels:
      return { ...state, entities: action.panels };

    case Actions.SetIsFetchingPanels:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetPanelsFetchError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default panelCatalog;
