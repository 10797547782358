import React from 'react';
import { Card, CardMedia, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { toFormattedPrice } from '../../../../../utils/helpers';
import { ProductSummary } from '../../../common/Cart/selectors';

type Props = {
  product: ProductSummary;
};

const ProductCard: React.FC<Props> = ({ product }) => {
  const classes = useStyles()();

  return (
    <Card component="li" elevation={0} className={classes.listItem}>
      <CardMedia className={classes.media} component="img" src={product.imageUrl} title={product.title} />
      <div className={classes.listItemBody}>
        <Typography variant="h6">{product.title}</Typography>
        <hgroup>
          <Typography variant="subtitle1">{toFormattedPrice(product.price)}</Typography>
          {product.monthlyPayment && (
            <Typography variant="body1" className={classes.monthlyPayment}>
              {toFormattedPrice(product.monthlyPayment?.initialPayment, 2)}/mo.
              <sup>*</sup>
            </Typography>
          )}
        </hgroup>
      </div>
    </Card>
  );
};

export default ProductCard;
