import { selectProposal, SelectProposalState } from '../../../common/Proposals/selectors';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { SolarOption } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';
import { selectLowestMonthlyLoanOptionForSolarOption } from '../../../common/Proposals/SolarOptions/selectors/selectLowestMonthlyLoanForSolarOption';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { getFinancingLegalText } from '../../common/LearnMore/BenefitsSummary/FinanceOption/FinancingLegal/getFinancingLegalText';

export const selectConsumptionPercentOffset = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === solarOptionId);
  if (!solarOption) return 0;

  return System.consumptionOffsetPercentage(solarOption, proposal, horizonLength);
};

export const selectCashInHandForCashOption = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const cashFinanceOption = proposal.financeOptions.find((fo) => fo.type === FinanceOptionType.Cash);

  return Pricing.cashInHandTotal(
    {
      ...proposal,
      cart: { solarOption: solarOptionId, essAdder: null, financeOption: cashFinanceOption?.id, selectedAdders: [] },
    },
    horizonLength,
  );
};

export const selectSolarOptionFirstYearProduction = (solarOption: SolarOption): number =>
  Math.floor(System.totalProduction(solarOption, 1));

export const selectSolarOptionCashPriceNetFirstYearIncentives = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithSolarOption = {
    ...proposal,
    cart: { ...proposal.cart, solarOption: solarOptionId, financeOption: null },
  };

  return Pricing.solarOptionPriceNetIncentives(proposalWithSolarOption, 1);
};

export const selectLoanPaymentLegalText = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): string | null => {
  const lowestMonthlyLoanOption = selectLowestMonthlyLoanOptionForSolarOption(state, proposalId, solarOptionId);
  if (!lowestMonthlyLoanOption) return null;

  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const solarOption = proposal.solarOptions.find((so) => so.id === solarOptionId);
  if (!solarOption) return null;

  const principle = solarOption.price - lowestMonthlyLoanOption.downPayment;
  const itcPercentage = ITC.percentage(proposal);

  return getFinancingLegalText(lowestMonthlyLoanOption, principle, itcPercentage);
};
