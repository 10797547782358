import React from 'react';
import { FinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import SelectButton from '../../../../common/SelectButton';
import { Button, List, ListItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUpdatingCart } from '../../../../../common/Proposals/selectors';
import { setLearMoreItemForFinanceBenefits } from '../../../../common/LearnMore/actions';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import { cashInHandForSolarOptions, selectSolarOptionsPriceNetFirstYear } from '../common/selectors';
import PopCard from '../../../../../../components/PopCard';
import FinancePartnerLogo from '../../../../../../components/FinancePartnerLogo';
import { RootState } from '../../../../../../RootState';
import { useStyles } from './styles';
import { RiBattery2ChargeLine } from 'react-icons/ri';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { selectCartTotal } from '../../../../common/LearnMore/BenefitsSummary/CashFlow/FirstYearCashFlow/selectors';
import SimpleDagger from '../../../../../../components/SimpleDagger';

type Props = {
  financeOption: FinanceOption;
  onSubmit: () => any;
  proposal: Proposal;
  preventFade?: boolean;
};

const SaveMostOption: React.FC<Props> = ({ onSubmit, financeOption, proposal, preventFade }) => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const isESSEligible = Financing.isEligibleESSAdderFinanceOption(financeOption);
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const totalPrice = useSelector((state: RootState) => selectCartTotal(state, proposal.id));
  const cashPriceNetFirstYear = useSelector((state: RootState) =>
    selectSolarOptionsPriceNetFirstYear(state, proposal.id),
  );
  const isSelected = proposal.cart?.financeOption === financeOption.id;
  const withFade = !preventFade && Boolean(proposal.cart?.financeOption && !isSelected);
  const cashInHand = cashInHandForSolarOptions(proposal, financeOption.id);

  return (
    <PopCard isSelected={isSelected} withFade={withFade} className={classes.root}>
      <div className={classes.topContainer}>
        <FinancePartnerLogo financeOption={financeOption} />

        <hgroup className={classes.priceContainer}>
          <Typography variant="h5">{toFormattedPrice(totalPrice)}</Typography>
          <Typography variant="body2">
            {`${toFormattedPrice(cashPriceNetFirstYear)} net first year incentives`}
            <SimpleDagger />
          </Typography>
        </hgroup>

        <Typography className={classes.totalSavingsTitle} variant="h4">
          Cash In Hand Over 25 Years <br />
          for your cart selections can be up to
        </Typography>
        {cashInHand >= 0 ? (
          <Typography className={classes.totalSavings} variant="h4">
            +{toFormattedPrice(cashInHand)}
          </Typography>
        ) : (
          <Typography className={classes.loss} variant="h4">
            -{toFormattedPrice(Math.abs(cashInHand))}
          </Typography>
        )}

        <Button
          variant="contained"
          className={classes.seeHowButton}
          size="small"
          disableElevation
          color="primary"
          onClick={() => dispatch(setLearMoreItemForFinanceBenefits({ id: financeOption.id, proposalId: proposal.id }))}
        >
          See how
        </Button>
      </div>

      <div className={classes.bottomContainer}>
        <List>
          {isESSEligible && (
            <ListItem className={classes.badgeTitleContainer}>
              <RiBattery2ChargeLine size={16} />
              <Typography variant="body2">Battery Compatible</Typography>
            </ListItem>
          )}
        </List>

        <SelectButton
          disabled={isUpdatingCart}
          isSubmitting={isUpdatingCart}
          isSelected={isSelected}
          buttonClassName={classes.button}
          onClick={onSubmit}
        >
          Select
        </SelectButton>
      </div>
    </PopCard>
  );
};

export default SaveMostOption;
