import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        fontSize: '13px',
        fontWeight: 700,
        textAlign: 'left',
        color: theme.palette.type === 'dark' ? Colors.GreyFour : Colors.GreyTwo,
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : Colors.GreyFive,
        border: `solid 1px ${Colors.GreyThree}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
      },
      link: {
        paddingLeft: theme.spacing(0.5),
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }),
  );
