import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
      strokeWidth: 2.21,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    highlight: {
      stroke: theme.palette.primary.main,
      strokeWidth: 3,
    },
  }),
);

const defaultWidth = 50;

const DetachedGarage: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 54">
      <title>{title || 'Detached Garage'}</title>
      <polyline points="57 53 57 34 81 34 81 53" />
      <polyline points="53 23 53 53 7 53 7 23" />
      <line x1="30" y1="8" x2="19" y2="16" />
      <line x1="30" y1="8" x2="41" y2="16" />
      <polyline points="85 32 85 53 53 53 53 32" />
      <polyline points="89 32 69 17 60 24 30 1 1 23" />
      <polyline points="77 28 69 22 61 28" />
      <line x1="93" y1="25" x2="88" y2="31" />
      <line x1="86" y1="20" x2="81" y2="26" />
      <line x1="81" y1="16" x2="76" y2="22" />
      <line x1="75" y1="11" x2="70" y2="17" />
      <line className={classes.highlight} x1="97" y1="28" x2="71" y2="8" />
    </svg>
  );
};

export default DetachedGarage;
