import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 0, 8, 0),
      display: 'flex',
      '& article': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    statisticsContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0, 3, 0, 0),
      width: '33%',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0, 3, 0),
        width: '100%',
      },
      padding: 0,
      '& li': {
        marginBottom: theme.spacing(3),
      },
      '& li:last-child': {
        marginBottom: 0,
      },
      '& h4': {
        maxWidth: '270px',
      },
    },
    footNote: {
      marginTop: theme.spacing(1),
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: theme.spacing(4),
      textAlign: 'center',
      borderRadius: theme.spacing(1),
      '& h3': {
        fontSize: '38px',
        margin: theme.spacing(1, 0),
      },
      '& h4': {
        fontSize: '20px',
      },
    },
    badgeTitleContainer: {
      width: '100%',
      marginBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      '& p': {
        marginLeft: theme.spacing(1),
        color: Colors.GreyTwo,
      },
      '& svg': {
        color: Colors.GreyTwo,
        stroke: Colors.GreyTwo,
        fill: Colors.GreyTwo,
      },
    },
    savings: {
      color: theme.palette.success.main,
    },
    loss: {
      color: theme.palette.primary.main,
    },
  }),
);
