import { Dispatch } from 'redux';
import { proposalToolApi } from '../../../../../lib/api/proposalToolApi';
import { updateProposalSharedOnDate } from '../../actions';

export enum Actions {
  SetIsSubmitting = 'invitations/SetIsSubmitting',
  SetErrorMessage = 'invitations/SetErrorMessage',
}

export const setIsSubmitting = (proposalId: number, isSubmitting: boolean) => ({
  type: Actions.SetIsSubmitting,
  proposalId,
  isSubmitting,
});

export const setErrorMessage = (proposalId: number, errorMessage: string | null) => ({
  type: Actions.SetErrorMessage,
  proposalId,
  errorMessage,
});

type ShareProposal = (dispatch: Dispatch, proposalId: number) => void;

export const shareProposal: ShareProposal = (dispatch, proposalId) => {
  dispatch(setErrorMessage(proposalId, null));
  dispatch(setIsSubmitting(proposalId, true));

  proposalToolApi
    .post(`/v1/proposals/${proposalId}/invite`)
    .then((response) => {
      dispatch(updateProposalSharedOnDate(response.data));
    })
    .catch((e: Error) => {
      dispatch(setErrorMessage(proposalId, e.message));
    })
    .finally(() => {
      dispatch(setIsSubmitting(proposalId, false));
    });
};
