import { selectProposal, SelectProposalState } from '../../selectors';
import StateIncentives from '@solvana/proposal-tool-domain/dist/lib/StateIncentives';

export const selectESSAdderTotalStateIncentives = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const essAdder = proposal.essAdders.find((ess) => ess.id === essAdderId);
  if (!essAdder) return 0;

  const proposalWithESSAdder = { ...proposal, cart: { ...proposal.cart, essAdder: essAdderId } };

  return StateIncentives.totalBatteryIncentives(proposalWithESSAdder, horizonLength);
};
