import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const Pergola: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 60">
      <title>{title || 'Pergola'}</title>
      <g strokeWidth="2.1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1.05 58.413V17.73h36.782v40.684M31.021 17.978l6.472 6.471M7.521 17.978L1.05 24.449M45.832 6.398h2.218v40.684M1.05 14.572h36.782" />
        <path
          className={classes.highlight}
          d="M13.604 11.612L24.583 1.137M27.604 11.612L38.583 1.137M9.78 6.125h36.053"
        />
        <path d="M40.557 11.847H3.775L15.05 1.05h36z" />
      </g>
    </svg>
  );
};

export default Pergola;
