import React, { useEffect } from 'react';
import Layout from 'containers/common/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Spinner from '../common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import AdvocateRow from './AdvocateRow';
import { selectAdvocates } from '../common/Advocates/selectors';
import { fetchAdvocates } from '../common/Advocates/actions';

const AdvocatesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAdvocates(dispatch);
  }, []);

  const advocates = useSelector(selectAdvocates);

  return (
    <Layout title="Solvana | Advocates Dashboard">
      <div className={classes.root}>
        <Typography className={classes.title} variant={'h3'}>
          Advocates
        </Typography>

        {advocates.isFetching ? (
          <Spinner />
        ) : (
          <TableContainer component={'div'}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advocates.entities.map((advocate) => (
                  <AdvocateRow key={advocate.id} advocate={advocate} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Layout>
  );
};

export default AdvocatesPage;
