import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
    tree: {
      stroke: theme.palette.primary.main,
      fill: theme.palette.background.paper,
    },
    mask: {
      fill: theme.palette.background.paper,
    },
  }),
);

const defaultWidth = 50;

const ALotOfShadingNoTreeRemoval: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 82">
      <title>{title || 'A lot of shading (no tree removal)'}</title>

      <g fill="none" fillRule="evenodd">
        <path
          d="M95.32 21.236c-4.865 4.867-12.83 4.79-17.79-.168-4.96-4.96-5.035-12.924-.168-17.791M69.261 14.699L62 15.85M83.899 29.338l-1.15 7.26M70.963 3.95L67.139 2M74.202 24.397l-3.035 3.035M94.649 27.635l1.948 3.825"
          className={classes.highlight}
          strokeWidth="2.94"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g className={classes.tree} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.352">
          <path d="M19.8 34.2l6.821 13.469-3.127-.001 5.4 10.663h-2.842l4.548 8.98-8.527-.001v4.49h-3.978l-.001-4.49H9l4.547-8.979h-2.842l5.4-10.663h-3.126L19.8 34.2z" />
          <path d="M35.4 31l8.59 17.194h-3.938l6.8 13.612-3.579-.001L49 73.269l-10.737-.001V79h-5.01l-.001-5.732H21.8l5.726-11.463-3.579.001 6.799-13.612h-3.935L35.4 31zM59 33.4l6.821 13.469-3.127-.001 5.4 10.663h-2.842l4.548 8.98-8.527-.001V71h-3.978l-.001-4.49H48.2l4.547-8.979h-2.842l5.4-10.663h-3.126L59 33.4z" />
        </g>
        <path className={classes.mask} d="M5.511 56.882v22.595h30.41V56.882L21 45z" />
        <path
          className={classes.detail}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.716 49.723l7.151 5.38"
        />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.716 45L2 59.082M35.92 59.93v19.547H5.512V59.931"
        />
        <path
          className={classes.detail}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.716 49.723l-7.151 5.38"
        />
        <path strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round" d="M20.716 45l18.716 14.082" />
        <path
          d="M46.6 27l9.6 19.2-4.401-.001L59.4 61.4l-4.001-.001L61.8 74.2l-12.001-.001.001 6.401h-5.6l-.001-6.401L31.4 74.2l6.4-12.801-4 .001 7.599-15.201L37 46.2 46.6 27z"
          className={classes.tree}
          strokeWidth="2.352"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ALotOfShadingNoTreeRemoval;
