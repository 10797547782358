import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginBottom: '30px',
        marginTop: 0,
      },
    }),
  );
