import { Reducer } from 'redux';
import {
  Actions,
  FinanceCategory,
  setFinanceOptionsCategory,
  setShowFinanceBenefitsOverview,
  setShowFinanceOptionCategories,
  setShowFinanceOptionItems,
} from './actions';

type ProposalActions = ReturnType<typeof setFinanceOptionsCategory> &
  ReturnType<typeof setShowFinanceOptionCategories> &
  ReturnType<typeof setShowFinanceOptionItems> &
  ReturnType<typeof setShowFinanceBenefitsOverview>;

export type SolarBenefitsUIState = {
  category: FinanceCategory | null;
  showFinanceCategories: boolean;
  showFinanceOptionItems: boolean;
  showFinanceBenefitsOverview: boolean;
};

const DEFAULT_STATE: SolarBenefitsUIState = {
  category: FinanceCategory.SaveMore,
  showFinanceCategories: true,
  showFinanceOptionItems: true,
  showFinanceBenefitsOverview: false,
};

const solarBenefitsUI: Reducer<SolarBenefitsUIState, ProposalActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetFinanceOptionsCategory:
      return { ...state, category: action.category };

    case Actions.SetShowFinanceOptionItems:
      return { ...state, showFinanceOptionItems: action.show };

    case Actions.SetShowFinanceOptionCategories:
      return { ...state, showFinanceCategories: action.show };

    case Actions.SetShowFinanceBenefitsOverview:
      return { ...state, showFinanceBenefitsOverview: action.show };

    default:
      return state;
  }
};

export default solarBenefitsUI;
