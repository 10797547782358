import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: theme.spacing(1, 0, 6, 0),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(0),
      },
    },
    header: {
      textAlign: 'center',
      marginBottom: theme.spacing(2.5),
      lineHeight: 0.9,
    },
    subHeader: {
      maxWidth: '500px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(10),

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(8),
      },
    },
    sectionTitle: {
      marginBottom: theme.spacing(6),
    },
    optionContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    error: {
      margin: theme.spacing(3, 0),
    },
    savings: {
      color: theme.palette.success.main,
    },
  }),
);
