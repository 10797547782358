import { Reducer } from 'redux';
import { Actions, setShowEligibleESSAdderFinanceOptions } from './actions';

type EligibleESSFinancingActions = ReturnType<typeof setShowEligibleESSAdderFinanceOptions>;

export type EligibleESSFinancingState = {
  isOpen: boolean;
};

const DEFAULT_STATE: EligibleESSFinancingState = {
  isOpen: false,
};

const essFinancingUI: Reducer<EligibleESSFinancingState, EligibleESSFinancingActions> = (
  state = DEFAULT_STATE,
  action,
) => {
  switch (action.type) {
    case Actions.SetShowEligibleESSAdderFinanceOptions:
      return { ...state, isOpen: action.isOpen };

    default:
      return state;
  }
};

export default essFinancingUI;
