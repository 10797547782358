import * as React from 'react';
import { CssBaseline, useScrollTrigger } from '@material-ui/core';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  threshold?: number;
}

function ElevationScroller(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold || 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function ElevationScroll({ children, ...props }: Props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroller {...props}>{children}</ElevationScroller>
    </React.Fragment>
  );
}
