import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { selectProposal, SelectProposalState } from '../../../../../common/Proposals/selectors';
import { cartTotalNetIncentives } from '@solvana/proposal-tool-domain/dist/lib/Pricing/cartTotalNetIncentives';

export const cashInHandForSolarOptions = (proposal: Proposal, financeOptionId: number): number =>
  Pricing.cashInHandTotal({ ...proposal, cart: { ...proposal.cart, financeOption: financeOptionId } }, 25);

export const solarOptionsTotalPrice = (proposal: Proposal): number => {
  const solarOptionPrice = Pricing.solarOptionPrice(proposal);
  const addersTotal = Pricing.addersTotalPrice(proposal);

  return solarOptionPrice + addersTotal;
};

export const selectSolarOptionsPriceNetFirstYear = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;
  return cartTotalNetIncentives(proposal, 1);
};

export const solarOptionsFinancedPrice = (proposal: Proposal, financeOptionId: number): number =>
  solarOptionsTotalPrice({ ...proposal, cart: { ...proposal.cart, financeOption: financeOptionId } });

export const selectIsCashFlowPositiveForSolarOptions = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  return Pricing.isCashFlowPositive({
    ...proposal,
    cart: { ...proposal.cart, financeOption: financeOptionId },
  });
};
