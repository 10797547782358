import React from 'react';
import Layout from 'containers/common/Layout';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const TeamsPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout title="Solvana | Teams">
      <div className={classes.root}>
        <Container>
          <Typography className={classes.title} variant={'h3'}>
            Teams
          </Typography>
        </Container>
      </div>
    </Layout>
  );
};

export default TeamsPage;
