import React from 'react';
import CustomerProfile from './CustomerProfile';
import EditCustomer from './EditCustomer';
import { Button, ButtonGroup, Container } from '@material-ui/core';
import DeleteCustomer from './DeleteCustomer';
import { useSelector } from 'react-redux';
import { selectIsAdmin, selectIsAdvocate, selectIsProposalCreator } from '../../Auth/selectors';
import { useStyles } from './styles';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';
import RFAList from './RFAList';
import ProposalsTable from '../../common/Proposals/ProposalsTable';
import { RootState } from '../../../RootState';
import { selectProposalsForCustomer } from '../selectors';

interface Props {
  customer: Customer | undefined;
}

const CustomerDetails: React.FC<Props> = ({ customer }) => {
  const classes = useStyles();
  const isAdmin = useSelector(selectIsAdmin);
  const isProposalCreator = useSelector(selectIsProposalCreator);
  const isAdvocate = useSelector(selectIsAdvocate);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const proposals = useSelector((state: RootState) => selectProposalsForCustomer(state, customer?.id || ''));

  if (!customer) {
    return null;
  }

  return (
    <>
      <Container>
        <ButtonGroup className={classes.editToolsButtonGroup} variant="text">
          {(isAdmin || isAdvocate) && (
            <Button color="primary" onClick={() => setOpenEdit(true)}>
              Edit
            </Button>
          )}

          {isAdmin && (
            <Button color="primary" onClick={() => setOpenDelete(true)}>
              Delete
            </Button>
          )}
        </ButtonGroup>

        <EditCustomer handleClose={() => setOpenEdit(false)} open={openEdit} customer={customer} />
        <DeleteCustomer id={customer.id} open={openDelete} handleClose={() => setOpenDelete(false)} />
        <CustomerProfile customer={customer} />
      </Container>

      {(isAdmin || isProposalCreator) && <RFAList customer={customer} />}

      <ProposalsTable proposals={proposals} />
    </>
  );
};

export default CustomerDetails;
