import { Reducer } from 'redux';
import { Actions, setError, setIsFetching, setOneAdvocate, setAdvocates } from './actions';
import { Advocate } from '@solvana/proposal-tool-domain/dist/Advocate';

type AdvocateActions = ReturnType<typeof setOneAdvocate> &
  ReturnType<typeof setAdvocates> &
  ReturnType<typeof setIsFetching> &
  ReturnType<typeof setError>;

export type State = {
  isFetching: boolean;
  error: string | null;
  entities: Advocate[];
};

const DEFAULT_STATE = {
  isFetching: false,
  error: null,
  entities: [],
};

const toUpdateAdvocateState = (state: State, advocate: Advocate): State => {
  const index = state.entities.findIndex((p) => p.id === advocate.id);
  if (index < 0) {
    return { ...state, entities: [...state.entities, advocate] };
  }

  return { ...state, entities: [...state.entities.slice(0, index), ...state.entities.slice(index + 1), advocate] };
};

const advocates: Reducer<State, AdvocateActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetOneAdvocate:
      return toUpdateAdvocateState(state, action.advocate);

    case Actions.SetAdvocates:
      return { ...state, entities: action.advocates };

    case Actions.SetIsFetching:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default advocates;
