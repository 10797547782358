import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    button: {
      margin: theme.spacing(3, 'auto', 3),
      maxWidth: '250px',
    },
    title: {
      marginTop: theme.spacing(3),
    },
  }),
);
