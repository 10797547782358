import React from 'react';
import { Button, Divider, ListItemIcon, Menu, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { CustomersRoute } from '../../../../pg-customers';
import { Routes as AdvocatesRoute } from '../../../../pg-advocates';
import { Auth } from 'aws-amplify';
import { setLoggedOut } from '../../../../Auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsEmployee } from '../../../../Auth/selectors';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './styles';
import { RiLogoutCircleRLine, RiCustomerService2Line, RiTeamLine, RiHome2Line } from 'react-icons/ri';
import DarkModeToggleButton from '../DarkModeToggleButton';
import MenuIcon from '../../../../../components/SolvanaIcons/MenuIcon';
import { HomePageRoute } from '../../../../pg-home';

type Props = {
  className?: string;
  invertedMenu?: boolean;
};

const UserMenu: React.FC<Props> = ({ className, invertedMenu }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isEmployee = useSelector(selectIsEmployee);
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    dispatch(setLoggedOut());
  };

  return (
    <div className={className || ''}>
      <nav className={classes.menuContainer}>
        <Button
          aria-label="user account dropdown"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          className={clsx(classes.iconButton, { [classes.inverted]: invertedMenu })}
          endIcon={<MenuIcon />}
        >
          Menu
        </Button>
        <DarkModeToggleButton white={invertedMenu} />
      </nav>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {isEmployee && (
          <MenuItem>
            <ListItemIcon>
              <RiTeamLine />
            </ListItemIcon>
            <Typography variant="body2" onClick={() => history.push(CustomersRoute.Path)}>
              Customers
            </Typography>
          </MenuItem>
        )}

        {isEmployee && (
          <MenuItem>
            <ListItemIcon>
              <RiCustomerService2Line />
            </ListItemIcon>
            <Typography variant="body2" onClick={() => history.push(AdvocatesRoute.Path)}>
              Advocates
            </Typography>
          </MenuItem>
        )}

        {isEmployee && <Divider />}

        <MenuItem>
          <ListItemIcon>
            <RiHome2Line />
          </ListItemIcon>
          <Typography variant="body2" onClick={() => history.push(HomePageRoute.Path)}>
            Home Dashboard
          </Typography>
        </MenuItem>

        <MenuItem>
          <ListItemIcon>
            <RiLogoutCircleRLine />
          </ListItemIcon>
          <Typography variant="body2" onClick={handleSignOut}>
            Sign out
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
