import React from 'react';
import { Checkbox, Container, FormControlLabel, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import SelectButton from '../../CheckoutPages/common/SelectButton';
import AddressForm from './AddressForm';
import { handleNextQuestionClick } from '../common/rfaSurveyQuestions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomerProfileSubmitError,
  selectHasMailingAddressErrors,
  selectIsRFAAddressMailingAddress,
  selectMailingAddress,
  selectRFAAddress,
  selectRFAAddressHasErrors,
  selectRFASurveyQuestion,
} from '../selectors';
import { solarSupportedStates } from '../../common/Address/solarSupportedStates';
import { selectIsProfileIncomplete } from '../../common/CurrentUserProfile/selectors';
import SolvanaMapPin from '../../../components/SolvanaIcons/SolvanaMapPin';
import {
  createCustomerProfile,
  RFAAddressField,
  setAddressField,
  setAddressState,
  setCustomerProfileSubmitError,
  setMailingAddressField,
  setMailingAddressState,
  setRFAAddressIsMailingAddress,
} from '../actions';
import { State } from '@solvana/state-domain/dist';

const RFAAddress: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const address = useSelector(selectRFAAddress);
  const mailingAddress = useSelector(selectMailingAddress);
  const customerProfileSubmitError = useSelector(selectCustomerProfileSubmitError);
  const { index } = useSelector(() => selectRFASurveyQuestion(pathname)) || {};
  const isProfileIncomplete = useSelector(selectIsProfileIncomplete);
  const hasMailingAddressErrors = useSelector(selectHasMailingAddressErrors);
  const rfaAddressIsMailingAddress = useSelector(selectIsRFAAddressMailingAddress);
  const isButtonDisabled =
    useSelector(selectRFAAddressHasErrors) ||
    (isProfileIncomplete && hasMailingAddressErrors && !rfaAddressIsMailingAddress);

  const handleClick = () => {
    if (!isProfileIncomplete) {
      handleNextQuestionClick(history, index || 0);
      return;
    }

    if (rfaAddressIsMailingAddress) {
      createCustomerProfile(dispatch, address, () => handleNextQuestionClick(history, index || 0));
      return;
    }

    createCustomerProfile(dispatch, mailingAddress, () => handleNextQuestionClick(history, index || 0));
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <SolvanaMapPin width={56} />
      <Typography variant="h5">Where would you like to install solar?</Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Please enter the address
      </Typography>

      <div className={classes.formContainer}>
        <AddressForm
          address={address}
          stateValueOverride={solarSupportedStates}
          onStateFieldChangeHandler={(state: State) => {
            dispatch(setAddressState(state));
          }}
          onFieldChangeHandler={(field: RFAAddressField, value: string) => {
            dispatch(setAddressField(field, value));
          }}
        />

        {isProfileIncomplete && (
          <>
            <Typography variant="h5">What is your mailing address?</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rfaAddressIsMailingAddress}
                  onChange={() => dispatch(setRFAAddressIsMailingAddress(!rfaAddressIsMailingAddress))}
                  name="My mailing address is the Same"
                  color="primary"
                />
              }
              label="My mailing address is the same"
            />
            {!rfaAddressIsMailingAddress && (
              <AddressForm
                address={mailingAddress}
                onStateFieldChangeHandler={(state: State) => {
                  dispatch(setMailingAddressState(state));
                }}
                onFieldChangeHandler={(field: RFAAddressField, value: string) => {
                  dispatch(setCustomerProfileSubmitError(null));
                  dispatch(setMailingAddressField(field, value));
                }}
              />
            )}
          </>
        )}
      </div>

      {customerProfileSubmitError && (
        <Typography variant="subtitle1" className={classes.error}>
          {customerProfileSubmitError}
        </Typography>
      )}

      <SelectButton fullWidth disabled={isButtonDisabled} onClick={handleClick}>
        Next
      </SelectButton>
    </Container>
  );
};

export default RFAAddress;
