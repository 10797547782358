import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: '#fff',
      width: '100%',
      '& span': {
        color: '#fff',
      },
      maxWidth: '800px',
      marginBottom: theme.spacing(10),
    },
    advantagesList: {
      padding: 0,
    },
    title: {
      color: '#fff',
      margin: theme.spacing(8, 0, 3),
    },
    exampleParagraph: {
      color: '#fff',
      maxWidth: '600px',
      marginBottom: theme.spacing(2),
    },
  }),
);
