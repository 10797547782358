import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.default,
      strokeWidth: 2.1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const InverterSolarEdge: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76">
      <title>{title || 'Solar Edge Inverter'}</title>
      <g strokeWidth="2.1" fillRule="evenodd">
        <circle className={classes.highlight} cx="38" cy="38" r="36.95" />
        <g>
          <path d="M9.05 42.482h57V5.05h-57zM9.05 71.766h57V47.05h-57z" />
        </g>
        <path d="M14.052 42.618v4.323M58.052 42.618v4.323" />
        <path d="M43.55 58.766a6 6 0 1 1-12.002-.001 6 6 0 0 1 12.002.001z" className={classes.highlight} />
      </g>
    </svg>
  );
};

export default InverterSolarEdge;
