import React from 'react';
import { Typography, Popover, Link, ClickAwayListener } from '@material-ui/core';
import { useStyles } from './styles';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import SiteVisitDescription from './SiteVisitDescription';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../RootState';
import { selectCartFinanceOption, selectHasMonthlyPayment } from '../../../common/Proposals/Cart/selectors';
import { toFormattedPrice } from '../../../../utils/helpers';

type Props = {
  proposal: Proposal;
};

const SubHeader: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const hasMonthlyPayment = useSelector((state: RootState) => selectHasMonthlyPayment(state, proposal.id));
  const financeOption = useSelector((state: RootState) => selectCartFinanceOption(state, proposal.id));

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography className={classes.root} variant="body1">
        The next step is to perform a
        <ClickAwayListener onClickAway={handlePopoverClose}>
          <Link
            color="secondary"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
            className={classes.link}
          >
            site visit
          </Link>
        </ClickAwayListener>
        . We require a small initial payment of <strong>{toFormattedPrice(financeOption?.downPayment || 299)}</strong>,
        which will be deducted from the final price. Refunds may be requested within <strong>3 days</strong> of payment
        {hasMonthlyPayment && ' or if you do not qualify for financing'}.
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <SiteVisitDescription />
      </Popover>
    </>
  );
};

export default SubHeader;
