import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { priceNetFedIncentives } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/priceNetFedIncentives';

export const selectAdderPriceNetFirstYearIncentives = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
  quantity: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const adder = proposal.adders.find((a) => a.id === adderId);
  if (!adder) return 0;

  const price = Pricing.cartTotal({
    ...proposal,
    cart: { selectedAdders: [{ adderId, quantity }] },
  });

  return adder.isITCEligible ? priceNetFedIncentives(price, proposal) : adder.price;
};
