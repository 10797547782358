import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext } from 'formik';
import BatteryOptionSelect from './BatteryOptionSelect';
import { useSelector } from 'react-redux';
import Spinner from '../../../Spinner';
import { defaultESSInverter, defaultNewESSAdder } from './defaults';
import InverterForm from '../common/InverterForm';
import { curry } from 'ramda';
import { InverterDTO } from '@solvana/proposal-tool-domain/dist/Proposal/Inverter';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectBatteryCatalog } from '../../../Catalog/Batteries/selectors';

const EssAdderOptions: React.FC = () => {
  const classes = useStyles()();
  const { setFieldValue, values, isSubmitting } = useFormikContext<ProposalDTO>();
  const batteryCatalog = useSelector(selectBatteryCatalog);

  const handleRemoveClick = (index: number) => {
    if (!values || !values.essAdders) {
      return;
    }

    setFieldValue('essAdders', [...values.essAdders.slice(0, index), ...values.essAdders.slice(index + 1)]);
  };

  const handleAddClick = () => {
    if (!values) {
      return;
    }

    setFieldValue('essAdders', [...(values.essAdders || []), defaultNewESSAdder]);
  };

  const handleAddInverter = (index: number) => {
    if (!values || !values.essAdders || !values.essAdders[index]) {
      return;
    }
    const essAdder = values.essAdders[index];

    const newInverterList = [...(essAdder.inverters || []), defaultESSInverter];
    setFieldValue(`essAdders[${index}].inverters`, newInverterList);
  };

  const onRemoveInverter = curry((index: number, inverterIndex: number) => {
    if (!values || !values.essAdders) {
      return;
    }
    const essAdder = values.essAdders[index];
    if (!essAdder || !essAdder.inverters) {
      return;
    }

    const newInverterList = [
      ...essAdder.inverters.slice(0, inverterIndex),
      ...essAdder.inverters.slice(inverterIndex + 1),
    ];
    setFieldValue(`essAdders[${index}].inverters`, newInverterList);
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          ESS Adders
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2}>
          {values?.essAdders?.map((a, i) => (
            <Grid item lg={3} md={4} sm={6} key={`${a.toString()}-${i}`}>
              <Card elevation={3}>
                <CardHeader
                  action={
                    <IconButton onClick={() => handleRemoveClick(i)} size="small">
                      <Close />
                    </IconButton>
                  }
                />
                <CardContent className={classes.option}>
                  {batteryCatalog.isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      <BatteryOptionSelect name={`essAdders[${i}].productId`} />
                      <TextField
                        name={`essAdders[${i}].quantity`}
                        disabled={isSubmitting}
                        type="number"
                        label="Quantity (Battery Count)"
                      />
                      <TextField
                        name={`essAdders[${i}].price`}
                        variant="filled"
                        disabled={isSubmitting}
                        type="number"
                        label="System Price"
                      />

                      <InverterForm
                        batteryCompatible
                        selected={a.inverters || ([] as InverterDTO[])}
                        rootName={`essAdders[${i}]`}
                        onRemove={onRemoveInverter(i)}
                      />

                      <Button startIcon={<Add />} onClick={() => handleAddInverter(i)} size="small">
                        Add ESS Inverter
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item md={3} sm={6} className={classes.addOption}>
            <Button startIcon={<Add />} onClick={handleAddClick} size="medium">
              Add ESS Option
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EssAdderOptions;
