import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useSelector } from 'react-redux';
import { toFormattedPrice } from '../../../../../utils/helpers';
import { RootState } from '../../../../../RootState';
import SelectButton from '../../../common/SelectButton';
import PopCard from '../../../../../components/PopCard';
import { Adder } from '@solvana/proposal-tool-domain/dist/Proposal/Adder';
import { selectAdderProductCatalog, selectLoanPaymentLegalText } from './selectors';
import { selectIsAdderSelected } from '../../../../common/Proposals/Cart/selectors';
import {
  selectAdderLowestMonthlyPaymentNetITC,
  selectAdderPriceNetFirstYearIncentives,
} from '../../../../common/Proposals/Adders/selectors';
import { selectIsUpdatingAdderInCart } from '../../../../common/Proposals/ProposalForm/Cart/selectors';
import SimpleDagger from '../../../../../components/SimpleDagger';
import { trackAdderAddedToCart, trackAdderRemovedFromCart } from '../../../../../utils/analytics/track';

type Props = {
  proposalId: number;
  error?: string;
  adder: Adder;
  onSelect: () => any;
  onRemove: () => any;
  disabled: boolean;
};

const AdderOption: React.FC<Props> = ({ proposalId, error, adder, onSelect, onRemove, disabled }) => {
  const classes = useStyles()();
  const lowestMonthlyPayment = useSelector((state: RootState) =>
    selectAdderLowestMonthlyPaymentNetITC(state, proposalId, adder.id),
  );
  const catalog = useSelector((state: RootState) => selectAdderProductCatalog(state, adder));
  const priceNetFedIncentives = useSelector((state: RootState) =>
    selectAdderPriceNetFirstYearIncentives(state, proposalId, adder.id, 1),
  );
  const isSelected = useSelector((state: RootState) => selectIsAdderSelected(state, proposalId, adder.id));
  const isUpdating = useSelector((state: RootState) => selectIsUpdatingAdderInCart(state, adder.id));
  const adderMonthlyPaymentLegalText = useSelector((state: RootState) =>
    selectLoanPaymentLegalText(state, proposalId, adder.id),
  );

  return (
    <PopCard isSelected={isSelected} withFade={false} className={classes.card}>
      <div className={classes.media}>
        <img src={catalog && catalog?.photoUrls.length > 0 ? catalog?.photoUrls[0] : ''} alt="System Adder Option" />
      </div>
      <div className={classes.cardContent}>
        <div className={classes.descriptionContainer}>
          <Typography className={classes.productName} variant="h5">
            {catalog?.model || '--'}
          </Typography>
          <Typography className={classes.description} variant="body1">
            {catalog?.description || '--'}
          </Typography>
        </div>

        <div className={classes.bottomContainer}>
          {lowestMonthlyPayment && (
            <hgroup className={classes.monthlyPriceGroup}>
              <Typography className={classes.cartTotal} variant="body2">
                As low as
              </Typography>
              <Typography className={classes.price} variant="h6">
                + {toFormattedPrice(lowestMonthlyPayment.monthlyPayment)}/mo.
              </Typography>
              <Typography variant="body2">(See more loan options on the next page)</Typography>
              <Typography variant="body2">{adderMonthlyPaymentLegalText}</Typography>
            </hgroup>
          )}

          <hgroup className={classes.cashPriceGroup}>
            <Typography className={classes.totalPrice} variant="h5">
              {toFormattedPrice(adder.price)}
            </Typography>
            {adder.isITCEligible && (
              <Typography className={classes.price} variant="body2">
                {adder.isITCEligible && `${toFormattedPrice(priceNetFedIncentives)} net incentives`} <SimpleDagger />
              </Typography>
            )}
          </hgroup>

          {error && (
            <Typography className={classes.error} color="primary" variant="subtitle2">
              {error}
            </Typography>
          )}

          <SelectButton
            onClick={() => {
              trackAdderAddedToCart({ f: proposalId, brand: catalog?.brand });
              onSelect();
            }}
            selectedClassName={classes.selectedButton}
            isSubmitting={isUpdating}
            isSelected={isSelected}
            disabled={disabled}
            selectedText="Added"
          >
            Add to Cart
          </SelectButton>

          {isSelected && (
            <Button
              disabled={disabled}
              color="primary"
              onClick={() => {
                trackAdderRemovedFromCart({ f: proposalId, brand: catalog?.brand });
                onRemove();
              }}
            >
              Remove
            </Button>
          )}
        </div>
      </div>
    </PopCard>
  );
};

export default AdderOption;
