import React from 'react';
import { useStyles } from './styles';
import { Divider, Link, List, ListItem, Typography } from '@material-ui/core';
import { toFormattedPrice } from 'utils/helpers';
import { ProductSummary } from '../../selectors';
import LineItem from '../common/LineItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import { selectITCRate } from '../../../../../common/Proposals/selectors';
import SimpleDagger from '../../../../../../components/SimpleDagger';

type Props = {
  product: ProductSummary;
  onSeeDetailsClick?: () => any;
  proposalId: number;
};

const CartItem: React.FC<Props> = ({ onSeeDetailsClick, product, proposalId }) => {
  const classes = useStyles()();
  const itcPercentage = useSelector((state: RootState) => selectITCRate(state, proposalId));

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <img className={classes.image} src={product.imageUrl} alt={product.title} />
      </div>
      <div className={classes.rightContainer}>
        <hgroup>
          <Typography variant="h5" noWrap={false} className={classes.title}>
            {product.title}
          </Typography>
          <Typography variant="h6" noWrap={false} className={classes.subtitle}>
            {product.subtitle}
          </Typography>
          {onSeeDetailsClick && (
            <Link className={classes.link} onClick={onSeeDetailsClick}>
              See Details
            </Link>
          )}

          {product.monthlyPayment && (
            <div className={classes.monthlyPaymentContainer}>
              <Typography variant="h6" className={classes.monthlyPayment}>
                {toFormattedPrice(product.monthlyPayment.initialPayment)}
              </Typography>
              <Typography variant="body1">/mo.</Typography>
            </div>
          )}
        </hgroup>

        <List className={classes.list}>
          <ListItem>
            <LineItem title="Price" value={product.price} />
          </ListItem>
          {product.itcValue > 0 && (
            <ListItem>
              <LineItem
                title={`${itcPercentage * 100}% Federal Investment Tax Credit (ITC)`}
                value={-product.itcValue}
                Sup={SimpleDagger}
              />
            </ListItem>
          )}
          {product.macrsValue > 0 && (
            <ListItem>
              <LineItem title="MACRS Cash Value" value={-product.macrsValue} Sup={SimpleDagger} />
            </ListItem>
          )}
          {product.firstYearStateIncentives > 0 && (
            <ListItem>
              <LineItem
                title="First Year State Incentives"
                value={-product.firstYearStateIncentives}
                Sup={SimpleDagger}
              />
            </ListItem>
          )}
          {product.stateTaxCredit !== undefined && product.stateTaxCredit > 0 && (
            <ListItem>
              <LineItem title="State Tax Credit" value={-product.stateTaxCredit} />
            </ListItem>
          )}

          <Divider className={classes.divider} color="white" />

          <ListItem>
            <LineItem title="Price Net First Year Incentives" value={product.priceNetFirstYearIncentives} />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default CartItem;
