import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const verticalToHorizBreakpoint = 'sm';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      cartIconButton: {
        [theme.breakpoints.up('md')]: {
          alignSelf: 'flex-start',
        },
        '& svg': {
          fill: '#fff',
          width: '30px',
          height: '30px',
          [theme.breakpoints.up('md')]: {
            transform: 'scale(-1, 1)',
          },
        },
      },
    }),
  );
