import React from 'react';
import { useStyles } from './styles';
import StyledButton from '../../../StyledButton';
import { ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';
import { Button, Typography } from '@material-ui/core';
import { shareProposal } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectErrorMessage, selectIsSubmitting } from './selectors';

type Props = {
  proposal: ProposalIndexItem;
};

const InviteCustomerButton: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state: RootState) => selectIsSubmitting(state, proposal.id));
  const errorMsg = useSelector((state: RootState) => selectErrorMessage(state, proposal.id));

  return (
    <div className={classes.root}>
      {proposal.sharedOn ? (
        <Button variant="outlined" size="small" color="secondary" onClick={() => shareProposal(dispatch, proposal.id)}>
          {isSubmitting ? 'Sending...' : 'Re-Send'}
        </Button>
      ) : (
        <StyledButton
          className={classes.styledButton}
          disabled={isSubmitting}
          color="primary"
          size="small"
          variant="contained"
          onClick={() => shareProposal(dispatch, proposal.id)}
        >
          {isSubmitting ? 'Sending...' : 'Send Proposal'}
        </StyledButton>
      )}

      {errorMsg && (
        <Typography className={classes.subtext} variant="body2" color="primary">
          {errorMsg}
        </Typography>
      )}

      {proposal.sharedOn && (
        <Typography className={classes.subtext} variant="body2">
          Sent on {new Date(proposal.sharedOn).toLocaleString()}
        </Typography>
      )}
    </div>
  );
};

export default InviteCustomerButton;
