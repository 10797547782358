import * as React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
};

const Metadata: React.FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Helmet>
  );
};

export default Metadata;
