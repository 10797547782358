import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../RootState';
import {
  selectAvgMonthlyStatePBIIncentivesOverLoanTerm,
  selectSolarSavingsOverLoanTerm,
  selectStateIncentives,
} from '../selectors';
import {
  selectIsLoanOption,
  selectMonthlyMacrValue,
  selectSolarOptionsMonthlyPaymentNetITC,
} from '../../../../../../common/Proposals/FinanceOptions/selectors';
import { State } from '@solvana/state-domain';
import { selectAvgMonthlyCashFlow } from './selectors';
import LineItem from '../../../../../../../components/Accounting/LineItem';
import { LineItemTotal } from '../../../../../../../components/Accounting';
import SimpleDagger from '../../../../../../../components/SimpleDagger';

type Props = {
  proposalId: number;
  financeOptionId: number;
  state: State;
};

const AveMonthlyCashFlow: React.FC<Props> = ({ proposalId, financeOptionId, state }) => {
  const classes = useStyles();
  const isLoanOption = useSelector((state: RootState) => selectIsLoanOption(state, proposalId, financeOptionId));
  const monthlyStateIncentives = useSelector((state: RootState) =>
    selectAvgMonthlyStatePBIIncentivesOverLoanTerm(state, proposalId, financeOptionId),
  );
  const monthlyPaymentNetITC = useSelector((state: RootState) =>
    selectSolarOptionsMonthlyPaymentNetITC(state, proposalId, financeOptionId),
  );
  const solarSavingsOverLoanTerm = useSelector((state: RootState) =>
    selectSolarSavingsOverLoanTerm(state, proposalId, financeOptionId),
  );
  const stateIncentives = useSelector((state: RootState) => selectStateIncentives(state, proposalId, 1));
  const avgMonthlyCashFlow = useSelector((state: RootState) =>
    selectAvgMonthlyCashFlow(state, proposalId, financeOptionId),
  );

  const monthlyMacrValue = useSelector((state: RootState) =>
    selectMonthlyMacrValue(state, proposalId, financeOptionId),
  );

  return (
    <Card elevation={0} className={classes.card}>
      <div>
        <Typography className={classes.cardTitle} variant="h4">
          {isLoanOption ? 'Average Monthly Cash Flow Over Loan Term' : 'Average Monthly Cash Flow'}
        </Typography>

        {isLoanOption && (
          <LineItem flipSavingsSign title="Monthly Loan Payment" ignoreSign value={-monthlyPaymentNetITC} />
        )}

        {monthlyMacrValue > 0 && (
          <LineItem flipSavingsSign title="Average Monthly MACR Value Over Loan Term" value={monthlyMacrValue} />
        )}

        {State.NJ === state && (
          <LineItem
            flipSavingsSign
            title="Average Monthly NJ REC Value"
            Sup={SimpleDagger}
            value={monthlyStateIncentives}
          />
        )}

        {State.MA === state && (
          <>
            <LineItem
              flipSavingsSign
              title="Average Monthly SMART Value"
              Sup={SimpleDagger}
              value={monthlyStateIncentives}
            />
            <LineItem
              flipSavingsSign
              title="Tax Credit for First Year"
              Sup={SimpleDagger}
              value={stateIncentives.taxCredit}
            />
          </>
        )}

        <LineItem flipSavingsSign title="Average Monthly Solar Offset" value={solarSavingsOverLoanTerm} />
      </div>

      <LineItemTotal title="Average Monthly Cash Flow Over Loan Term" value={avgMonthlyCashFlow} />
    </Card>
  );
};

export default AveMonthlyCashFlow;
