import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(10),
      flexGrow: 1,
      padding: theme.spacing(0, 3),
    },
    chatWidgetWrapper: {
      '& div.Papercups-toggleButtonContainer': {
        zIndex: theme.zIndex.drawer - 1,
      },
    },
    contentContainer: {
      marginTop: theme.spacing(4),
      maxWidth: '1408px',
      margin: '0 auto',
      width: '100%',
    },
    heroContainer: {
      verticalAlign: 'center',
      margin: theme.spacing(0, 'auto', 6),
      maxWidth: '1408px',
      justifyContent: 'stretch',
      display: 'flex',
      maxHeight: '500px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
        flexDirection: 'column',
      },
    },
    rightHeroContainer: {
      overflow: 'hidden',
      width: '60%',
      borderRadius: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.spacing(2),
        width: '100%',
      },
      '& img': {
        width: '100%',
        borderRadius: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          borderRadius: theme.spacing(2),
        },
      },
    },
    leftHeroContainer: {
      width: '40%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(3),
      '& h1': {
        fontSize: '42px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    gridItem: {
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(6),
      '& h4': {
        marginBottom: theme.spacing(4),
        fontWeight: 900,
      },
    },
  }),
);
