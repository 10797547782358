import { defaultResidentialFinanceOptions } from './FinanceOptions/defaults';
import { defaultResidentialESSAdders } from './EssAdderOptions/defaults';
import { emptySolarOption } from './SolarOptions/defaults';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { State } from '@solvana/state-domain/dist';
import { defaultResidentialAdders } from './AdderOptions/defaults';

export const defaultResidentialProposal: ProposalDTO = {
  name: 'Your Go Solar Options',
  customerId: '',
  adders: defaultResidentialAdders,
  advocateId: '',
  essAdders: defaultResidentialESSAdders,
  solarOptions: [emptySolarOption],
  utility: {
    annualInflationPercentage: 2.2,
    annualFixedCharge: '' as any,
    annualConsumption: '' as any,
    valueOfSolar: '' as any,
    rate: '' as any,
    utilityType: '',
  },
  tax: {
    entity: TaxEntity.Residential,
    installationYear: new Date().getFullYear(),
    rate: 0,
  },
  address: {
    address1: '',
    address2: '',
    city: '',
    state: State.NJ,
    zip: '',
  },
  financeOptions: defaultResidentialFinanceOptions,
};
