import { Dispatch } from 'redux';
import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { Advocate } from '@solvana/proposal-tool-domain/dist/Advocate';

export enum Actions {
  SetOneAdvocate = 'advocates/SetOneAdvocate',
  SetAdvocates = 'advocates/SetAdvocates',
  SetIsFetching = 'advocates/SetIsFetching',
  SetError = 'advocates/SetError',
}

export const setOneAdvocate = (advocate: Advocate) => ({
  type: Actions.SetOneAdvocate,
  advocate,
});

export const setAdvocates = (advocates: Advocate[]) => ({
  type: Actions.SetAdvocates,
  advocates,
});

export const setIsFetching = (isFetching: boolean) => ({
  type: Actions.SetIsFetching,
  isFetching,
});

export const setError = (error: string | null) => ({
  type: Actions.SetError,
  error,
});

export const fetchAdvocates = (dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get('/v1/advocates')
    .then((response) => {
      dispatch(setAdvocates(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};

export const fetchAdvocate = (id: string, dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get(`/v1/advocates/${id}`)
    .then((response) => {
      dispatch(setOneAdvocate(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};
