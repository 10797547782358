import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    leftContainer: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(0, 6, 0, 2),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 3, 0, 2),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(0, 2),
        alignItems: 'center',
        marginBottom: theme.spacing(6),
      },
    },
    rightContainer: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(0, 2, 0, 6),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2, 0, 3),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(0, 2),
        alignItems: 'center',
      },
    },
  }),
);
