import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { HiChevronLeft } from 'react-icons/hi';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      '& svg': {
        fill: '#fff',
      },
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1.5),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
);

type Props = {
  onClick: () => any;
  className?: string;
};

const BackButton: React.FC<Props> = ({ onClick, className }) => {
  const classes = useStyles();

  return (
    <IconButton size="small" className={clsx(classes.backButton, { [className || '']: className })} onClick={onClick}>
      <HiChevronLeft />
    </IconButton>
  );
};

export default BackButton;
