import React, { useEffect } from 'react';
import { List, ListItem, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProposals } from 'containers/common/Proposals/actions';
import { selectProposals } from 'containers/common/Proposals/selectors';
import PopCard from '../../../components/PopCard';
import { toChooseSolarOptionPath } from '../../CheckoutPages/routes';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { RFAIntroductionRoute } from '../../pg-rfa/routes';
import { getMyRFAs } from '../../pg-rfa/actions';
import { selectRFAEntities } from '../../pg-rfa/selectors';
import { RiCompasses2Line } from 'react-icons/ri';
import clsx from 'clsx';
import CircleLogo from '../../common/Logos/CircleLogo';

const HomeProposals: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchProposals(dispatch);
    getMyRFAs(dispatch);
  }, []);

  const { index } = useSelector(selectProposals);
  const RFAs = useSelector(selectRFAEntities);

  return (
    <List className={classes.root}>
      {RFAs.map((rfa) => {
        if (rfa.proposalId || rfa.doesNotQualify) return null;
        return (
          <ListItem key={rfa.rfaId}>
            <Paper elevation={0} className={classes.itemContainer}>
              <div className={clsx(classes.leftContainer, { [classes.rfaBackground]: true })}>
                <RiCompasses2Line size="58px" />
              </div>
              <div className={classes.rightContainer}>
                <Typography variant="h5" className={classes.name}>
                  Your proposal is being created...
                </Typography>

                <Typography variant="subtitle2" noWrap className={classes.address}>
                  {rfa.address.address1} {rfa.address.address2}
                  <br />
                  {rfa.address.city}, {rfa.address.state} {rfa.address.zip}
                </Typography>

                {rfa.createdAt && (
                  <Typography variant="body1" noWrap className={classes.timestamp}>
                    {moment(rfa.createdAt).format('LL')}
                  </Typography>
                )}
              </div>
            </Paper>
          </ListItem>
        );
      })}
      {index.map((proposal) => (
        <ListItem key={proposal.id}>
          <PopCard
            onClick={() => history.push(toChooseSolarOptionPath(proposal.id))}
            className={classes.itemContainer}
            isSelected={false}
            withFade={false}
          >
            <div className={clsx(classes.leftContainer, { [classes.proposalReadyBackground]: true })}>
              <CircleLogo white size="96px" />
            </div>
            <div className={classes.rightContainer}>
              <Typography variant="h5" className={classes.name}>
                {proposal.name}
              </Typography>

              <Typography variant="subtitle2" noWrap className={classes.address}>
                {proposal.address.address1} {proposal.address.address2}
                <br />
                {proposal.address.city}, {proposal.address.state} {proposal.address.zip}
              </Typography>

              {proposal.createdAt && (
                <Typography variant="body1" noWrap className={classes.timestamp}>
                  {moment(proposal.createdAt).format('LL')}
                </Typography>
              )}
            </div>
          </PopCard>
        </ListItem>
      ))}
      <ListItem>
        <PopCard
          onClick={() => history.push(RFAIntroductionRoute.Path)}
          className={classes.newProposalCard}
          isSelected={false}
          withFade={false}
        >
          <Typography variant="h6" noWrap>
            Request a New Proposal
          </Typography>
        </PopCard>
      </ListItem>
    </List>
  );
};

export default HomeProposals;
