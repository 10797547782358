import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
      },
    },
    subtitle: {
      maxWidth: '500px',
      textAlign: 'center',
      margin: theme.spacing(4, 0, 8, 0),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(3, 0, 6, 0),
      },
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      marginBottom: theme.spacing(1),
    },
    contactInfo: {
      textAlign: 'center',
      maxWidth: '500px',
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(3.5),
      },
    },
    contactName: {
      fontWeight: 900,
    },
    contactDetail: {
      paddingBottom: '2px',
    },
    contactDetailIcon: {
      marginRight: theme.spacing(1),
    },
    iconTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
  }),
);
