import React from 'react';
import { Button, CardContent, CardMedia, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ESSAdder } from '@solvana/proposal-tool-domain/dist/Proposal/ESSAdder';
import { toFormattedPrice } from '../../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectIsUpdatingCart } from '../../../../common/Proposals/selectors';
import SelectButton from '../../../common/SelectButton';
import {
  firstBatteryPhotoFor,
  resolveESSAdderName,
  selectCatalogForESSAdders,
  selectESSAdderFinancedPriceNetIncentives,
  selectMonthlyPaymentsForESSAdder,
} from '../../../../common/Proposals/ESSAdders/selectors';
import PopCard from '../../../../../components/PopCard';
import { isLoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { selectCartESSAdder } from '../../../../common/Proposals/Cart/selectors';
import { selectFinanceOption } from '../../../../common/Proposals/FinanceOptions/selectors';
import SimpleDagger from '../../../../../components/SimpleDagger';
import { selectLoanPaymentLegalText } from './selectors';
import { trackESSAdderAddedToCart } from '../../../../../utils/analytics/track';

type Props = {
  essAdder: ESSAdder;
  onSubmit: () => any;
  onLearnMore: () => any;
  hasSelection: boolean;
  proposalId: number;
};

const ESSAdderOption: React.FC<Props> = ({ essAdder, proposalId, onSubmit, onLearnMore, hasSelection }) => {
  const classes = useStyles();

  const monthlyPayments = useSelector((state: RootState) =>
    selectMonthlyPaymentsForESSAdder(state, proposalId, essAdder.id),
  );
  const financedPriceNetIncentives = useSelector((state: RootState) =>
    selectESSAdderFinancedPriceNetIncentives(state, proposalId, essAdder.id, 1),
  );
  const financeOption = useSelector((state: RootState) => selectFinanceOption(state, proposalId));
  const selectedEssAdder = useSelector((state: RootState) => selectCartESSAdder(state, proposalId));
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const catalogItems = useSelector((state: RootState) => selectCatalogForESSAdders([essAdder], state));
  const loanLegalText = useSelector((state: RootState) => selectLoanPaymentLegalText(state, proposalId, essAdder.id));
  const essAdderName = resolveESSAdderName(essAdder, catalogItems);

  return (
    <PopCard
      className={classes.root}
      isSelected={essAdder.id === selectedEssAdder?.id}
      withFade={hasSelection && essAdder.id !== selectedEssAdder?.id}
    >
      <CardMedia
        className={classes.media}
        component="img"
        src={firstBatteryPhotoFor(essAdder, catalogItems)}
        title="Battery Image"
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.productName} variant="h5">
          {essAdderName}
        </Typography>

        <hgroup className={classes.priceGroup}>
          <Typography className={classes.priceTitle} variant="subtitle1">
            Price
          </Typography>
          <Typography variant="h5">{toFormattedPrice(essAdder.price)}</Typography>
          <Typography className={classes.cashPrice} variant="body2">
            {toFormattedPrice(financedPriceNetIncentives)}
            <br />
            net first year incentives <SimpleDagger />
          </Typography>
        </hgroup>

        {financeOption && isLoanFinanceOption(financeOption.type) && monthlyPayments && (
          <div className={classes.loanCopyContainer}>
            <Typography className={classes.productPrice} variant="subtitle1">
              Adds {toFormattedPrice(monthlyPayments.initialPayment)}/mo.
              <sup className={classes.asterisk}>*</sup>
            </Typography>
            <Typography variant="body2">{loanLegalText}</Typography>
          </div>
        )}

        <Button
          variant="contained"
          className={classes.seeComponents}
          size="small"
          disableElevation
          color="primary"
          onClick={onLearnMore}
        >
          See battery components
        </Button>

        <SelectButton
          onClick={() => {
            trackESSAdderAddedToCart({
              proposalId,
              essAdderName,
              financedPriceNetIncentives,
              monthlyPrice: monthlyPayments?.initialPayment,
              price: essAdder.price,
            });
            onSubmit();
          }}
          isSelected={essAdder.id === selectedEssAdder?.id}
          disabled={isUpdatingCart}
          selectedText="Added"
        >
          Add to Cart
        </SelectButton>
      </CardContent>
    </PopCard>
  );
};

export default ESSAdderOption;
