import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = makeStyles((_theme) =>
  createStyles({
    root: {
      color: Colors.GreyThree,
      width: '100%',
    },
    white: {
      color: '#fff',
    },
  }),
);
