import React, { useEffect } from 'react';
import Layout from 'containers/common/Layout';
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import Spinner from '../common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers } from '../common/Customers/actions';
import { selectCustomers } from '../common/Customers/selectors';
import CustomerRow from './CustomerRow';
import { selectIsAdmin } from '../Auth/selectors';
import CreateCustomer from './CreateCustomer';
import ModalView from 'containers/common/ModalView';

const CustomersPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomers(dispatch);
  }, []);

  const isAdmin = useSelector(selectIsAdmin);
  const customers = useSelector(selectCustomers);
  const [openCreate, setOpenCreate] = React.useState(false);

  return (
    <Layout title="Solvana | Customers Dashboard">
      <div className={classes.root}>
        <Typography className={classes.title} variant={'h3'}>
          Customers
        </Typography>

        <ButtonGroup className={classes.editToolsButtonGroup} variant="text">
          {isAdmin && (
            <Button color="primary" onClick={() => setOpenCreate(true)}>
              Create
            </Button>
          )}
        </ButtonGroup>

        {isAdmin && (
          <ModalView handleClose={() => setOpenCreate(false)} open={openCreate}>
            <CreateCustomer />
          </ModalView>
        )}

        {customers.isFetching ? (
          <Spinner />
        ) : (
          <TableContainer component={'div'}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.entities.map((customer) => (
                  <CustomerRow key={customer.id} customer={customer} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Layout>
  );
};

export default CustomersPage;
