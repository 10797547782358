import React from 'react';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectFinanceOptionsByCategory } from './selectors';
import { RootState } from '../../../../../RootState';
import { Grid, Typography } from '@material-ui/core';
import LoanOption from './LoanOption';
import { selectCart, selectProposal } from '../../../../common/Proposals/selectors';
import {
  FinanceOption,
  FinanceOptionType,
  isLoanFinanceOption,
  LoanFinanceOption,
} from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { Cart } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';
import { updateCart } from '../../../../common/Proposals/actions';
import SaveMostOption from './SaveMostOption';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import ErrorDialog from '../../../../../components/ErrorDialog';
import { trackFinanceOptionSelected } from '../../../../../utils/analytics/track';

const FinanceOptions: React.FC = () => {
  const classes = useStyles()();
  const [financeError, setFinanceError] = React.useState<null | string>(null);
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const financeOptions = useSelector((state: RootState) => selectFinanceOptionsByCategory(state, proposalId));
  const cart = useSelector(selectCart(id)) as Cart;
  const dispatch = useDispatch();

  if (!proposal) {
    return null;
  }

  if (!financeOptions.length) {
    return (
      <section className={classes.root}>
        <Typography color="primary" variant="subtitle2">
          No finance options available.
        </Typography>
      </section>
    );
  }

  const handleSelection = (financeOption: FinanceOption) => {
    if (proposal.cart.essAdder && !Financing.isEligibleESSAdderFinanceOption(financeOption)) {
      setFinanceError(
        'This finance option is not available with an Energy Storage System. Please first remove the Energy Storage System from your cart.',
      );
      return;
    }
    updateCart(dispatch, id, { ...cart, financeOption: financeOption.id });
  };

  return (
    <>
      <ErrorDialog error={financeError} onClose={() => setFinanceError(null)} />
      <Grid container spacing={5} className={classes.root}>
        {financeOptions.map((f) => (
          <Grid item md={4} sm={6} xs={12} key={`finance-option-${f.id}`}>
            {isLoanFinanceOption(f.type) && (
              <LoanOption
                onSubmit={() => {
                  trackFinanceOptionSelected({ proposalId });
                  handleSelection(f);
                }}
                loanOption={f as LoanFinanceOption}
                proposal={proposal}
              />
            )}

            {FinanceOptionType.Cash === f.type && (
              <SaveMostOption onSubmit={() => handleSelection(f)} financeOption={f} proposal={proposal} />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default FinanceOptions;
