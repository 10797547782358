import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    submitContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: theme.spacing(3, 0, 8, 0),
      width: '100%',
      textAlign: 'center',
    },
    hidden: {
      visibility: 'hidden',
      height: '0px',
    },
    input: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    error: {
      color: theme.palette.primary.main,
    },
    submitErrorMessage: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
    },
    submitButtonHeader: {
      marginBottom: theme.spacing(3),
    },
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& label, h6': {
        fontSize: '16px',
        fontWeight: 500,
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
      },
    },
    billingInfoTitle: {
      margin: theme.spacing(4, 0, 2, 0),
      width: '100%',
    },
    addressTitle: {
      margin: theme.spacing(0, 0, 2, 0),
      width: '100%',
    },
    stripeInput: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    paymentSubtitleContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      '& svg': {
        verticalAlign: 'middle',
      },
    },
    paymentSubtitle: {
      display: 'inline',
      verticalAlign: 'middle',
      padding: theme.spacing(0, 0.25, 0, 0),
      color: Colors.GreyThree,
    },
    lock: {
      color: Colors.GreyThree,
      verticalAlign: 'middle',
      fontSize: '12px',
      marginRight: theme.spacing(0.5),
    },
    stripeLogo: {
      paddingTop: '3px',
    },
  }),
);
