import { selectProposal, SelectProposalState } from '../../../../common/Proposals/selectors';
import { YearlyValues } from '@solvana/proposal-tool-domain/dist/common';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import StateIncentives from '@solvana/proposal-tool-domain/dist/lib/StateIncentives';

export const selectAnnualUtilityBills = (state: SelectProposalState, proposalId: number): YearlyValues => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return {};

  return System.annualUtilityBills(proposal, 25);
};

export const selectIsITCQualified = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  return ITC.percentage(proposal) > 0;
};

export const selectHasStateIncentives = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  return StateIncentives.total(proposal, 1) > 0;
};
