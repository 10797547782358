import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useLocation, useHistory } from 'react-router-dom';
import { CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Route as SignInRoute } from '../pg-sign-in/route';
import { Auth } from 'aws-amplify';
import StyledButton from '../../common/StyledButton';

interface Args {
  code: string | null;
  username: string | null;
  onSuccess: () => void;
  onError: (e: string) => void;
}

const submitHandler = ({ code, username, onSuccess, onError }: Args) => {
  if (!username || !code) {
    onError('This URL is missing credentials.');
    return;
  }

  Auth.confirmSignUp(username, code)
    .then(onSuccess)
    .catch(async (e) => {
      if (e.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(username);
        onError(
          'You must first confirm your email. Please check your inbox (it may be in your spam folder) to validate your email address',
        );
        return;
      }

      onError(e.message);
    });
};

const ConfirmSignUpPage: React.FC = () => {
  const classes = useStyles();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get('username');
  const email = params.get('email');
  const code = params.get('code');

  useEffect(
    () => submitHandler({ code, username, onSuccess: () => setIsConfirmed(true), onError: (e: string) => setError(e) }),
    [code, username],
  );

  if (error) {
    return (
      <AuthLayout title="Solvana | Confirm email" header="Confirm your email">
        <div className={classes.root}>
          <Typography className={classes.message} variant="subtitle1" color="primary">
            {error}
          </Typography>

          <div className={classes.buttonContainer}>
            <StyledButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => history.push(`${SignInRoute.Path}?email=${encodeURIComponent(email || '')}`)}
            >
              Sign in
            </StyledButton>
          </div>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title="Solvana | Confirm email" header="Confirm your email">
      <div className={classes.root}>
        {isConfirmed ? (
          <>
            <Typography className={classes.message} variant="subtitle1" color="primary">
              Your email has been confirmed!
            </Typography>

            <div className={classes.buttonContainer}>
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                onClick={() => history.push(`${SignInRoute.Path}?email=${encodeURIComponent(email || '')}`)}
              >
                Sign in
              </StyledButton>
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    </AuthLayout>
  );
};

export default ConfirmSignUpPage;
