import React from 'react';
import { useStyles } from './styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toCustomerPagePath } from '../../pg-customer/routes';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

const CustomerRow = ({ customer }: { customer: Customer }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <Link href="#" onClick={() => history.push(toCustomerPagePath(customer.id))}>
            {`${customer.lastName}, ${customer.firstName}`}
          </Link>
        </TableCell>
        <TableCell align="right">{customer.email}</TableCell>
        <TableCell align="right">{customer.phoneNumber}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default CustomerRow;
