import { Reducer } from 'redux';
import { Actions, setCartAdderUpdateError, setIsUpdatingAdder } from './actions';

type ProposalActions = ReturnType<typeof setCartAdderUpdateError> & ReturnType<typeof setIsUpdatingAdder>;

export type CartUIState = {
  adderErrors: { [key: number]: string | null };
  addersUpdating: { [key: number]: boolean };
};

export type SelectCartUIState = {
  cartUI: CartUIState;
};

const DEFAULT_STATE: CartUIState = {
  adderErrors: {},
  addersUpdating: {},
};

const cartUI: Reducer<CartUIState, ProposalActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetIsUpdatingAdderInCart:
      return { ...state, addersUpdating: { ...state.addersUpdating, [action.adderId]: action.isUpdating } };

    case Actions.SetCartAdderUpdateError:
      return { ...state, adderErrors: { ...state.adderErrors, [action.adderId]: action.error } };

    default:
      return state;
  }
};

export default cartUI;
