import { State as LearnMoreUIState } from './reducer';
import { LearnMoreItem, LearnMoreItemType } from './actions';
import { selectCatalogForSolarOption, selectSolarOptionById } from '../../../common/Proposals/SolarOptions/selectors';
import { PanelCatalogState } from '../../../common/Catalog/Panels/reducer';
import { BatteryCatalogState } from '../../../common/Catalog/Batteries/reducer';
import { InverterCatalogState } from '../../../common/Catalog/Inverters/reducer';
import { ProposalsState } from '../../../common/Proposals/reducer';
import { selectCatalogForESSAdders, selectESSAdderById } from '../../../common/Proposals/ESSAdders/selectors';

interface SelectLearnMoreItem {
  learnMoreUI: LearnMoreUIState;
}

interface SelectLearnMoreItemFunc {
  (state: SelectLearnMoreItem): LearnMoreItem | null;
}

export const selectLearnMoreItem: SelectLearnMoreItemFunc = (state) => state.learnMoreUI.item;

interface SelectSolarOptionImageURLs {
  panelCatalog: PanelCatalogState;
  inverterCatalog: InverterCatalogState;
  proposals: ProposalsState;
}

const selectSolarOptionImageURLs = (state: SelectSolarOptionImageURLs, item: LearnMoreItem): string[] => {
  const solarOption = selectSolarOptionById(state, item.proposalId, item.id);
  if (!solarOption) return [];

  const solarOptionCatalog = selectCatalogForSolarOption(solarOption, state);
  if (!solarOptionCatalog) return [];

  let imgURLs = [] as string[];
  for (let i = 0; i < solarOption.subArrays.length; i += 1) {
    const productId = solarOption.subArrays[i].productId;
    const urls = solarOptionCatalog.subArrays[productId].photoUrls;
    if (urls.length) {
      imgURLs = [...imgURLs, ...urls];
    }
  }

  const inverterImgURLs = solarOptionCatalog.inverters.reduce(
    (acc, curr) => [...acc, ...curr.photoUrls],
    [] as string[],
  );

  return [...imgURLs, ...inverterImgURLs];
};

interface SelectESSAdderOptionImageURLs {
  batteryCatalog: BatteryCatalogState;
  inverterCatalog: InverterCatalogState;
  proposals: ProposalsState;
}

export const selectESSAdderImageURLs = (state: SelectESSAdderOptionImageURLs, item: LearnMoreItem): string[] => {
  const essAdder = selectESSAdderById(item.id, item.proposalId, state);
  if (!essAdder) return [];

  const essAdderCatalog = selectCatalogForESSAdders([essAdder], state);
  if (!essAdderCatalog) return [];

  let imgURLs = [] as string[];
  const essInfo = essAdderCatalog[item.id];
  const urls = essInfo.battery?.photoUrls;
  if (urls?.length) {
    imgURLs = [...urls];
  }

  const inverterImgURLs = essInfo.inverters.reduce((acc, curr) => [...acc, ...curr.photoUrls], [] as string[]);

  return [...imgURLs, ...inverterImgURLs];
};

interface SelectLearnMoreItemImageURLs {
  batteryCatalog: BatteryCatalogState;
  inverterCatalog: InverterCatalogState;
  learnMoreUI: LearnMoreUIState;
  panelCatalog: PanelCatalogState;
  proposals: ProposalsState;
}

export const selectLearnMoreItemImageURLs = (state: SelectLearnMoreItemImageURLs): string[] => {
  const item = selectLearnMoreItem(state);
  if (!item) return [];

  if (item.type === LearnMoreItemType.SolarOption) {
    return selectSolarOptionImageURLs(state, item);
  }

  if (item.type === LearnMoreItemType.ESSAdder) {
    return selectESSAdderImageURLs(state, item);
  }

  return [];
};
