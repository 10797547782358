import React from 'react';

type Props = {
  className?: string;
  double?: boolean;
};

const SimpleDagger: React.FC<Props> = ({ className, double }) =>
  double ? <sup className={className}>&Dagger;</sup> : <sup className={className}>&dagger;</sup>;

export default SimpleDagger;
