import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import RFAIntroduction from './RFAIntroduction';
import { RFAIntroductionRoute, RFASurveyRoutes } from './routes';
import rfaSurveyQuestions from './common/rfaSurveyQuestions';
import Layout from '../common/Layout';
import { AppBar, Link, useMediaQuery } from '@material-ui/core';
import ProgressBar from '../common/ProgressBar';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { HomePageRoute } from '../pg-home';
import HorizontalLogo from '../common/Logos/HorizontalLogo';
import UserMenu from '../common/Layout/Header/UserMenu';
import ElevationScroll from '../../components/ElevationScroll';
import json2mq from 'json2mq';
import { useSelector } from 'react-redux';
import { selectIsCustomer } from '../Auth/selectors';

const RFAPages: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isUnderMdView = useMediaQuery(json2mq({ maxWidth: theme.breakpoints.values.md }));
  const isCustomer = useSelector(selectIsCustomer);

  if (!isCustomer) return <Redirect to={HomePageRoute.Path} />;

  return (
    <Switch>
      <Route
        path={Object.values(RFASurveyRoutes)}
        component={() => (
          <Layout title="Solvana | Let's Get You Started!" hideHeader noMarginTop={isUnderMdView}>
            {isUnderMdView ? (
              <>
                <AppBar color="default" elevation={0} position="static" className={classes.smallHeaderContainer}>
                  <Link className={classes.brand} onClick={() => history.push(HomePageRoute.Path)}>
                    <HorizontalLogo height="42px" />
                  </Link>
                  <UserMenu className={classes.userMenu} />
                </AppBar>
                <ElevationScroll threshold={60}>
                  <AppBar color="default" elevation={0} position="sticky" className={classes.smallProgressBarContainer}>
                    <ProgressBar pages={rfaSurveyQuestions} />
                  </AppBar>
                </ElevationScroll>
              </>
            ) : (
              <ElevationScroll>
                <AppBar color="default" elevation={0} position="fixed" className={classes.headerContainer}>
                  <Link className={classes.brand} onClick={() => history.push(HomePageRoute.Path)}>
                    <HorizontalLogo height="42px" />
                  </Link>
                  <ProgressBar pages={rfaSurveyQuestions} />
                  <UserMenu className={classes.userMenu} />
                </AppBar>
              </ElevationScroll>
            )}
            {rfaSurveyQuestions.map((survey) => (
              <Route key={survey.path} path={survey.path} component={survey.component} />
            ))}
          </Layout>
        )}
      />

      <Route path={RFAIntroductionRoute.Path} exact component={RFAIntroduction} />
    </Switch>
  );
};

export default RFAPages;
