import { RFA } from '@solvana/rfa-domain';
import { Actions, setCustomerRFAEntities } from './actions';
import { Reducer } from 'redux';

type RFAActions = ReturnType<typeof setCustomerRFAEntities>;

type CustomerRFAState = {
  entities: {
    [customerAuthUUID: string]: RFA[];
  };
};

export type SelectCustomerRFAState = {
  customerRFAs: CustomerRFAState;
};

const DEFAULT_STATE: CustomerRFAState = {
  entities: {},
};

const customerRFAs: Reducer<CustomerRFAState, RFAActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetCustomerRFAEntities:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.customerAuthUUID]: action.entities,
        },
      };

    default:
      return state;
  }
};

export default customerRFAs;
