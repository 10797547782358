import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectRFAFormData } from '../../selectors';
import { toFormattedPrice } from '../../../../utils/helpers';
import DiscreteSlider from './DiscreteSlider';
import StyledButton from '../../../common/StyledButton';
import { useDispatch } from 'react-redux';
import { submitRFA } from '../../actions';
import { RFA } from '@solvana/rfa-domain';

const SimpleUtilityForm: React.FC = () => {
  const classes = useStyles();
  const defaultValue = 250;
  const dispatch = useDispatch();
  const [bill, setBill] = React.useState(defaultValue);
  const rfaFormData = useSelector(selectRFAFormData);

  if (!rfaFormData) return null;
  const rfaData = rfaFormData as RFA;

  return (
    <div className={classes.root}>
      <Typography className={classes.summaryText} variant="h4">
        On average I pay <b>{toFormattedPrice(bill)}</b>/mo.
      </Typography>
      <DiscreteSlider
        defaultValue={defaultValue}
        min={50}
        max={1500}
        step={10}
        onChange={(_e, v) => setBill(v as number)}
      />

      <StyledButton
        className={classes.button}
        color="primary"
        size="large"
        variant="contained"
        onClick={() =>
          submitRFA(dispatch, {
            ...rfaData,
            survey: [
              ...rfaData.survey,
              { question: 'Utility bill estimate', answers: [`${toFormattedPrice(bill)}/mo.`] },
            ],
          })
        }
      >
        Submit
      </StyledButton>
    </div>
  );
};

export default SimpleUtilityForm;
