import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../../../theme/Colors';
import { verticalToHorizBreakpoint } from '../../styles';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textOverflow: 'ellipsis',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'left',
          marginBottom: 0,
        },
      },
      total: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: `solid 2px ${Colors.Dark}`,
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderBottom: 'none',
          paddingBottom: 0,
          marginBottom: 0,
          paddingRight: theme.spacing(1),
        },
      },
      priceContainer: {
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          borderLeft: `solid 2px ${Colors.Dark}`,
          paddingLeft: theme.spacing(1),
        },
      },
      price: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          marginBottom: 0,
        },
      },
      perMonth: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: 0,
      },
      cartTotal: {
        color: '#fff',
        fontSize: '12px',
        margin: 0,
        [theme.breakpoints.down('lg')]: {
          fontSize: '11px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
        },
      },
    }),
  );
