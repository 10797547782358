import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../../theme/Colors';

export const verticalToHorizBreakpoint = 'sm';
const closeButtonAndCartIconPaddingTopMultiplier = 2.8;

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
      },
      appBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
      contentContainerNav: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier),
        marginBottom: theme.spacing(1),

        [theme.breakpoints.down('xl')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '30px'),
        },

        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '25px'),
        },

        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '21px'),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
      },
      closeIconButton: {
        '& svg': {
          fill: '#fff',
          width: '46px',
          height: '46px',
        },
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          width: '54px',
          margin: theme.spacing(0, 'auto', 1, 'auto'),
        },
      },
      cartBody: {
        width: '100%',
        flexGrow: 1,
        padding: theme.spacing(0, 13, 8),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(0, 6),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 2),
          marginTop: theme.spacing(12),
        },
      },
      disclaimerContainer: {
        display: 'block',
        whiteSpace: 'pre-wrap',
        '& p': {
          color: Colors.GreyFive,
        },
      },
      customerInfo: {
        margin: theme.spacing(3, 0, 4),
        color: Colors.GreyFive,
      },
      cartListItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: theme.spacing(0, 0, 2, 0),
        borderRadius: theme.spacing(1),
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        padding: theme.spacing(1.5),
      },
      title: {
        color: '#fff',
        marginBottom: theme.spacing(2),
      },
      cartSummary: {
        width: '100%',
        margin: theme.spacing(4, 0, 1),
        '& hr': {
          color: Colors.GreyFive,
        },
        '& h6': {
          color: '#fff',
        },
      },
    }),
  );
