import React from 'react';
import { Container, FormControlLabel, FormGroup, LinearProgress, Typography } from '@material-ui/core';
import ElectricIcon from '../../../components/SolvanaIcons/ElectricIcon';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSubmittingRFA,
  selectRFASubmitError,
  selectRFASubmitSuccess,
  selectShowUtilityAPIIFrame,
} from '../selectors';
import { resetDefaultState, setShowUtilityAPIIFrame } from '../actions';
import StyledButton from '../../common/StyledButton';
import { HomePageRoute } from '../../pg-home';
import { useHistory } from 'react-router-dom';
import UtilityAPI from './UtilityAPI';
import SwitchButton from '../../../components/SwitchButton';
import SimpleUtilityForm from './SimpleUtilityForm';

const RFAUtilityBills: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const showIFrame = useSelector(selectShowUtilityAPIIFrame);
  const submitSuccess = useSelector(selectRFASubmitSuccess);
  const error = useSelector(selectRFASubmitError);
  const isSubmittingRFA = useSelector(selectIsSubmittingRFA);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <ElectricIcon size={56} />
      <Typography className={classes.title} variant="h5">
        How much is your electric bill?
      </Typography>

      {isSubmittingRFA ? (
        <LinearProgress className={classes.spacerTop} />
      ) : (
        <>
          {submitSuccess ? (
            <>
              <Typography className={classes.successTitle} variant="h5">
                Success!
              </Typography>
              <Typography variant="h5">
                We have received your request and will contact you when your proposal is ready!
              </Typography>
              <StyledButton
                className={classes.spacerTop}
                color="primary"
                size="large"
                variant="contained"
                onClick={() => {
                  dispatch(resetDefaultState());
                  history.push(HomePageRoute.Path);
                }}
              >
                Go to Dashboard
              </StyledButton>
            </>
          ) : (
            <>
              <FormGroup className={classes.switchContainer}>
                <FormControlLabel
                  control={
                    <SwitchButton
                      className={classes.spacerTop}
                      color="primary"
                      size="medium"
                      checked={showIFrame}
                      onClick={() => dispatch(setShowUtilityAPIIFrame(!showIFrame))}
                    />
                  }
                  label="I want the most accurate proposal"
                />
              </FormGroup>

              {error && (
                <Typography color="primary" variant="subtitle1">
                  {error}
                </Typography>
              )}

              {showIFrame ? <UtilityAPI /> : <SimpleUtilityForm />}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default RFAUtilityBills;
