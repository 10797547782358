export const segmentAnalytics = (): null => {
  const analytics = ((window as any).analytics = (window as any).analytics || []);
  if (!analytics.initialize) {
    // eslint-disable-next-line no-console
    if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e: any) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key: any, e: any) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
        const n = document.getElementsByTagName('script')[0];
        n?.parentNode?.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = 'fCuB5EpPteHD9R4eSG45Y81aNuNyBAGK';
      analytics.SNIPPET_VERSION = '4.15.3';
      analytics.load('fCuB5EpPteHD9R4eSG45Y81aNuNyBAGK');
    }
  }
  return null;
};
