import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.main,
      width: '100%',
    },
  }),
);
