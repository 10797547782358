import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    sectionTitle: {
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    optionContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      width: '100%',
      marginBottom: theme.spacing(6),
      flexWrap: 'wrap',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    listItem: {
      maxWidth: '300px',
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        maxWidth: '50%',
      },

      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
      },
    },
    seeComponents: {
      textTransform: 'unset',
      margin: theme.spacing(2, 0, 5),
      padding: theme.spacing(0.25, 1.5),
    },
    cardContent: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: theme.spacing(6, 2.5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5, 2.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2),
      },
    },
    productName: {
      lineHeight: 1,
      fontWeight: 900,
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        maxWidth: '250px',
      },
    },
    productPrice: {
      lineHeight: 1,
    },
    asterisk: {
      position: 'relative',
      top: '3px',
    },
    dagger: {
      marginLeft: theme.spacing(1),
    },
    noneCard: {
      width: '100%',
      height: '100%',
    },
    noneTitle: {
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        top: '40%',
      },
    },
    noneCardContainer: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
    },
    media: {
      width: '100%',
    },
    chooseOptionLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noneSelectedContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: '30px',
      },
    },
  }),
);
