import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import FinanceSaveCategories from './FinanceSaveCategories';
import FinanceOptions from './FinanceOptions';

const Financing: React.FC = () => {
  const classes = useStyles()();

  return (
    <section className={classes.root}>
      <section className={classes.section}>
        <Typography className={classes.title} variant="h6">
          Solar Finance Options
        </Typography>
        <FinanceSaveCategories />
      </section>

      <section>
        <FinanceOptions />
      </section>
    </section>
  );
};

export default Financing;
