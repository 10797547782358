import React from 'react';
import { LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { useDispatch } from 'react-redux';
import { Link, Typography } from '@material-ui/core';
import { setLearnMoreItemForFinancingFAQ } from '../../../../LearnMore/actions';
import { useStyles } from './styles';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { getFinancingLegalText } from '../../../../LearnMore/BenefitsSummary/FinanceOption/FinancingLegal/getFinancingLegalText';

type Props = {
  proposal: Proposal;
  loanOption: LoanFinanceOption;
  hideAsterisk?: boolean;
};

const CartSummaryReAmortizationExplanation: React.FC<Props> = ({ loanOption, proposal, hideAsterisk }) => {
  const dispatch = useDispatch();
  const classes = useStyles()();
  const principle = Pricing.cartTotal(proposal) - loanOption.downPayment;
  const itcPercentage = ITC.percentage(proposal);
  const legalText = getFinancingLegalText(loanOption, principle, itcPercentage);

  return (
    <div>
      <Typography className={classes.root} variant="body2">
        {!hideAsterisk && <sup>* </sup>}
        {legalText}
        <Link
          className={classes.link}
          onClick={() =>
            dispatch(
              setLearnMoreItemForFinancingFAQ({
                proposalId: proposal.id,
                id: proposal.id,
              }),
            )
          }
        >
          Learn More
        </Link>
      </Typography>
    </div>
  );
};

export default CartSummaryReAmortizationExplanation;
