import { Dispatch } from 'redux';
import { proposalToolApi } from '../../../../../lib/api/proposalToolApi';
import { removeSelectedAdder, setSelectedAdder } from '../../actions';

export enum Actions {
  SetCartAdderUpdateError = 'projects/CartAdderUpdateError',
  SetIsUpdatingAdderInCart = 'projects/SetIsUpdatingAdderInCart',
}

export const setIsUpdatingAdder = (adderId: number, isUpdating: boolean) => ({
  type: Actions.SetIsUpdatingAdderInCart,
  adderId,
  isUpdating,
});

export const setCartAdderUpdateError = (adderId: number, error: string | null) => ({
  type: Actions.SetCartAdderUpdateError,
  adderId,
  error,
});

type UpdateCartAdder = (
  dispatch: Dispatch,
  proposalId: string,
  adderData: {
    adderId: number;
    quantity: number;
  },
) => void;

export const updateCartAdder: UpdateCartAdder = (dispatch, proposalId, adder) => {
  dispatch(setIsUpdatingAdder(adder.adderId, true));
  dispatch(setCartAdderUpdateError(adder.adderId, null));

  proposalToolApi
    .put(`/v1/proposals/${proposalId}/cart/adder`, adder)
    .then((response) => {
      dispatch(setSelectedAdder(proposalId, response.data));
      dispatch(setIsUpdatingAdder(adder.adderId, false));
    })
    .catch((e: Error) => {
      dispatch(setIsUpdatingAdder(adder.adderId, false));
      dispatch(setCartAdderUpdateError(adder.adderId, e.toString()));
    });
};

type RemoveAdderFromCart = (dispatch: Dispatch, proposalId: string, selectedAdderId: number, adderId: number) => void;

export const removeAdderFromCart: RemoveAdderFromCart = (dispatch, proposalId, selectedAdderId, adderId) => {
  dispatch(setIsUpdatingAdder(adderId, true));
  dispatch(setCartAdderUpdateError(adderId, null));

  proposalToolApi
    .delete(`/v1/proposals/${proposalId}/cart/adder`, { data: { selectedAdderId } })
    .then(() => {
      dispatch(removeSelectedAdder(proposalId, selectedAdderId));
      dispatch(setIsUpdatingAdder(adderId, false));
    })
    .catch((e: Error) => {
      dispatch(setIsUpdatingAdder(adderId, false));
      dispatch(setCartAdderUpdateError(adderId, e.toString()));
    });
};
