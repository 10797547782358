import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    dollarSign: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    mask: {
      fill: theme.palette.type === 'dark' ? Colors.SecondaryDark : '#fff',
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 50;

const CashFlowPositive: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 67">
      <title>Cash Flow Positive</title>
      <g fill="none" fillRule="evenodd">
        <path
          className={classes.dollarSign}
          d="M33.67 23.955l.222 1.397-1.055.169-.224-1.402a4.22 4.22 0 0 1-2.547-.798l.663-1.123c.756.521 1.612.69 2.289.581.69-.11 1.115-.522 1.027-1.076-.1-.633-.623-.8-1.67-.95-1.37-.195-2.608-.441-2.837-1.883-.188-1.175.463-2.184 1.833-2.532l-.233-1.47 1.054-.168.243 1.528c.74.027 1.548.244 2.133.577l-.635 1.118c-.711-.355-1.434-.456-1.97-.37-.678.109-1.058.473-.975.992.086.542.637.699 1.572.841 1.59.226 2.709.457 2.95 1.98.2 1.236-.505 2.207-1.84 2.59"
        />
        <path
          className={classes.mask}
          strokeWidth="2.1"
          strokeLinecap="round"
          d="M62.775 29.16L6.411 38.15 2.094 11.086l56.363-8.99z"
        />
        <path
          d="M41.436 18.687a9.115 9.115 0 0 1-7.566 10.437A9.117 9.117 0 0 1 31 11.12a9.116 9.116 0 0 1 10.437 7.567z"
          strokeWidth="2.1"
          strokeLinecap="round"
        />
        <path className={classes.mask} d="M62.714 40.784L5.877 46.006 3.37 18.714l56.837-5.222z" />
        <path strokeWidth="2.1" strokeLinecap="round" d="M62.714 40.784L5.877 46.006 3.37 18.714l56.837-5.222z" />
        <path
          d="M42.119 28.915a9.114 9.114 0 0 1-8.244 9.91c-5.012.462-9.45-3.23-9.91-8.241a9.115 9.115 0 1 1 18.154-1.668z"
          strokeWidth="2.1"
          strokeLinecap="round"
        />
        <path
          className={classes.dollarSign}
          d="M34.019 33.655l.13 1.408-1.064.098-.13-1.414a4.205 4.205 0 0 1-2.488-.965l.735-1.076c.719.571 1.563.796 2.247.732.696-.064 1.146-.448 1.095-1.004-.059-.638-.57-.84-1.605-1.058-1.352-.288-2.57-.617-2.704-2.07-.108-1.184.609-2.149 2-2.404l-.138-1.482 1.064-.098.142 1.54c.738.077 1.528.346 2.089.718l-.71 1.073c-.684-.4-1.399-.549-1.939-.5-.684.063-1.087.402-1.039.925.05.546.59.74 1.512.944 1.573.332 2.673.636 2.813 2.17.116 1.248-.653 2.171-2.01 2.463"
        />
        <path className={classes.mask} d="M61.82 52.643l-57.073.4-.193-27.407 57.074-.4z" />
        <path strokeWidth="2.1" strokeLinecap="round" d="M61.82 52.643l-57.073.4-.193-27.407 57.074-.4z" />
        <path
          d="M42.302 39.075a9.116 9.116 0 1 1-18.23.127 9.116 9.116 0 0 1 18.23-.127z"
          strokeWidth="2.1"
          strokeLinecap="round"
        />
        <path d="M33.83 43.114l.01 1.414-1.068.007-.01-1.42a4.213 4.213 0 0 1-2.399-1.17l.826-1.012c.668.63 1.489.926 2.176.92.7-.004 1.181-.349 1.177-.908-.005-.641-.497-.885-1.51-1.19-1.323-.4-2.51-.831-2.521-2.292-.006-1.188.789-2.088 2.196-2.225l-.012-1.489 1.068-.008.012 1.546c.728.14 1.492.476 2.02.894L35 37.19c-.649-.458-1.35-.667-1.89-.664-.689.006-1.12.309-1.116.834.004.548.526.788 1.428 1.07 1.538.462 2.609.858 2.619 2.4.009 1.25-.834 2.106-2.211 2.283" />
        <path className={classes.mask} d="M59.899 65.132L3.1 59.53l2.688-27.274 56.8 5.601z" />
        <path strokeWidth="2.1" strokeLinecap="round" d="M59.899 65.132L3.1 59.53l2.688-27.274 56.8 5.601z" />
        <path
          d="M41.915 49.587A9.117 9.117 0 0 1 23.772 47.8c.493-5.01 4.955-8.67 9.966-8.177a9.113 9.113 0 0 1 8.177 9.965z"
          strokeWidth="2.1"
          strokeLinecap="round"
        />
        <path
          className={classes.dollarSign}
          d="M33.066 52.714l-.14 1.407-1.062-.106.139-1.412a4.205 4.205 0 0 1-2.261-1.417l.925-.918c.599.697 1.384 1.076 2.067 1.144.696.067 1.21-.222 1.266-.78.063-.639-.4-.933-1.375-1.342-1.275-.537-2.408-1.09-2.265-2.544.116-1.182 1.002-1.994 2.415-1.982l.148-1.481 1.063.104-.152 1.539c.709.215 1.434.63 1.914 1.1l-.897.92c-.596-.524-1.272-.803-1.812-.857-.683-.067-1.143.188-1.194.71-.055.547.44.84 1.306 1.215 1.48.622 2.504 1.128 2.354 2.66-.124 1.247-1.05 2.01-2.44 2.04"
        />
      </g>
    </svg>
  );
};

export default CashFlowPositive;
