import React from 'react';
import { Route as SignInRoute } from '../../pg-sign-in/route';
import { Route, Redirect } from 'react-router-dom';
import { HomePageRoute as HomeRoutes } from '../../../pg-home/route';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectIsEmployee } from '../../selectors';

const EmployeesOnlyRoute = ({ component, ...rest }: any) => {
  const isEmployee = useSelector(selectIsEmployee);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const routeComponent = (props: any) => {
    if (!isAuthenticated) {
      return <Redirect to={{ pathname: SignInRoute.Path }} />;
    }

    return isEmployee ? React.createElement(component, props) : <Redirect to={{ pathname: HomeRoutes.Path }} />;
  };

  return <Route {...rest} render={routeComponent} />;
};

export default EmployeesOnlyRoute;
