import React from 'react';
import InverterOptionSelect from './InverterOptionSelect';
import TextField from '../../TextField';
import { useFormikContext } from 'formik';
import { useStyles } from './styles';
import { CardHeader, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { InverterDTO } from '@solvana/proposal-tool-domain/dist/Proposal/Inverter';

type Props = {
  selected: InverterDTO[];
  rootName: string;
  onRemove: (index: number) => any;
  batteryCompatible?: boolean;
};

const InverterForm: React.FC<Props> = ({ onRemove, batteryCompatible, selected, rootName }) => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext();
  return (
    <div className={classes.root}>
      <Divider />

      {selected.length === 0 && <Typography variant="subtitle1">Inverters</Typography>}

      {selected.map((inverter, index) => {
        return (
          <div key={`${rootName}-${inverter.toString()}-${index}`}>
            <div className={classes.items}>
              <CardHeader
                title={`Inverter ${index + 1}`}
                action={
                  <IconButton onClick={() => onRemove(index)} size="small">
                    <Close />
                  </IconButton>
                }
              />
              <InverterOptionSelect
                batteryCompatible={batteryCompatible}
                key={`${rootName}-${inverter.toString()}-${index}`}
                name={`${rootName}.inverters[${index}].productId`}
              />

              <TextField
                name={`${rootName}.inverters[${index}].quantity`}
                disabled={isSubmitting}
                type="number"
                label="Quantity"
              />
            </div>

            {index < selected.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default InverterForm;
