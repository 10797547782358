import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  size?: number;
};

const defaultSize = 50;

const EnergyIndependence: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67 67"
    >
      <title>Energy Independence</title>
      <g strokeWidth="2.1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M65.092 33.07c0 17.686-14.336 32.022-32.022 32.022S1.048 50.756 1.048 33.07c0-17.685 14.336-32.022 32.022-32.022S65.092 15.385 65.092 33.07z" />
        <path d="M34.504 9.173L17.776 35.46h13.382l-5.257 21.507 22.94-30.588H33.549z" />
      </g>
    </svg>
  );
};

export default EnergyIndependence;
