import React from 'react';
import { Typography } from '@material-ui/core';
import FederalIncentivesSummary from './FederalIncentivesSummary';
import { State } from '@solvana/state-domain';
import NJBenefits from './NJBenefits';
import ElectricBillSavingsSummary from './ElectricBillSavingsSummary';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import { selectProposal } from '../../../../../common/Proposals/selectors';
import { selectHasStateIncentives, selectIsITCQualified } from '../selectors';
import { selectLearnMoreItem } from '../../selectors';
import AdditionalIncentives from './AdditionalIncentives';
import MassSMARTBenefits from './MassSMARTBenefits';
import StateTaxCredit from './StateTaxCredit';

const SolarIncentives: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const isITCQualified = useSelector((state: RootState) => selectIsITCQualified(state, proposalId));
  const hasStateIncentives = useSelector((state: RootState) => selectHasStateIncentives(state, proposalId));
  const learnMoreUI = useSelector(selectLearnMoreItem);

  if (!proposal || !learnMoreUI) {
    return null;
  }

  const { state } = proposal.address;

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Solar Incentives
      </Typography>

      {isITCQualified && <FederalIncentivesSummary financeOptionId={learnMoreUI.id} proposal={proposal} />}

      {hasStateIncentives && (
        <>
          {State.NJ === state && <NJBenefits proposalId={proposalId} />}
          {State.MA === state && <MassSMARTBenefits proposalId={proposalId} />}
          <StateTaxCredit proposalId={proposalId} />
        </>
      )}

      <ElectricBillSavingsSummary proposalId={proposal.id} />
      <AdditionalIncentives proposal={proposal} />
    </section>
  );
};

export default SolarIncentives;
