import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(2, 0),
      '& a': {
        textDecoration: 'underline',
      },
      '& a:hover': {
        cursor: 'pointer',
      },
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    checkbox: {
      marginRight: theme.spacing(1),
    },
    checkboxLabel: {
      paddingTop: theme.spacing(1),
    },
  }),
);
