import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { useStyles, verticalToHorizBreakpoint } from './styles';
import { ClickAwayListener, useMediaQuery, useTheme } from '@material-ui/core';
import { GoSolarPageParams } from '../../routes';
import { useParams } from 'react-router-dom';
import TotalPrice from './DockedView/TotalPrice';
import CartIcon from './CartIcon';
import CartSummary from './CartSummary';
import DockedView from './DockedView';
import { trackCartOpened } from '../../../../utils/analytics/track';

const Cart: React.FC = () => {
  const classes = useStyles()();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isUnderSmMediaSize = useMediaQuery(theme.breakpoints.down(verticalToHorizBreakpoint));
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);

  const handleToggleDrawer = () => {
    if (!open) trackCartOpened({ proposalId });
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <Drawer
        anchor={isUnderSmMediaSize ? 'bottom' : 'right'}
        elevation={0}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <ClickAwayListener onClickAway={() => (open ? setOpen(false) : null)}>
          <div className={classes.drawerContentContainer}>
            {isUnderSmMediaSize && !open && (
              <nav className={classes.smallViewNavBar}>
                <div className={classes.iconPlusTotal}>
                  <CartIcon onClick={handleToggleDrawer} />
                  <TotalPrice />
                </div>
              </nav>
            )}
            {open ? (
              <CartSummary onClick={handleToggleDrawer} />
            ) : (
              <DockedView onToggle={handleToggleDrawer} proposalId={proposalId} />
            )}
          </div>
        </ClickAwayListener>
      </Drawer>
    </div>
  );
};

export default Cart;
