import React from 'react';
import { useStyles } from './styles';
import { LinearProgress, Typography } from '@material-ui/core';

type Props = {
  value: number;
  subtitle?: string;
  subSubtitle?: string;
};

const StatPercentage: React.FC<Props> = ({ value, subtitle, subSubtitle }) => {
  const classes = useStyles()();
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.percent}>
        {value.toLocaleString('en', { maximumFractionDigits: 2 })}%
      </Typography>
      <LinearProgress variant="determinate" className={classes.container} value={value > 100 ? 100 : value} />
      {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      {subSubtitle && (
        <Typography variant="body2" className={classes.subSubtitle}>
          {subSubtitle}
        </Typography>
      )}
    </div>
  );
};

export default StatPercentage;
