import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paragraph: {
      margin: theme.spacing(3, 0),
    },
    formContainer: {
      margin: theme.spacing(5, 0, 4),
      borderRadius: theme.spacing(1),
    },
  }),
);
