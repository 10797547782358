import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '400px',
    },
    input: {
      width: '100%',
      marginBottom: '30px',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '200px',
      margin: theme.spacing(0, 'auto', 3, 'auto'),
    },
    success: {
      color: theme.palette.success.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    error: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    confirmationMessage: {
      backgroundColor: theme.palette.grey['200'],
      padding: theme.spacing(2, 2),
      margin: theme.spacing(2, 0),
    },
  }),
);
