import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { Dispatch } from 'redux';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

export enum Actions {
  RemoveCustomer = 'projects/RemoveCustomer',
  SetCustomers = 'projects/SetCustomers',
  SetOneCustomer = 'projects/SetOneCustomer',
  SetIsFetching = 'projects/SetIsFetching',
  SetError = 'projects/SetError',
}

export const setCustomers = (customers: Customer[]) => ({
  type: Actions.SetCustomers,
  customers,
});

export const setOneCustomer = (customer: Customer) => ({
  type: Actions.SetOneCustomer,
  customer,
});

export const removeCustomer = (id: string) => ({
  type: Actions.RemoveCustomer,
  id,
});

export const setError = (error: string | null) => ({
  type: Actions.SetError,
  error,
});

export const setIsFetching = (isFetching: boolean) => ({
  type: Actions.SetIsFetching,
  isFetching,
});

export const fetchCustomers = (dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get('/v1/customers')
    .then((response) => {
      dispatch(setCustomers(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};

export const fetchCustomer = (id: string, dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get(`/v1/customers/${id}`)
    .then((response) => {
      dispatch(setOneCustomer(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};

export const fetchCustomerByAuthUUID = (authUUID: string, dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get(`/v1/customers/auth-uuid/${authUUID}`)
    .then((response) => {
      dispatch(setOneCustomer(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};
