import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      margin: '30px 0 30px 20px',
    },
  }),
);
