import React from 'react';
import { useStyles } from './styles';
import { List, ListItem, Typography, Link, Divider } from '@material-ui/core';
import ReactMarkdownWithHTML from 'react-markdown';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  resolveESSAdderName,
  selectCatalogForESSAdders,
  selectESSAdderById,
  selectESSAdderFinancedPrice,
  selectESSAdderFinancedPriceNetIncentives,
  selectEssAdderInverterCount,
  selectESSAdderITCValue,
  selectESSAdderMACRSValue,
} from 'containers/common/Proposals/ESSAdders/selectors';
import ESSStateIncentives from './ESSStateIncentives';
import { RootState } from '../../../../../RootState';
import { selectITCRate } from '../../../../common/Proposals/selectors';
import { toFormattedPrice } from '../../../../../utils/helpers';
import SimpleDagger from '../../../../../components/SimpleDagger';

type Props = {
  essAdderId: number;
  proposalId: number;
};

const ESSAdderOptionItem: React.FC<Props> = ({ proposalId, essAdderId }) => {
  const classes = useStyles()();
  const essAdder = useSelector((state: RootState) => selectESSAdderById(essAdderId, proposalId, state));
  const itcRate = useSelector((state: RootState) => selectITCRate(state, proposalId));
  const itcValue = useSelector((state: RootState) => selectESSAdderITCValue(state, proposalId, essAdderId));
  const macrsValue = useSelector((state: RootState) => selectESSAdderMACRSValue(state, proposalId, essAdderId));
  const financedPrice = useSelector((state: RootState) => selectESSAdderFinancedPrice(state, proposalId, essAdderId));
  const cashPriceNetIncentives = useSelector((state: RootState) =>
    selectESSAdderFinancedPriceNetIncentives(state, proposalId, essAdderId, 25),
  );
  const catalogItems = useSelector((state: RootState) => selectCatalogForESSAdders(essAdder ? [essAdder] : [], state));
  const essAdderName = useSelector(() => (essAdder ? resolveESSAdderName(essAdder, catalogItems) : ''));
  const inverterCount = useSelector(() => (essAdder ? selectEssAdderInverterCount(essAdder) : 0));

  if (!essAdder || !catalogItems[essAdder.id]) {
    return null;
  }

  const essAdderInfo = catalogItems[essAdder.id];

  return (
    <>
      <Typography variant="h3" className={classes.learnMoreHeadline}>
        {essAdderName}
      </Typography>

      <hgroup className={classes.priceInfo}>
        <Typography variant="h5" className={classes.learnMoreText}>
          Cost Breakdown
        </Typography>

        <Typography variant="h5" className={classes.title}>
          Price: <strong>{toFormattedPrice(financedPrice)}</strong>
        </Typography>

        {itcRate > 0 && (
          <Typography variant="subtitle1" className={classes.title}>
            {itcRate * 100}% Investment Tax Credit (ITC): <strong>-{toFormattedPrice(itcValue)}</strong>
          </Typography>
        )}

        {macrsValue > 0 && (
          <Typography variant="subtitle1" className={classes.title}>
            MACRS Cash Value: <strong>-{toFormattedPrice(macrsValue)}</strong>
          </Typography>
        )}

        <ESSStateIncentives essAdderId={essAdder.id} proposalId={proposalId} />

        <Divider className={classes.divider} />

        <Typography variant="subtitle1" className={classes.title}>
          Net Total: <strong>{toFormattedPrice(cashPriceNetIncentives)}</strong>
        </Typography>
      </hgroup>

      <Typography variant="h5" className={classes.learnMoreText}>
        Battery
      </Typography>

      {essAdderInfo.battery && (
        <div className={classes.descriptionContainer}>
          <ReactMarkdownWithHTML>{essAdderInfo.battery.description}</ReactMarkdownWithHTML>
        </div>
      )}

      <Typography variant="h5" className={classes.learnMoreText}>
        Inverters
      </Typography>

      {essAdderInfo.inverters.map((inverter) => (
        <div key={`inverter-${inverter.id}`} className={classes.descriptionContainer}>
          <ReactMarkdownWithHTML>{inverter.description}</ReactMarkdownWithHTML>
        </div>
      ))}

      <div className={classes.specContainer}>
        <section className={clsx(classes.specSubSection, { [classes.panelSection]: true })}>
          <Typography variant="h6" className={classes.learnMoreText}>
            Battery Specs
          </Typography>

          <List>
            <ListItem>Manufacturer: {essAdderInfo.battery?.brand || '--'}</ListItem>
            <ListItem>Model: {essAdderInfo.battery?.model || '--'}</ListItem>
            <ListItem>Battery Count: {essAdder.quantity}</ListItem>
            {essAdderInfo.battery?.datasheetUrls.map((d) => (
              <ListItem key={`${d.name}-${d.url}`}>
                Download: &nbsp;
                <Link href={d.url} title={`Download ${d.name}`} target="_blank" variant="body2" rel="noopener">
                  {d.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </section>

        <section className={classes.specSubSection}>
          <Typography variant="h6" className={classes.learnMoreText}>
            Inverter Specs
          </Typography>

          {essAdderInfo.inverters.map((inverter) => (
            <List key={`inverter-${inverter.id}`}>
              <ListItem>Manufacturer: {inverter.brand}</ListItem>
              <ListItem>Quantity: {inverterCount}</ListItem>
              <ListItem>Monitoring: {inverter.monitoring ? 'Yes' : 'No'}</ListItem>
              {inverter.datasheetUrls.map((d) => (
                <ListItem key={`${d.name}-${d.url}`}>
                  Download: &nbsp;
                  <Link href={d.url} title={`Download ${d.name}`} target="_blank" variant="body2" rel="noopener">
                    {d.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          ))}
        </section>
      </div>
      <List className={classes.footNotes}>
        <ListItem>
          <Typography variant="body1">
            <SimpleDagger className={classes.footerDagger} />
            Net first year incentives
          </Typography>
        </ListItem>
      </List>
    </>
  );
};

export default ESSAdderOptionItem;
