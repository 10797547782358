import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';
import StyledLink from '../../../Link';
import { toChooseSolarOptionPath } from '../../../../CheckoutPages/routes';
import InviteCustomerButton from '../InviteCustomerButton';

type Props = {
  proposal: ProposalIndexItem;
  canInvite: boolean;
};

const ProposalRow: React.FC<Props> = ({ proposal, canInvite }) => (
  <React.Fragment>
    <TableRow>
      <TableCell component="th" scope="row">
        {proposal.id}
      </TableCell>

      <TableCell component="th" scope="row">
        <StyledLink to={toChooseSolarOptionPath(proposal.id.toString())}>{proposal.name || 'Untitled'}</StyledLink>
      </TableCell>

      <TableCell component="th" scope="row">
        {proposal.customerId}
      </TableCell>

      <TableCell component="th" scope="row">
        {proposal.advocateId}
      </TableCell>

      <TableCell component="th" scope="row">
        {canInvite ? <InviteCustomerButton proposal={proposal} /> : '--'}
      </TableCell>
    </TableRow>
  </React.Fragment>
);

export default ProposalRow;
