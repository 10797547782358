import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCashOption, selectEligibleLoanOptions, selectIsESSFinancingUIOpen } from './selectors';
import { setShowEligibleESSAdderFinanceOptions } from './actions';
import { List, ListItem } from '@material-ui/core';
import { RootState } from '../../../../RootState';
import LoanOption from '../../pg-choose-your-financing/Financing/FinanceOptions/LoanOption';
import { selectProposal } from '../../../common/Proposals/selectors';
import SaveMostOption from '../../pg-choose-your-financing/Financing/FinanceOptions/SaveMostOption';
import { updateCart } from '../../../common/Proposals/actions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  proposalId: number;
};

const ESSAdderFinanceOptions: React.FC<Props> = ({ proposalId }) => {
  const classes = useStyles();
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const isOpen = useSelector(selectIsESSFinancingUIOpen);
  const cashOption = useSelector((state: RootState) => selectCashOption(state, proposalId));
  const loanOptions = useSelector((state: RootState) => selectEligibleLoanOptions(state, proposalId));
  const dispatch = useDispatch();

  if (!proposal) return null;

  const handleClose = () => {
    dispatch(setShowEligibleESSAdderFinanceOptions(false));
  };

  const handleSubmit = (financeOptionId: number) => {
    updateCart(dispatch, proposal.id, { ...proposal.cart, financeOption: financeOptionId });
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Batteries are only available with select solar finance options. If you would like to add a battery please change
        your solar finance option to one of the following choices:
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        <List>
          {loanOptions.map((lo) => (
            <ListItem key={lo.id}>
              <LoanOption loanOption={lo} preventFade onSubmit={() => handleSubmit(lo.id)} proposal={proposal} />
            </ListItem>
          ))}
          {cashOption && (
            <ListItem>
              <SaveMostOption
                financeOption={cashOption}
                preventFade
                onSubmit={() => handleSubmit(cashOption.id)}
                proposal={proposal}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ESSAdderFinanceOptions;
