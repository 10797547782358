import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  height?: number;
};

const defaultSize = 50;

const FederalIcon: React.FC<Props> = ({ height }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} height={height || defaultSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 60">
      <g strokeWidth="2.1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2 20.2L27.022 2 49.6 20.2zM5 24.84h42M5 53h42M2 58h47.6M8 48h35M25.95 30.112v13.44M33.93 30.112v13.44M41.91 30.112v13.44M9.99 30.112v13.44M17.97 30.112v13.44" />
      </g>
    </svg>
  );
};

export default FederalIcon;
