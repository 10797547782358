import React from 'react';
import * as yup from 'yup';
import { TextField as MUITextField } from 'formik-material-ui';
import { Auth } from 'aws-amplify';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { FormControl, LinearProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setGroups, setIDTokenClaims, setIsAuthenticated } from '../actions';
import { Route as SignUpRoute } from '../pg-sign-up/route';
import { Route as ForgotPasswordRoute } from '../pg-forgot-password/route';
import { toUserSession } from '../../common/UserSession';
import SubheaderWithLink from '../../common/SubheaderWithLink';
import AuthLayout from '../AuthLayout';
import { useStyles } from '../styles';
import StyledButton from '../../common/StyledButton';
import { useLocation, useHistory } from 'react-router-dom';
import { isAdmin, isAdvocate } from '../../common/Group';
import { CustomersRoute } from '../../pg-customers';
import RequirePasswordChange from './RequirePasswordChange';

export const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
});

interface Params {
  email: string;
  password: string;
}

const toSafelyDecoded = (encoded: string) => {
  try {
    return atob(encoded);
  } catch {
    return '';
  }
};

const SignInPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [requiresPasswordChange, setRequiresPasswordChange] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState(false);
  const params = new URLSearchParams(location.search);
  const email = params.get('email') || '';
  const tok = toSafelyDecoded(params.get('tok') || '');
  const hasQueryParams = Boolean(email) && Boolean(tok);
  const initialValues: Params = { email, password: tok };
  const dispatch = useDispatch();

  const submitHandler = async ({ email, password }: Params, f: FormikHelpers<Params>) => {
    try {
      const result = await Auth.signIn({ username: email, password });

      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(result);
        setRequiresPasswordChange(true);
        return;
      }

      if (result.signInUserSession !== null) {
        dispatch(setIsAuthenticated(true));
        const session = result.getSignInUserSession();
        const userSession = toUserSession(session.accessToken.payload);
        dispatch(setGroups(userSession));
        dispatch(setIDTokenClaims(session.getIdToken().payload));

        if (isAdmin(userSession) || isAdvocate(userSession)) {
          history.push(CustomersRoute.Path);
        }
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(email);
        f.setStatus({
          error: 'You must first confirm your email. Please check your inbox to validate your email address',
        });
        return;
      }
      f.setStatus({ error: e.message });
    }
  };

  const removeQueryParams = () => {
    history.push(location.pathname);
  };

  if (requiresPasswordChange) {
    return <RequirePasswordChange cognitoUser={cognitoUser} />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
      {({ isValid, setFieldValue, submitForm, setStatus, status, isSubmitting }) => {
        if (hasQueryParams && !isSubmitting) {
          submitForm();
          removeQueryParams();
        }

        if (isSubmitting) {
          return (
            <AuthLayout title="Solvana | Sign in" header="">
              <LinearProgress className={classes.progress} />
            </AuthLayout>
          );
        }

        return (
          <AuthLayout title="Solvana | Sign in" header="Sign in to Solvana" subHeader="Please enter your details below">
            <Form>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting || hasQueryParams}
                  component={MUITextField}
                  className={classes.input}
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('email', e.target.value);
                  }}
                  type="email"
                  label="Email"
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="password"
                  type="password"
                  label="Password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('password', e.target.value);
                  }}
                />
              </FormControl>

              {status && status.error && (
                <Typography variant="subtitle1" className={classes.error}>
                  {status.error}
                </Typography>
              )}

              <div className={classes.submitContainer}>
                <StyledButton
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Sign in
                </StyledButton>
              </div>

              <div className={classes.linkContainer}>
                <SubheaderWithLink to={SignUpRoute.Path} subheader="Need an account?" name="Create one here" />
                <SubheaderWithLink to={ForgotPasswordRoute.Path} name="I forgot my password" />
              </div>
            </Form>
          </AuthLayout>
        );
      }}
    </Formik>
  );
};

export default SignInPage;
