import React from 'react';
import { Typography, Popover, Link } from '@material-ui/core';
import { useStyles } from './styles';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import Factors from './Factors';

type Props = {
  proposal: Proposal;
};

const locationFor = (taxEntity: TaxEntity): string =>
  ({
    [TaxEntity.Commercial]: 'business',
    [TaxEntity.Residential]: 'home',
    [TaxEntity.NonProfit]: 'organization',
  }[taxEntity] || 'home');

const SubHeader: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography className={classes.root} variant="body1">
        The following options are turn-key systems that include everything you need to go solar. They were designed for
        your {locationFor(proposal.tax.entity)} by our experts based on over
        <Link
          color="secondary"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.link}
        >
          30 factors
        </Link>
        . Please choose a solar option to continue.
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Factors />
      </Popover>
    </>
  );
};

export default SubHeader;
