import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
    tree: {
      stroke: theme.palette.primary.main,
      fill: theme.palette.background.paper,
    },
    mask: {
      fill: theme.palette.background.paper,
    },
  }),
);

const defaultWidth = 50;

const ALotOfShadingWithTreeRemoval: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 82">
      <title>{title || 'A lot of shading (tree removal)'}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M110.32 21.236c-4.865 4.867-12.83 4.79-17.79-.168-4.96-4.96-5.035-12.924-.168-17.791M84.261 14.699L77 15.85M98.899 29.338l-1.15 7.26M85.963 3.95L82.139 2M89.202 24.397l-3.035 3.035M109.649 27.635l1.948 3.825"
          className={classes.highlight}
          strokeWidth="2.94"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.89 65.163l-.296 8.522-4.487-.157L69 76.599h36.675l-12.293-6.774-.109 3.125-10.468-5.77-.099 2.842-8.815-4.859z"
          className={classes.tree}
          strokeWidth="2.352"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g className={classes.tree} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.352">
          <path d="M50.4 31l8.59 17.194h-3.938l6.8 13.612-3.579-.001L64 73.269l-10.737-.001V79h-5.01l-.001-5.732H36.8l5.726-11.463-3.579.001 6.799-13.612h-3.935L50.4 31z" />
          <path d="M33.783 64.163l.297 8.522 4.487-.157.107 3.071H1.999l12.293-6.774.108 3.125 10.469-5.77.098 2.842 8.816-4.859z" />
        </g>
        <path className={classes.mask} d="M20.511 56.882v22.595h30.41V56.882L36 45z" />
        <path
          className={classes.detail}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M35.716 49.723l7.151 5.38"
        />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M35.716 45L17 59.082M50.92 59.93v19.547H20.512V59.931"
        />
        <path
          className={classes.detail}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M35.716 49.723l-7.151 5.38"
        />
        <path strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round" d="M35.716 45l18.716 14.082" />
        <path
          d="M61.6 27l9.6 19.2-4.401-.001L74.4 61.4l-4.001-.001L76.8 74.2l-12.001-.001.001 6.401h-5.6l-.001-6.401L46.4 74.2l6.4-12.801-4 .001 7.599-15.201L52 46.2 61.6 27z"
          className={classes.tree}
          strokeWidth="2.352"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ALotOfShadingWithTreeRemoval;
