import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { verticalToHorizBreakpoint } from '../../styles';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        border: 'none',
        width: '100%',
        textOverflow: 'ellipsis',
        borderRadius: theme.spacing(0.5),
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,0.1)',
        transition: 'all .2s ease-out',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.2)',
        },
        background: 'transparent',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          maxWidth: '80px',
        },
      },
      media: {
        width: '100%',
        margin: '0 auto',
      },
      content: {
        padding: `${theme.spacing(0.5, 1, 1)} !important`,
        margin: 0,
      },
      name: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        fontSize: '14px',
      },
      price: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        fontSize: '12px',
        margin: 0,
        padding: 0,
      },
    }),
  );
