import React from 'react';
import { useStyles } from './styles';
import { List, ListItem, Typography, Link, Chip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import ReactMarkdownWithHTML from 'react-markdown';
import {
  resolveSolarOptionName,
  selectCatalogForSolarOption,
  selectOrderedSolarOptions,
  selectSolarOptionById,
} from '../../../../common/Proposals/SolarOptions/selectors';
import { totalInverterCount, totalPanelCount } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';
import clsx from 'clsx';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import SolarIcon from '../../../../../components/SolvanaIcons/SolarIcon';

type Props = {
  solarOptionId: number;
  proposalId: number;
};

const SolarOptionItem: React.FC<Props> = ({ proposalId, solarOptionId }) => {
  const classes = useStyles()();
  const solarOption = useSelector((state: RootState) => selectSolarOptionById(state, proposalId, solarOptionId));
  const catalogItems = useSelector((state: RootState) => {
    const emptyCatalog = { subArrays: {}, inverters: [] };
    return solarOption ? selectCatalogForSolarOption(solarOption, state) : emptyCatalog;
  });
  const orderedSolarOptions = useSelector((state: RootState) => selectOrderedSolarOptions(state, proposalId));
  const isUpgrade = orderedSolarOptions.findIndex((o) => o.id === solarOptionId) > 0;

  if (!solarOption) return null;

  const horizonLength = 1;
  const production = System.yearlyProduction(solarOption, horizonLength)[1];

  const subArrayProducts = catalogItems ? Object.values(catalogItems.subArrays) : [];
  const subArrayCatalogData = subArrayProducts.length > 0 ? subArrayProducts[0] : undefined;

  return (
    <>
      {isUpgrade && <Chip className={classes.label} color="primary" label="UPGRADE" />}

      <Typography variant="h3" className={classes.learnMoreHeadline}>
        {resolveSolarOptionName(catalogItems)}
      </Typography>

      <Typography variant="h5" className={classes.learnMoreText}>
        Panels
      </Typography>

      {subArrayCatalogData && subArrayCatalogData.description && (
        <div className={classes.descriptionContainer}>
          <ReactMarkdownWithHTML>{subArrayCatalogData.description}</ReactMarkdownWithHTML>
        </div>
      )}

      <Typography variant="h5" className={classes.learnMoreText}>
        Inverters
      </Typography>

      {catalogItems.inverters.map((inverter) => (
        <div key={`inverter-${inverter.id}`} className={classes.descriptionContainer}>
          <ReactMarkdownWithHTML>{inverter.description}</ReactMarkdownWithHTML>
        </div>
      ))}

      <div className={classes.specContainer}>
        <section className={clsx(classes.specSubSection, { [classes.panelSection]: true })}>
          <Typography variant="h6" className={classes.learnMoreText}>
            Panel Specs
          </Typography>

          <List>
            <ListItem>Manufacturer: {subArrayCatalogData?.brand || '--'}</ListItem>
            <ListItem>Model: {subArrayCatalogData?.model || '--'}</ListItem>
            <ListItem>Panel Count: {totalPanelCount(solarOption)}</ListItem>
            <ListItem>
              System Production: {`${production.toLocaleString('en-US', { maximumFractionDigits: 0 })} kWh in year 1`}
            </ListItem>
            <ListItem>
              Workmanship Warranty: {subArrayCatalogData ? `${subArrayCatalogData.workmanshipWarranty} yrs` : '--'}
            </ListItem>
            <ListItem>
              Performance Warranty: {subArrayCatalogData ? `${subArrayCatalogData.performanceWarranty} yrs` : '--'}
            </ListItem>
            <ListItem>
              Annual Degradation: {subArrayCatalogData ? `${100 * subArrayCatalogData.annualDeg} %` : '--'}
            </ListItem>
            {subArrayCatalogData?.datasheetUrls.map((d) => (
              <ListItem key={`${d.name}-${d.url}`}>
                Download: &nbsp;
                <Link href={d.url} title={`Download ${d.name}`} target="_blank" variant="body2" rel="noopener">
                  {d.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </section>

        <section className={classes.specSubSection}>
          <Typography variant="h6" className={classes.learnMoreText}>
            Inverter Specs
          </Typography>

          {catalogItems.inverters.map((inverter) => (
            <List key={`inverter-${inverter.id}`}>
              <ListItem>Manufacturer: {inverter.brand}</ListItem>
              <ListItem>Quantity: {totalInverterCount(solarOption)}</ListItem>
              <ListItem>Monitoring: {inverter.monitoring ? 'Yes' : 'No'}</ListItem>
              {inverter.datasheetUrls.map((d) => (
                <ListItem key={`${d.name}-${d.url}`}>
                  Download: &nbsp;
                  <Link href={d.url} title={`Download ${d.name}`} target="_blank" variant="body2" rel="noopener">
                    {d.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          ))}
        </section>
      </div>
      <div className={classes.installationInfo}>
        <SolarIcon white />
        <hgroup>
          <Typography variant="h6" className={classes.learnMoreText}>
            Installation:
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            Usually installed within 4 months of purchase.
          </Typography>
        </hgroup>
      </div>
    </>
  );
};

export default SolarOptionItem;
