export enum Actions {
  SetFinanceOptionsCategory = 'solar-benefits/SetFinanceOptionsCategory',
  SetShowFinanceOptionCategories = 'solar-benefits/ShowFinanceOptionCategories',
  SetShowFinanceOptionItems = 'solar-benefits/ShowFinanceOptionItems',
  SetShowFinanceBenefitsOverview = 'solar-benefits/SetShowFinanceBenefitsOverview',
}

export enum FinanceCategory {
  SaveMore = 'Save More',
  SaveMost = 'Save Most',
}

export const setFinanceOptionsCategory = (category: FinanceCategory) => ({
  type: Actions.SetFinanceOptionsCategory,
  category,
});

export const setShowFinanceBenefitsOverview = (show: boolean) => ({
  type: Actions.SetShowFinanceBenefitsOverview,
  show,
});

export const setShowFinanceOptionItems = (show: boolean) => ({
  type: Actions.SetShowFinanceOptionItems,
  show,
});

export const setShowFinanceOptionCategories = (show: boolean) => ({
  type: Actions.SetShowFinanceOptionCategories,
  show,
});
