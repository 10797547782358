import { Dispatch } from 'redux';
import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { UserProfile } from '@solvana/proposal-tool-domain/dist/common/UserProfile';

export enum Actions {
  SetCurrentUserProfile = 'currentUserProfile/SetCurrentUserProfile',
  SetIsFetching = 'currentUserProfile/SetIsFetching',
  SetHasResolved = 'currentUserProfile/SetHasResolved',
}

export const setCurrentUserProfile = (currentUserProfile: UserProfile) => ({
  type: Actions.SetCurrentUserProfile,
  currentUserProfile,
});

export const setIsFetching = (isFetching: boolean) => ({
  type: Actions.SetIsFetching,
  isFetching,
});

export const setHasResolved = (hasResolved: boolean) => ({
  type: Actions.SetHasResolved,
  hasResolved,
});

export const fetchCurrentUserProfile = (dispatch: Dispatch) => {
  dispatch(setIsFetching(true));

  proposalToolApi
    .get('/v1/my-profile')
    .then((response) => {
      if (response.data) {
        dispatch(setCurrentUserProfile(response.data));
      }
    })
    .catch()
    .finally(() => {
      dispatch(setIsFetching(false));
      dispatch(setHasResolved(true));
    });
};
