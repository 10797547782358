import { Dispatch } from 'redux';
import { Panel } from '@solvana/catalog-domain/dist/Panel';
import { catalogApi } from '../../../../lib/api/catalogApi';

export enum Actions {
  SetIsFetchingPanels = 'catalog/SetIsFetchingPanels',
  SetPanelsFetchError = 'catalog/SetPanelsFetchError',
  SetPanels = 'catalog/SetPanels',
}

export const setPanels = (panels: Panel[]) => ({
  type: Actions.SetPanels,
  panels,
});

export const setPanelsFetchError = (error: string | null) => ({
  type: Actions.SetPanelsFetchError,
  error,
});

export const setIsFetchingPanels = (isFetching: boolean) => ({
  type: Actions.SetIsFetchingPanels,
  isFetching,
});

export const fetchPanels = (dispatch: Dispatch) => {
  dispatch(setIsFetchingPanels(true));
  dispatch(setPanelsFetchError(null));

  catalogApi
    .get('/panels')
    .then((response) => {
      dispatch(setPanels(response.data));
      dispatch(setIsFetchingPanels(false));
    })
    .catch((e) => {
      dispatch(setIsFetchingPanels(false));
      dispatch(setPanelsFetchError(e.toString()));
    });
};
