import { selectProposal, SelectProposalState } from '../../../../../common/Proposals/selectors';
import { FinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { YearlyValues } from '@solvana/proposal-tool-domain/dist/common';
import StateIncentives from '@solvana/proposal-tool-domain/dist/lib/StateIncentives';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { useMemo } from 'react';

const horizonForFinanceOption = (financeOption?: FinanceOption): number => {
  if (!financeOption) return 0;
  return Math.floor((financeOption.termInMonths || 0) / 12);
};

export const selectAvgMonthlyStatePBIIncentivesOverLoanTerm = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = useMemo(() => proposal.financeOptions.find((fo) => fo.id === financeOptionId), [
    financeOptionId,
    proposal,
  ]);
  const horizonLength = horizonForFinanceOption(financeOption) || 25;

  const incentives = StateIncentives.incentives(proposal, horizonLength);
  const pbiTotal = Object.values(incentives.pbi).reduce((acc, pbi) => {
    acc += pbi;
    return acc;
  }, 0);

  return pbiTotal / horizonLength / 12;
};

export const selectTaxCreditMonthlyValueForFinanceTerm = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  const horizonLength = horizonForFinanceOption(financeOption) || 25;

  const incentives = StateIncentives.incentives(proposal, horizonLength);
  return incentives.taxCredit / (horizonLength * 12);
};

export const selectSolarSavingsOverLoanTerm = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  const horizonLength = horizonForFinanceOption(financeOption) || 25;

  const total = System.totalSolarSavings(proposal, horizonLength);

  return total / horizonLength / 12;
};

export const selectCashInHandTotal = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return Pricing.cashInHandTotal(
    { ...proposal, cart: { ...proposal.cart, financeOption: financeOptionId } },
    horizonLength,
  );
};

interface AveAnnualSavings {
  duringFinancingTerm: number;
  afterFinancingTerm: number;
}

export const selectAvgAnnualSavings = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
  horizonLength: number,
): AveAnnualSavings => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return { duringFinancingTerm: 0, afterFinancingTerm: 0 };

  return Pricing.avgAnnualSavingsForFinanceTerm(
    { ...proposal, cart: { ...proposal.cart, financeOption: financeOptionId } },
    horizonLength,
  );
};

export const selectStateIncentives = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): { taxCredit: number; pbi: YearlyValues } => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return { taxCredit: 0, pbi: {} };

  return StateIncentives.incentives(proposal, horizonLength);
};

export const selectStateIncentivesTotal = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return StateIncentives.total(proposal, horizonLength);
};

export const selectFinanceOption = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): FinanceOption | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return proposal.financeOptions.find((fo) => fo.id === financeOptionId);
};
