import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import { YearlyValues } from '@solvana/proposal-tool-domain/dist/common';
import { isLoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import System from '@solvana/proposal-tool-domain/dist/lib/System';

export const selectAnnualFinancingNetIncentives = (
  state: SelectProposalState,
  proposalID: number,
  financeOptionId: number,
): YearlyValues | undefined => {
  const proposal = selectProposal(state, proposalID);
  if (!proposal) return {};

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return {};

  return Pricing.annualPaymentsNetAllIncentives({
    ...proposal,
    cart: { ...proposal.cart, financeOption: financeOptionId },
  });
};

export const selectRemainingBills = (
  state: SelectProposalState,
  proposalID: number,
  horizonLength: number,
): YearlyValues => {
  const proposal = selectProposal(state, proposalID);
  if (!proposal) return {};

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return {};

  return System.remainingUtilityBills(solarOption, proposal, horizonLength);
};

export const selectAnnualUtilityBills = (
  state: SelectProposalState,
  proposalID: number,
  horizonLength: number,
): YearlyValues => {
  const proposal = selectProposal(state, proposalID);
  if (!proposal) return {};

  return System.annualUtilityBills(proposal, horizonLength);
};
