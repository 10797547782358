import React from 'react';
import { useStyles } from './styles';
import IconButton from '@material-ui/core/IconButton';
import { RiCloseCircleFill } from 'react-icons/ri';

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
};

const CloseButton: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles()();

  return (
    <IconButton classes={{ root: classes.root }} size="small" onClick={onClick}>
      <RiCloseCircleFill />
    </IconButton>
  );
};

export default CloseButton;
