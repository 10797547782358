import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      textTransform: 'capitalize',
      fontWeight: 900,
      marginRight: theme.spacing(3),
      color: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      height: '36px',
    },
    inverted: {
      '& svg': {
        fill: '#fff',
      },
      color: '#fff',
    },
    menuContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    menuTitle: {
      marginRight: theme.spacing(2),
      fontWeight: 900,
    },
  }),
);
