import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    objectFill: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 50;

const NoSalesTax: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 60">
      <title>No Sales Tax</title>

      <g fill="none" fillRule="evenodd">
        <path
          d="M21.116 38.748c0-1.336-.956-2.233-2.137-2.233-1.276 0-2.127 1.004-2.127 2.233 0 1.346.957 2.243 2.127 2.243 1.279 0 2.137-1.005 2.137-2.243zm-6.165-.01c0-2.175 1.608-3.95 4.008-3.95 2.224 0 4.027 1.58 4.027 3.95 0 2.175-1.599 3.968-4.027 3.968-2.204 0-4.008-1.6-4.008-3.968zM9.675 42.21l6.271-14.131 1.795.78-6.261 14.13-1.805-.78zm-1.278-7.773c1.288 0 2.137-1.014 2.137-2.252 0-1.346-.946-2.234-2.137-2.234-1.258 0-2.105.995-2.105 2.234 0 1.346.945 2.252 2.106 2.252zm-.01 1.707c-2.212 0-4.018-1.59-4.018-3.98 0-2.174 1.61-3.95 4.019-3.95 2.214 0 4.018 1.572 4.018 3.95 0 2.186-1.61 3.98-4.019 3.98z"
          className={classes.objectFill}
        />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.05 12.39h25.256v46.055l-2.893-1.667-2.377 1.667-2.377-1.667-2.377 1.667-2.377-1.667-2.376 1.69-2.378-1.69-2.377 1.69-2.377-1.69-3.347 1.667z"
        />
        <path d="M47.696 14.967c0 7.686-6.23 13.917-13.917 13.917-7.687 0-13.918-6.23-13.918-13.917 0-7.687 6.23-13.917 13.918-13.917 7.687 0 13.917 6.23 13.917 13.917" />
        <path
          d="M47.696 14.967c0 7.686-6.23 13.917-13.917 13.917-7.687 0-13.918-6.23-13.918-13.917 0-7.687 6.23-13.917 13.918-13.917 7.687 0 13.917 6.23 13.917 13.917z"
          strokeWidth="2.1"
        />
        <path strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round" d="M43.333 5.21L23.818 24.725" />
      </g>
    </svg>
  );
};

export default NoSalesTax;
