import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserProfile } from '../../../common/CurrentUserProfile/selectors';
import base64url from 'base64url';
import { selectIsSubmittingRFA, selectRFAFormData, selectRFASubmitSuccess } from '../../selectors';
import { setRFASubmitError, submitRFA } from '../../actions';

const UtilityAPI: React.FC = () => {
  const classes = useStyles();
  const { entity } = useSelector(selectCurrentUserProfile);
  const dispatch = useDispatch();
  const submitSuccess = useSelector(selectRFASubmitSuccess);
  const isSubmittingRFA = useSelector(selectIsSubmittingRFA);
  const rfaFormData = useSelector(selectRFAFormData);
  const rfaState = base64url(JSON.stringify(rfaFormData));
  const baseURL = `https://utilityapi.com/authorize/go-solvana?state=${rfaState}`;
  const iFrameSrc = entity ? `${baseURL}&email=${encodeURIComponent(entity.email)}` : baseURL;

  const setError = (error: string) => {
    dispatch(setRFASubmitError(error));
  };

  const handleMessage = (msg: MessageEvent) => {
    if (msg.origin === document.location.origin && msg.data.utilityApiRedirect) {
      if (!msg.data.error) {
        const { referral, state } = msg.data;
        const decodedState = JSON.parse(base64url.decode(state));
        const rfa = { ...decodedState, referralId: referral };

        submitRFA(dispatch, rfa);
        return;
      }

      switch (msg.data['error']) {
        case 'access_denied':
          setError('Access Declined. Please try again.');
          break;
        case 'utility_down':
          setError('Unfortunately the utility system is currently unavailable. Please try again later.');
          break;
        case 'access_invalid':
          setError('It looks like the access credentials you provided were invalid. Please try again.');
          break;
        case 'access_locked':
          setError('It looks like this account is locked out. Please go unlock your account and try again.');
          break;
        default:
          setError('Whoops! Something went wrong :-(');
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (!isSubmittingRFA && !submitSuccess) {
    return (
      <>
        <Typography className={classes.paragraph} variant="subtitle1">
          Securely share your utility usage history with Solvana for proposals with the most accurate financial benefit
          simulations.
        </Typography>
        <Card className={classes.formContainer}>
          <iframe
            title="Utility API Form"
            src={iFrameSrc}
            style={{
              height: '800px',
              width: '100%',
              border: 'none',
            }}
          />
        </Card>
      </>
    );
  }

  return null;
};

export default UtilityAPI;
