import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 0,
      overflowY: 'scroll',
    },
    paper: {
      width: '100%',
      maxWidth: '600px',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: 'none',
      '&:focus-visible, &:focus, &:active': {
        border: 'none',
        boxShadow: 'none',
      },
    },
    paperWide: {
      width: '100%',
      maxWidth: '1200px',
    },
    titleBar: {
      padding: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
