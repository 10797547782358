import { FAQItem } from './types';

export const residentialFAQs: FAQItem[] = [
  {
    summary: 'What is re-amortization?',
    details:
      'A loan re-amortization is the act of recalculating the monthly payment based on the balance of the loan at the time of the re-amortization.',
  },
  {
    summary: 'What if I pay more than my minimum monthly payment during the first 18 months?',
    details:
      'Any amount you pay above your monthly payment in the first 18 months will get applied toward your payment due. If you pay more than the monthly payment at this point, it will not change your minimum monthly payment until the re-amortization on the 18th month.',
  },
  {
    summary: 'What if I want to make a payment above my minimum monthly payment after the 18th month?',
    details:
      'Yes, you begin accruing interest daily once your loan is funded. Paying more than your required monthly payments in the first 18 months will not reduce the interest that is accrued. Your monthly payments will first go toward any accrued interest then toward your principal.',
  },
  {
    summary: 'Am I paying interest in the first 18 months?',
    details:
      'Yes, you begin accruing interest daily once your loan is funded. Paying above your monthly payments in the first 18 months will not reduce the interest that is accrued. Your monthly payments will first go toward any accrued interest then toward your principal.',
  },
  {
    summary: 'Can I pay the loan off early?',
    details:
      'Yes! You can prepay the loan in full or in part at any time. Simply call your lender to find out the payoff balance. Remember, the earlier you pay it off , the less interest you will have accrued on the loan. There is no charge or penalty to prepay your loan or any portion thereof.',
  },
  {
    summary: 'What is the charge for a late payment?',
    details:
      'If you make a late payment you will be charged the higher of either 5% of your monthly payment or $25. Paying by automated payments makes sure you never have a late payment and never hurt your credit score due to a late payment.',
  },
];
