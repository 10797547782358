import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { toFormattedPrice } from 'utils/helpers';

type Props = {
  title: string;
  value: number;
  Sup?: React.FC;
};

const LineItem: React.FC<Props> = ({ title, value, Sup }) => {
  const classes = useStyles()();

  return (
    <hgroup className={classes.root}>
      <Typography className={classes.title} variant="body1">
        {title} {Sup && <Sup />}
      </Typography>
      {value > 0 ? (
        <Typography className={classes.loss} variant="body1">
          {toFormattedPrice(value)}
        </Typography>
      ) : (
        <Typography className={classes.savings} variant="body1">
          <strong>{value === 0 ? '--' : `-${toFormattedPrice(Math.abs(value))}`}</strong>
        </Typography>
      )}
    </hgroup>
  );
};

export default LineItem;
