import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { Auth } from 'aws-amplify';

export const toApiClient = (baseURL: string): AxiosInstance => {
  const client = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const user = await Auth.currentAuthenticatedUser();

      if (!user) return Promise.reject(new Error('User session not found'));

      const { jwtToken } = user.signInUserSession.idToken;

      if (!config.headers) config.headers = {} as AxiosRequestHeaders;
      config.headers.Authorization = `Bearer ${jwtToken}`;

      return Promise.resolve(config);
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return client;
};
