import React from 'react';
import { useStyles } from './styles';
import { Card } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  isSelected: boolean;
  withFade: boolean;
  className?: string;
  onClick?: () => any;
  children: React.ReactNode;
};

const PopCard: React.FC<Props> = ({ children, onClick, isSelected, withFade, className }) => {
  const classes = useStyles()();

  return (
    <Card
      onClick={() => onClick && onClick()}
      elevation={0}
      className={clsx(classes.root, {
        [classes.rootSelected]: isSelected,
        [classes.transparent]: withFade,
        [classes.pointer]: onClick,
        [className || '']: Boolean(className),
      })}
    >
      {children}
    </Card>
  );
};

export default PopCard;
