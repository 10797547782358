import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        color: Colors.GreyFive,
        margin: theme.spacing(0, 0, 3),
        '& h6, p, h2': {
          color: Colors.GreyFive,
        },
        '& h6': {
          marginBottom: theme.spacing(2),
        },
        padding: theme.spacing(2, 2, 0),

        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          '& h6': {
            marginBottom: theme.spacing(1),
          },
        },
      },
      leftContainer: {
        '& svg': {
          width: '250px',
        },
        [theme.breakpoints.down('md')]: {
          margin: '0 auto',
        },
      },
      rightContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
      },
      btn: {
        textTransform: 'none',
        padding: theme.spacing(0, 1),
      },
    }),
  );
