import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = () =>
  makeStyles(() =>
    createStyles({
      input: {
        width: '100%',
        marginBottom: '20px',
      },
    }),
  );
