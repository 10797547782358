import { Dispatch } from 'redux';
import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { Proposal, ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';
import { SelectedAdder } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';

export enum Actions {
  SetCartUpdateError = 'proposals/SetCartUpdateError',
  SetError = 'proposals/SetError',
  SetIsFetching = 'proposals/SetIsFetching',
  SetIsInviting = 'proposals/SetIsInviting',
  SetIsUpdatingCart = 'proposals/SetIsUpdatingCart',
  SetOneProposal = 'proposals/SetOneProposal',
  SetProposals = 'proposals/SetProposals',
  SetSelectedAdder = 'proposals/SetSelectedAdder',
  RemoveSelectedAdder = 'proposals/RemoveSelectedAdder',
  UpdateProposalSharedOnDate = 'proposals/UpdateProposalSharedOnDate',
}

export const setOneProposal = (proposal: Proposal) => ({
  type: Actions.SetOneProposal,
  proposal,
});

export const updateProposalSharedOnDate = (proposal: Proposal) => ({
  type: Actions.UpdateProposalSharedOnDate,
  proposal,
});

export const setSelectedAdder = (proposalId: string, selectedAdder: SelectedAdder) => ({
  type: Actions.SetSelectedAdder,
  proposalId,
  selectedAdder,
});

export const removeSelectedAdder = (proposalId: string, selectedAdderId: number) => ({
  type: Actions.RemoveSelectedAdder,
  proposalId,
  selectedAdderId,
});

export const setProposals = (proposals: ProposalIndexItem[]) => ({
  type: Actions.SetProposals,
  proposals,
});

export const setIsFetching = (isFetching: boolean) => ({
  type: Actions.SetIsFetching,
  isFetching,
});

export const setIsUpdatingCart = (isUpdatingCart: boolean) => ({
  type: Actions.SetIsUpdatingCart,
  isUpdatingCart,
});

export const setError = (error: string | null) => ({
  type: Actions.SetError,
  error,
});

export const setCartUpdateError = (cartUpdateError: string | null) => ({
  type: Actions.SetCartUpdateError,
  cartUpdateError,
});

export const fetchProposalsForCustomer = (dispatch: Dispatch, customerId: string) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get(`/v1/customers/${customerId}/proposals`)
    .then((response) => {
      dispatch(setProposals(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};

export const fetchProposals = (dispatch: Dispatch) => {
  dispatch(setIsFetching(true));
  dispatch(setError(null));

  proposalToolApi
    .get('/v1/proposals')
    .then((response) => {
      dispatch(setProposals(response.data));
      dispatch(setIsFetching(false));
    })
    .catch((e) => {
      dispatch(setIsFetching(false));
      dispatch(setError(e.toString()));
    });
};

type UpdateCart = (
  dispatch: Dispatch,
  proposalId: string | number,
  cart: { solarOption?: number | null; financeOption?: number | null; essAdder?: number | null },
  onSuccess?: () => any,
) => void;

export const updateCart: UpdateCart = (dispatch, proposalId, cart, onSuccess) => {
  dispatch(setIsUpdatingCart(true));
  dispatch(setCartUpdateError(null));

  proposalToolApi
    .put(`/v1/proposals/${proposalId}/cart`, cart)
    .then((response) => {
      dispatch(setOneProposal(response.data));
      dispatch(setIsUpdatingCart(false));
      onSuccess && onSuccess();
    })
    .catch((e) => {
      dispatch(setIsUpdatingCart(false));
      dispatch(setCartUpdateError(e.toString()));
    });
};
