import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    objectFill: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 50;

const NoPropertyTax: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={width || defaultWidth}
      height={width || defaultWidth}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 71 67"
    >
      <title>No Property Tax</title>

      <g fill="none" fillRule="evenodd">
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.053 32.405l34.12-31.352L69.29 32.405"
        />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.43 33.635L35.173 9.66l26.742 23.975M55.384 19.763V9.661h-5.3v4.787"
        />
        <path
          d="M40.506 44.467c0-.958-.686-1.602-1.531-1.602-.918 0-1.526.72-1.526 1.602 0 .965.686 1.609 1.526 1.609.915 0 1.531-.722 1.531-1.61zm-4.42-.007c0-1.56 1.153-2.833 2.874-2.833 1.595 0 2.889 1.133 2.889 2.833 0 1.56-1.147 2.846-2.889 2.846-1.581 0-2.874-1.147-2.874-2.846zm-3.785 2.49L36.8 36.815l1.287.56-4.49 10.134-1.295-.559zm-.916-5.575c.924 0 1.532-.727 1.532-1.616 0-.964-.679-1.602-1.532-1.602-.903 0-1.511.714-1.511 1.602 0 .965.678 1.616 1.51 1.616zm-.007 1.224c-1.588 0-2.882-1.14-2.882-2.854 0-1.56 1.154-2.832 2.882-2.832 1.588 0 2.882 1.125 2.882 2.832 0 1.567-1.154 2.855-2.882 2.855v-.001z"
          className={classes.objectFill}
        />
        <path strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round" d="M59.148 31.791v33.197H11.197V31.79" />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26.115 25.561h18.114v33.033l-2.074-.72-1.705.72-1.705-.72-1.705.72-1.705-.72-1.705.737-1.705-.737-1.705.737-1.705-.737-2.4.72z"
        />
      </g>
    </svg>
  );
};

export default NoPropertyTax;
