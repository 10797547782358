import { selectProposal, SelectProposalState } from '../../../../common/Proposals/selectors';
import { selectCartFinanceOption } from '../../../../common/Proposals/Cart/selectors';

export const selectCheckoutPrice = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const selectedFinanceOption = selectCartFinanceOption(state, proposalId);
  if (!selectedFinanceOption) return 0;

  return selectedFinanceOption.downPayment || 0;
};
