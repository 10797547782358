import React from 'react';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectProposal } from '../../../../common/Proposals/selectors';
import CashFlow from './CashFlow';
import { selectLearnMoreItem } from '../selectors';
import WithWithoutSolar from './WithWithoutSolar';
import SolarIncentives from './SolarIncentives';
import FinanceOption from './FinanceOption';
import IncentivesDisclaimer from './IncentivesDisclaimer';

const BenefitsSummary: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const learnMoreUI = useSelector(selectLearnMoreItem);

  if (!proposal || !learnMoreUI) {
    return null;
  }

  return (
    <section className={classes.root}>
      <FinanceOption financeOptionId={learnMoreUI.id} proposal={proposal} />
      <CashFlow state={proposal.address.state} proposalId={proposal.id} financeOptionId={learnMoreUI.id} />
      <WithWithoutSolar proposal={proposal} financeOptionId={learnMoreUI.id} />
      <SolarIncentives />
      <IncentivesDisclaimer white />
    </section>
  );
};

export default BenefitsSummary;
