import React from 'react';
import ModalView from '../../../common/ModalView';
import { FormikHelpers } from 'formik';
import { proposalToolApi } from '../../../../lib/api/proposalToolApi';
import { removeCustomer } from '../../../common/Customers/actions';
import { Dispatch } from 'redux';
import { curry } from 'ramda';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import DeleteConfirmationForm from '../../../common/DeleteConfirmationForm';

type Props = {
  id: string;
  open: boolean;
  handleClose: () => void;
};

export const deleteCustomer = curry(
  (dispatch: Dispatch, id: string, _, { setSubmitting, setStatus, resetForm }: FormikHelpers<any>) => {
    setSubmitting(true);
    proposalToolApi
      .delete(`/v1/customers/${id}`)
      .then(() => {
        dispatch(removeCustomer(id));
        resetForm();
        setStatus({ success: 'Success.' });
        setSubmitting(false);
      })
      .catch((e) => {
        setStatus({ error: e.error });
        setSubmitting(false);
      });
  },
);

const DeleteCustomer: React.FC<Props> = ({ handleClose, open, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <ModalView handleClose={handleClose} open={open}>
      <div className={classes.root}>
        <DeleteConfirmationForm submitHandler={deleteCustomer(dispatch, id)} />
      </div>
    </ModalView>
  );
};

export default DeleteCustomer;
