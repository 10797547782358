import { selectProposal, SelectProposalState } from '../../selectors';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { MonthlyPaymentsResult } from '@solvana/proposal-tool-domain/dist/lib/Financing/monthlyPayments';

export const selectCartESSAdderMonthlyPayment = (
  state: SelectProposalState,
  proposalId: number,
): MonthlyPaymentsResult | null => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const { cart } = proposal;

  const essAdder = proposal.essAdders.find((ess) => ess.id === cart.essAdder);
  if (!essAdder) return null;

  const financeOption = proposal.financeOptions.find(
    (fo) => isLoanFinanceOption(fo.type) && cart.financeOption === fo.id,
  );
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return null;
  const loanOption = financeOption as LoanFinanceOption;

  return Financing.monthlyPayments(essAdder.price, loanOption);
};
