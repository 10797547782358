import React from 'react';
import * as yup from 'yup';
import { TextField as MUITextField } from 'formik-material-ui';
import { Auth } from 'aws-amplify';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { FormControl, LinearProgress, Typography } from '@material-ui/core';
import SubheaderWithLink from '../../common/SubheaderWithLink';
import AuthLayout from '../AuthLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from '../styles';
import { Route as SignInRoute } from '../pg-sign-in/route';
import StyledButton from '../../common/StyledButton';

export const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  code: yup.string().required().label('Code'),
  password: yup.string().required().label('New Password'),
});

interface Params {
  code: string;
  password: string;
  email: string;
}

const ResetPasswordPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email') || '';
  const initialValues: Params = { password: '', code: '', email };

  const submitHandler = async ({ password, code, email }: Params, f: FormikHelpers<Params>) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      history.push(SignInRoute.Path);
    } catch (e) {
      f.setStatus({ error: e.message });
    }
  };

  return (
    <AuthLayout
      title="Solvana | Reset password"
      header="Reset your password"
      subHeader="Please check your email for a reset code (it may be in your spam folder)"
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
        {({ isValid, setFieldValue, setStatus, status, isSubmitting }) => {
          return (
            <Form>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('email', e.target.value);
                  }}
                  type="email"
                  label="Email"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="code"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('code', e.target.value);
                  }}
                  type="text"
                  label="Code"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('password', e.target.value);
                  }}
                  type="password"
                  label="New Password"
                />
              </FormControl>
              {status && status.error && (
                <Typography variant="subtitle1" className={classes.error}>
                  {status.error}
                </Typography>
              )}
              {isSubmitting && <LinearProgress />}
              <div className={classes.submitContainer}>
                <StyledButton
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Change password
                </StyledButton>
              </div>
              <SubheaderWithLink to={SignInRoute.Path} subheader="Already have a Solvana account?" name="Sign in" />
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
