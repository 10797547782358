import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from './track';

export default function usePageTrack() {
  const { pathname } = useLocation();
  useEffect(() => {
    trackPage(pathname);
  }, [pathname]);
}
