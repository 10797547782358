import React, { useEffect } from 'react';
import { useStyles } from './styles';
import Layout from '../common/Layout';
import SubHeader from './SubHeader';
import { useParams, Redirect } from 'react-router-dom';
import { GoSolarPageParams, toChooseFinancingPath, toChooseBatteriesPath } from '../routes';
import { useSelector } from 'react-redux';
import { selectProposal } from '../../common/Proposals/selectors';
import { RootState } from '../../../RootState';
import StripeCheckoutForm from './StripeCheckoutForm';
import { selectCustomerForId } from '../../common/Customers/selectors';
import PageTitle from '../common/PageTitle';
import { Container } from '@material-ui/core';
import { canViewCheckoutPage, resolveNextStep } from '../common/Layout/selectors';
import Summary from './Summary';
import { toFormattedPrice } from '../../../utils/helpers';
import { selectCheckoutPrice } from './StripeCheckoutForm/CheckoutForm/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import { selectProposalHasBatterOptions } from '../pg-choose-your-batteries/selectors';
import { trackCheckoutPageViewed } from '../../../utils/analytics/track';
import { selectCartTotal } from '../common/LearnMore/BenefitsSummary/CashFlow/FirstYearCashFlow/selectors';

const Checkout: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const hasBatteryOptions = useSelector((state: RootState) => selectProposalHasBatterOptions(state, proposalId));
  const customer = useSelector((state: RootState) => proposal && selectCustomerForId(proposal.customerId, state));
  const checkoutPrice = useSelector((state: RootState) => selectCheckoutPrice(state, proposalId));
  const cartTotalPrice = useSelector((state: RootState) => selectCartTotal(state, proposalId));

  useEffect(() => trackCheckoutPageViewed({ proposalId, cartTotalPrice }), [proposalId, cartTotalPrice]);

  if (!proposal || !customer) {
    return <Layout />;
  }

  if (!canViewCheckoutPage(proposal)) {
    return <Redirect to={resolveNextStep(proposal)} />;
  }

  return (
    <ScrollToTop>
      <Layout>
        <Container className={classes.root} maxWidth="lg">
          <PageTitle previousPath={hasBatteryOptions ? toChooseBatteriesPath(id) : toChooseFinancingPath(id)}>
            Get started with
            <br />
            only {toFormattedPrice(checkoutPrice, 0)}
          </PageTitle>
          <SubHeader proposal={proposal} />
          <div className={classes.container}>
            <div className={classes.leftContainer}>
              <Summary proposalId={proposal.id} />
            </div>
            <div className={classes.rightContainer}>
              <StripeCheckoutForm customer={customer} proposalId={proposal.id} />
            </div>
          </div>
        </Container>
      </Layout>
    </ScrollToTop>
  );
};

export default Checkout;
