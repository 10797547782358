import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import { GoSolarPageParams } from '../../../../routes';
import { useParams } from 'react-router-dom';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import { selectCartMonthlyPayments } from '../../../../../common/Proposals/Cart/selectors/selectCartMonthlyPayments';
import { selectCartTotal } from '../../../LearnMore/BenefitsSummary/CashFlow/FirstYearCashFlow/selectors';

const TotalPrice: React.FC = () => {
  const classes = useStyles()();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const cartTotal = useSelector((state: RootState) => selectCartTotal(state, proposalId));
  const monthlyPayments = useSelector((state: RootState) => selectCartMonthlyPayments(state, proposalId));

  return (
    <div className={classes.root}>
      <Typography className={classes.total} variant="subtitle2">
        Total
      </Typography>
      <div className={classes.priceContainer}>
        {monthlyPayments ? (
          <>
            <Typography className={classes.price} variant="subtitle2">
              {toFormattedPrice(monthlyPayments.initialPayment)}
              <br />
              per month
            </Typography>
          </>
        ) : (
          <Typography className={classes.price} variant="subtitle2">
            {toFormattedPrice(cartTotal)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TotalPrice;
