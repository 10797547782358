import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    progress: {
      width: '100%',
    },
    input: {
      width: '100%',
      marginBottom: '30px',
    },
    submitContainer: {
      margin: theme.spacing(3, 'auto', 5, 'auto'),
      maxWidth: '200px',
    },
    success: {
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    error: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    hint: {
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    linkContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      width: '100%',
    },
    links: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
