import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from 'theme/Colors';

export const dockedWidthMD = '96px';
export const dockedWidthXL = '115px';
export const verticalToHorizBreakpoint = 'sm';
const mobileBreakpoint = 850;

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      learnMoreHeadline: {
        fontSize: '40px',
        color: '#fff',
        marginBottom: theme.spacing(4),
      },
      label: {
        marginBottom: theme.spacing(1),
        fontWeight: 700,
      },
      learnMoreText: {
        color: '#fff',
      },
      title: {
        color: Colors.GreyFour,
        lineHeight: 1.25,
      },
      specContainer: {
        display: 'flex',
        marginBottom: theme.spacing(8),
        '& li': {
          padding: 'unset',
          marginRight: theme.spacing(4),
          color: Colors.GreyFour,
        },
        '& ul': {
          padding: 0,
        },
        '& a': {
          color: '#fff',
        },
        [`@media only screen and (max-width: ${mobileBreakpoint}px)`]: {
          justifyContent: 'space-between',
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      panelSection: {
        marginRight: theme.spacing(2.5),
      },
      specSubSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        '& ul': {
          marginBottom: theme.spacing(2),
        },
        [`@media only screen and (max-width: ${mobileBreakpoint}px)`]: {
          width: 'unset',
        },
      },
      installationInfo: {
        display: 'flex',
        marginBottom: theme.spacing(8),
        '& svg': {
          marginRight: theme.spacing(2),
        },
      },
      descriptionContainer: {
        '& img': {
          maxWidth: '100%',
        },
        marginBottom: theme.spacing(4),
      },
    }),
  );
