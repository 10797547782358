import React from 'react';
import gravatar from 'gravatar';
import { Avatar, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import { RiMailLine, RiPhoneLine } from 'react-icons/ri';
import { PatternFormat } from 'react-number-format';
import clsx from 'clsx';
import { verticalToHorizBreakpoint } from '../../CheckoutPages/common/Cart/DockedView/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: '50px',
      flexShrink: 1,
      padding: theme.spacing(0.5, 2, 0.5, 0.5),
      [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
        padding: theme.spacing(0.5),
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: 0,
        border: 'none',
      },
    },
    avatar: {
      width: '93px',
      height: '93px',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
        margin: theme.spacing(0, 0, 1),
      },
    },
    contactName: {
      fontWeight: 900,
    },
    contactDetailIcon: {
      marginRight: theme.spacing(1),
    },
    iconTitle: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
        justifyContent: 'center',
      },
    },
  }),
);

type Props = {
  className?: string;
  user: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
};

const ContactInfo: React.FC<Props> = ({ user, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [className || '']: Boolean(className) })}>
      <Avatar
        alt={`${user.firstName}'s profile picture`}
        src={gravatar.url(user.email, { s: '100', protocol: 'https' })}
        className={classes.avatar}
      />
      <div>
        <Typography variant="subtitle1" className={classes.contactName}>
          {toFullName(user)}
        </Typography>
        <span className={classes.iconTitle}>
          <RiMailLine className={classes.contactDetailIcon} />
          <Typography variant="subtitle2">{user.email}</Typography>
        </span>
        <span className={classes.iconTitle}>
          <RiPhoneLine className={classes.contactDetailIcon} />
          <Typography variant="subtitle2">
            <PatternFormat format="+1 (###) ###-####" displayType="text" value={user.phoneNumber} />
          </Typography>
        </span>
      </div>
    </div>
  );
};

export default ContactInfo;
