import rfaSurveyQuestions, { QuestionType, RFAQuestion, RfaSurveyQuestions } from './common/rfaSurveyQuestions';
import { SelectRFAState } from './reducer';
import { Address, RFA, RFADto, validateRFAAddress } from '@solvana/rfa-domain';
import { ValidationError } from 'yup';
import { SelectCurrentUserProfileState } from '../common/CurrentUserProfile/reducer';
import { selectIsProfileIncomplete } from '../common/CurrentUserProfile/selectors';

export const selectRFAAddress = ({ rfa }: SelectRFAState): Address => rfa.address;

export const selectHasResolvedRFAFetch = ({ rfa }: SelectRFAState): boolean => rfa.hasResolvedFetch;

export const selectRFAFormData = ({ rfa }: SelectRFAState): RFADto => ({
  address: rfa.address,
  survey: Object.keys(rfa.survey).map((question) => ({
    question,
    answers: rfa.survey[question as RFAQuestion] || [''],
  })),
});

export const selectIsRFAAddressMailingAddress = ({ rfa }: SelectRFAState): boolean => rfa.rfaAddressIsMailingAddress;

export const selectMailingAddress = ({ rfa }: SelectRFAState): Address => rfa.mailingAddress;

export const selectIsSubmittingCustomerProfile = ({ rfa }: SelectRFAState): boolean => rfa.isSubmittingProfile;

export const selectShowUtilityAPIIFrame = ({ rfa }: SelectRFAState): boolean => rfa.showUtilityAPIIFrame;

export const selectRFASubmitSuccess = ({ rfa }: SelectRFAState): boolean => rfa.submitSuccess;

export const selectRFAEntities = ({ rfa }: SelectRFAState): RFA[] => rfa.entities;

export const selectIsSubmittingRFA = ({ rfa }: SelectRFAState): boolean => rfa.isSubmittingRFA;

export const selectRFASubmitError = ({ rfa }: SelectRFAState): string | null => rfa.rfaSubmitError;

export const selectCustomerProfileSubmitError = ({ rfa }: SelectRFAState): string | null =>
  rfa.customerSubmitProfileError;

export const selectRFAAddressErrors = ({ rfa }: SelectRFAState): { error: ValidationError | null } =>
  validateRFAAddress(rfa.address);

export const selectMailingAddressErrors = ({ rfa }: SelectRFAState): { error: ValidationError | null } =>
  validateRFAAddress(rfa.mailingAddress);

export const selectHasMailingAddressErrors = (state: SelectRFAState): boolean => {
  const { error } = selectMailingAddressErrors(state);
  return Boolean(error);
};

export const selectRFAAddressHasErrors = (state: SelectRFAState): boolean => {
  const { error } = selectRFAAddressErrors(state);
  return Boolean(error);
};

interface CurrentRFASurveyQuestion {
  survey: RfaSurveyQuestions;
  index: number;
}

export const selectRFASurveyQuestion = (route: string): CurrentRFASurveyQuestion | undefined => {
  const index = rfaSurveyQuestions.findIndex((survey) => survey.path === route);
  if (index < 0) return undefined;

  return {
    survey: rfaSurveyQuestions[index],
    index,
  };
};

export const selectIsLastQuestion = (route: string): boolean => {
  const index = rfaSurveyQuestions.findIndex((survey) => survey.path === route);
  return index === rfaSurveyQuestions.length - 1;
};

export const selectRedirectPathForUnansweredQuestions = (
  state: SelectRFAState & SelectCurrentUserProfileState,
  question: string,
): string | undefined => {
  const currentQuestionIndex = rfaSurveyQuestions.findIndex((manifest) => question === manifest.question);
  if (currentQuestionIndex <= 0) return;

  const previousQuestions = rfaSurveyQuestions.slice(0, currentQuestionIndex);
  for (let i = 0; i < previousQuestions.length; i += 1) {
    if (QuestionType.Address === rfaSurveyQuestions[i].questionType) {
      const address = selectRFAAddress(state);
      const { error } = validateRFAAddress(address);
      const isProfileIncomplete = selectIsProfileIncomplete(state);

      if (error || isProfileIncomplete) {
        return rfaSurveyQuestions[i].path;
      }
      continue;
    }

    const question = rfaSurveyQuestions[i].question;
    if (!state.rfa.survey[question]?.length) {
      return rfaSurveyQuestions[i].path;
    }
  }

  return undefined;
};

export const selectIsQuestionAnswered = ({ rfa }: SelectRFAState, question: RFAQuestion): boolean => {
  const answersForQuestion = rfa.survey[question] || {};
  return Boolean(Object.values(answersForQuestion).find((a) => a !== undefined));
};

export const selectIsAnswerSelected = ({ rfa }: SelectRFAState, question: RFAQuestion, answer: string): boolean => {
  const answersForQuestion = rfa.survey[question] || [];
  return Boolean(answersForQuestion.find((a) => a === answer));
};
