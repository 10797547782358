import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { FinanceOptionDTO, FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { SunlightFinancialLoans } from '@solvana/proposal-tool-domain/dist/lib/Financing/SunlightFinancial';
import { sunstoneCreditLoanOptions } from './sunstoneCreditLoanOptions';

const RESIDENTIAL_DOWN_PAYMENT = 299.0;

export const newFinanceOption = {
  type: '' as any,
  termInMonths: '' as any,
  rate: '' as any,
  initialPaymentFactor: '' as any,
  secondPaymentFactor: '' as any,
  downPayment: '' as any,
};

export const defaultNonprofitFinanceOptions: FinanceOptionDTO[] = [
  {
    type: FinanceOptionType.Cash,
    downPayment: 999.0,
  },
];

export const defaultCommercialFinanceOptions: FinanceOptionDTO[] = [
  {
    type: FinanceOptionType.Cash,
    downPayment: 10_000,
  },
  ...sunstoneCreditLoanOptions,
];

export const defaultResidentialFinanceOptions: FinanceOptionDTO[] = [
  {
    type: FinanceOptionType.Cash,
    downPayment: 1500,
  },
  ...Object.values(SunlightFinancialLoans).map((option) => ({
    ...option,
    downPayment: RESIDENTIAL_DOWN_PAYMENT,
  })),
];

export const resolveFinanceOptions = (entity: TaxEntity): FinanceOptionDTO[] => {
  if (TaxEntity.Residential === entity) {
    return defaultResidentialFinanceOptions;
  }

  if (TaxEntity.Commercial === entity) {
    return defaultCommercialFinanceOptions;
  }

  return defaultNonprofitFinanceOptions;
};
