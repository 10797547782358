import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingBottom: theme.spacing(2),
        '& li': {
          padding: theme.spacing(0),
          width: '100%',
        },
      },
      title: {
        color: '#fff',
        borderBottom: 'solid 1px #fff',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      orderDetailsLink: {
        cursor: 'pointer',
        color: theme.palette.info.main,
        '&:hover': {
          color: theme.palette.info.dark,
        },
      },
      divider: {
        backgroundColor: Colors.GreyFour,
        margin: theme.spacing(1, 0),
      },
      total: {
        color: '#fff',
        textAlign: 'right',
        margin: theme.spacing(2, 0, 6),
      },
    }),
  );
