import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';

export const selectAvgMonthlyCashFlow = (
  state: SelectProposalState,
  proposalId: number,
  financeOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return 0;
  const loan = financeOption as LoanFinanceOption;
  const horizon = Financing.horizonForOption(loan);

  const cashInHandTotal = Pricing.cashInHandTotal(
    { ...proposal, cart: { ...proposal.cart, financeOption: loan.id } },
    horizon,
  );

  const termInMonths = loan.termInMonths || 1;

  return cashInHandTotal / termInMonths;
};
