import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.default,
      strokeWidth: 2.1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      fillRule: 'evenodd',
    },
    highlight: {
      stroke: theme.palette.primary.main,
      strokeWidth: 3,
    },
  }),
);

const defaultWidth = 50;

const InverterEnphase: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 77">
      <title>{title || 'Enphase Inverter'}</title>
      <g>
        <path d="M21 24.716h42.167V2H21zM59.504 75.48H24.762a3.712 3.712 0 0 1-3.712-3.713V48.05h42.167v23.717a3.712 3.712 0 0 1-3.713 3.712z" />
        <path d="M12.05 17.908h9v-9h-9zM66.05 37.73h9v-9h-9zM64.05 59.48h9v-9h-9zM64.05 35.23h2v-4h-2z" />
        <path
          d="M63.217 47.766H12.408c-6.273 0-11.358-5.085-11.358-11.358 0-6.273 5.085-11.358 11.358-11.358h50.809v22.716z"
          className={classes.highlight}
        />
      </g>
    </svg>
  );
};

export default InverterEnphase;
