import { Dispatch } from 'redux';
import { Inverter } from '@solvana/catalog-domain/dist/Inverter';
import { catalogApi } from '../../../../lib/api/catalogApi';

export enum Actions {
  SetIsFetchingInverters = 'catalog/SetIsFetchingInverters',
  SetInvertersFetchError = 'catalog/SetInvertersFetchError',
  SetInverters = 'catalog/SetInverters',
}

export const setInverters = (inverters: Inverter[]) => ({
  type: Actions.SetInverters,
  inverters,
});

export const setInvertersFetchError = (error: string | null) => ({
  type: Actions.SetInvertersFetchError,
  error,
});

export const setIsFetchingInverters = (isFetching: boolean) => ({
  type: Actions.SetIsFetchingInverters,
  isFetching,
});

export const fetchInverters = (dispatch: Dispatch) => {
  dispatch(setIsFetchingInverters(true));
  dispatch(setInvertersFetchError(null));

  catalogApi
    .get('/inverters')
    .then((response) => {
      dispatch(setInverters(response.data));
      dispatch(setIsFetchingInverters(false));
    })
    .catch((e) => {
      dispatch(setIsFetchingInverters(false));
      dispatch(setInvertersFetchError(e.toString()));
    });
};
