import { FinanceOption, FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { ProposalsState } from '../../../../common/Proposals/reducer';
import { SolarBenefitsUIState } from '../../reducer';
import { FinanceCategory } from '../../actions';
import { selectLoanFinanceOptions, SelectProposalState } from '../../../../common/Proposals/selectors';

const SAVE_MOST_FINANCE_OPTION_TYPES = [FinanceOptionType.Cash];
const filterBySaveMost = (financeOptions: FinanceOption[]): FinanceOption[] =>
  financeOptions.filter((o) => SAVE_MOST_FINANCE_OPTION_TYPES.includes(o.type));

interface SelectFinanceOptions {
  (state: { proposals: ProposalsState; solarBenefitsUI: SolarBenefitsUIState }, proposalId: number): FinanceOption[];
}

export const selectFinanceOptionsByCategory: SelectFinanceOptions = (state, proposalId) => {
  const proposal = state.proposals.entities.find((p) => p.id === proposalId);
  const { category } = state.solarBenefitsUI;
  if (!proposal || !category) return [];

  if (FinanceCategory.SaveMore === category) {
    return selectLoanFinanceOptions(state, proposalId);
  }

  if (FinanceCategory.SaveMost === category) {
    return filterBySaveMost(proposal.financeOptions);
  }

  return [];
};

export const selectHasFinanceOptions = (state: SelectProposalState, proposalId: number): boolean => {
  return selectLoanFinanceOptions(state, proposalId).length > 0;
};
