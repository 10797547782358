import { Adder as CatalogAdder } from '@solvana/catalog-domain/dist/Adder';
import { AdderCatalogState } from '../../../../common/Catalog/Adders/reducer';
import { find } from 'ramda';
import { selectProposal, SelectProposalState } from '../../../../common/Proposals/selectors';
import { selectAdderLowestMonthlyLoanOption } from '../../../../common/Proposals/Adders/selectors/selectAdderLowestMonthlyPaymentNetITC';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { getFinancingLegalText } from '../../../common/LearnMore/BenefitsSummary/FinanceOption/FinancingLegal/getFinancingLegalText';

export const selectAdderProductCatalog = (
  state: { adderCatalog: AdderCatalogState },
  adder: { productId: number },
): CatalogAdder | undefined => {
  return find((a) => a.id === adder.productId, state.adderCatalog.entities);
};

export const selectLoanPaymentLegalText = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
): string | null => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const lowestMonthlyLoanOption = selectAdderLowestMonthlyLoanOption(state, proposalId, adderId);
  if (!lowestMonthlyLoanOption) return null;

  const adder = proposal.adders.find((a) => a.id === adderId);
  if (!adder) return null;

  const principle = adder.price;
  const itcPercentage = ITC.percentage(proposal);

  return getFinancingLegalText(lowestMonthlyLoanOption, principle, itcPercentage);
};
