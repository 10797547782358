import * as React from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { LinearProgress, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useStyles } from './styles';
import StyledButton from '../StyledButton';

enum Confirmation {
  Message = 'Do or do not. There is no try.',
}

export const schema = yup.object().shape({
  confirm: yup.string().required().equals([Confirmation.Message]),
});

export interface ConfirmationData {
  confirm: string;
}

export interface CustomerSubmitHandler {
  (data: ConfirmationData, actions: FormikHelpers<ConfirmationData>): any;
}

type Props = {
  submitHandler: CustomerSubmitHandler;
};

const DeleteConfirmationForm: React.FC<Props> = ({ submitHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">Please enter the following phrase to confirm:</Typography>
      <Typography color="primary" variant="subtitle1" className={classes.confirmationMessage}>
        {Confirmation.Message}
      </Typography>

      <Formik validationSchema={schema} onSubmit={submitHandler} initialValues={{ confirm: '' }}>
        {({ isValid, status, isSubmitting }) => (
          <Form>
            <Field
              disabled={isSubmitting}
              component={TextField}
              className={classes.input}
              name={'confirm'}
              type={'confirm'}
              label={'confirm'}
            />

            {isSubmitting && <LinearProgress />}

            {status && status.success && (
              <Typography variant="subtitle1" className={classes.success}>
                {status.success}
              </Typography>
            )}

            {status && status.error && (
              <Typography variant="subtitle1" className={classes.error}>
                {status.error}
              </Typography>
            )}

            <div className={classes.submitContainer}>
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                Delete
              </StyledButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeleteConfirmationForm;
