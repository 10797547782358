import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Route as SignInRoute } from '../../pg-sign-in/route';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../selectors';

const AuthenticatedRoute = ({ component, ...rest }: any) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Route {...rest} render={(props: any) => React.createElement(component, props)} />;
  }

  return <Redirect to={{ pathname: SignInRoute.Path }} />;
};

export default AuthenticatedRoute;
