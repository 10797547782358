import { selectProposal, SelectProposalState } from '../../selectors';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { MonthlyPaymentsResult } from '@solvana/proposal-tool-domain/dist/lib/Financing/monthlyPayments';

export const selectCartAdderMonthlyPayment = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
): MonthlyPaymentsResult | null => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const { cart } = proposal;

  const adder = proposal.adders.find((a) => a.id === adderId);
  if (!adder) return null;

  const financeOption = proposal.financeOptions.find(
    (fo) => isLoanFinanceOption(fo.type) && cart.financeOption === fo.id,
  );
  if (!financeOption || !isLoanFinanceOption(financeOption.type)) return null;
  const loanOption = financeOption as LoanFinanceOption;

  return Financing.monthlyPayments(adder.price, loanOption);
};
