import { SolarOption } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';
import { Panel } from '@solvana/catalog-domain/dist/Panel';
import { Inverter } from '@solvana/catalog-domain/dist/Inverter';
import { Inverter as ProposalDomainInverter } from '@solvana/proposal-tool-domain/dist/Proposal/Inverter';
import { reduce } from 'ramda';
import { PanelCatalogState } from '../../../Catalog/Panels/reducer';
import { InverterCatalogState } from '../../../Catalog/Inverters/reducer';
import { ProposalsState } from '../../reducer';
import { selectProposal, SelectProposalState } from '../../selectors';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import { selectLowestMonthlyLoanForSolarOption } from './selectLowestMonthlyLoanForSolarOption';
import { selectLowestMonthlyPaymentForSolarOptionNetIncentives } from './selectLowestMonthlyPaymentForSolarOptionNetIncentives';
import { selectSolarOptionITCValue } from './selectSolarOptionITCValue';
import { selectSolarOptionMACRSValue } from './selectSolarOptionMACRSValue';

type InverterProduct = ProposalDomainInverter & Inverter;

type SolarOptionCatalog = {
  subArrays: {
    [productId: number]: Panel;
  };
  inverters: InverterProduct[];
};

interface SelectCatalogForSolarOption {
  panelCatalog: PanelCatalogState;
  inverterCatalog: InverterCatalogState;
}

export const selectCatalogForSolarOption = (
  solarOption: SolarOption,
  state: SelectCatalogForSolarOption,
): SolarOptionCatalog => {
  let subArrays = {};

  for (let i = 0; i < solarOption.subArrays.length; i += 1) {
    const productId = solarOption.subArrays[i].productId;
    const panel = state.panelCatalog.entities.find((e) => e.id === productId);
    if (panel) {
      subArrays = { ...subArrays, [productId]: panel };
    }
  }

  const inverters = reduce(
    (acc, inverter) => {
      const catalogInv = state.inverterCatalog.entities.find((i) => i.id === inverter.productId);
      if (catalogInv) {
        acc.push({ ...catalogInv, ...inverter });
      }

      return acc;
    },
    [] as InverterProduct[],
    solarOption.inverters,
  );

  return {
    subArrays,
    inverters,
  };
};

export const solarOptionFirstSubArrayPhoto = (catalogItems?: SolarOptionCatalog): string => {
  const defaultImageUrl = '';

  if (!catalogItems) {
    return defaultImageUrl;
  }
  const subArrayProducts = Object.values(catalogItems.subArrays);

  if (subArrayProducts.length > 0 && subArrayProducts[0].photoUrls.length > 0) {
    return subArrayProducts[0].photoUrls[0];
  }

  return defaultImageUrl;
};

export const resolveSolarOptionName = (catalogItems?: SolarOptionCatalog): string => {
  const defaultName = '--';

  if (!catalogItems) {
    return defaultName;
  }

  const subArrayProducts = Object.values(catalogItems.subArrays);
  if (subArrayProducts.length > 0) {
    return `${subArrayProducts[0].brand} - ${subArrayProducts[0].watts}W`;
  }

  return defaultName;
};

export const selectSolarOptionById = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): SolarOption | undefined => {
  const proposal = state.proposals.entities.find((p) => p.id === proposalId);
  if (!proposal) return undefined;

  return proposal.solarOptions.find((s) => s.id === solarOptionId);
};

const cheapestToMoreExpensive = (a: SolarOption, b: SolarOption): number => {
  if (a.price < b.price) {
    return -1;
  }

  if (a.price > b.price) {
    return 1;
  }

  return 0;
};

interface SelectOrderedSolarOptions {
  (state: { proposals: ProposalsState }, proposalId: number): SolarOption[];
}

export const selectOrderedSolarOptions: SelectOrderedSolarOptions = ({ proposals }, proposalId) => {
  const proposal = proposals.entities.find((p) => p.id === proposalId);
  if (!proposal) return [];
  return proposal.solarOptions.sort(cheapestToMoreExpensive);
};

export const selectPanelCount = (state: SelectProposalState, proposalId: number, solarOptionId: number): number => {
  const solarOption = selectSolarOptionById(state, proposalId, solarOptionId);
  if (!solarOption) return 0;

  return solarOption.subArrays.reduce((acc, subArray) => acc + subArray.panelCount, 0);
};

export const selectTotalSolarSavings = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return System.totalSolarSavings(proposal, horizonLength);
};

export const selectAvgSolarSavings = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  return selectTotalSolarSavings(state, proposalId, horizonLength) / (horizonLength * 12);
};

export {
  selectSolarOptionITCValue,
  selectSolarOptionMACRSValue,
  selectLowestMonthlyLoanForSolarOption,
  selectLowestMonthlyPaymentForSolarOptionNetIncentives,
};
