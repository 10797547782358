import React from 'react';
import { useStyles } from './styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RFA } from '@solvana/rfa-domain';
import AddressLinkToGoogleMaps from '../../../../common/AddressLinkToGoogleMaps';
import { toChooseSolarOptionPath } from '../../../../CheckoutPages/routes';
import moment from 'moment';
import { toRFADetailRoute } from '../../../../pg-rfa-details/routes';

const RFARow = ({ rfa, authUUID }: { rfa: RFA; authUUID: string }) => {
  const history = useHistory();
  const classes = useStyles();

  const getStatus = (rfa: RFA): string => {
    if (rfa.doesNotQualify) return 'Does Not Qualify';
    if (rfa.proposalId !== undefined) return 'Has Proposal';
    return 'Needs Proposal';
  };

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <Typography variant="body1">{getStatus(rfa)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{moment(rfa.createdAt).format('ll')}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Link href="#" onClick={() => history.push(toRFADetailRoute(authUUID, rfa.rfaId))}>
          {rfa.rfaId}
        </Link>
      </TableCell>
      <TableCell>
        <AddressLinkToGoogleMaps address={rfa.address} />
      </TableCell>
      <TableCell>
        {rfa.proposalId ? (
          <Link href="#" onClick={() => history.push(toChooseSolarOptionPath(rfa.proposalId as number))}>
            View (ID: {rfa.proposalId})
          </Link>
        ) : (
          <Typography variant="body1">--</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RFARow;
