import React, { useEffect, useMemo } from 'react';
import Layout from 'containers/common/Layout';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../RootState';
import { Redirect, useParams } from 'react-router-dom';
import { getCustomerRFAs } from '../pg-customer/actions';
import { selectCustomerRFA } from '../pg-customer/selectors';
import moment from 'moment';
import AddressLinkToGoogleMaps from '../common/AddressLinkToGoogleMaps';
import { selectIsAdmin, selectIsProposalCreator } from '../Auth/selectors';
import { HomePageRoute } from '../pg-home';
import { fetchCustomerByAuthUUID } from '../common/Customers/actions';
import { selectCustomerForAuthUUID } from '../common/Customers/selectors';
import { Customer, toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import CreateCustomer, { DEFAULT_CUSTOMER } from 'containers/pg-customers/CreateCustomer';

const RFADetailsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUUID, rfaId } = useParams<{ authUUID: string; rfaId: string }>();
  const isAdmin = useSelector(selectIsAdmin);
  const isProposalCreator = useSelector(selectIsProposalCreator);

  const isAuthorized = isAdmin || isProposalCreator;

  const toString = (val: string | undefined): string => val || '';

  useEffect(() => {
    fetchCustomerByAuthUUID(authUUID, dispatch);
    getCustomerRFAs(authUUID, dispatch);
  }, [authUUID]);

  const rfa = useSelector((state: RootState) => selectCustomerRFA(state, authUUID, rfaId));
  const customer = useSelector((state: RootState) => selectCustomerForAuthUUID(authUUID, state));

  const customerDTO: Customer = useMemo(() => {
    return {
      id: DEFAULT_CUSTOMER.id,
      address: rfa?.address || DEFAULT_CUSTOMER.address,
      email: toString(rfa?.contact?.email),
      firstName: toString(rfa?.contact?.firstName),
      lastName: toString(rfa?.contact?.lastName),
      phoneNumber: toString(rfa?.contact?.phoneNumber),
    };
  }, [rfa]);

  if (!isAuthorized) return <Redirect to={HomePageRoute.Path} />;

  return (
    <Layout title="Solvana | Customer RFA Details">
      <Container maxWidth="md" className={classes.root}>
        <Typography className={classes.title} variant={'h3'}>
          Customer RFA
        </Typography>

        {!rfa ? (
          <Typography color="primary" variant={'h5'}>
            Not found
          </Typography>
        ) : (
          <>
            {customer && (
              <hgroup className={classes.listHeaderItem}>
                <Typography variant={'h6'}>{toFullName(customer)}</Typography>
                <Typography variant={'h6'}>{customer.email}</Typography>
              </hgroup>
            )}

            {rfa.contact && (
              <hgroup className={classes.listHeaderItem}>
                <Typography variant={'h6'}>{toFullName(rfa.contact)}</Typography>
                <Typography variant={'h6'}>Email: {rfa.contact.email}</Typography>
                <Typography variant={'h6'}>Phone: {rfa.contact.phoneNumber}</Typography>
              </hgroup>
            )}

            <hgroup className={classes.listHeaderItem}>
              <Typography variant={'h6'}>RFA ID: {rfa.rfaId}</Typography>
              <Typography variant={'h6'}>Proposal ID: {rfa.proposalId || '--'}</Typography>
              <Typography variant={'h6'}>Does Not Qualify: {rfa.doesNotQualify ? 'true' : 'false'}</Typography>
              <Typography variant={'h6'}>Created on: {moment(rfa.createdAt).format('LL')}</Typography>
            </hgroup>

            <Typography variant={'h6'}>Address</Typography>
            <AddressLinkToGoogleMaps address={rfa.address} />

            <List className={classes.questionList}>
              {rfa.survey.map((question) => (
                <ListItem key={question.question}>
                  <Typography variant={'h6'}>{question.question}</Typography>

                  <List className={classes.answerList}>
                    {question.answers.map((answer) => (
                      <ListItem key={answer}>
                        <Typography variant={'body1'}>{answer}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </>
        )}

        {rfa && (
          <div className={classes.formContainer}>
            <CreateCustomer customerDTO={customerDTO} submitTitle="Create Customer" />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default RFADetailsPage;
