import React from 'react';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { Card, Link, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../RootState';
import { selectEstimatedHomeValueIncrease } from './selectors';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import EnergyIndependence from '../../../../../../../components/SolvanaIcons/EnergyIndependence';
import HomeValue from '../../../../../../../components/SolvanaIcons/HomeValue';
import NoPropertyTax from '../../../../../../../components/SolvanaIcons/NoPropertyTax';
import NoSalesTax from '../../../../../../../components/SolvanaIcons/NoSalesTax';
import FightsClimateChange from '../../../../../../../components/SolvanaIcons/FightsClimateChange';
import Neighborhood from '../../../../../../../components/SolvanaIcons/Neighborhood';

type Props = {
  proposal: Proposal;
};
const AdditionalIncentives: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const isResidential = TaxEntity.Residential === proposal.tax.entity;
  const estimatedHomeValueIncrease = useSelector((state: RootState) =>
    selectEstimatedHomeValueIncrease(state, proposal.id),
  );

  return (
    <Card className={classes.root} elevation={0}>
      <ul className={classes.gridContainer}>
        <li>
          <EnergyIndependence size={65} />
          <Typography variant="subtitle1">Energy Independence</Typography>
        </li>

        {isResidential && (
          <li>
            <HomeValue width={65} />
            <Typography variant="subtitle1">
              <strong>
                +{toFormattedPrice(estimatedHomeValueIncrease)}
                <sup>*</sup>
              </strong>
            </Typography>
            <Typography variant="subtitle1">Home Value</Typography>
          </li>
        )}

        <li>
          <NoPropertyTax width={65} />
          <Typography variant="subtitle1">No Added</Typography>
          <Typography variant="subtitle1">Property Tax</Typography>
        </li>

        <li>
          <NoSalesTax width={55} />
          <Typography variant="subtitle1">No Sales Tax</Typography>
        </li>

        <li>
          <FightsClimateChange size={65} />
          <Typography variant="subtitle1">Fights Climate Change</Typography>
        </li>

        <li>
          <Neighborhood width={100} />
          <Typography variant="subtitle1">Strengthens Neighborhood Infrastructure</Typography>
        </li>
      </ul>
      {isResidential && (
        <div className={classes.footerList}>
          <Typography variant="subtitle2">
            <sup>*</sup> According to the Department of Energy home values garner premiums of $2.68/W to $4.31/W across
            states, averaging $3.78/W. We use $3.00/W in our calculations. Read more{' '}
            <Link
              target="_blank"
              href="https://eta-publications.lbl.gov/sites/default/files/lbnl-1002778_fact_sheet.pdf"
            >
              here
            </Link>
            .
          </Typography>
        </div>
      )}
    </Card>
  );
};

export default AdditionalIncentives;
