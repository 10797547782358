import { rfaApi } from '../../lib/api/rfaApi';
import { RFA } from '@solvana/rfa-domain';
import { Dispatch } from 'redux';

export enum Actions {
  SetCustomerRFAEntities = 'CustomerRFAs/SetCustomerRFAEntities',
}

export const setCustomerRFAEntities = (customerAuthUUID: string, entities: RFA[]) => ({
  type: Actions.SetCustomerRFAEntities,
  entities,
  customerAuthUUID,
});

export const getCustomerRFAs = (customerAuthUUID: string, dispatch: Dispatch) => {
  rfaApi
    .get(`/customer/${customerAuthUUID}`)
    .then((resp) => {
      dispatch(setCustomerRFAEntities(customerAuthUUID, resp.data));
    })
    .catch();
};
