import { curry, find } from 'ramda';
import { CustomerState, SelectCustomerState } from './reducer';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

export const selectCustomers = (state: SelectCustomerState): CustomerState => state.customers;

export const selectCustomerForId = curry((id: string, state: SelectCustomerState): Customer | undefined =>
  find((c: Customer): boolean => c.id === id, state.customers.entities),
);

export const selectCustomerForAuthUUID = curry((authUUID: string, state: SelectCustomerState): Customer | undefined =>
  find((c: Customer): boolean => c.authUUID === authUUID, state.customers.entities),
);

export const selectIsFetchingCustomer = (state: SelectCustomerState): boolean => state.customers.isFetching;
export const selectCustomerFetchError = (state: SelectCustomerState): string | null => state.customers.error;
