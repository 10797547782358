import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      list: {
        padding: 0,
        margin: theme.spacing(0, 0, 6),
        '& li': {
          padding: 0,
          marginBottom: theme.spacing(3),
        },
        '& li:last-child': {
          marginBottom: 0,
        },
      },
      summaryContainer: {
        marginBottom: theme.spacing(6),
        '& strong': {
          color: theme.palette.success.main,
        },
      },
      divider: {
        margin: theme.spacing(2, 0, 1),
        backgroundColor: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      },
      title: {
        marginBottom: theme.spacing(3),
      },
      totalTitle: {
        marginBottom: theme.spacing(1),
      },
      totalPrice: {
        margin: theme.spacing(0, 0, 0),
      },
      monthlyPayment: {
        margin: theme.spacing(0, 0, 4),
      },
      footerList: {
        borderTop: `solid 1px ${Colors.GreyThree}`,
        margin: theme.spacing(6, 0, 0),
        padding: 0,

        '& p': {
          color: theme.palette.type === 'dark' ? Colors.GreyFour : Colors.GreyTwo,
          fontSize: '12px',
        },
        '& li': {
          margin: 0,
          padding: theme.spacing(1, 0, 0),
        },
      },
    }),
  );
