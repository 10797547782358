import * as React from 'react';
import SelectButton from '../../../common/SelectButton';
import Spinner from '../../../../common/Spinner';
import StripeLogo from '../../../../common/StripeLogo';
import clsx from 'clsx';
import { BillingAddress, StripeEvent } from './types';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Colors } from '../../../../../theme/Colors';
import { Customer, toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import { Field, Form, Formik } from 'formik';
import { FormControl, FormHelperText, MenuItem, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { RiLock2Fill } from 'react-icons/ri';
import { STATE_VALUES } from '@solvana/state-domain';
import { TextField } from 'formik-material-ui';
import { schema } from './schema';
import { useMemo, useState } from 'react';
import { useStyles } from './styles';
import { proposalToolApi } from '../../../../../lib/api/proposalToolApi';
import { PaymentProviders } from '@solvana/payments-domain/dist/common/PaymentProviders';
import { setOneProposal } from '../../../../common/Proposals/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectCheckoutPrice } from './selectors';
import ContractCheckbox from './ContractCheckbox';
import { toFormattedPrice } from '../../../../../utils/helpers';
import {
  trackCheckoutCompleted,
  trackCheckoutFailed,
  trackCheckoutSubmitStarted,
} from '../../../../../utils/analytics/track';

export type Props = {
  proposalId: number;
  customer: Customer;
};

const stateOptions = STATE_VALUES.map((stateValue) => ({ value: stateValue, label: stateValue }));

const CheckoutForm: React.FC<Props> = ({ customer, proposalId }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [hasAgreedToContract, setHasAgreedToContract] = useState(false);
  const checkoutPrice = useSelector((state: RootState) => selectCheckoutPrice(state, proposalId));
  const theme = useTheme();
  const isSmallMediaOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const isDark = theme.palette.type === 'dark';
  const [stripeStatus, setStripeStatus] = useState<{ [key: string]: StripeEvent }>({});
  const [stripeIsLoading, setStripeIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const textFieldFor = (name: string, disabled: boolean, label?: string) => (
    <Field
      disabled={disabled}
      component={TextField}
      className={classes.input}
      name={name}
      type="text"
      label={label || name}
    />
  );

  const initialValues = {
    name: toFullName(customer),
    address1: customer.address.address1,
    address2: customer.address.address2,
    city: customer.address.city,
    state: customer.address.state,
    zip: customer.address.zip,
  };

  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: isDark ? '#fff' : Colors.Dark,
          '::placeholder': {
            color: Colors.GreyThree,
          },
        },
        invalid: {
          color: theme.palette.primary.main,
        },
      },
      disabled: isSubmitting,
    }),
    [isDark, isSubmitting, theme.palette.primary.main],
  );

  const submitHandler = async (values: BillingAddress) => {
    trackCheckoutSubmitStarted({ proposalId });

    if (!stripe || !elements) return;
    const card = elements.getElement(CardNumberElement);
    if (!card) return;

    setIsSubmitting(true);
    setSubmitError(null);

    const { error, token } = await stripe.createToken(card, {
      name: values.name,
      address_line1: values.address1,
      address_line2: values.address2,
      address_city: values.city,
      address_state: values.state,
      address_zip: values.zip,
    });

    if (error) {
      trackCheckoutFailed({ proposalId, errorCode: error.code });
      setIsSubmitting(false);
      setSubmitError(error.message || null);
      return;
    }

    proposalToolApi
      .post(`/v1/proposals/${proposalId}/checkout`, {
        provider: PaymentProviders.Stripe,
        token: token?.id,
      })
      .then((result) => {
        trackCheckoutCompleted({ proposalId, checkoutPrice });
        dispatch(setOneProposal(result.data));
        setIsSubmitting(false);
      })
      .catch((e) => {
        trackCheckoutFailed({ proposalId, message: e.message });
        setSubmitError(e.message);
        setIsSubmitting(false);
      });
  };

  const handleStripFormChange = (event: StripeEvent) => {
    setSubmitError(null);
    setStripeStatus({ ...stripeStatus, [event.elementType]: { ...event } });
  };

  const { cardNumber, cardExpiry, cardCvc } = stripeStatus;

  return (
    <Formik initialValues={{ ...initialValues }} validationSchema={schema} onSubmit={submitHandler}>
      {({ isValid, isSubmitting }) => (
        <Form className={classes.form}>
          <Typography className={classes.addressTitle} variant="h6">
            Billing Address
          </Typography>

          {textFieldFor('name', isSubmitting, 'Name')}
          {textFieldFor('address1', isSubmitting, 'Address Line 1')}
          {textFieldFor('address2', isSubmitting, 'Address Line 2')}
          {textFieldFor('city', isSubmitting, 'City')}

          <Field
            component={TextField}
            className={classes.input}
            type="text"
            name="state"
            label="State"
            select
            variant="standard"
            helperText="Please select a State"
            InputLabelProps={{ shrink: true }}
          >
            {stateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>

          {textFieldFor('zip', isSubmitting, 'Zip Code')}

          <Typography className={classes.billingInfoTitle} variant="h6">
            Billing Information
          </Typography>

          <FormControl className={clsx(classes.formControl, { [classes.hidden]: stripeIsLoading })}>
            <Typography variant="subtitle2">Credit Card Number</Typography>
            <Paper className={classes.stripeInput} elevation={1}>
              <CardNumberElement
                id="cardNumber"
                onChange={handleStripFormChange}
                onReady={() => setStripeIsLoading(false)}
                options={{ ...options, disabled: isSubmitting, showIcon: true }}
              />
            </Paper>
            {cardNumber && cardNumber.error && (
              <FormHelperText className={classes.error}>{cardNumber.error.message}</FormHelperText>
            )}
          </FormControl>

          <FormControl className={clsx(classes.formControl, { [classes.hidden]: stripeIsLoading })}>
            <Typography variant="subtitle2">Expiration Date</Typography>
            <Paper className={classes.stripeInput} elevation={1}>
              <CardExpiryElement onChange={handleStripFormChange} options={{ ...options, disabled: isSubmitting }} />
            </Paper>
            {cardExpiry && cardExpiry.error && (
              <FormHelperText className={classes.error}>{cardExpiry.error.message}</FormHelperText>
            )}
          </FormControl>

          <FormControl className={clsx(classes.formControl, { [classes.hidden]: stripeIsLoading })}>
            <Typography variant="subtitle2">CVC</Typography>
            <Paper className={classes.stripeInput} elevation={1}>
              <CardCvcElement onChange={handleStripFormChange} options={{ ...options, disabled: isSubmitting }} />
            </Paper>
            {cardCvc && cardCvc.error && (
              <FormHelperText className={classes.error}>{cardCvc.error.message}</FormHelperText>
            )}
          </FormControl>

          <ContractCheckbox
            onAccept={() => setHasAgreedToContract(!hasAgreedToContract)}
            isAccepted={hasAgreedToContract}
          />

          <div className={classes.submitContainer}>
            {stripeIsLoading ? (
              <Spinner />
            ) : (
              <>
                {submitError && <FormHelperText className={classes.submitErrorMessage}>{submitError}</FormHelperText>}
                <Typography className={classes.submitButtonHeader} variant="h6">
                  Due now: {toFormattedPrice(checkoutPrice)}
                </Typography>
                <SelectButton
                  disabled={!stripe || isSubmitting || !isValid || !hasAgreedToContract}
                  isSubmitting={isSubmitting}
                  type="submit"
                  fullWidth={isSmallMediaOrLess}
                >
                  Place Order
                </SelectButton>
              </>
            )}
            <div className={classes.paymentSubtitleContainer}>
              <RiLock2Fill className={classes.lock} />
              <Typography className={classes.paymentSubtitle} variant="subtitle2">
                Secured by
              </Typography>
              <StripeLogo className={classes.stripeLogo} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
