import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    loss: {
      color: theme.palette.primary.main,
      fontWeight: 900,
    },
    lineItemValue: {
      fontWeight: 900,
    },
    savings: {
      color: theme.palette.success.main,
      fontWeight: 900,
    },
  }),
);
