import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    buttonContainer: {
      margin: theme.spacing(0, 'auto'),
      width: '100%',
      maxWidth: '200px',
      textAlign: 'center',
    },
    message: {
      marginBottom: theme.spacing(2),
    },
  }),
);
