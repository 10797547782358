import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(8),
      },
      section: {
        marginBottom: theme.spacing(5),
      },
      title: {
        fontSize: theme.spacing(3),
        marginBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      button: {
        textTransform: 'unset',
        margin: theme.spacing(1, 0, 0),
        padding: theme.spacing(0.25, 1.5),
      },
      saveTitle: {
        fontSize: theme.spacing(3),
        marginBottom: 0,
      },
      loanBlurbContainer: {
        maxWidth: '640px',
        marginBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      cost: {
        color: theme.palette.primary.main,
      },
      savings: {
        color: theme.palette.success.main,
      },
    }),
  );
