import { CurrentUserProfileState, SelectCurrentUserProfileState } from './reducer';
import { not } from 'ramda';

export const selectCurrentUserProfile = ({
  currentUserProfile,
}: SelectCurrentUserProfileState): CurrentUserProfileState => currentUserProfile;

export const selectIsProfileIncomplete = ({ currentUserProfile }: SelectCurrentUserProfileState): boolean =>
  not(currentUserProfile.entity);

export const selectIsFetchingCurrentUserProfile = ({ currentUserProfile }: SelectCurrentUserProfileState): boolean =>
  currentUserProfile.isFetching;

export const selectHasResolvedCurrentUserProfile = ({ currentUserProfile }: SelectCurrentUserProfileState): boolean =>
  currentUserProfile.hasResolved;

export const selectCurrentUserChatMetadata = (
  state: SelectCurrentUserProfileState,
  metadata?: { [key: string]: any },
) => {
  const currentUser = selectCurrentUserProfile(state);

  return {
    name: `${currentUser.entity?.firstName || ''} ${currentUser.entity?.lastName || ''}`,
    email: currentUser.entity?.email,
    external_id: currentUser.entity?.id || 'unknown',
    metadata,
  };
};
