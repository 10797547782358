import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '800px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 700,
    },
  }),
);
