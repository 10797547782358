import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import { handleSignForValue } from '../helpers';

type Props = {
  title: string;
  value: number;
  Sup?: React.FC;
  ignoreSign?: boolean;
};

const LineItem: React.FC<Props> = ({ title, value, ignoreSign, Sup }) => {
  const classes = useStyles();
  const formattedValue = handleSignForValue(value, false, ignoreSign);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="body1">
        {title}
        {Sup && <Sup />}:
      </Typography>
      <Typography
        className={clsx(classes.title, {
          [classes.savings]: value > 0 && !ignoreSign,
          [classes.loss]: value < 0 && !ignoreSign,
        })}
        variant="body1"
      >
        {formattedValue}
      </Typography>
    </div>
  );
};

export default LineItem;
