import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    summaryText: {
      '& b': {
        color: theme.palette.primary.main,
      },
    },
    formContainer: {
      margin: theme.spacing(5, 0, 4),
      borderRadius: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(10),
    },
  }),
);
