import { RootState } from '../../../RootState';
import { curry, find } from 'ramda';
import { ProposalsState } from './reducer';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { Cart } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';

export interface SelectProposalState {
  proposals: {
    entities: Proposal[];
  };
}

export const selectProposal = (state: SelectProposalState, id: number): Proposal | undefined =>
  find((p) => p.id === id, state.proposals.entities);

interface SelectCart {
  (id: string | number, state: RootState): Cart | undefined;
}

export const selectCart = curry<SelectCart>((id, state) => {
  const proposal = find((p) => p.id.toString() === id.toString(), state.proposals.entities);
  return proposal ? proposal.cart : undefined;
});

interface SelectLoanFinanceOptionsState {
  proposals: {
    entities: Proposal[];
  };
}

export const selectLoanFinanceOptions = (
  state: SelectLoanFinanceOptionsState,
  proposalId: number,
): LoanFinanceOption[] => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return [];

  return proposal.financeOptions.filter((o) => isLoanFinanceOption(o.type)) as LoanFinanceOption[];
};

export const selectProposals = (state: RootState): ProposalsState => state.proposals;

const productCount = (cart: Cart): number => {
  let count = 0;
  count += cart.solarOption ? 1 : 0;
  count += cart.essAdder ? 1 : 0;
  count += cart.selectedAdders.length;
  return count;
};

export const selectProductCount = (state: RootState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  return proposal ? productCount(proposal.cart) || 0 : 0;
};

export const selectIsUpdatingCart = (state: RootState): boolean => state.proposals.isUpdatingCart;

export const selectCartUpdateError = (state: RootState): string | null => state.proposals.cartUpdateError;

export const selectITCRate = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return ITC.percentage(proposal);
};
