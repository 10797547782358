import React from 'react';
import * as yup from 'yup';
import { TextField as MUITextField } from 'formik-material-ui';
import { Auth } from 'aws-amplify';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { FormControl, LinearProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setGroups, setIsAuthenticated } from '../../actions';
import { CognitoUser, toUserSession } from '../../../common/UserSession';
import { useStyles } from '../../styles';
import StyledButton from '../../../common/StyledButton';
import { PASSWORD_REGEX } from '../../../../utils/regex';
import AuthLayout from '../../AuthLayout';

export const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(PASSWORD_REGEX, { message: 'Password does not match minimum requirement' })
    .required()
    .label('New Password'),
});

type Props = {
  cognitoUser: any;
};

interface Params {
  newPassword: string;
}

const RequirePasswordChange: React.FC<Props> = ({ cognitoUser }) => {
  const classes = useStyles();
  const initialValues: Params = { newPassword: '' };
  const dispatch = useDispatch();

  const submitHandler = async ({ newPassword }: Params, f: FormikHelpers<Params>) => {
    try {
      await Auth.completeNewPassword(cognitoUser, newPassword);
      const user = await Auth.currentSession();
      dispatch(setIsAuthenticated(true));
      const userSession = toUserSession(user.getAccessToken().payload as CognitoUser);
      dispatch(setGroups(userSession));
    } catch (e) {
      f.setStatus({ error: e });
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
      {({ isValid, setFieldValue, setStatus, status, isSubmitting }) => {
        if (isSubmitting) {
          return (
            <AuthLayout title="Sign in" header="">
              <LinearProgress className={classes.progress} />
            </AuthLayout>
          );
        }

        return (
          <AuthLayout
            title="Solvana | Sign in"
            header="Please enter a new password"
            subHeader="Password must be a minimum of 8 characters with at least ONE number, ONE capital letter, and ONE special character (e.g. $, %, *)."
          >
            <Form>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="newPassword"
                  type="password"
                  label="New Password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('newPassword', e.target.value);
                  }}
                />
              </FormControl>

              {status && status.error && (
                <Typography variant="subtitle1" className={classes.error}>
                  {status.error}
                </Typography>
              )}

              <div className={classes.submitContainer}>
                <StyledButton
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Change Password
                </StyledButton>
              </div>
            </Form>
          </AuthLayout>
        );
      }}
    </Formik>
  );
};

export default RequirePasswordChange;
