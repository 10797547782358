import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';

export const selectSolarOptionITCValue = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const price = Pricing.solarOptionPrice({ ...proposal, cart: { ...proposal.cart, solarOption: solarOptionId } });

  return ITC.value(price, proposal);
};
