import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const dockedWidthMD = '96px';
export const dockedWidthXL = '115px';
export const verticalToHorizBreakpoint = 'sm';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      drawerPaper: {
        width: '80%',
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.success.main,
        color: 'white',

        [theme.breakpoints.only('md')]: {
          width: 'calc(100% - 196px)',
        },

        [theme.breakpoints.up('xl')]: {
          width: '85%',
        },

        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          width: '100%',
          height: '100%',
          transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowY: 'scroll',
        },

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      learnMoreNav: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(4),
        position: 'sticky',
        top: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1),
        },
      },
      closeIconButton: {
        '& svg': {
          fill: '#fff',
          width: '46px',
          height: '46px',
          [theme.breakpoints.down('xs')]: {
            width: '42px',
            height: '42px',
          },
        },
      },
      learnMoreBody: {
        display: 'flex',
        margin: theme.spacing(0, '10%', 8, '10%'),
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          margin: theme.spacing(0, '6%', 8, '6%'),
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          margin: theme.spacing(0, 3, 6, 3),
        },
      },
      imageContainer: {
        width: '30%',
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          width: 'unset',
          marginRight: 0,
          marginBottom: theme.spacing(4),
        },
      },
      learnMoreImage: {
        width: '100%',
      },
      learnMoreHeadline: {
        fontSize: '40px',
        color: '#fff',
        marginBottom: theme.spacing(4),
      },
      learnMoreText: {
        color: '#fff',
      },
      contentContainer: {
        maxWidth: '50%',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      },
    }),
  );
