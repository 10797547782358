import React from 'react';
import { Typography } from '@material-ui/core';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { useStyles } from './styles';
import { getFinancingLegalText } from './getFinancingLegalText';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';

type Props = {
  loan: LoanFinanceOption;
  proposal: Proposal;
};

const FinancingLegal: React.FC<Props> = ({ loan, proposal }) => {
  const classes = useStyles();
  const principle = Pricing.cartTotal(proposal) - loan.downPayment;
  const itcPercentage = ITC.percentage(proposal);
  const legalText = getFinancingLegalText(loan, principle, itcPercentage);

  return (
    <Typography className={classes.root} variant="subtitle2">
      <sup>*</sup>
      {legalText}
    </Typography>
  );
};

export default FinancingLegal;
