import React from 'react';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import BenefitCard from '../common/BenefitCard';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import FederalIcon from '../../../../../../../components/SolvanaIcons/FederalIcon';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { RootState } from '../../../../../../../RootState';
import { useSelector } from 'react-redux';
import { selectCartTotalITCValue, selectCartTotalMACRSValue } from '../../CashFlow/FirstYearCashFlow/selectors';

type Props = {
  proposal: Proposal;
  financeOptionId: number;
};

const FederalIncentivesSummary: React.FC<Props> = ({ proposal, financeOptionId }) => {
  const itcValue = useSelector((state: RootState) => selectCartTotalITCValue(state, proposal.id, financeOptionId));
  const macrsValue = useSelector((state: RootState) => selectCartTotalMACRSValue(state, proposal.id, financeOptionId));
  const taxRate = (proposal.tax.rate || 0) * 100;
  const itcRate = ITC.percentage(proposal) * 100;
  const taxYear = new Date().getFullYear();
  const totalFedIncentives = macrsValue + itcValue;

  const entityType = proposal.tax.entity === TaxEntity.Residential ? 'homeowners' : 'businesses';

  return (
    <BenefitCard
      title={`+${toFormattedPrice(totalFedIncentives)}`}
      subtitle="Federal Incentives"
      Description={() => (
        <>
          <p>
            The <i>Federal Investment Tax Credit</i> (ITC) provides {entityType} with a huge incentive to go solar. For
            solar-generating systems purchased in {taxYear}, taxpayers are eligible for a{' '}
            <strong>{itcRate}% dollar-for-dollar credit</strong> against federal tax owed in {taxYear}. That means you
            may be able to save as much as <strong>{toFormattedPrice(itcValue)}</strong> in taxes in the first year!
            Unused ITCs can be carried forward to future tax years. Check with your tax advisor to find out how you can
            utilize the ITC.
          </p>
          {macrsValue > 0 && (
            <p>
              <i>Modified Accelerated Cost-Recovery System</i> (MACRS) depreciation provides qualified {entityType} with
              the ability to deduct depreciation of solar-generating systems from taxable income. Based on your tax rate
              of <strong>{taxRate}%</strong> you may be eligible to deduct{' '}
              <strong>{toFormattedPrice(macrsValue)}</strong> from your taxable income. Please speak to your tax
              specialist to see if you qualify.
            </p>
          )}
        </>
      )}
      Icon={() => <FederalIcon height={65} />}
    />
  );
};

export default FederalIncentivesSummary;
