import { Reducer } from 'redux';
import { Actions, setIsSubmitting, setErrorMessage } from './actions';

type ProposalActions = ReturnType<typeof setIsSubmitting> & ReturnType<typeof setErrorMessage>;

export type InviteCustomersState = {
  inviteCustomersUI: InviteCustomers;
};

export type InviteCustomers = {
  submitting: { [id: number]: boolean };
  errorMessages: { [id: number]: string | null | undefined };
};

const DEFAULT_STATE: InviteCustomers = {
  submitting: {},
  errorMessages: {},
};

const inviteCustomersUI: Reducer<InviteCustomers, ProposalActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetIsSubmitting:
      return {
        ...state,
        submitting: {
          ...state.submitting,
          [action.proposalId]: action.isSubmitting,
        },
      };

    case Actions.SetErrorMessage:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          [action.proposalId]: action.errorMessage,
        },
      };

    default:
      return state;
  }
};

export default inviteCustomersUI;
