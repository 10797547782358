import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectProposal } from '../../common/Proposals/selectors';
import { RootState } from '../../../RootState';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import ESSAdderOptions from './ESSAdderOptions';
import { selectCustomerForId } from '../../common/Customers/selectors';
import SelectButton from '../common/SelectButton';
import { GoSolarPageParams, toCheckoutPath, toChooseFinancingPath } from '../routes';
import Layout from '../common/Layout';
import PageTitle from '../common/PageTitle';
import { toFormattedPrice } from '../../../utils/helpers';
import { selectCashInHandTotalForSolarOptions, selectProposalHasBatterOptions } from './selectors';
import ESSAdderFinanceOptions from './ESSAdderFinanceOptions';
import { canViewBatteriesPage, resolveNextStep } from '../common/Layout/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import FootNotes from '../common/FootNotes';
import { trackESSAdderOptionsPageViewed } from '../../../utils/analytics/track';

const ReinvestYourSavings: React.FC<GoSolarPageParams> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const hasBatteryOptions = useSelector((state: RootState) => selectProposalHasBatterOptions(state, proposalId));
  const customer = useSelector((state: RootState) => selectCustomerForId(proposal ? proposal.customerId : '', state));
  const cashInHand = useSelector((state: RootState) => selectCashInHandTotalForSolarOptions(state, proposalId, 25));

  useEffect(() => trackESSAdderOptionsPageViewed({ proposalId }), [proposalId]);

  if (!proposal || !customer) {
    return <Layout />;
  }

  if (!canViewBatteriesPage(proposal)) {
    return <Redirect to={resolveNextStep(proposal)} />;
  }

  if (!hasBatteryOptions) {
    return <Redirect to={toCheckoutPath(id)} />;
  }

  return (
    <ScrollToTop>
      <Layout>
        <div className={classes.root}>
          <Container maxWidth="lg">
            <PageTitle previousPath={toChooseFinancingPath(id)}>
              Reinvest your
              <br /> savings
            </PageTitle>

            <Typography className={classes.subHeader} variant="body1">
              {customer.firstName}, based on your solar selections you will have up to{' '}
              <strong className={classes.savings}>{toFormattedPrice(cashInHand)}</strong> cash in hand over the next 25
              years. Many customers reinvest some of the savings from their PV solar system into an Energy Storage
              System to improve independence, peace-of-mind, quality of life, savings, and add more value to their home.
            </Typography>

            <ESSAdderOptions essAdders={proposal.essAdders} />
          </Container>

          <Container maxWidth="md">
            <SelectButton onClick={() => history.push(toCheckoutPath(id))} fullWidth>
              Next: Checkout
            </SelectButton>

            <FootNotes />
          </Container>
        </div>
        <ESSAdderFinanceOptions proposalId={proposal.id} />
      </Layout>
    </ScrollToTop>
  );
};

export default ReinvestYourSavings;
