import { RFAQuestion } from './common/rfaSurveyQuestions';
import { Address, RFA } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain/dist';
import { Dispatch } from 'redux';
import { AddressDTO } from '@solvana/proposal-tool-domain/dist/common/Address';
import { proposalToolApi } from '../../lib/api/proposalToolApi';
import { setCurrentUserProfile } from '../common/CurrentUserProfile/actions';
import { rfaApi } from '../../lib/api/rfaApi';

export enum Actions {
  SetRFASurveySingleAnswer = 'RFASurvey/SetRFASurveySingleAnswer',
  SetAddressField = 'RFASurvey/SetAddressField',
  SetAddressState = 'RFASurvey/SetAddressState',
  SetRFAAddressIsMailingAddress = 'RFASurvey/SetRFAAddressIsMailingAddress',
  SetMailingAddressField = 'RFASurvey/SetMailingAddressField',
  SetMailingAddressState = 'RFASurvey/SetMailingAddressState',
  SetIsSubmittingProfile = 'RFASurvey/SetIsSubmittingProfile',
  SetCustomerProfileSubmitError = 'RFASurvey/SetCustomerProfileSubmitError',
  ToggleRFASurveyMultiAnswer = 'RFASurvey/ToggleRFASurveyMultiAnswer',
  SetIsSubmittingRFA = 'RFASurvey/SetIsSubmittingRFA',
  SetHasResolvedRFAFetch = 'RFASurvey/SetHasResolvedRFAFetch',
  SetRFASubmitError = 'RFASurvey/SetRFASubmitError',
  SetShowUtilityAPIIFrame = 'RFASurvey/ShowUtilityAPIIFrame',
  SetRFASubmitSuccess = 'RFASurvey/RFASubmitSuccess',
  SetRFAEntities = 'RFASurvey/SetRFAEntities',
  AppendRFAEntity = 'RFASurvey/AppendRFAEntity',
  ResetDefaultState = 'RFASurvey/ResetDefaultState',
}

export const setRFASurveySingleAnswer = (question: RFAQuestion, answer: string) => ({
  type: Actions.SetRFASurveySingleAnswer,
  question,
  answer,
});

export const setIsSubmittingProfile = (isSubmittingProfile: boolean) => ({
  type: Actions.SetIsSubmittingProfile,
  isSubmittingProfile,
});

export const setHasResolvedRFAFetch = (hasResolvedFetch: boolean) => ({
  type: Actions.SetHasResolvedRFAFetch,
  hasResolvedFetch,
});

export const setRFASubmitSuccess = (submitSuccess: boolean) => ({
  type: Actions.SetRFASubmitSuccess,
  submitSuccess,
});

export const setRFAEntities = (entities: RFA[]) => ({
  type: Actions.SetRFAEntities,
  entities,
});

export const appendRFAEntity = (rfa: RFA) => ({
  type: Actions.AppendRFAEntity,
  rfa,
});

export const resetDefaultState = () => ({
  type: Actions.ResetDefaultState,
});

export const setShowUtilityAPIIFrame = (showUtilityAPIIFrame: boolean) => ({
  type: Actions.SetShowUtilityAPIIFrame,
  showUtilityAPIIFrame,
});

export const setIsSubmittingRFA = (isSubmittingRFA: boolean) => ({
  type: Actions.SetIsSubmittingRFA,
  isSubmittingRFA,
});

export const setCustomerProfileSubmitError = (customerProfileSubmitError: string | null) => ({
  type: Actions.SetCustomerProfileSubmitError,
  customerProfileSubmitError,
});

export const setRFASubmitError = (rfaSubmitError: string | null) => ({
  type: Actions.SetRFASubmitError,
  rfaSubmitError,
});

export const setRFAAddressIsMailingAddress = (rfaAddressIsMailingAddress: boolean) => ({
  type: Actions.SetRFAAddressIsMailingAddress,
  rfaAddressIsMailingAddress,
});

export type RFAAddressField = keyof Omit<Address, 'state'>;

export const setAddressField = (field: RFAAddressField, value: string) => ({
  type: Actions.SetAddressField,
  field,
  value,
});

export const setMailingAddressField = (field: RFAAddressField, value: string) => ({
  type: Actions.SetMailingAddressField,
  field,
  value,
});

export const setMailingAddressState = (state: State) => ({
  type: Actions.SetMailingAddressState,
  state,
});

export const setAddressState = (state: State) => ({
  type: Actions.SetAddressState,
  state,
});

export const toggleRFASurveyMultiAnswer = (question: RFAQuestion, answer: string) => ({
  type: Actions.ToggleRFASurveyMultiAnswer,
  question,
  answer,
});

export const createCustomerProfile = (dispatch: Dispatch, address: AddressDTO, onSuccess?: () => any) => {
  dispatch(setIsSubmittingProfile(true));
  dispatch(setCustomerProfileSubmitError(null));

  proposalToolApi
    .post('/v1/customers/profile/complete', { address })
    .then((resp) => {
      dispatch(setCurrentUserProfile(resp.data));
      onSuccess && onSuccess();
    })
    .catch(() => {
      dispatch(setCustomerProfileSubmitError('Oops...Something went wrong :-('));
    })
    .finally(() => {
      dispatch(setIsSubmittingProfile(false));
    });
};

export const submitRFA = (dispatch: Dispatch, rfa: RFA, onSuccess?: () => any) => {
  dispatch(setIsSubmittingRFA(true));
  dispatch(setRFASubmitError(null));

  rfaApi
    .post('/create', rfa)
    .then((_resp) => {
      dispatch(setRFASubmitSuccess(true));
      onSuccess && onSuccess();
    })
    .catch(() => {
      dispatch(setRFASubmitError('Oops...Something went wrong :-('));
    })
    .finally(() => {
      dispatch(setIsSubmittingRFA(false));
    });
};

export const getMyRFAs = (dispatch: Dispatch) => {
  dispatch(setHasResolvedRFAFetch(false));
  rfaApi
    .get('/my-requests')
    .then((resp) => {
      dispatch(setRFAEntities(resp.data));
      dispatch(setHasResolvedRFAFetch(true));
    })
    .catch();
};
