import * as React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from './styles';

type Props = {
  title: string;
  subtitle: string;
  Description: React.FC;
  Icon: React.FC;
};

const BenefitCard: React.FC<Props> = ({ Icon, title, subtitle, Description }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.summary}>
        <Icon />
        <hgroup className={classes.hgroup}>
          <Typography variant="h4">Up To</Typography>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="h4">{subtitle}</Typography>
        </hgroup>
      </div>
      <div className={classes.details}>
        <Description />
      </div>
    </Card>
  );
};

export default BenefitCard;
