import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginBottom: theme.spacing(2),
        },
      },
      title: {
        color: Colors.GreyFive,
        alignSelf: 'flex-end',
        [theme.breakpoints.down('sm')]: {
          fontWeight: 900,
        },
      },
      savings: {
        color: Colors.Neon,
        alignSelf: 'flex-end',
      },
      loss: {
        color: Colors.GreyFive,
        alignSelf: 'flex-end',
      },
    }),
  );
