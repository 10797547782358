import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { HomePageRoute as HomeRoutes } from '../../../pg-home/route';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../selectors';

const UnauthenticatedRoute = ({ component, ...rest }: any) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Redirect to={{ pathname: HomeRoutes.Path }} />;
  }

  const routeComponent = (props: any) => React.createElement(component, props);

  return <Route {...rest} render={routeComponent} />;
};

export default UnauthenticatedRoute;
