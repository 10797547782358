import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

const MenuIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={18} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 0h9a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm4 10h5a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2zM1 5h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MenuIcon;
