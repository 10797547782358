import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '../../../styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { INSTALL_TYPE_VALUE_ARRAY } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption/SubArray';

type Props = {
  name: string;
};

const InstallTypeSelect: React.FC<Props> = ({ name }) => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext<ProposalDTO>();
  const selectOptions = INSTALL_TYPE_VALUE_ARRAY.map((value) => ({
    label: value,
    value,
  }));

  return (
    <Field
      component={TextField}
      className={classes.input}
      disabled={isSubmitting}
      type="text"
      name={name}
      label="Install Type"
      select
      variant="standard"
      helperText="Please select the installation type"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default InstallTypeSelect;
