import React from 'react';
import { createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StyledLink from '../Link';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      margin: theme.spacing(0, 0),
    },
    subheader: {
      paddingRight: theme.spacing(1),
    },
  }),
);

interface Props {
  to: string;
  name: string;
  subheader?: string | null;
}

const SubheaderWithLink: React.FC<Props> = ({ to, name, subheader }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {subheader && (
        <Typography className={classes.subheader} variant="subtitle1">
          {subheader}
        </Typography>
      )}
      <StyledLink to={to}>{name}</StyledLink>
    </div>
  );
};

export default SubheaderWithLink;
