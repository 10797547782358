import { Reducer } from 'redux';
import { Actions, setIsDarkMode } from './actions';

type ProjectAction = ReturnType<typeof setIsDarkMode>;

export type State = {
  isDark: boolean;
};

const DEFAULT_STATE: State = {
  isDark: false,
};

const layoutUI: Reducer<State, ProjectAction> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetIsDarkMode:
      return { ...state, isDark: action.isDark };

    default:
      return state;
  }
};

export default layoutUI;
