import React, { useEffect } from 'react';
import { Avatar, Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Layout from '../common/Layout';
import SelectButton from '../common/SelectButton';
import gravatar from 'gravatar';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { HomePageRoute as HomeRoute } from 'containers/pg-home';
import { useSelector } from 'react-redux';
import { RootState } from 'RootState';
import { selectProposal } from 'containers/common/Proposals/selectors';
import { GoSolarPageParams } from '../routes';
import { selectAdvocate } from 'containers/common/Advocates/selectors';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import Spinner from 'containers/common/Spinner';
import { RiMailLine, RiPhoneLine } from 'react-icons/ri';
import clsx from 'clsx';
import PageTitle from '../common/PageTitle';
import { canViewCompletePage, resolveNextStep } from '../common/Layout/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import { trackCheckoutCompletedPageViewed } from '../../../utils/analytics/track';
import { PatternFormat } from 'react-number-format';

const Complete: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const advocate = useSelector((state: RootState) => proposal && selectAdvocate(state, proposal.advocateId));

  useEffect(() => trackCheckoutCompletedPageViewed({ proposalId }), [proposalId]);

  if (!advocate) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  if (!proposal) return <Layout />;

  if (!canViewCompletePage(proposal)) {
    return <Redirect to={resolveNextStep(proposal)} />;
  }

  return (
    <ScrollToTop>
      <Layout hideProgressBar hideAdvocateContactInfo>
        <Container className={classes.root} maxWidth="md">
          <PageTitle>Congratulations!</PageTitle>
          <Typography variant="body2" className={classes.subtitle}>
            Your solar advocate, {advocate.firstName}, will reach out to you shortly to schedule the site visit and help
            you get started on your financing option. If you have any questions please reach out to{' '}
            {`${advocate.firstName} `}
            directly.
          </Typography>

          <Avatar
            alt={`${advocate.firstName}'s profile picture`}
            src={gravatar.url(advocate.email, { s: '200', protocol: 'https' })}
            className={classes.avatar}
          />

          <div className={classes.contactInfo}>
            <Typography variant="subtitle1" className={clsx(classes.contactName, { [classes.contactDetail]: true })}>
              {toFullName(advocate)}
            </Typography>
            <span className={classes.iconTitle}>
              <RiPhoneLine className={classes.contactDetailIcon} />
              <Typography variant="subtitle2">
                <PatternFormat format="+1 (###) ###-####" displayType="text" value={advocate.phoneNumber} />
              </Typography>
            </span>
            <span className={classes.iconTitle}>
              <RiMailLine className={classes.contactDetailIcon} />
              <Typography variant="subtitle2" className={classes.contactDetail}>
                {advocate.email}
              </Typography>
            </span>
          </div>

          <SelectButton onClick={() => history.push(HomeRoute.Path)}>Home</SelectButton>
        </Container>
      </Layout>
    </ScrollToTop>
  );
};

export default Complete;
