import React from 'react';
import TextField from '../../TextField';
import { useFormikContext } from 'formik';
import { useStyles } from './styles';
import { CardHeader, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PanelOptionSelect from './PanelOptionSelect';
import InstallTypeSelect from './InstallTypeSelect';
import { SubArrayDTO } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption/SubArray';

type Props = {
  selected: SubArrayDTO[];
  rootName: string;
  onRemove: (index: number) => any;
};

const SubArrayForm: React.FC<Props> = ({ onRemove, selected, rootName }) => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext();
  return (
    <div className={classes.root}>
      <Divider />

      {selected.length === 0 && <Typography variant="subtitle1">Sub Arrays</Typography>}

      {selected.map((subArray, index) => {
        return (
          <div key={`${rootName}-${subArray.toString()}-${index}`}>
            <div className={classes.items}>
              <CardHeader
                title={`Sub Array ${index + 1}`}
                action={
                  <IconButton onClick={() => onRemove(index)} size="small">
                    <Close />
                  </IconButton>
                }
              />
              <PanelOptionSelect
                key={`${rootName}-${subArray.toString()}-${index}`}
                productIdName={`${rootName}.subArrays[${index}].productId`}
                annualDegradationName={`${rootName}.subArrays[${index}].annualDegradation`}
              />

              <InstallTypeSelect name={`${rootName}.subArrays[${index}].installType`} />

              <TextField
                name={`${rootName}.subArrays[${index}].panelCount`}
                disabled={isSubmitting}
                type="number"
                label="Panel Count"
              />

              <TextField
                name={`${rootName}.subArrays[${index}].annualDegradation`}
                disabled
                type="number"
                label="Annual Degradation"
              />

              <TextField
                name={`${rootName}.subArrays[${index}].pvWattRatio`}
                disabled={isSubmitting}
                type="number"
                label="PV Watt Ratio"
              />

              <TextField
                name={`${rootName}.subArrays[${index}].size`}
                disabled={isSubmitting}
                type="number"
                label="Size (Watts)"
              />

              <TextField
                name={`${rootName}.subArrays[${index}].notes`}
                disabled={isSubmitting}
                multiline
                variant="outlined"
                label="Notes"
              />
            </div>

            {index < selected.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default SubArrayForm;
