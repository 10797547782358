import React from 'react';
import { useStyles } from './styles';
import { Card, Chip, Typography } from '@material-ui/core';
import { RiCheckboxCircleFill, RiCheckboxBlankCircleLine } from 'react-icons/ri';
import clsx from 'clsx';
import { FinanceCategory, setFinanceOptionsCategory, setShowFinanceOptionItems } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectSolarBenefitsUI } from '../../../selectors';
import SaveMoreIcon from '../../../../../../components/SolvanaIcons/SaveIcons/SaveMoreIcon';
import SaveMostIcon from '../../../../../../components/SolvanaIcons/SaveIcons/SaveMostIcon';
import { trackFinancingSavingsCategoryClicked } from '../../../../../../utils/analytics/track';

type Props = {
  title: string;
  subtitle: string;
  category: FinanceCategory;
  label?: string;
};

const Category: React.FC<Props> = ({ category, title, subtitle, label }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const solarBenefitsUI = useSelector(selectSolarBenefitsUI);
  const isSelected = category === solarBenefitsUI.category;

  const handleClick = () => {
    trackFinancingSavingsCategoryClicked({ category });
    dispatch(setFinanceOptionsCategory(category));
    dispatch(setShowFinanceOptionItems(true));
  };

  return (
    <Card
      elevation={0}
      onMouseDown={handleClick}
      className={clsx(classes.root, {
        [classes.transparent]: !isSelected && solarBenefitsUI.category,
        [classes.rootSelected]: isSelected,
      })}
    >
      <div className={classes.iconContainer}>
        {isSelected ? (
          <RiCheckboxCircleFill className={classes.selected} />
        ) : (
          <RiCheckboxBlankCircleLine className={classes.unselected} />
        )}
        {category === FinanceCategory.SaveMore && <SaveMoreIcon className={classes.icon} />}
        {category === FinanceCategory.SaveMost && <SaveMostIcon className={classes.icon} />}
      </div>
      <hgroup>
        <span className={classes.titleContainer}>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
          {label && <Chip className={classes.label} size="small" label={label} color="primary" />}
        </span>
        <Typography className={classes.subtitle} variant="h6">
          {subtitle}
        </Typography>
      </hgroup>
    </Card>
  );
};

export default Category;
