import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import RFARow from './RFARow';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';
import { getCustomerRFAs } from '../../actions';
import { selectCustomerRFAs } from '../../selectors';
import { RootState } from '../../../../RootState';

type Props = {
  customer: Customer;
};
const RFAList: React.FC<Props> = ({ customer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getCustomerRFAs(customer.authUUID || '', dispatch);
  }, [customer.authUUID]);

  const RFAs = useSelector((state: RootState) => selectCustomerRFAs(state, customer.authUUID || ''));

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant={'h5'}>
        RFAs
      </Typography>

      <TableContainer component={'div'}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>RFA ID</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Proposal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {RFAs.map((rfa) => (
              <RFARow key={rfa.rfaId} rfa={rfa} authUUID={customer.authUUID || ''} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RFAList;
