import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { Colors } from '../../../../theme/Colors';

type Props = {
  width?: number;
  className?: string;
};

const defaultSize = 58;

const SaveMoreIcon: React.FC<Props> = ({ width, className }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';

  return (
    <svg width={width || defaultSize} className={className} viewBox="0 0 58 56" xmlns="http://www.w3.org/2000/svg">
      <title>Save More Icon</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M55.53 15.168c-3.476 3.476-9.165 3.421-12.708-.12-3.543-3.543-3.597-9.232-.12-12.708M36.915 10.499l-5.186.821M47.371 20.955l-.822 5.186M38.131 2.82l-2.732-1.392M40.444 17.426l-2.168 2.168M55.05 19.738l1.391 2.732"
          stroke="#FB563B"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke={isDarkMode ? '#fff' : '#0E202D'}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.72 19.741L2.004 33.822M35.91 34.6v19.546H5.5V34.6"
        />
        <text
          fontFamily="Averta-ExtraBold, Averta"
          fontSize="14"
          fontWeight="600"
          fill={isDarkMode ? '#fff' : '#0E202D'}
          transform="translate(2 1)"
        >
          <tspan x="11.032" y="44.1">
            $$
          </tspan>
        </text>
        <path
          stroke={isDarkMode ? Colors.GreyThree : '#344154'}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.72 24.464l-7.151 5.38"
        />
        <path
          stroke={isDarkMode ? '#fff' : '#0E202D'}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.72 19.741l18.716 14.081"
        />
        <path
          stroke={isDarkMode ? Colors.GreyThree : '#344154'}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.72 24.464l7.151 5.38"
        />
        <path
          stroke={isDarkMode ? '#fff' : '#0E202D'}
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M39.436 27.285l-3.085 3.707M35.211 24.163l-3.086 3.706M31.532 21.437l-3.085 3.707M27.569 18.332l-3.085 3.707"
        />
        <path
          stroke="#FB563B"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M42.222 29.407L25.114 16.374"
        />
      </g>
    </svg>
  );
};

export default SaveMoreIcon;
