import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const ElectricCar: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 96">
      <title>{title || 'Electric Car'}</title>
      <g strokeWidth="2.1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M11.135 67.76h41.3l-3.71-7.153a6.32 6.32 0 0 0-5.143-2.647H20.507c-1.94 0-3.772.89-4.97 2.416l-4.402 7.384zM5.535 88.76h52.5V77.24a9.368 9.368 0 0 0-2.744-6.624l-2.856-2.856h-41.3l-2.856 2.856a9.368 9.368 0 0 0-2.744 6.625V88.76z" />
        <path d="M18.835 77.56h11.2l1.4 2.1 2.1-2.1h11.2s-2.1-2.8-12.6-2.8-13.3 2.8-13.3 2.8zM5.709 75.9l4.916.992a6.296 6.296 0 0 0 5.698-1.72l.412-.412M7.222 71.71s10.213.25 11.613 5.85M57.335 75.9l-4.916.992a6.296 6.296 0 0 1-5.698-1.72l-.412-.412M55.822 71.71s-10.213.25-11.613 5.85M11.924 94.36H8.246a2.711 2.711 0 0 1-2.711-2.711v-2.89h9.1v2.89a2.711 2.711 0 0 1-2.711 2.71zM55.324 94.36h-3.678a2.711 2.711 0 0 1-2.711-2.711v-2.89h9.1v2.89a2.711 2.711 0 0 1-2.711 2.71zM9.735 68.46H4.523a.92.92 0 0 1-.749-1.455l1.61-2.254A2.844 2.844 0 0 1 7.7 63.56h1.396c.78 0 1.45.55 1.602 1.314l.438 2.186M53.835 68.46h5.212a.92.92 0 0 0 .748-1.455l-1.61-2.254a2.844 2.844 0 0 0-2.314-1.191h-1.396c-.78 0-1.45.55-1.602 1.314l-.438 2.186M32.135 57.26l-2.779-9.45a5.26 5.26 0 0 0-5.522-3.754c-3.775.336-14.907 1.735-21.799 6.904 1.4 1.4 2.8 1.4 2.8 1.4s11.9-5.6 20.3-4.2c1.4 4.2 2.998 9.622 2.998 9.622M32.835 57.26l2.779-9.45a5.26 5.26 0 0 1 5.522-3.754c3.775.336 14.907 1.735 21.799 6.904-1.4 1.4-2.8 1.4-2.8 1.4s-11.9-5.6-20.3-4.2c-1.4 4.2-2.998 9.622-2.998 9.622" />
        <g className={classes.highlight}>
          <path d="M31.035 37.035v-1.75a3.15 3.15 0 0 0-3.15-3.15h-14.7a3.15 3.15 0 0 1 0-6.3h14.7a3.15 3.15 0 0 0 3.15-3.15v-2.45M31.035 20.235a9.1 9.1 0 0 1-9.1-9.1v-2.1h18.2v2.1a9.1 9.1 0 0 1-9.1 9.1zM27.656 8.802V2.035M33.956 8.802V2.035" />
        </g>
      </g>
    </svg>
  );
};

export default ElectricCar;
