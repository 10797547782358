import { curry } from 'ramda';

export interface GTAGProperties {
  [key: string]: any;
}

export const trackPage = (page: string) => {
  if (!(window as any).analytics) return;
  (window as any)?.analytics.page(page);
};

export const track = curry((event: string, properties?: GTAGProperties) => {
  if (!(window as any).analytics) return;
  (window as any)?.analytics.track(event, properties);
});

export const trackDarkModeSet = track('darkModeSet');
export const trackLightModeSet = track('lightModeSet');

export interface ProposalProperties {
  [key: string]: any;
}

export const trackCartOpened = (properties: ProposalProperties) => track('cartOpened', properties);

export const trackSolarOptionsPageViewed = (properties: ProposalProperties) =>
  track('solarOptionsPageViewed', properties);

export const trackSolarOptionAddedToCart = (properties: ProposalProperties) =>
  track('solarOptionAddedToCart', properties);

export const trackSolarOptionSeeComponentsClicked = (properties: ProposalProperties) =>
  track('solarOptionSeeComponentsClicked', properties);

export const trackAdderOptionsPageViewed = (properties: ProposalProperties) =>
  track('adderOptionsPageViewed', properties);
export const trackAdderAddedToCart = (properties: ProposalProperties) => track('adderAddedToCart', properties);
export const trackAdderRemovedFromCart = (properties: ProposalProperties) => track('adderRemovedFromCart', properties);

export const trackFinancingPageViewed = (properties: ProposalProperties) => track('financingPageViewed', properties);
export const trackFinanceOptionSelected = (properties: ProposalProperties) =>
  track('financeOptionSelected', properties);
export const trackFinancingSavingsCategoryClicked = (properties: ProposalProperties) =>
  track('financingSavingsCategoryClicked', properties);

export const trackFinanceOptionLearnMoreClicked = (properties: ProposalProperties) =>
  track('financeOptionLearnMoreClicked', properties);

export const trackESSAdderOptionsPageViewed = (properties: ProposalProperties) =>
  track('essAdderOptionsPageViewed', properties);
export const trackESSAdderSeeComponentsClicked = (properties: ProposalProperties) =>
  track('essAdderSeeComponentsClicked', properties);
export const trackESSAdderAddedToCart = (properties: ProposalProperties) => track('essAdderAddedToCart', properties);
export const trackESSAdderRemovedFromCart = (properties: ProposalProperties) =>
  track('essAdderRemovedFromCart', properties);

export const trackCheckoutPageViewed = (properties: ProposalProperties) => track('checkoutPageViewed', properties);
export const trackCheckoutSubmitStarted = (properties: ProposalProperties) =>
  track('checkoutSubmitStarted', properties);
export const trackCheckoutFailed = (properties: ProposalProperties) => track('checkoutFailed', properties);
export const trackCheckoutCompleted = (properties: ProposalProperties) => track('checkoutCompleted', properties);

export const trackCheckoutCompletedPageViewed = (properties: ProposalProperties) =>
  track('checkoutCompletedPageViewed', properties);
