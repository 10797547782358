import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        color: Colors.GreyFive,
        margin: theme.spacing(0, 0, 3),
        '& h6, p': {
          color: Colors.GreyFive,
          fontSize: '14px',
        },
      },
    }),
  );
