import React from 'react';
import { useStyles } from './styles';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

type Props = {
  name: string;
  imageURL: string;
  formattedPrice: string;
};

const ProductThumbnail: React.FC<Props> = ({ name, formattedPrice, imageURL }) => {
  const classes = useStyles()();

  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia component="img" className={classes.media} src={imageURL} title={name} />
      <CardContent
        classes={{
          root: classes.content,
        }}
      >
        <div>
          <Typography className={classes.price} variant="subtitle2">
            {formattedPrice}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductThumbnail;
