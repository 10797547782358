import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(8),
    },
    title: {
      color: '#fff',
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', 6, 'auto'),
      fontSize: '28px',
    },
  }),
);
