import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'RootState';
import { selectProposal } from 'containers/common/Proposals/selectors';
import { useHistory, useParams } from 'react-router-dom';
import { GoSolarPageParams, toChooseFinancingPath } from 'containers/CheckoutPages/routes';
import { selectFinanceOption } from 'containers/common/Proposals/FinanceOptions/selectors';
import FinancePartnerLogo from 'components/FinancePartnerLogo';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { canViewCompletePage } from 'containers/CheckoutPages/common/Layout/selectors';
import CartSummaryReAmortizationExplanation from '../OrderSummary/CartSummaryReAmortizationExplanation';

type Props = {};

const SelectedFinanceOption: React.FC<Props> = () => {
  const classes = useStyles()();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id) || 0;
  const history = useHistory();

  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const financeOption = useSelector((state: RootState) => proposal && selectFinanceOption(state, proposal.id));

  if (!financeOption || !proposal) return null;

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <FinancePartnerLogo financeOption={financeOption} />
        {isLoanFinanceOption(financeOption.type) && (
          <CartSummaryReAmortizationExplanation
            hideAsterisk
            proposal={proposal}
            loanOption={financeOption as LoanFinanceOption}
          />
        )}
      </div>
      <div className={classes.rightContainer}>
        {isLoanFinanceOption(financeOption.type) && (
          <>
            <Typography variant="h6">Finance Option</Typography>
            <Typography variant="body1">{(financeOption?.termInMonths || 0) / 12} Year Loan</Typography>

            <Typography className={''} variant="subtitle2">
              {financeOption?.rate || '--'}% APR
            </Typography>
          </>
        )}

        {!canViewCompletePage(proposal) && (
          <Button
            disableElevation
            size="small"
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => history.push(toChooseFinancingPath(proposal.id.toString()))}
          >
            Change
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectedFinanceOption;
