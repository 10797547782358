import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '../../styles';
import { Field, useFormikContext } from 'formik';
import { TextField as MUITextField } from 'formik-material-ui';
import {
  COMMERCIAL_FINANCE_OPTIONS,
  FinanceOptionType,
  RESIDENTIAL_FINANCE_OPTIONS,
} from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';

interface Option {
  label: string;
  value: string;
}

type Props = {
  name: string;
};

const resolveSelectOptions = (taxEntity?: TaxEntity): Option[] => {
  if (TaxEntity.Residential === taxEntity) {
    return RESIDENTIAL_FINANCE_OPTIONS.map((o) => ({
      label: o,
      value: o,
    }));
  }

  if (TaxEntity.Commercial === taxEntity) {
    return COMMERCIAL_FINANCE_OPTIONS.map((o) => ({
      label: o,
      value: o,
    }));
  }

  return [
    {
      label: FinanceOptionType.Cash,
      value: FinanceOptionType.Cash,
    },
  ];
};

const FinanceOptionTypeSelect: React.FC<Props> = ({ name }) => {
  const classes = useStyles()();
  const { values, isSubmitting } = useFormikContext<ProposalDTO>();
  const selectOptions = resolveSelectOptions(values?.tax?.entity);

  return (
    <Field
      component={MUITextField}
      className={classes.input}
      disabled={isSubmitting}
      type="text"
      name={name}
      label="Finance Option Type"
      select
      variant="standard"
      helperText="Please select a finance option type"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default FinanceOptionTypeSelect;
