import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      rightContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        padding: theme.spacing(2, 2, 2, 8),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2, 2, 2, 5),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      },
      leftContainer: {
        display: 'flex',
        margin: 0,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
          alignItems: 'unset',
        },
      },
      title: {
        margin: 0,
        color: '#fff',
        whiteSpace: 'pre-wrap',
      },
      subtitle: {
        margin: 0,
        color: '#fff',
        whiteSpace: 'pre-wrap',
      },
      monthlyPayment: {
        color: '#fff',
        fontWeight: 700,
        '& sub': {
          top: 0,
        },
      },
      divider: {
        backgroundColor: Colors.GreyFour,
        margin: theme.spacing(1, 0),
      },
      list: {
        alignSelf: 'flex-end',
        width: '100%',
        margin: theme.spacing(4, 0, 0, 0),
        '& li': {
          padding: 0,
        },
        '& h6': {
          padding: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1, 0, 0, 0),
        },
      },
      link: {
        cursor: 'pointer',
        color: theme.palette.info.main,
        '&:hover': {
          color: theme.palette.info.dark,
        },
      },
      image: {
        height: '200px',
        borderRadius: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(2),
        },
      },
      monthlyPaymentContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(0.5),
        width: '100%',
        '& p': {
          color: Colors.GreyFive,
          paddingLeft: 2,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      netIncentivesFooter: {
        color: Colors.GreyFour,
        lineHeight: 1,
      },
    }),
  );
