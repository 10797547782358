import { either } from 'ramda';
import { UserSession } from '../UserSession';

export enum Groups {
  Admins = 'admins',
  ProposalCreators = 'proposal-creators',
  Customers = 'customers',
  ProcessManagers = 'process-managers',
  Advocates = 'advocates',
  Experimental = 'experimental',
}

export const hasGroup = (requiredGroup: Groups, sessionUser: UserSession | undefined): boolean => {
  const { groups } = sessionUser || {};

  if (!groups) {
    return false;
  }

  return Boolean(groups.filter((g) => requiredGroup === g).length);
};

export const isAdmin = (sessionUser: UserSession): boolean => hasGroup(Groups.Admins, sessionUser);
export const isProposalCreator = (sessionUser: UserSession): boolean => hasGroup(Groups.ProposalCreators, sessionUser);
export const isAdvocate = (sessionUser: UserSession): boolean => hasGroup(Groups.Advocates, sessionUser);
export const isExperimenter = (sessionUser: UserSession): boolean => hasGroup(Groups.Experimental, sessionUser);

export const isProcessManager = (sessionUser: UserSession): boolean => hasGroup(Groups.ProcessManagers, sessionUser);

export const isAdminOrProcessManager = either(isAdmin, isProcessManager);
