import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
      width: '100%',
      '& hgroup': {
        margin: theme.spacing(0, 5, 2, 0),
      },
      '& hgroup:last-child': {
        margin: theme.spacing(0, 0, 2),
      },
      display: 'flex',
      justifyContent: 'flex-start',
    },
    title: {
      margin: 0,
    },
    detail: {
      margin: 0,
    },
    card: {
      padding: theme.spacing(2),
    },
  }),
);
