import { Dispatch } from 'redux';
import { Adder } from '@solvana/catalog-domain/dist/Adder';
import { catalogApi } from '../../../../lib/api/catalogApi';

export enum Actions {
  SetIsFetchingAdders = 'catalog/SetIsFetchingAdders',
  SetAddersFetchError = 'catalog/SetAddersFetchError',
  SetAdders = 'catalog/SetAdders',
}

export const setAdders = (adders: Adder[]) => ({
  type: Actions.SetAdders,
  adders,
});

export const setAddersFetchError = (error: string | null) => ({
  type: Actions.SetAddersFetchError,
  error,
});

export const setIsFetchingAdders = (isFetching: boolean) => ({
  type: Actions.SetIsFetchingAdders,
  isFetching,
});

export const fetchAdders = (dispatch: Dispatch) => {
  dispatch(setIsFetchingAdders(true));
  dispatch(setAddersFetchError(null));

  catalogApi
    .get('/adders')
    .then((response) => {
      dispatch(setAdders(response.data));
      dispatch(setIsFetchingAdders(false));
    })
    .catch((e) => {
      dispatch(setIsFetchingAdders(false));
      dispatch(setAddersFetchError(e.toString()));
    });
};
