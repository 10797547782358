import React from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Address as AddressDomain } from '@solvana/proposal-tool-domain/dist/common/Address';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    companyName: {
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  address: AddressDomain;
  name?: string;
  company?: string;
};

const Address: React.FC<Props> = ({ company, name, address }) => {
  const classes = useStyles();
  const { address1, address2, city, zip, state } = address;

  return (
    <div className={classes.root}>
      {company && (
        <Typography className={classes.companyName} variant="subtitle2">
          {company}
        </Typography>
      )}
      {name && <Typography variant="subtitle2">{name}</Typography>}
      <Typography variant="subtitle2">{address1}</Typography>
      {address2 && <Typography variant="subtitle2">{address2}</Typography>}
      <Typography variant="subtitle2">{`${city}, ${state} ${zip}`}</Typography>
    </div>
  );
};

export default Address;
