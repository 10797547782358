import { selectProposal, SelectProposalState } from '../../../common/Proposals/selectors';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import { FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';

export const selectTotalUtilityBill = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return System.sumOfUtilityBills(proposal, horizonLength);
};

export const selectAvgMonthlyUtilityBill = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  return selectTotalUtilityBill(state, proposalId, horizonLength) / (horizonLength * 12);
};

export const selectHasSunlightFinancialOption = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  const sunlightFinancialOption = proposal.financeOptions.find((fo) => fo.type === FinanceOptionType.SunlightFinancial);
  return Boolean(sunlightFinancialOption);
};
