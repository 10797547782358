import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { MACRS } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/MACRS';

export const selectCartTotal = (state: SelectProposalState, proposalId: number, itcEligibleOnly?: boolean): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return Pricing.cartTotal(proposal, itcEligibleOnly);
};

export const selectCartTotalITCValue = (
  state: SelectProposalState,
  proposalId: number,
  financeOption?: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithFinanceOption =
    financeOption === undefined ? proposal : { ...proposal, cart: { ...proposal.cart, financeOption } };

  const total = Pricing.cartTotal(proposalWithFinanceOption, true);

  return ITC.value(total, proposal);
};

export const selectCartTotalMACRSValue = (
  state: SelectProposalState,
  proposalId: number,
  financeOption?: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithFinanceOption =
    financeOption === undefined ? proposal : { ...proposal, cart: { ...proposal.cart, financeOption } };

  const total = Pricing.cartTotal(proposalWithFinanceOption, true);

  return MACRS.value(total, proposal);
};
