import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import SubHeader from './SubHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart, selectCartUpdateError, selectProposal } from '../../common/Proposals/selectors';
import { RootState } from '../../../RootState';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import SolarOptionCard from './SolarOption';
import { updateCart } from '../../common/Proposals/actions';
import { Cart } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';
import FootNotes from '../common/FootNotes';
import SelectButton from '../common/SelectButton';
import { GoSolarPageParams, toChooseAddersPath } from '../routes';
import Layout from '../common/Layout';
import { selectOrderedSolarOptions } from '../../common/Proposals/SolarOptions/selectors';
import PageTitle from '../common/PageTitle';
import { canViewCheckoutPages, resolveNextStep } from '../common/Layout/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import { trackSolarOptionsPageViewed } from '../../../utils/analytics/track';
import { setShowUtilityModal } from '../actions';

const ChooseYourSolarOptionPage: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const orderedSolarOptions = useSelector((state: RootState) => selectOrderedSolarOptions(state, proposalId));
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector(selectCart(id)) as Cart;
  const cartUpdateError = useSelector(selectCartUpdateError);

  useEffect(() => {
    dispatch(setShowUtilityModal(true));
    trackSolarOptionsPageViewed({ proposalId });
  }, [proposalId]);

  if (!proposal) return <Layout />;
  if (!canViewCheckoutPages(proposal)) <Redirect to={resolveNextStep(proposal)} />;

  return (
    <ScrollToTop>
      <Layout>
        <div className={classes.root}>
          <PageTitle>Choose your solar option</PageTitle>

          <SubHeader proposal={proposal} />
          <div aria-label="Solar options" className={classes.solarOptionContainer}>
            {orderedSolarOptions.map((solarOption, index) => (
              <SolarOptionCard
                isUpgrade={index + 1 === orderedSolarOptions.length && orderedSolarOptions.length > 1}
                solarOption={solarOption}
                proposalId={proposal.id}
                key={solarOption.id}
                onSubmit={() => {
                  updateCart(dispatch, proposal.id, { ...cart, solarOption: solarOption.id });
                }}
              />
            ))}
          </div>
          {cartUpdateError && (
            <Typography className={classes.error} variant="subtitle1" color="primary">
              {cartUpdateError}
            </Typography>
          )}
          <Container maxWidth="md">
            <SelectButton disabled={!cart.solarOption} onClick={() => history.push(toChooseAddersPath(id))} fullWidth>
              Next: Add-ons
            </SelectButton>
            <FootNotes />
          </Container>
        </div>
      </Layout>
    </ScrollToTop>
  );
};

export default ChooseYourSolarOptionPage;
