import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import SimpleDagger from '../../../../components/SimpleDagger';

const FootNotes: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.text} variant="subtitle2">
        <SimpleDagger className={classes.footNoteSymbol} />
        As the purchaser and owner of a solar photovoltaic system, you may qualify for certain federal, state, local, or
        other rebates, tax credits or incentives (collectively, &quot;Incentives&quot;). If you have any questions as to
        whether and when you qualify for any Incentives and the amount of such Incentives, please consult and discuss
        with your personal tax or financial advisor. Solvana makes no representation, warranty or guaranty as to the
        availability or amount of such Incentives.
      </Typography>
    </div>
  );
};

export default FootNotes;
