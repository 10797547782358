import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
  }),
);

const defaultWidth = 50;

const NoShading: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 70">
      <title>{title || 'No Shading'}</title>

      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g className={classes.highlight} strokeWidth="2.94">
          <path d="M68.32 21.236c-4.865 4.867-12.83 4.79-17.79-.168-4.96-4.96-5.035-12.924-.168-17.791M42.261 14.699L35 15.85M43.963 3.95L40.139 2M47.202 24.397l-3.035 3.035M67.649 27.635l1.948 3.825M56.899 29.338l-1.15 7.26" />
        </g>
        <g strokeWidth="2.1">
          <path d="M20.716 34L2 48.082M35.92 48.93v19.547H5.512V48.931" />
          <path className={classes.detail} d="M20.716 38.723l-7.151 5.38" />
          <path d="M20.716 34l18.716 14.082" />
          <path className={classes.detail} d="M20.716 38.723l7.151 5.38" />
        </g>
      </g>
    </svg>
  );
};

export default NoShading;
