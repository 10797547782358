import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, MenuItem, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { Field, useFormikContext } from 'formik';
import { TextField as MUITextField } from 'formik-material-ui';
import { fetchAdvocates } from '../../../Advocates/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdvocates } from '../../../Advocates/selectors';
import { useParams } from 'react-router-dom';
import { CustomerPageParams } from '../../../../pg-customer/routes';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';

const General: React.FC = () => {
  const classes = useStyles()();
  const { id } = useParams<CustomerPageParams>();
  const { isSubmitting, setFieldValue } = useFormikContext<ProposalDTO>();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAdvocates(dispatch);
    setFieldValue('customerId', id);
  }, [id]);

  const advocates = useSelector(selectAdvocates);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          General
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <FormControl className={classes.formControl}>
          <TextField name={'name'} disabled={isSubmitting} label="Name" />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField name={'customerId'} disabled label="Customer" />
        </FormControl>

        <FormControl className={classes.formControl}>
          <Field
            component={MUITextField}
            className={classes.input}
            type="text"
            name="advocateId"
            label="Advocate"
            select
            variant="standard"
            helperText="Please select an Advocate"
            margin="normal"
            InputLabelProps={{ shrink: true }}
          >
            {advocates.entities.map((advocate) => (
              <MenuItem key={advocate.id} value={advocate.id}>
                {toFullName(advocate)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default General;
