import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      maxWidth: '550px',
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 1),
    },
    popover: {
      pointerEvents: 'none',
      padding: theme.spacing(5),
    },
    paper: {
      padding: theme.spacing(5),
    },
    link: {
      padding: theme.spacing(0, 0.5),
      textDecoration: 'underline',
      color: theme.palette.type === 'dark' ? '#fff' : theme.palette.secondary.main,
    },
  }),
);
