import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import System from '@solvana/proposal-tool-domain/dist/lib/System';

const DOE_HOME_VALUE_MULTIPLIER = 3;

export const selectEstimatedHomeValueIncrease = (state: SelectProposalState, proposalID: number): number => {
  const proposal = selectProposal(state, proposalID);
  if (!proposal || proposal.tax.entity !== TaxEntity.Residential) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  const systemSize = System.size(solarOption);

  return DOE_HOME_VALUE_MULTIPLIER * systemSize;
};
