import { AdderDTO } from '@solvana/proposal-tool-domain/dist/Proposal/Adder';

export const defaultAdder: AdderDTO = {
  productId: '' as any,
  price: 0,
  maxQuantity: 1,
  isITCEligible: true,
};

const squirrelGuard: AdderDTO = {
  productId: 1,
  price: '' as any,
  maxQuantity: 1,
  isITCEligible: true,
};

const surgeProtector: AdderDTO = {
  productId: 3,
  price: 799,
  maxQuantity: 1,
  isITCEligible: true,
};

const evCharger: AdderDTO = {
  productId: 4,
  price: 2_057,
  maxQuantity: 1,
  isITCEligible: true,
};

const solarEdgeExtendedWarranty: AdderDTO = {
  productId: 2,
  price: 702,
  maxQuantity: 1,
  isITCEligible: true,
};

const enteligentEvCharger: AdderDTO = {
  productId: 14,
  price: 3_499,
  maxQuantity: 1,
  isITCEligible: true,
};

export const defaultResidentialAdders = [
  squirrelGuard,
  solarEdgeExtendedWarranty,
  enteligentEvCharger,
  evCharger,
  surgeProtector,
];
