import React, { useEffect } from 'react';
import Header from './Header';
import { useStyles } from './styles';
import Metadata from './Metadata';
import clsx from 'clsx';
import { fetchCurrentUserProfile } from '../CurrentUserProfile/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHasResolvedCurrentUserProfile,
  selectIsFetchingCurrentUserProfile,
} from '../CurrentUserProfile/selectors';

type Props = {
  title: string;
  hideHeaderLogo?: boolean;
  hideHeader?: boolean;
  noMarginTop?: boolean;
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ title, children, hideHeaderLogo, hideHeader, noMarginTop }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasResolvedCurrentUserProfile = useSelector(selectHasResolvedCurrentUserProfile);
  const isFetchingCurrentUserProfile = useSelector(selectIsFetchingCurrentUserProfile);

  useEffect(() => {
    if (isFetchingCurrentUserProfile) return;
    if (hasResolvedCurrentUserProfile) return;
    fetchCurrentUserProfile(dispatch);
  }, [isFetchingCurrentUserProfile, hasResolvedCurrentUserProfile]);

  if (!hasResolvedCurrentUserProfile) {
    return (
      <>
        <Metadata title={title} />
      </>
    );
  }

  return (
    <>
      <Metadata title={title} />

      <div>
        {!hideHeader && <Header hideLogo={hideHeaderLogo} />}

        <div className={clsx(classes.root, { [classes.noMarginTop]: noMarginTop })}>
          <main>{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
