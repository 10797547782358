import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';

export const selectLowestMonthlyLoanForSolarOption = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): ReturnType<typeof Pricing.lowestMonthlyPaymentForSolarOption> | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  const proposalWithSolarOption = { ...proposal, cart: { solarOption: solarOptionId } };

  return Pricing.lowestMonthlyPaymentForSolarOption(proposalWithSolarOption);
};

export const selectLowestMonthlyLoanOptionForSolarOption = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
): LoanFinanceOption | undefined => {
  const result = selectLowestMonthlyLoanForSolarOption(state, proposalId, solarOptionId);
  if (!result) return undefined;

  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  const option = proposal.financeOptions.find((fo) => fo.id === result.financeOptionId && isLoanFinanceOption(fo.type));
  return option ? (option as LoanFinanceOption) : undefined;
};
