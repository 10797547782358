import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import {
  toCheckoutPath,
  toChooseSolarOptionPath,
  toCompletePath,
  toChooseBatteriesPath,
  toChooseAddersPath,
} from '../../routes';

export const canViewCheckoutPages = (proposal: Proposal): boolean => !proposal.initialPayment;

export const canViewAddersPage = (proposal: Proposal): boolean =>
  canViewCheckoutPages(proposal) && Boolean(proposal.cart.solarOption);

export const canViewFinancingPage = (proposal: Proposal): boolean =>
  canViewCheckoutPages(proposal) && Boolean(proposal.cart.solarOption);

export const canViewBatteriesPage = (proposal: Proposal): boolean =>
  canViewFinancingPage(proposal) && Boolean(proposal.cart.financeOption);

export const canViewCheckoutPage = (proposal: Proposal): boolean => canViewBatteriesPage(proposal);

export const canViewCompletePage = (proposal: Proposal): boolean => Boolean(proposal.initialPayment);

export const resolveNextStep = (proposal: Proposal): string => {
  if (canViewCompletePage(proposal)) return toCompletePath(proposal.id.toString());
  if (canViewCheckoutPage(proposal)) return toCheckoutPath(proposal.id.toString());
  if (canViewBatteriesPage(proposal)) return toChooseBatteriesPath(proposal.id.toString());
  if (canViewAddersPage(proposal)) return toChooseAddersPath(proposal.id.toString());

  return toChooseSolarOptionPath(proposal.id.toString());
};
