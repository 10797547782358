import { FinanceOptionType, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { toFormattedPrice } from '../../../../../../../utils/helpers';

export const getFinancingLegalText = (loan: LoanFinanceOption, principle: number, itcPercentage: number): string => {
  const firstSeventeenMonthlyPrice = principle * loan.initialPaymentFactor;
  const firstSeventeen = toFormattedPrice(firstSeventeenMonthlyPrice);
  const remainingTerm = loan.termInMonths - 17;
  const remainingPayment1 = toFormattedPrice(principle * loan.initialPaymentFactor);
  const remainingPayment2 = toFormattedPrice(principle * loan.secondPaymentFactor);
  const financedITCValue = itcPercentage * principle;

  if (FinanceOptionType.SunstoneCredit === loan.type) {
    return `The fixed Interest Rate for a ${loan.termInMonths / 12} year loan is ${
      loan.rate
    }% and will have a financed amount of ${toFormattedPrice(
      principle,
    )}. The monthly payment is ${firstSeventeen} with a special payment of ${toFormattedPrice(
      financedITCValue,
    )} due before month 18`;
  }

  return `The fixed Annual Percentage Rate for a ${loan.termInMonths / 12} year loan is ${
    loan.rate
  }%, and will have a financed amount of ${toFormattedPrice(
    principle,
  )}. The monthly payment for the first 17 months will be ${firstSeventeen}. If you make a ${
    itcPercentage * 100
  }% voluntary payment of ${toFormattedPrice(
    financedITCValue,
  )} in month 18, your remaining ${remainingTerm} payments will be approximately ${remainingPayment1}/mo. If no voluntary payment is made, the remaining ${remainingTerm} payments will be ${remainingPayment2}/mo. Monthly payment assumes ACH enrollment, if not, rates will increase by 0.5% APR.`;
};
