import { EligibleESSFinancingState } from './reducer';
import { FinanceOption, FinanceOptionType, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { selectProposal, SelectProposalState } from '../../../common/Proposals/selectors';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';

export const selectESSFinancingUI = (state: { essFinancingUI: EligibleESSFinancingState }): EligibleESSFinancingState =>
  state.essFinancingUI;

export const selectIsESSFinancingUIOpen = (state: { essFinancingUI: EligibleESSFinancingState }): boolean =>
  selectESSFinancingUI(state).isOpen;

export const selectEligibleLoanOptions = (state: SelectProposalState, proposalId: number): LoanFinanceOption[] => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return [];

  const loanOptions = Financing.getLoanOptions(proposal);

  return loanOptions.filter((lfo) => Financing.isEligibleESSAdderFinanceOption(lfo));
};

export const selectCashOption = (state: SelectProposalState, proposalId: number): FinanceOption | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return proposal.financeOptions.find((fo) => fo.type === FinanceOptionType.Cash);
};
