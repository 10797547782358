import { UserSession } from '../common/UserSession';

export enum Actions {
  SetGroups = 'auth/SET_GROUPS',
  SetCognitoUserProfile = 'auth/SetCognitoUserProfile',
  SetIsAuthenticated = 'auth/SetIsAuthenticated',
  SetLoggedOut = 'auth/SetLoggedOut',
  SetHasResolved = 'auth/SetHasResolved',
}

export const setGroups = (sessionUser: UserSession | null) => ({
  type: Actions.SetGroups,
  sessionUser,
});

export interface IDTokenClaims {
  [key: string]: any;
}

export const setIDTokenClaims = (idTokenClaims: IDTokenClaims | null) => ({
  type: Actions.SetCognitoUserProfile,
  idTokenClaims,
});

export const setIsAuthenticated = (isAuthenticated: boolean) => ({
  type: Actions.SetIsAuthenticated,
  isAuthenticated,
});

export const setHasResolvedAuth = (hasResolved: boolean) => ({
  type: Actions.SetHasResolved,
  hasResolved,
});

export const setLoggedOut = () => ({
  type: Actions.SetLoggedOut,
});
