import { FinanceOptionDTO, FinanceOptionType } from '@solvana/proposal-tool-domain/dist/FinanceOption';

export const sunstoneCreditLoanOptions: FinanceOptionDTO[] = [
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 60,
    rate: 2.99,
    initialPaymentFactor: 0.013744,
    secondPaymentFactor: 0.013744,
    downPayment: 1_500,
  },
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 96,
    rate: 2.99,
    initialPaymentFactor: 0.008914,
    secondPaymentFactor: 0.008914,
    downPayment: 1_500,
  },
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 120,
    rate: 3.29,
    initialPaymentFactor: 0.00744,
    secondPaymentFactor: 0.00744,
    downPayment: 1_500,
  },
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 144,
    rate: 3.49,
    initialPaymentFactor: 0.006465,
    secondPaymentFactor: 0.006465,
    downPayment: 1_500,
  },
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 180,
    rate: 3.99,
    initialPaymentFactor: 0.005623,
    secondPaymentFactor: 0.005623,
    downPayment: 1_500,
  },
  {
    type: FinanceOptionType.SunstoneCredit,
    termInMonths: 240,
    rate: 3.99,
    initialPaymentFactor: 0.004599,
    secondPaymentFactor: 0.004599,
    downPayment: 1_500,
  },
];
