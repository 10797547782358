import React from 'react';
import rfaSurveyQuestions, { handleNextQuestionClick, QuestionType } from '../common/rfaSurveyQuestions';
import { Typography, Container, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsQuestionAnswered,
  selectRedirectPathForUnansweredQuestions,
  selectRFASurveyQuestion,
} from '../selectors';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import SelectButton from '../../CheckoutPages/common/SelectButton';
import AnswerCard from './AnswerCard';
import { setRFASurveySingleAnswer, toggleRFASurveyMultiAnswer } from '../actions';
import { RootState } from '../../../RootState';
import { RFASurveyRoutes } from '../routes';

const RFASurvey: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { survey, index } = useSelector(() => selectRFASurveyQuestion(pathname)) || {};
  const isQuestionAnswered = useSelector((state: RootState) =>
    Boolean(survey && selectIsQuestionAnswered(state, survey.question)),
  );
  const redirectPathForUnansweredQuestions = useSelector((state: RootState) =>
    selectRedirectPathForUnansweredQuestions(state, survey?.question || ''),
  );

  if (redirectPathForUnansweredQuestions) return <Redirect to={redirectPathForUnansweredQuestions} />;
  if (!survey || index === undefined) return <Redirect to={rfaSurveyQuestions[0].path} />;
  const isLastQuestion = index === rfaSurveyQuestions.length - 1;

  const { answerChoices, question } = survey;

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5">{question}</Typography>

      <Typography className={classes.subtitle} variant="subtitle1">
        {survey.questionType}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        justify="center"
        spacing={3}
        className={classes.answerOptionsContainer}
      >
        {answerChoices.map((option) => (
          <Grid item xs={12} md={6} key={option.answerChoice}>
            <AnswerCard
              question={question}
              withFade={isQuestionAnswered}
              option={option}
              onClick={() => {
                if (QuestionType.SelectOne === survey.questionType) {
                  dispatch(setRFASurveySingleAnswer(question, option.answerChoice));
                  return;
                }

                dispatch(toggleRFASurveyMultiAnswer(question, option.answerChoice));
              }}
            />
          </Grid>
        ))}
      </Grid>
      <SelectButton
        disabled={!isQuestionAnswered}
        fullWidth
        onClick={() => {
          if (isLastQuestion) {
            history.push(RFASurveyRoutes.UtilityBills);
            return;
          }
          handleNextQuestionClick(history, index);
        }}
      >
        {isLastQuestion ? 'Next (Utility Data)' : 'Next Question'}
      </SelectButton>
    </Container>
  );
};

export default RFASurvey;
