export enum Actions {
  SetLearnMoreItem = 'LearMore/SetLearMoreItem',
}

export enum LearnMoreItemType {
  SolarOption = 'SolarOption',
  ESSAdder = 'ESSAdder',
  FinanceOptionBenefits = 'FinanceOptionBenefits',
  FinancingFAQ = 'FinancingFAQ',
}

export type LearnMoreItem = {
  id: number;
  proposalId: number;
  type: LearnMoreItemType;
};

export const setLearMoreItem = (item: LearnMoreItem | null) => ({
  type: Actions.SetLearnMoreItem,
  item,
});

interface LearnMoreParams {
  id: number;
  proposalId: number;
}

export const setLearMoreItemForESSAdder = (item: LearnMoreParams | null) => ({
  type: Actions.SetLearnMoreItem,
  item: {
    ...item,
    type: LearnMoreItemType.ESSAdder,
  },
});

export const setLearMoreItemForSolarOption = (item: LearnMoreParams | null) => ({
  type: Actions.SetLearnMoreItem,
  item: {
    ...item,
    type: LearnMoreItemType.SolarOption,
  },
});

export const setLearMoreItemForFinanceBenefits = (item: LearnMoreParams | null) => ({
  type: Actions.SetLearnMoreItem,
  item: {
    ...item,
    type: LearnMoreItemType.FinanceOptionBenefits,
  },
});

export const setLearnMoreItemForFinancingFAQ = (item: LearnMoreParams) => ({
  type: Actions.SetLearnMoreItem,
  item: {
    ...item,
    type: LearnMoreItemType.FinancingFAQ,
  },
});
