import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    white: {
      fill: '#fff',
    },
  }),
);

type Props = {
  size?: number;
  white?: boolean;
};

const defaultSize = 50;

const SolarIcon: React.FC<Props> = ({ size, white }) => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.root, { [classes.white]: white })}
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero">
        <path d="M49.895 42.553l-6-12A1.001 1.001 0 0043 30H7c-.379 0-.725.214-.895.553l-6 12A1 1 0 001 44h23v4h-5a1 1 0 100 2h12a1 1 0 100-2h-5v-4h23a1.001 1.001 0 00.895-1.447zM2.618 42l5-10h34.764l5 10H2.618zM25 18c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zM25 4a1 1 0 001-1V1a1 1 0 00-2 0v2a1 1 0 001 1zM24 23v2a1 1 0 002 0v-2a1 1 0 00-2 0zM34 13a1 1 0 001 1h2a1 1 0 100-2h-2a1 1 0 00-1 1zM13 14h2a1 1 0 000-2h-2a1 1 0 000 2zM32.071 6.929a.997.997 0 00.707-.293l1.414-1.414a.999.999 0 10-1.414-1.414l-1.414 1.414a.999.999 0 00.707 1.707zM17.222 19.364l-1.414 1.414a.999.999 0 101.414 1.414l1.414-1.414a.999.999 0 10-1.414-1.414zM32.778 22.192a.997.997 0 001.414 0 .999.999 0 000-1.414l-1.414-1.414a.999.999 0 10-1.414 1.414l1.414 1.414zM17.222 6.636a.997.997 0 001.414 0 .999.999 0 000-1.414l-1.414-1.414a.999.999 0 10-1.414 1.414l1.414 1.414z" />
      </g>
    </svg>
  );
};

export default SolarIcon;
