import { SubArrayDTO } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption/SubArray';
import { SolarOptionDTO } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';

export const emptyInverter = {
  productId: '' as any,
  quantity: 1,
};

export const emptySubArray: SubArrayDTO = {
  annualDegradation: '' as any,
  installType: '' as any,
  notes: '',
  productId: '' as any,
  pvWattRatio: '' as any,
  size: '' as any,
  panelCount: '' as any,
};

export const emptySolarOption: SolarOptionDTO = {
  price: '' as any,
  notes: '',
  inverters: [{ ...emptyInverter }],
  subArrays: [{ ...emptySubArray }],
};
