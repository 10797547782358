import React from 'react';
import { FormikHelpers } from 'formik';
import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { setOneProposal } from '../../common/Proposals/actions';
import { Dispatch } from 'redux';
import { curry } from 'ramda';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import ProposalForm from '../../common/Proposals/ProposalForm';
import { defaultResidentialProposal } from '../../common/Proposals/ProposalForm/defaults';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { Container } from '@material-ui/core';

export const createProposal = curry(
  (dispatch: Dispatch, proposal, { setSubmitting, setValues, setStatus }: FormikHelpers<ProposalDTO>) => {
    setSubmitting(true);
    proposalToolApi
      .post('/v1/proposals', proposal)
      .then((response) => {
        dispatch(setOneProposal(response.data));
        setValues(response.data);
        setStatus({ success: 'Success.' });
        setSubmitting(false);
      })
      .catch((e) => {
        setStatus({ error: e.toString() });
        setSubmitting(false);
      });
  },
);

const CreateProposal: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Container className={classes.root}>
      <ProposalForm proposal={defaultResidentialProposal} submitHandler={createProposal(dispatch)} />
    </Container>
  );
};

export default CreateProposal;
