import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';

const StyledLink: React.FC<LinkProps & { to: string }> = ({ to, children, onClick }) => {
  return (
    <Link component={RouterLink} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

export default StyledLink;
