import * as React from 'react';
import { Checkbox, Link, Typography } from '@material-ui/core';
import { useStyles } from './styles';

type Props = {
  isAccepted: boolean;
  onAccept: () => any;
};

const ContractCheckbox: React.FC<Props> = ({ isAccepted, onAccept }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          onClick={onAccept}
          className={classes.checkbox}
          checked={isAccepted}
          inputProps={{ 'aria-label': 'Contract agreement' }}
        />
        <Typography className={classes.checkboxLabel} variant="body1">
          By placing this order, I agree to the{' '}
          <Link
            href="https://solvana-catalog-resources.s3.amazonaws.com/contracts/Solvana_Customer+Contract_2021.08.18_SSP.pdf"
            target="_blank"
          >
            MASTER ENGINEERING, PROCUREMENT AND CONSTRUCTION SERVICES AGREEMENT
          </Link>
          . I also authorize Solvana to begin submitting permits, schedule a site visit, and prepare for my upcoming
          install.
        </Typography>
      </div>
    </section>
  );
};

export default ContractCheckbox;
