import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderTop: `solid 1px ${theme.palette.type === 'dark' ? Colors.GreyTwo : Colors.GreyThree}`,
      paddingTop: theme.spacing(1),
      margin: theme.spacing(12, 0, 6),

      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(10, 0, 6),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(8, 0, 6),
      },
    },
    text: {
      color: theme.palette.type === 'dark' ? Colors.GreyTwo : Colors.GreyThree,
      width: '100%',
    },
    footNoteSymbol: {
      marginRight: theme.spacing(1),
    },
  }),
);
