import { Dispatch } from 'redux';
import { Battery } from '@solvana/catalog-domain/dist/Battery';
import { catalogApi } from '../../../../lib/api/catalogApi';

export enum Actions {
  SetIsFetchingBatteries = 'catalog/SetIsFetchingBatteries',
  SetBatteriesFetchError = 'catalog/SetBatteriesFetchError',
  SetBatteries = 'catalog/SetBatteries',
}

export const setBatteries = (batteries: Battery[]) => ({
  type: Actions.SetBatteries,
  batteries,
});

export const setBatteriesFetchError = (error: string | null) => ({
  type: Actions.SetBatteriesFetchError,
  error,
});

export const setIsFetchingBatteries = (isFetching: boolean) => ({
  type: Actions.SetIsFetchingBatteries,
  isFetching,
});

export const fetchBatteries = (dispatch: Dispatch) => {
  dispatch(setIsFetchingBatteries(true));
  dispatch(setBatteriesFetchError(null));

  catalogApi
    .get('/batteries')
    .then((response) => {
      dispatch(setBatteries(response.data));
      dispatch(setIsFetchingBatteries(false));
    })
    .catch((e) => {
      dispatch(setIsFetchingBatteries(false));
      dispatch(setBatteriesFetchError(e.toString()));
    });
};
