import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { dockedWidthLG, dockedWidthMD, dockedWidthXL } from './DockedView/styles';

const openDrawerWidthOffset = '217px';
const openDrawerHeightOffset = '0';
export const verticalToHorizBreakpoint = 'sm';
const closeButtonAndCartIconPaddingTopMultiplier = 2.8;

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      drawer: {
        width: `calc(100% - ${openDrawerWidthOffset})`,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        color: '#fff',
      },
      drawerOpen: {
        backgroundColor: theme.palette.secondary.main,
        width: '65%',
        [theme.breakpoints.down('lg')]: {
          width: '80%',
        },
        [theme.breakpoints.only('md')]: {
          width: 'calc(100% - 196px)',
        },
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          width: '100%',
          height: `calc(100% - ${openDrawerHeightOffset})`,
          transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowY: 'scroll',
        },

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          width: '100%',
          transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          height: '86px',
          overflowY: 'hidden',
        },
        overflowX: 'hidden',
        width: dockedWidthMD,
        [theme.breakpoints.up('lg')]: {
          width: dockedWidthLG,
        },
        [theme.breakpoints.up('xl')]: {
          width: dockedWidthXL,
        },
      },
      drawerContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          flexDirection: 'column',
        },
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        height: '100%',
      },
      cartBody: {
        width: '100%',
        flexGrow: 1,
      },
      contentContainerNav: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignContent: 'center',
        padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier),

        [theme.breakpoints.down('xl')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '30px'),
        },

        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '25px'),
        },

        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, '21px'),
        },
      },
      smallViewNavBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, '21px'),
        position: 'fixed',
        width: '100%',
        bottom: 0,
        zIndex: 1,
        backgroundColor: theme.palette.secondary.main,
      },
      iconPlusTotal: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    }),
  );
