import React from 'react';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Metadata from '../../common/Layout/Metadata';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Colors } from '../../../theme/Colors';
import DarkModeToggleButton from '../../common/Layout/Header/DarkModeToggleButton';
import HorizontalLogo from '../../common/Logos/HorizontalLogo';
import Header from '../../common/Layout/Header';

const horizontalToColumnBreakpoint = 'md';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      '& form': {
        width: '100%',
        maxWidth: '500px',
      },
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '40%',
      overflow: 'hidden',
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: 'url("https://solvana-catalog-resources.s3.amazonaws.com/images/solar-house-1-800px.jpg")',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      padding: theme.spacing(3, 6),
      [theme.breakpoints.down(horizontalToColumnBreakpoint)]: {
        width: '100%',
        maxHeight: '280px',
        marginTop: '80px',
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: '200px',
      },
    },
    rightContainer: {
      width: '60%',
      '& hgroup': {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    rightInnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '500px',
      margin: '0 auto',
      padding: theme.spacing(3),
      height: '100vh',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    nav: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
    headerTitle: {
      fontSize: '32px',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },
    subHeaderTitle: {
      fontSize: '16px',
      fontWeight: 500,
      marginTop: theme.spacing(1),
      color: Colors.GreyTwo,
    },
  }),
);

interface Props {
  title: string;
  header: string;
  subHeader?: string;
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ title, children, header, subHeader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isColumn = useMediaQuery(theme.breakpoints.down(horizontalToColumnBreakpoint));

  return (
    <>
      <Metadata title={title} />
      <div className={classes.root}>
        <div className={classes.leftContainer}>{!isColumn && <HorizontalLogo white height="55px" />}</div>
        <div className={classes.rightContainer}>
          {isColumn ? (
            <Header />
          ) : (
            <nav className={classes.nav}>
              <DarkModeToggleButton />
            </nav>
          )}
          <section className={classes.rightInnerContainer}>
            <hgroup>
              <Typography className={classes.headerTitle} variant="h1">
                {header}
              </Typography>
              {subHeader && (
                <Typography className={classes.subHeaderTitle} variant="h2">
                  {subHeader}
                </Typography>
              )}
            </hgroup>
            {children}
          </section>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
