import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
    },
    highlight: {
      stroke: theme.palette.primary.main,
      strokeWidth: 3,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
  }),
);

const defaultWidth = 50;

const SolarRoof: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 62">
      <title>{title || 'Solar Roof Mount'}</title>
      <g strokeWidth="2.21" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M31 8L2 30M54 30v30H8V30" />
        <path className={classes.detail} d="M31 15l-11 8" />
        <path d="M31 8l29 22" />
        <path className={classes.detail} d="M31 15l11 8" />
        <path d="M60 19l-5 6M53 14l-5 6M48 10l-5 6M42 5l-5 6" />
        <path className={classes.highlight} d="M64 22L38 2" />
      </g>
    </svg>
  );
};

export default SolarRoof;
