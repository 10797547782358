import React from 'react';
import { useStyles } from './styles';
import { Divider, List, ListItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import {
  selectCartITCValue,
  selectCartTotalFinancedPrice,
  selectCartTotalNetFirstYearIncentives,
} from '../../../../../common/Proposals/Cart/selectors';
import { selectStateIncentivesTotal } from '../../../LearnMore/BenefitsSummary/CashFlow/selectors';
import LineItem from '../common/LineItem';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import { selectFinanceOption } from '../../../../../common/Proposals/FinanceOptions/selectors';
import { selectITCRate, selectProposal } from '../../../../../common/Proposals/selectors';
import { selectCartTotalMACRSValue } from '../../../LearnMore/BenefitsSummary/CashFlow/FirstYearCashFlow/selectors';
import { selectCartMonthlyPayments } from '../../../../../common/Proposals/Cart/selectors/selectCartMonthlyPayments';
import SimpleDagger from '../../../../../../components/SimpleDagger';

type Props = {
  proposalId: number;
};

const OrderSummary: React.FC<Props> = ({ proposalId }) => {
  const classes = useStyles()();
  const cartITCValue = useSelector((state: RootState) => selectCartITCValue(state, proposalId));
  const itcPercentage = useSelector((state: RootState) => selectITCRate(state, proposalId)) * 100;
  const cartMACRSValue = useSelector((state: RootState) => selectCartTotalMACRSValue(state, proposalId));
  const firstYearStateIncentives = useSelector((state: RootState) => selectStateIncentivesTotal(state, proposalId, 1));
  const cartMonthlyPayments = useSelector((state: RootState) => selectCartMonthlyPayments(state, proposalId));
  const totalPrice = useSelector((state: RootState) => selectCartTotalFinancedPrice(state, proposalId));
  const cartTotalNetFirstYearIncentives = useSelector((state: RootState) =>
    selectCartTotalNetFirstYearIncentives(state, proposalId),
  );
  const financeOption = useSelector((state: RootState) => selectFinanceOption(state, proposalId));
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));

  if (!proposal || !financeOption) return null;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Cart Total
      </Typography>
      <List>
        <ListItem>
          <LineItem title="Total Price" value={totalPrice} />
        </ListItem>
        <ListItem>
          <LineItem
            title={`${itcPercentage}% Federal Investment Tax Credit (ITC)`}
            value={-cartITCValue}
            Sup={SimpleDagger}
          />
        </ListItem>
        {cartMACRSValue > 0 && (
          <ListItem>
            <LineItem title="MACRS Cash Value" value={-cartMACRSValue} Sup={SimpleDagger} />
          </ListItem>
        )}
        {firstYearStateIncentives > 0 && (
          <ListItem>
            <LineItem title="First Year State Incentives" value={-firstYearStateIncentives} Sup={SimpleDagger} />
          </ListItem>
        )}
      </List>

      <Divider className={classes.divider} color="white" />

      <LineItem title="Price Net First Year Incentives" value={cartTotalNetFirstYearIncentives} />

      {cartMonthlyPayments && (
        <>
          <Typography className={classes.total} variant="h5">
            {toFormattedPrice(cartMonthlyPayments.initialPayment)}/mo.
            <sup>*</sup>
          </Typography>
        </>
      )}
    </div>
  );
};

export default OrderSummary;
