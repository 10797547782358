import { Reducer } from 'redux';
import { Actions, setIsFetchingInverters, setInverters, setInvertersFetchError } from './actions';
import { Inverter } from '@solvana/catalog-domain/dist/Inverter';

type InverterCatalogActions = ReturnType<typeof setIsFetchingInverters> &
  ReturnType<typeof setInvertersFetchError> &
  ReturnType<typeof setInverters>;

export type InverterCatalogState = {
  isFetching: boolean;
  error: string | null;
  entities: Inverter[];
};

const DEFAULT_STATE: InverterCatalogState = {
  isFetching: false,
  error: null,
  entities: [],
};

const inverterCatalog: Reducer<InverterCatalogState, InverterCatalogActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetInverters:
      return { ...state, entities: action.inverters };

    case Actions.SetIsFetchingInverters:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetInvertersFetchError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default inverterCatalog;
