import { selectProposal, SelectProposalState } from '../../../common/Proposals/selectors';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';

export const selectHasEligibleFinanceOption = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  const selectedFinanceOption = proposal.financeOptions.find((fo) => fo.id === proposal.cart.financeOption);
  if (!selectedFinanceOption) return false;

  return Financing.isEligibleESSAdderFinanceOption(selectedFinanceOption);
};
