import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../../theme/Colors';
import { isDarkMode } from '../../../../../theme/helpers';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'center',
      height: '100%',
    },
    seeComponents: {
      textTransform: 'unset',
      marginBottom: theme.spacing(5),
      padding: theme.spacing(0.25, 1.5),
    },
    cardContent: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4, 2.5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5, 2.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2),
      },
    },
    productName: {
      lineHeight: 1,
      fontWeight: 900,
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('md')]: {
        maxWidth: '250px',
      },
    },
    loanCopyContainer: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.dark : Colors.GreyFive,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(2),
      '& p': {
        textAlign: 'left',
      },
    },
    productPrice: {
      fontWeight: 900,
    },
    asterisk: {
      position: 'relative',
      top: '3px',
    },
    media: {
      width: '100%',
    },
    footerList: {
      color: Colors.GreyThree,
      fontSize: '12px',
    },
    chooseOptionLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noneSelectedContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: '30px',
      },
    },
    priceTitle: {
      color: isDarkMode(theme) ? Colors.GreyThree : Colors.GreyTwo,
    },
    cashPrice: {
      margin: theme.spacing(1, 0, 0),
      padding: 0,
      lineHeight: 1,
    },
    multiYearIncentivePrice: {
      margin: 0,
      padding: 0,
      '& sup': {
        marginLeft: theme.spacing(0.5),
        fontSize: '0.4rem',
      },
    },
    priceGroup: {
      margin: theme.spacing(2.5, 0),
    },
    netIncentivesClause: {
      marginTop: theme.spacing(-0.5),
    },
  }),
);
