import { Reducer } from 'redux';
import { Actions, setCurrentUserProfile, setHasResolved, setIsFetching } from './actions';
import { UserProfile } from '@solvana/proposal-tool-domain/dist/common/UserProfile';

type AdvocateActions = ReturnType<typeof setCurrentUserProfile> &
  ReturnType<typeof setIsFetching> &
  ReturnType<typeof setHasResolved>;

export type CurrentUserProfileState = {
  isFetching: boolean;
  hasResolved: boolean;
  entity?: UserProfile;
};

export type SelectCurrentUserProfileState = {
  currentUserProfile: CurrentUserProfileState;
};

const DEFAULT_STATE: CurrentUserProfileState = {
  isFetching: false,
  hasResolved: false,
};

const currentUserProfile: Reducer<CurrentUserProfileState, AdvocateActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetCurrentUserProfile:
      return { ...state, entity: action.currentUserProfile };

    case Actions.SetHasResolved:
      return { ...state, hasResolved: action.hasResolved };

    case Actions.SetIsFetching:
      return { ...state, isFetching: action.isFetching };

    default:
      return state;
  }
};

export default currentUserProfile;
