import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
      strokeWidth: 2.1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const NoSquirrel: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 67">
      <title>{title || 'No Squirrels'}</title>
      <path d="M41.4 33.3c8.8 0 16 7.2 16 16s-7.2 16-16 16h-27" />
      <path d="M30.4 43.3L27 45c-4.1 2-6.6 6.2-6.6 10.7v9.4m37-33.8s10 8 6 20-12 14-22 14" />
      <path d="M15.4 43.3s4-6 9-6v-10h-6.9c-2.5 0-4.2-2.5-3.3-4.8l4.2-11.2s5-8 12-3c5-8 9-6 9-6s2 6-3 10c2 4 0 9 0 9s5 5 5 11c2-7.9 5.7-13.9 12.7-14 5.7-.1 10.8 3.3 12.6 8.7 1.7 5.2.2 10.2-3.1 13.5" />
      <path d="M26.4 15.3c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1z" />
      <path
        className={classes.highlight}
        d="M3.7 46.4v.2c.7 2.9 4.4 7.5 7.3 6.7 2.9-.7 4-6.4 3.3-9.4 0-.1 0-.2-.1-.2L3.7 46.4z"
      />
      <path
        className={classes.highlight}
        d="M15.7 43.4s-1.6-6.4-8.5-4.7c-6.9 1.7-4.8 8-4.8 8l13.3-3.3zm-7.8-7.8s-1 1.7-.7 3.1M58.3 22.7L20.4 57.8M19.6 22.7l37.9 35.1"
      />
    </svg>
  );
};

export default NoSquirrel;
