import React from 'react';
import { TextField as MUITextField } from 'formik-material-ui';
import { FastField, FieldAttributes } from 'formik';
import { useStyles } from './styles';

type Props = {
  name: string;
  disabled: boolean;
  label?: string;
  type?: string;
} & FieldAttributes<any>;

const TextField: React.FC<Props> = ({ name, disabled, label, type, ...others }) => {
  const classes = useStyles()();

  return (
    <FastField
      disabled={disabled}
      component={MUITextField}
      className={classes.input}
      name={name}
      type={type || 'text'}
      label={label || name}
      {...others}
    />
  );
};

export default TextField;
