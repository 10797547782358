import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    innerContainer: {
      marginTop: theme.spacing(3),
    },
    error: {
      margin: theme.spacing(3, 0),
    },
  }),
);
