import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      textAlign: 'center',
      maxWidth: '500px',
    },
    subtitle: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      maxWidth: '300px',
    },
    titleContainer: {
      width: '100%',
    },
  }),
);
