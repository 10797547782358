import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { verticalToHorizBreakpoint } from '../../Cart/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& hgroup': {
        textAlign: 'right',
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
        flexDirection: 'column',
        padding: 0,
        marginRight: 0,
        '& hgroup': {
          margin: theme.spacing(0, 0, 2),
          textAlign: 'center',
        },
      },
    },
    button: {
      textTransform: 'unset',
      margin: theme.spacing(2, 0, 0),
      padding: theme.spacing(0.25, 1.5),
    },
  }),
);
