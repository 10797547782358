import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { isDarkMode } from '../../../../theme/helpers';

const UPGRADE_LABEL_HEIGHT = 30;
const breakPoint = 'sm';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 3, 8.5, 3),
      [theme.breakpoints.down(breakPoint)]: {
        margin: theme.spacing(1, '1%', 6, '1%'),
      },
      padding: theme.spacing(0, 0, 3, 0),
      maxWidth: '380px',
    },
    attribute: {
      fontSize: '0.95rem',
    },
    savings: {
      color: theme.palette.success.main,
    },
    totalPotentialSavings: {
      marginBottom: theme.spacing(2),
      fontWeight: 900,
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.dark : Colors.GreyFive,
      borderRadius: '8px',
      padding: theme.spacing(1, 3),
    },
    attributeContainer: {
      margin: theme.spacing(4, 0),
    },
    withMargin: {
      marginTop: `${UPGRADE_LABEL_HEIGHT}px`,
    },
    upgrade: {
      backgroundColor: theme.palette.primary.main,
      height: `${UPGRADE_LABEL_HEIGHT}px`,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      position: 'relative',
    },
    upgradeTitle: {
      color: '#fff',
    },
    innerContainer: {
      padding: theme.spacing(0, 3, 2),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& ul': {
        margin: 0,
        padding: 0,
      },
      '& li': {
        padding: 0,
        margin: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          marginRight: theme.spacing(1),
        },
      },
    },
    sectionTitle: {
      fontWeight: 900,
      margin: theme.spacing(3, 0, 0.8, 0),
    },
    inverterGroup: {
      marginBottom: theme.spacing(2),
    },
    selectButton: {
      minWidth: '180px',
      fontSize: '1.05rem',
      textTransform: 'capitalize',
      letterSpacing: theme.spacing(0.08),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
      shadow: 'none',
      padding: theme.spacing(1),
      borderRadius: '8px',
      color: '#fff',
    },
    systemComponentTitle: {
      fontWeight: 900,
      fontSize: '20px',
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
    asterisk: {
      position: 'relative',
      top: '3px',
    },
    monthlyPayment: {
      fontWeight: 900,
    },
    monthlyPaymentContainer: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.dark : Colors.GreyFive,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      '& p': {
        textAlign: 'left',
      },
    },
    priceTitle: {
      color: isDarkMode(theme) ? Colors.GreyThree : Colors.GreyTwo,
    },
    cashPrice: {
      margin: 0,
      padding: 0,
      position: 'relative',
    },
    pricingContainer: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    priceGroup: {
      margin: theme.spacing(1.5, 0, 1),
    },
    media: {
      width: '100%',
    },
    seeSystemComponentsBtn: {
      textTransform: 'unset',
      margin: theme.spacing(1, 0, 5),
      padding: theme.spacing(0.25, 1.5),
    },
  }),
);
