import * as React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import SavingsChart from './SavingsChart';
import CashFlowPositive from '../../../../../../components/SolvanaIcons/CashFlowPositive';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../RootState';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import {
  cashInHandForSolarOptions,
  selectIsCashFlowPositiveForSolarOptions,
} from '../../../../pg-choose-your-financing/Financing/FinanceOptions/common/selectors';

type Props = {
  proposal: Proposal;
  financeOptionId: number;
};

const WithWithoutSolar: React.FC<Props> = ({ proposal, financeOptionId }) => {
  const classes = useStyles();
  const utilityBill = System.sumOfUtilityBills(proposal, 25);
  const isCashFlowPositive = useSelector((state: RootState) =>
    selectIsCashFlowPositiveForSolarOptions(state, proposal.id, financeOptionId),
  );
  const solarSavings = System.totalSolarSavings(proposal, 25);
  const cashInHand = cashInHandForSolarOptions(proposal, financeOptionId);
  const financeOption = proposal.financeOptions.find((fo) => fo.id === financeOptionId);
  const solarSavingsPercentage = (solarSavings / utilityBill) * 100;

  const formattedSolarSavingsPercentage = solarSavingsPercentage.toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  if (!financeOption) return null;

  return (
    <section className={classes.root}>
      <ul className={classes.statisticsContainer}>
        <Card component="li" className={classes.card} elevation={0}>
          <Typography variant="h4">Without solar you will pay</Typography>
          <Typography variant="h3" className={classes.loss}>
            {toFormattedPrice(utilityBill)}
          </Typography>
          <Typography variant="h4">over the next 25 years with your current electric plan.</Typography>
        </Card>
        <Card component="li" className={classes.card} elevation={0}>
          <Typography variant="h4">With solar you will offset your electric bill by</Typography>
          <Typography variant="h3">{toFormattedPrice(solarSavings)}</Typography>
          <Typography variant="subtitle2">({formattedSolarSavingsPercentage}% of your electric bill)</Typography>
          {solarSavingsPercentage > 100 && (
            <Typography variant="subtitle2">
              (Utility credit of{' '}
              <strong className={classes.savings}>
                +{toFormattedPrice(solarSavings * ((solarSavingsPercentage - 100) / 100))}
              </strong>
              )
            </Typography>
          )}
        </Card>
        {cashInHand > 0 && (
          <Card component="li" className={classes.card} elevation={0}>
            <Typography variant="h4">
              Purchasing your cart selections with this finance option will put up to
            </Typography>
            <Typography variant="h3" className={classes.savings}>
              {toFormattedPrice(cashInHand)}
            </Typography>
            <Typography variant="h4">in your pocket over the next 25 years.</Typography>
          </Card>
        )}
      </ul>
      <article>
        <Card elevation={0} className={classes.card}>
          {isCashFlowPositive && (
            <div className={classes.badgeTitleContainer}>
              <CashFlowPositive width={36} />
              <Typography variant="body1">Cash Flow Positive</Typography>
            </div>
          )}
          <SavingsChart financeOptionId={financeOptionId} proposalId={proposal.id} />
          <Typography className={classes.footNote} variant="body2">
            * Calculations use a {proposal.utility.annualInflationPercentage}% utility bill annual inflation rate.
          </Typography>
        </Card>
      </article>
    </section>
  );
};

export default WithWithoutSolar;
