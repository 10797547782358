import React from 'react';
import { useStyles } from './styles';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart } from '../../../common/Proposals/selectors';
import { RootState } from '../../../../RootState';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../routes';
import { removeAdderFromCart, updateCartAdder } from '../../../common/Proposals/ProposalForm/Cart/actions';
import AdderOption from './AdderOption';

type Props = {
  proposal: Proposal;
};

const AdderOptions: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const cart = useSelector((state: RootState) => selectCart(proposalId, state));

  if (!cart) {
    return null;
  }

  const handleSelect = (adderId: number) => {
    updateCartAdder(dispatch, id, { adderId, quantity: 1 });
  };

  const handleRemove = (adderId: number) => {
    const selectedAdder = cart.selectedAdders.find((sa) => sa.adderId === adderId);
    if (selectedAdder) {
      removeAdderFromCart(dispatch, id, selectedAdder.id, adderId);
    }
  };

  return (
    <div aria-label="Energy storage system options" className={classes.root}>
      {proposal.adders.map((adder) => (
        <AdderOption
          key={`adder-${adder.id}`}
          adder={adder}
          disabled={false}
          onRemove={() => handleRemove(adder.id)}
          onSelect={() => handleSelect(adder.id)}
          proposalId={proposal.id}
        />
      ))}
    </div>
  );
};

export default AdderOptions;
