import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    highlight: {
      stroke: theme.palette.primary.main,
      fill: 'none',
    },
  }),
);

const defaultWidth = 50;

const NoSnowfall: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 83">
      <title>{title || 'No Snowfall'}</title>
      <g fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M55.299 15.063c.221-.88.351-1.797.351-2.746 0-6.222-5.044-11.267-11.267-11.267-4.406 0-8.212 2.536-10.063 6.222a11.213 11.213 0 0 0-4.67-1.022c-5.326 0-9.778 3.7-10.954 8.667h-5.08c-6.94 0-12.566 5.626-12.566 12.566S6.676 40.05 13.616 40.05h39.868c6.94 0 12.566-5.626 12.566-12.567 0-6.321-4.674-11.538-10.751-12.42z"
          strokeWidth="2.1"
        />
        <g className={classes.highlight} strokeWidth="2.4">
          <path d="M48.255 46.98c-.24 6.878-6.117 12.253-13.126 12.01-7.01-.245-12.497-6.018-12.257-12.897M24.737 59.972l-4.525 5.794M45.427 60.695l4.11 6.094M18.651 50.95l-4.126 1.181M34.965 63.695l-.15 4.29M52.127 52.118l4.034 1.469" />
        </g>
      </g>
    </svg>
  );
};

export default NoSnowfall;
