import React from 'react';
import { useStyles, verticalToHorizBreakpoint } from './styles';
import { GoSolarPageParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import CartIcon from '../CartIcon';
import { AppBar, List, ListItem, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import OrderSummary from './OrderSummary';
import { selectCartAddersData, selectCartESSAdderData, selectCartSolarOptionData } from '../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'RootState';
import { setLearMoreItemForESSAdder, setLearMoreItemForSolarOption } from '../../LearnMore/actions';
import CloseButton from 'components/CloseButton';
import CartItem from './CartItem';
import IncentivesDisclaimer from '../../LearnMore/BenefitsSummary/IncentivesDisclaimer';
import SimpleDagger from 'components/SimpleDagger';
import CustomerInformation from './CustomerInformation';
import SelectedFinanceOption from './SelectedFinanceOption';
import { selectFinanceOption } from 'containers/common/Proposals/FinanceOptions/selectors';
import AdvocateInformation from './AdvocateInformation';

type Props = {
  onClick: () => any;
};

const CartSummary: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles()();
  const theme = useTheme();
  const isUnderSmMediaSize = useMediaQuery(theme.breakpoints.down(verticalToHorizBreakpoint));
  const dispatch = useDispatch();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const solarOption = useSelector((state: RootState) => selectCartSolarOptionData(state, proposalId));
  const essAdder = useSelector((state: RootState) => selectCartESSAdderData(state, proposalId));
  const adders = useSelector((state: RootState) => selectCartAddersData(state, proposalId));
  const financeOption = useSelector((state: RootState) => proposalId && selectFinanceOption(state, proposalId));

  return (
    <div className={classes.root}>
      <AppBar
        position={isUnderSmMediaSize ? 'fixed' : 'relative'}
        color="secondary"
        elevation={isUnderSmMediaSize ? 5 : 0}
        className={classes.appBar}
      >
        <Toolbar className={classes.contentContainerNav}>
          <CartIcon onClick={onClick} />
          <CloseButton onClick={onClick} />
        </Toolbar>
      </AppBar>
      <section className={classes.cartBody}>
        <Typography variant="h4" className={classes.title}>
          Your selections
        </Typography>

        <List>
          <ListItem className={classes.cartListItem}>
            {solarOption && (
              <CartItem
                product={solarOption}
                onSeeDetailsClick={() => dispatch(setLearMoreItemForSolarOption({ id: solarOption.id, proposalId }))}
                proposalId={proposalId}
              />
            )}
          </ListItem>

          {adders.map((adder) => (
            <ListItem key={`adder-${adder.id}`} className={classes.cartListItem}>
              <CartItem product={adder} proposalId={proposalId} />
            </ListItem>
          ))}

          {essAdder && (
            <ListItem className={classes.cartListItem}>
              <CartItem
                product={essAdder}
                onSeeDetailsClick={() => dispatch(setLearMoreItemForESSAdder({ id: essAdder.id, proposalId }))}
                proposalId={proposalId}
              />
            </ListItem>
          )}

          {financeOption && (
            <ListItem className={classes.cartListItem}>
              <SelectedFinanceOption />
            </ListItem>
          )}
        </List>

        <section className={classes.cartSummary}>
          <OrderSummary proposalId={proposalId} />
        </section>

        <div className={classes.disclaimerContainer}>
          <IncentivesDisclaimer Sup={SimpleDagger} />
        </div>

        <div className={classes.customerInfo}>
          <CustomerInformation />
        </div>

        <div className={classes.customerInfo}>
          <AdvocateInformation />
        </div>
      </section>
    </div>
  );
};

export default CartSummary;
