export enum RFASurveyRoutes {
  Address = '/request-a-proposal/address',
  ElectricCar = '/request-a-proposal/electric-car',
  Financing = '/request-a-proposal/financing-preference',
  InstallType = '/request-a-proposal/install-type',
  Shading = '/request-a-proposal/shading',
  SmallAnimals = '/request-a-proposal/small-animals',
  Snowfall = '/request-a-proposal/snowfall',
  UtilityBills = '/request-a-proposal/utility-bills',
}

export enum RFAUtilityAPIRedirectPage {
  Path = '/utility-api-redirect',
}

export enum RFAIntroductionRoute {
  Path = '/request-a-proposal/start',
}

export const ALL_RFA_ROUTES = [...Object.values(RFASurveyRoutes), ...Object.values(RFAIntroductionRoute)];
