import React, { useEffect } from 'react';
import * as queryString from 'querystring';

const UtilityAPIRedirectIFramePage: React.FC = () => {
  const rawData = { ...queryString.parse(document.location.search.substring(1)), utilityApiRedirect: true };

  useEffect(() => {
    window.parent.postMessage(rawData, document.location.origin);
  }, [rawData]);

  return null;
};

export default UtilityAPIRedirectIFramePage;
