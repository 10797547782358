import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core';
import { Colors } from '../Colors';
import { AvertaExtraBold, AvertaRegular, AvertaSemiBold } from '../fonts/Averta';
const fontFamily = ['Averta', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',');

export const makeTheme = (isDark: boolean): Theme =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        type: isDark ? 'dark' : 'light',
        background: {
          paper: isDark ? Colors.DarkMode.PaperBackground : Colors.Background,
          default: isDark ? Colors.Dark : Colors.Background,
        },
        primary: {
          main: Colors.Primary,
        },
        secondary: {
          main: Colors.Secondary,
        },
        error: {
          main: Colors.Primary,
        },
        warning: {
          main: Colors.Warning,
        },
        info: {
          main: Colors.Info,
        },
        success: {
          main: Colors.Success,
        },
      },
      typography: {
        fontFamily,
        h1: { fontWeight: 900 },
        h2: { fontWeight: 900 },
        h3: { fontWeight: 900 },
        h4: { fontWeight: 900 },
        h5: { fontWeight: 700 },
        h6: { fontWeight: 700 },
        allVariants: {
          color: isDark ? Colors.GreyFour : Colors.Dark,
        },
        fontWeightBold: 900,
        fontWeightMedium: 700,
        fontWeightRegular: 500,
      },
      props: {
        MuiButtonBase: {
          disableRipple: true,
        },
        MuiButtonGroup: {
          disableRipple: true,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [AvertaExtraBold, AvertaRegular, AvertaSemiBold],
          },
        },
        MuiAppBar: {
          colorDefault: {
            backgroundColor: isDark ? Colors.Dark : Colors.Background,
          },
        },
      },
    }),
  );
