import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FACTORS } from './factors';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '500px',
    },
  }),
);

const Factors: React.FC = () => {
  const classes = useStyles();
  return <Typography className={classes.root}>{FACTORS.join(', ')}</Typography>;
};

export default Factors;
