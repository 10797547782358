import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        width: '100%',
        textAlign: 'center',
      },
      container: {
        height: 10,
        borderRadius: 8,
      },
      percent: {
        marginTop: theme.spacing(1),
      },
      colorPrimary: {
        backgroundColor: Colors.GreyThree,
      },
      bar: {
        borderRadius: 8,
        backgroundColor: theme.palette.primary.main,
      },
      subSubtitle: {
        color: theme.palette.type === 'dark' ? Colors.GreyThree : Colors.GreyTwo,
      },
    }),
  );
