import { SelectCustomerRFAState } from './reducer';
import { RFA } from '@solvana/rfa-domain';
import { ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';

export const selectCustomerRFAs = ({ customerRFAs }: SelectCustomerRFAState, customerAuthUUID: string): RFA[] =>
  customerRFAs.entities[customerAuthUUID] || [];

export const selectCustomerRFA = (
  state: SelectCustomerRFAState,
  customerAuthUUID: string,
  rfaId: string,
): RFA | undefined => {
  const RFAs = selectCustomerRFAs(state, customerAuthUUID);
  return RFAs.find((rfa) => rfa.rfaId === rfaId);
};

export const selectProposalsForCustomer = (
  state: { proposals: { index: ProposalIndexItem[] } },
  customerId: string,
): ProposalIndexItem[] => state.proposals.index.filter((i) => i.customerId === customerId);
