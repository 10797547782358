import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    subtitle: {
      textAlign: 'center',
      maxWidth: '565px',
      margin: theme.spacing(0, 'auto', 6, 'auto'),
    },
  }),
);
