import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '../../styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectAdderCatalog } from '../../../../Catalog/Adders/selectors';

type Props = {
  name: string;
};

const AdderOptionSelect: React.FC<Props> = ({ name }) => {
  const classes = useStyles()();
  const { setFieldValue, isSubmitting } = useFormikContext<ProposalDTO>();
  const adderCatalog = useSelector(selectAdderCatalog);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}.productId`, e.target.value);
    const adder = adderCatalog.entities.find((a) => a.id === parseInt(e.target.value));
    setFieldValue(`${name}.isITCEligible`, Boolean(adder?.isITCEligible));
  };

  const selectOptions = adderCatalog.entities.map((a) => ({
    label: `${a.brand} - ${a.model}`,
    value: a.id,
  }));

  return (
    <Field
      component={TextField}
      className={classes.input}
      disabled={isSubmitting}
      type="text"
      name={`${name}.productId`}
      onChange={handleChange}
      label="Adder Product"
      select
      variant="standard"
      helperText="Please select an Adder product"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default AdderOptionSelect;
