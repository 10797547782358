import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 100;

const Neighborhood: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 48">
      <g fillRule="evenodd" strokeLinecap="round">
        <path strokeWidth="2.1" strokeLinejoin="round" d="M1.05 32.376l13.88-12.755 13.88 12.755" />
        <path strokeWidth="1.5" d="M4.05 32.876l10.88-9.753 10.88 9.753" />
        <g strokeLinejoin="round" strokeWidth="2.1">
          <path d="M24.683 32.126v13.505H5.176V32.126M71.219 32.376l13.88-12.755 13.88 12.755" />
        </g>
        <path strokeWidth="1.5" d="M74.22 32.876l10.879-9.753 10.879 9.753" />
        <g strokeLinejoin="round" strokeWidth="2.1">
          <path d="M94.852 32.126v13.505H75.344V32.126M26.308 23.213L50.425 1.05l24.119 22.163" />
        </g>
        <path
          strokeWidth="2.1"
          strokeLinejoin="round"
          d="M31.522 24.082L50.425 7.134 69.33 24.082M65.037 14.275V7.133H61.29v3.385"
        />
        <path strokeWidth="2.1" strokeLinejoin="round" d="M67.374 22.779v23.466H33.478V22.779" />
      </g>
    </svg>
  );
};

export default Neighborhood;
