import React from 'react';
import { useStyles } from './styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Advocate } from '@solvana/proposal-tool-domain/dist/Advocate';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';

const ProposalRow = ({ advocate }: { advocate: Advocate }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {advocate.id}
        </TableCell>

        <TableCell component="th" scope="row">
          {toFullName(advocate)}
        </TableCell>

        <TableCell component="th" scope="row">
          {advocate.email}
        </TableCell>

        <TableCell component="th" scope="row">
          {advocate.phoneNumber}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ProposalRow;
