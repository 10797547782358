import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import StyledButton from '../../common/StyledButton';
import Layout from 'containers/common/Layout';
import rfaSurveyQuestions from '../common/rfaSurveyQuestions';

const Introduction: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout title="Solvana | Let's Get You Started!">
      <Container maxWidth="md" className={classes.root}>
        <Typography className={classes.title} variant={'h5'}>
          Welcome to Solvana<sup>&trade;</sup>
        </Typography>
        <Typography variant={'h3'}>
          Get your solar proposal
          <br /> in 3 easy steps
        </Typography>

        <List className={classes.iconList}>
          <ListItem>
            <div className={classes.circle}>
              <Typography variant="body1">1</Typography>
            </div>
            <Typography variant="body1">Complete the site location questionnaire.</Typography>
          </ListItem>
          <ListItem>
            <div className={classes.circle}>
              <Typography variant="body1">2</Typography>
            </div>
            <Typography variant="body1">Securely share your utility usage from the past year.</Typography>
          </ListItem>
          <ListItem>
            <div className={classes.circle}>
              <Typography variant="body1">3</Typography>
            </div>
            <Typography variant="body1">
              In 48-hours you will receive a digital solar proposal with options customized for you.
            </Typography>
          </ListItem>
        </List>

        <StyledButton
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={() => history.push(rfaSurveyQuestions[0].path)}
        >
          Get Started
        </StyledButton>
      </Container>
    </Layout>
  );
};

export default Introduction;
