import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { ProposalIndexItem } from '@solvana/proposal-tool-domain/dist/Proposal';
import ProposalRow from './ProposalRow';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectHasInvitePermission } from './selectors';

type Props = {
  proposals: ProposalIndexItem[];
};

const ProposalsTable: React.FC<Props> = ({ proposals }) => {
  const classes = useStyles();
  const canInvite = useSelector(selectHasInvitePermission);

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant={'h5'}>
        Proposals
      </Typography>
      <TableContainer component={'div'}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Advocate</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposals.map((proposal) => (
              <ProposalRow key={proposal.id} proposal={proposal} canInvite={canInvite} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default ProposalsTable;
