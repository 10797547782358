import React from 'react';
import { CircularProgress, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  }),
);

type Props = {
  fullPage?: boolean;
};

const Spinner: React.FC<Props> = ({ fullPage }) => {
  const classes = useStyles();
  const containerClass = fullPage ? classes.fullPage : classes.root;

  return (
    <div className={containerClass}>
      <CircularProgress />
    </div>
  );
};

export default Spinner;
