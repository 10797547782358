import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(2),
      },
      items: {
        padding: theme.spacing(0, 2, 2, 2),
      },
    }),
  );
