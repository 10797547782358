import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'RootState';
import { selectProposal } from 'containers/common/Proposals/selectors';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from 'containers/CheckoutPages/routes';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import { selectAdvocate } from 'containers/common/Advocates/selectors';

type Props = {};

const AdvocateInformation: React.FC<Props> = () => {
  const classes = useStyles()();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id) || 0;

  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const advocate = useSelector((state: RootState) => selectAdvocate(state, proposal?.advocateId || '0'));

  if (!advocate) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h6">Your Advocate</Typography>
      <Typography variant="h6">{toFullName(advocate)}</Typography>
      <Typography variant="body1">{advocate.email}</Typography>
      <Typography variant="body1">{advocate.phoneNumber}</Typography>
    </div>
  );
};

export default AdvocateInformation;
