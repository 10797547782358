import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const NonElectricCar: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 77">
      <title>{title || 'Non Electric Car'}</title>
      <g strokeWidth="2.1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M9.268 48.635h41.3l-3.709-7.153a6.32 6.32 0 0 0-5.143-2.647H18.641c-1.94 0-3.773.89-4.97 2.416l-4.403 7.384zM3.668 69.635h52.5v-11.52a9.368 9.368 0 0 0-2.744-6.624l-2.856-2.856h-41.3l-2.856 2.856a9.368 9.368 0 0 0-2.744 6.625v11.519z" />
        <path d="M3.842 56.775l4.917.992a6.296 6.296 0 0 0 5.698-1.72l.411-.412" />
        <path d="M5.356 52.585s10.213.25 11.613 5.85h25.373c1.4-5.6 11.614-5.85 11.614-5.85" />
        <path d="M55.468 56.775l-4.916.992a6.294 6.294 0 0 1-5.697-1.72l-.413-.412M10.057 75.235H6.38a2.711 2.711 0 0 1-2.71-2.711v-2.889h9.1v2.889a2.711 2.711 0 0 1-2.712 2.711zM53.457 75.235H49.78a2.711 2.711 0 0 1-2.71-2.711v-2.889h9.1v2.889a2.711 2.711 0 0 1-2.712 2.711zM7.868 49.335H2.657a.92.92 0 0 1-.75-1.455l1.61-2.254a2.844 2.844 0 0 1 2.315-1.191H7.23c.779 0 1.45.55 1.602 1.314l.437 2.186M51.968 49.335h5.211a.92.92 0 0 0 .75-1.455l-1.61-2.254a2.844 2.844 0 0 0-2.314-1.191h-1.397c-.779 0-1.45.55-1.602 1.314l-.438 2.186" />
        <path
          className={classes.highlight}
          d="M21.868 31.835h17.5v-30.1h-17.5zM41.468 1.735h-22.4M27.468 15.735h-8.4M41.468 15.735h-8.4M41.468 31.835h-22.4"
        />
        <path
          d="M33.068 16.552a2.8 2.8 0 0 1-5.6 0c0-1.547 2.8-5.134 2.8-5.134s2.8 3.587 2.8 5.134z"
          className={classes.highlight}
        />
      </g>
    </svg>
  );
};

export default NonElectricCar;
