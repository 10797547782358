import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '../../styles';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectBatteryCatalog } from '../../../../Catalog/Batteries/selectors';

type Props = {
  name: string;
};

const BatteryOptionSelect: React.FC<Props> = ({ name }) => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext<ProposalDTO>();
  const batteryCatalog = useSelector(selectBatteryCatalog);

  const selectOptions = batteryCatalog.entities.map((b) => ({
    label: `${b.brand} - ${b.model}`,
    value: b.id,
  }));

  return (
    <Field
      component={TextField}
      className={classes.input}
      disabled={isSubmitting}
      type="text"
      name={name}
      label="Battery"
      select
      variant="standard"
      helperText="Please select an ESS Adder product"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default BatteryOptionSelect;
