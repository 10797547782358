export interface GoSolarPageParams {
  id: string;
}

export enum GoSolarRoutes {
  ChooseYourSolarOption = '/go-solar/:id/solar',
  RedirectChooseYourSolarOption = '/go-solar/:id/customize',
  ChooseYourAdders = '/go-solar/:id/adders',
  ChooseYourFinancing = '/go-solar/:id/financing',
  ChooseYourBatteries = '/go-solar/:id/reinvest',
  Checkout = '/go-solar/:id/checkout',
  Complete = '/go-solar/:id/complete',
}

export const toChooseSolarOptionPath = (id: string | number): string =>
  GoSolarRoutes.ChooseYourSolarOption.replace(':id', `${id}`);
export const toChooseAddersPath = (id: string): string => GoSolarRoutes.ChooseYourAdders.replace(':id', id);
export const toChooseBatteriesPath = (id: string): string => GoSolarRoutes.ChooseYourBatteries.replace(':id', id);
export const toChooseFinancingPath = (id: string): string => GoSolarRoutes.ChooseYourFinancing.replace(':id', id);
export const toCheckoutPath = (id: string): string => GoSolarRoutes.Checkout.replace(':id', id);
export const toCompletePath = (id: string): string => GoSolarRoutes.Complete.replace(':id', id);
