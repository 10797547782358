import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      margin: '30px 0',
      paddingLeft: '20px',
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    editToolsButtonGroup: {
      marginBottom: '20px',
      marginLeft: '20px',
    },
  }),
);
