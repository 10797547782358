import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Spinner from '../../../Spinner';
import { emptySolarOption, emptyInverter, emptySubArray } from './defaults';
import InverterForm from '../common/InverterForm';
import { curry } from 'ramda';
import SubArrayForm from './SubArrayForm';
import { InverterDTO } from '@solvana/proposal-tool-domain/dist/Proposal/Inverter';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectPanelCatalog } from '../../../Catalog/Panels/selectors';

const SolarOptions: React.FC = () => {
  const classes = useStyles()();
  const { setFieldValue, values, isSubmitting } = useFormikContext<ProposalDTO>();
  const panelCatalog = useSelector(selectPanelCatalog);

  const handleRemoveClick = (index: number) => {
    if (!values || !values.solarOptions) {
      return;
    }

    setFieldValue('solarOptions', [...values.solarOptions.slice(0, index), ...values.solarOptions.slice(index + 1)]);
  };

  const handleAddClick = () => {
    if (!values) {
      return;
    }

    setFieldValue('solarOptions', [...(values.solarOptions || []), emptySolarOption]);
  };

  const handleAddInverter = (index: number) => {
    if (!values || !values.solarOptions || !values.solarOptions[index]) {
      return;
    }
    const solarOption = values.solarOptions[index];

    const newInverterList = [...(solarOption.inverters || []), emptyInverter];
    setFieldValue(`solarOptions[${index}].inverters`, newInverterList);
  };

  const handleAddSubArray = (index: number) => {
    if (!values || !values.solarOptions) {
      return;
    }
    const solarOption = values.solarOptions[index];
    if (!solarOption || !solarOption.subArrays) {
      return;
    }

    const newSubArrayList = [...(solarOption.subArrays || []), emptySubArray];
    setFieldValue(`solarOptions[${index}].subArrays`, newSubArrayList);
  };

  const onRemoveInverter = curry((index: number, inverterIndex: number) => {
    if (!values || !values.solarOptions) {
      return;
    }
    const solarOption = values.solarOptions[index];
    if (!solarOption || !solarOption.inverters) {
      return;
    }

    const newInverterList = [
      ...solarOption.inverters.slice(0, inverterIndex),
      ...solarOption.inverters.slice(inverterIndex + 1),
    ];
    setFieldValue(`solarOptions[${index}].inverters`, newInverterList);
  });

  const onRemoveSubArray = curry((index: number, subArrayIndex: number) => {
    if (!values || !values.solarOptions) {
      return;
    }
    const solarOption = values.solarOptions[index];
    if (!solarOption || !solarOption.subArrays) {
      return;
    }

    const newSubArrayList = [
      ...solarOption.subArrays.slice(0, subArrayIndex),
      ...solarOption.subArrays.slice(subArrayIndex + 1),
    ];
    setFieldValue(`solarOptions[${index}].subArrays`, newSubArrayList);
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Solar Options
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2}>
          {values?.solarOptions?.map((option, i) => (
            <Grid item lg={6} key={`${option.toString()}-${i}`}>
              <Card elevation={5}>
                <CardHeader
                  title={`Solar Option ${i + 1}`}
                  action={
                    <IconButton onClick={() => handleRemoveClick(i)} size="small">
                      <Close />
                    </IconButton>
                  }
                />
                <CardContent className={classes.option}>
                  {panelCatalog.isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      <TextField
                        name={`solarOptions[${i}].price`}
                        disabled={isSubmitting}
                        type="number"
                        variant="filled"
                        label="System Price"
                      />

                      <TextField
                        name={`solarOptions[${i}].notes`}
                        disabled={isSubmitting}
                        multiline
                        variant="outlined"
                        label="Notes"
                      />

                      <SubArrayForm
                        selected={option.subArrays || []}
                        rootName={`solarOptions[${i}]`}
                        onRemove={onRemoveSubArray(i)}
                      />

                      <Button startIcon={<Add />} onClick={() => handleAddSubArray(i)} size="small">
                        Add Sub Array
                      </Button>

                      <InverterForm
                        selected={option.inverters || ([] as InverterDTO[])}
                        rootName={`solarOptions[${i}]`}
                        onRemove={onRemoveInverter(i)}
                      />

                      <Button startIcon={<Add />} onClick={() => handleAddInverter(i)} size="small">
                        Add Inverter
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item lg={6} className={classes.addOption}>
            <Button startIcon={<Add />} onClick={handleAddClick} size="medium">
              Add Solar Option
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SolarOptions;
