import { selectLoanFinanceOptions, selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';

export const selectAdderLowestMonthlyPaymentNetITC = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
): ReturnType<typeof Pricing.lowestMonthlyPaymentForAdder> | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  const loanFinanceOptions = selectLoanFinanceOptions(state, proposalId);

  return Pricing.lowestMonthlyPaymentForAdder(adderId, 1, proposal.adders, loanFinanceOptions);
};

export const selectAdderLowestMonthlyLoanOption = (
  state: SelectProposalState,
  proposalId: number,
  adderId: number,
): LoanFinanceOption | undefined => {
  const result = selectAdderLowestMonthlyPaymentNetITC(state, proposalId, adderId);
  if (!result) return undefined;

  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  const option = proposal.financeOptions.find((fo) => fo.id === result.financeOptionId && isLoanFinanceOption(fo.type));
  return option ? (option as LoanFinanceOption) : undefined;
};
