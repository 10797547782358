import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BackButton from '../../../common/Buttons/BackButton';
import { useHistory } from 'react-router-dom';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginBottom: theme.spacing(2.5),
    },
    header: {
      textAlign: 'center',
      margin: theme.spacing(0, 'auto'),
    },
    button: {
      position: 'absolute',
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(0.75),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
  }),
);

type Props = {
  previousPath?: string;
  children: React.ReactNode;
};

const PageTitle: React.FC<Props> = ({ children, previousPath }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <section className={classes.root}>
      {previousPath && <BackButton onClick={() => history.push(previousPath)} className={classes.button} />}
      <Typography className={classes.header} variant="h3">
        {children}
      </Typography>
    </section>
  );
};

export default PageTitle;
