import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from 'theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      margin: '0 auto',
      padding: theme.spacing(0, 6, 6),
      '& h5': {
        fontWeight: theme.typography.fontWeightBold,
        maxWidth: '65%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      },
      '& table': {
        margin: theme.spacing(1, 'auto', 0),
        maxWidth: '65%',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      },
    },
    p: {
      margin: theme.spacing(2, 'auto', 0),
      maxWidth: '50%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    negative: {
      color: theme.palette.primary.main,
    },
    btn: {
      margin: theme.spacing(2, 'auto', 0),
      maxWidth: 200,
    },
    note: {
      color: Colors.GreyThree,
      fontSize: '12px',
      margin: theme.spacing(1, 'unset', 2),
    },
    blurbContainer: {
      margin: '0 auto',
      maxWidth: '85%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    table: {
      margin: theme.spacing(6, 'auto', 0),
      '& th': {
        fontSize: '12px',
      },
      '& td': {
        fontSize: '20px',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
);
