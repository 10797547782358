import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const dockedWidthMD = '96px';
export const dockedWidthLG = '104px';
export const dockedWidthXL = '115px';

export const verticalToHorizBreakpoint = 'sm';
const closeButtonAndCartIconPaddingTopMultiplier = 2.8;

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        margin: '0',
        padding: theme.spacing(closeButtonAndCartIconPaddingTopMultiplier, 1, 1, 1),
        width: dockedWidthLG,
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          padding: theme.spacing(0, 1, 1, 1),
        },
        [theme.breakpoints.down('md')]: {
          width: dockedWidthMD,
        },
        [theme.breakpoints.up('xl')]: {
          width: dockedWidthXL,
        },
        height: '100%',
        '& li': {
          padding: 0,
          margin: 0,
        },
        '@media only screen and (max-width: 499px)': {
          display: 'none',
        },
      },
      cartIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(0, 'auto', 2, 'auto'),

        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          margin: theme.spacing(1, 'auto'),
        },
      },
      closeIconButton: {
        '& svg': {
          fill: '#fff',
          width: '30px',
          height: '30px',
        },
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          width: '54px',
          margin: theme.spacing(0, 'auto', 1, 'auto'),
        },
      },
      list: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        overflowY: 'scroll',
        overflowX: 'hidden',
        margin: '0',
        width: '100%',
        height: '100%',
        '& li': {
          padding: 0,
          margin: 0,
        },
      },
      productListItem: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      total: {
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
          alignSelf: 'flex-end',
        },
      },
    }),
  );
