import React from 'react';
import { Button, CardMedia, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { SolarOption } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart, selectIsUpdatingCart } from '../../../common/Proposals/selectors';
import { toFormattedPrice } from '../../../../utils/helpers';
import { RootState } from '../../../../RootState';
import SelectButton from '../../common/SelectButton';
import {
  selectCatalogForSolarOption,
  selectLowestMonthlyLoanForSolarOption,
  selectPanelCount,
  solarOptionFirstSubArrayPhoto,
} from '../../../common/Proposals/SolarOptions/selectors';
import { LearnMoreItemType, setLearMoreItem } from '../../common/LearnMore/actions';
import clsx from 'clsx';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import PopCard from '../../../../components/PopCard';
import { RiCheckboxCircleLine, RiShieldCheckLine } from 'react-icons/ri';
import {
  selectCashInHandForCashOption,
  selectSolarOptionCashPriceNetFirstYearIncentives,
  selectConsumptionPercentOffset,
  selectSolarOptionFirstYearProduction,
  selectLoanPaymentLegalText,
} from './selectors';
import StatPercentage from '../../../../components/StatPercentage';
import SimpleDagger from '../../../../components/SimpleDagger';
import { trackSolarOptionAddedToCart, trackSolarOptionSeeComponentsClicked } from '../../../../utils/analytics/track';

type Props = {
  solarOption: SolarOption;
  isUpgrade: boolean;
  proposalId: number;
  onSubmit: () => void;
};

const SolarOptionCard: React.FC<Props> = ({ proposalId, solarOption, onSubmit, isUpgrade }) => {
  const classes = useStyles();
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const firstYearProduction = useSelector(() => selectSolarOptionFirstYearProduction(solarOption));
  const priceNetFirstYearIncentives = useSelector((state: RootState) =>
    selectSolarOptionCashPriceNetFirstYearIncentives(state, proposalId, solarOption.id),
  );
  const cashInHandForCashOption = useSelector((state: RootState) =>
    selectCashInHandForCashOption(state, proposalId, solarOption.id, 25),
  );
  const consumptionPercentOffset = useSelector((state: RootState) =>
    selectConsumptionPercentOffset(state, proposalId, solarOption.id, 1),
  );
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => selectCart(proposalId, state));
  const lowestMonthlyPayment = useSelector((state: RootState) =>
    selectLowestMonthlyLoanForSolarOption(state, proposalId, solarOption.id),
  );
  const loanPaymentLegalText = useSelector((state: RootState) =>
    selectLoanPaymentLegalText(state, proposalId, solarOption.id),
  );
  const catalogItems = useSelector((state: RootState) => selectCatalogForSolarOption(solarOption, state));
  const panelCount = useSelector((state: RootState) => selectPanelCount(state, proposalId, solarOption.id));

  const inverterCount = solarOption.inverters.reduce((total, inverter) => total + inverter.quantity, 0);
  const inverterBrand = catalogItems.inverters.length > 0 ? catalogItems.inverters[0].brand : '';
  const inverterWarranty = catalogItems.inverters.length > 0 ? catalogItems.inverters[0].warranty : 0;

  const subArrayProducts = Object.values(catalogItems.subArrays);
  const firstSubArray = subArrayProducts.length > 0 ? subArrayProducts[0] : undefined;

  const isSelected = cart?.solarOption === solarOption.id;
  const hasSelection = Boolean(cart?.solarOption);

  const totalSystemSize = (System.size(solarOption) / 1_000).toLocaleString('en-US', {
    maximumFractionDigits: 3,
  });

  const hasMonitoring = Boolean(catalogItems.inverters.find((inverter) => inverter.monitoring));

  const handleLearnMore = () => {
    trackSolarOptionSeeComponentsClicked({ proposalId });
    dispatch(setLearMoreItem({ type: LearnMoreItemType.SolarOption, proposalId: proposalId, id: solarOption.id }));
  };

  return (
    <PopCard isSelected={isSelected} withFade={!isSelected && hasSelection} className={classes.root}>
      {isUpgrade && (
        <div className={classes.upgrade}>
          <Typography className={classes.upgradeTitle} variant="subtitle2">
            Upgrade
          </Typography>
        </div>
      )}

      <CardMedia
        component="img"
        className={clsx(classes.media, { [classes.withMargin]: !isUpgrade })}
        src={solarOptionFirstSubArrayPhoto(catalogItems)}
        title="Solar Panel Image"
      />
      <div className={classes.innerContainer}>
        <StatPercentage
          value={Math.round(consumptionPercentOffset)}
          subtitle="electric usage offset"
          subSubtitle={`(${firstYearProduction.toLocaleString()} kWh in first year)`}
        />

        <List>
          <ListItem>
            <Typography className={classes.systemComponentTitle}>
              {panelCount} {firstSubArray?.brand || ''} Panels
            </Typography>
          </ListItem>
          <ListItem>
            <Typography className={classes.attribute}>{totalSystemSize} kW system size</Typography>
          </ListItem>

          {firstSubArray && firstSubArray.performanceWarranty > 0 && (
            <ListItem>
              <RiShieldCheckLine size={20} />
              <Typography className={classes.attribute}>
                {firstSubArray.performanceWarranty}-year Performance Warranty
              </Typography>
            </ListItem>
          )}

          {firstSubArray && firstSubArray.workmanshipWarranty > 0 && (
            <ListItem>
              <RiShieldCheckLine size={20} />
              <Typography className={classes.attribute}>
                {firstSubArray.workmanshipWarranty}-year Workmanship Warranty
              </Typography>
            </ListItem>
          )}

          <ListItem>
            <RiShieldCheckLine size={20} />
            <Typography className={classes.attribute}>10-year Solvana Labor Warranty</Typography>
          </ListItem>

          <ListItem>
            <Typography className={classes.systemComponentTitle}>
              {inverterCount} {inverterBrand} {inverterCount > 1 ? 'Inverters' : 'Inverter'}
            </Typography>
          </ListItem>

          {inverterWarranty && (
            <ListItem>
              <RiShieldCheckLine size={20} />
              <Typography className={classes.attribute}>{inverterWarranty}-year Warranty</Typography>
            </ListItem>
          )}

          {hasMonitoring && (
            <ListItem>
              <RiCheckboxCircleLine size={20} />
              <Typography className={classes.attribute}>System Monitoring</Typography>
            </ListItem>
          )}
        </List>
        <Button
          variant="contained"
          className={classes.seeSystemComponentsBtn}
          size="small"
          disableElevation
          color="primary"
          onClick={handleLearnMore}
        >
          See system components
        </Button>

        {cashInHandForCashOption > 0 && (
          <Typography className={classes.totalPotentialSavings}>
            Up to <strong className={classes.savings}>+{toFormattedPrice(cashInHandForCashOption)}</strong>
            <br /> cash in hand over 25 years.
          </Typography>
        )}

        <div className={classes.pricingContainer}>
          <hgroup className={classes.priceGroup}>
            <Typography className={classes.priceTitle} variant="subtitle1">
              Price
            </Typography>
            <Typography variant="h5">{toFormattedPrice(solarOption.price)}</Typography>
            <Typography className={classes.cashPrice} variant="body2">
              ({`${toFormattedPrice(priceNetFirstYearIncentives)} net first year incentives`}) <SimpleDagger />
            </Typography>
          </hgroup>

          {lowestMonthlyPayment !== undefined && (
            <div className={classes.monthlyPaymentContainer}>
              <Typography className={classes.monthlyPayment} variant="subtitle1">
                As low as {toFormattedPrice(lowestMonthlyPayment.monthlyPayment)}/mo.
              </Typography>
              <Typography variant="body2">(See more loan options on the next page)</Typography>
              <Typography variant="body2">{loanPaymentLegalText}</Typography>
            </div>
          )}
        </div>

        <SelectButton
          onClick={() => {
            trackSolarOptionAddedToCart({
              proposalId,
              cashInHandForCashOption,
              inverterBrand,
              inverterCount,
              panelBrand: firstSubArray?.brand,
              panelCount,
              price: solarOption.price,
              totalSystemSize,
            });
            onSubmit();
          }}
          isSubmitting={isUpdatingCart}
          isSelected={isSelected}
          disabled={isUpdatingCart}
        >
          Add to Cart
        </SelectButton>
      </div>
    </PopCard>
  );
};

export default SolarOptionCard;
