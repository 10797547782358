import { selectProposal, SelectProposalState } from '../../../../../common/Proposals/selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { isLoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';

export const selectCartMonthlyPaymentForLoanOption = (
  state: SelectProposalState,
  proposalId: number,
  loanOptionId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const loanOption = proposal.financeOptions.find((o) => isLoanFinanceOption(o.type) && o.id === loanOptionId);
  if (!loanOption) return 0;

  const proposalWithLoanOption = { ...proposal, cart: { ...proposal.cart, financeOption: loanOption.id } };

  return Pricing.monthlyPaymentForCartNetITC(proposalWithLoanOption);
};
