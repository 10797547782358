import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Carousel from 'react-material-ui-carousel';
import { useStyles, verticalToHorizBreakpoint } from './styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectLearnMoreItem, selectLearnMoreItemImageURLs } from './selectors';
import { LearnMoreItemType, setLearMoreItem } from './actions';
import SolarOptionItem from './SolarOptionItem';
import ESSStateIncentives from './ESSAdderOptionItem';
import CloseButton from 'components/CloseButton';
import BenefitsSummary from './BenefitsSummary';
import LoanFinancingFAQ from './LoanFinancingFAQ';

const LearnMore: React.FC = () => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const item = useSelector(selectLearnMoreItem);
  const imgURLs = useSelector(selectLearnMoreItemImageURLs);
  const theme = useTheme();
  const isUnderSmMediaSize = useMediaQuery(theme.breakpoints.down(verticalToHorizBreakpoint));

  const onEscKeyPress = React.useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        dispatch(setLearMoreItem(null));
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', onEscKeyPress, false);

    return () => {
      document.removeEventListener('keydown', onEscKeyPress, false);
    };
  }, [onEscKeyPress]);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setLearMoreItem(null));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        anchor={isUnderSmMediaSize ? 'bottom' : 'right'}
        elevation={0}
        classes={{
          paper: classes.drawerPaper,
        }}
        open={Boolean(item)}
      >
        <section>
          <nav className={classes.learnMoreNav}>
            <CloseButton onClick={handleClose} />
          </nav>
          {item && item.type === LearnMoreItemType.FinanceOptionBenefits && (
            <div className={classes.learnMoreBody}>
              <BenefitsSummary />
            </div>
          )}
          {item && item.type === LearnMoreItemType.FinancingFAQ && (
            <div className={classes.learnMoreBody}>
              <LoanFinancingFAQ />
            </div>
          )}
          {item && (item.type === LearnMoreItemType.ESSAdder || item.type === LearnMoreItemType.SolarOption) && (
            <div className={classes.learnMoreBody}>
              <article className={classes.imageContainer}>
                <Carousel autoPlay={false}>
                  {imgURLs.map((src, i) => (
                    <img className={classes.learnMoreImage} key={`${src}-${i}`} src={src} alt="Product" />
                  ))}
                </Carousel>
              </article>
              <article className={classes.contentContainer}>
                {item && item.type === LearnMoreItemType.SolarOption && (
                  <SolarOptionItem solarOptionId={item.id} proposalId={item.proposalId} />
                )}

                {item && item.type === LearnMoreItemType.ESSAdder && (
                  <ESSStateIncentives essAdderId={item.id} proposalId={item.proposalId} />
                )}
              </article>
            </div>
          )}
        </section>
      </Drawer>
    </div>
  );
};

export default LearnMore;
