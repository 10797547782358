import { Groups } from '../common/Group';
import { CognitoUserProfile, SelectAuthState } from './reducer';

const EMPLOYEE_GROUPS = [Groups.Advocates, Groups.Admins, Groups.ProposalCreators, Groups.ProcessManagers];

export const selectGroups = ({ auth }: SelectAuthState): Groups[] => auth.groups;

export const selectIDTokenClaims = ({ auth }: SelectAuthState): CognitoUserProfile | null => auth.cognitoUserProfile;

export const selectIsEmployee = ({ auth }: SelectAuthState): boolean =>
  Boolean(auth.groups.find((g) => EMPLOYEE_GROUPS.includes(g)));

export const selectIsCustomer = ({ auth }: SelectAuthState): boolean =>
  Boolean(auth.groups.find((g) => g === Groups.Customers));

export const selectIsAdmin = ({ auth }: SelectAuthState): boolean =>
  Boolean(auth.groups.find((g) => g === Groups.Admins));

export const selectIsProposalCreator = ({ auth }: SelectAuthState): boolean =>
  Boolean(auth.groups.find((g) => g === Groups.ProposalCreators));

export const selectIsAdvocate = ({ auth }: SelectAuthState): boolean =>
  Boolean(auth.groups.find((g) => g === Groups.Advocates));

export const selectIsAuthenticated = ({ auth }: SelectAuthState): boolean => auth.isAuthenticated;

export const selectHasResolvedAuth = ({ auth }: SelectAuthState): boolean => auth.hasResolved;
