import AvertaExtraBoldFont from './averta-extrabold-webfont.woff2';
import AvertaSemiBoldFont from './averta-semibold-webfont.woff2';
import AvertaRegularFont from './averta-regular-webfont.woff2';

export const AvertaExtraBold = {
  fontFamily: 'Averta',
  fontStyle: 'bold',
  fontWeight: 900,
  src: `
    local('Averta'),
    local('Averta-ExtraBold'),
    url(${AvertaExtraBoldFont}) format('woff2')
  `,
};

export const AvertaSemiBold = {
  fontFamily: 'Averta',
  fontStyle: 'bolder',
  fontWeight: 700,
  src: `
    local('Averta'),
    local('Averta-SemiBold'),
    url(${AvertaSemiBoldFont}) format('woff2')
  `,
};

export const AvertaRegular = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Averta'),
    local('Averta-Regular'),
    url(${AvertaRegularFont}) format('woff2')
  `,
};
