import * as yup from 'yup';
import { STATE_VALUES } from '@solvana/state-domain';

export const schema = yup.object().shape({
  name: yup.string().trim().required().label('Name'),
  address1: yup.string().trim().required().label('Address Line 1'),
  address2: yup.string().label('Address Line 2'),
  city: yup.string().trim().required().label('City'),
  state: yup.string().oneOf(STATE_VALUES).required().label('State'),
  zip: yup.string().trim().required().label('Zip Code'),
});
