import React from 'react';
import { useSelector } from 'react-redux';
import { selectSMARTTerm, selectSMARTValue, selectTotalProductionForSmartTerm, selectTotalPbiValue } from './selectors';
import { RootState } from '../../../../../../../RootState';
import { toFormattedPrice } from '../../../../../../../utils/helpers';
import BenefitCard from '../common/BenefitCard';
import MassIcon from '../../../../../../../components/SolvanaIcons/StateIcons/MassIcon';

type Props = {
  proposalId: number;
};

const MassSMARTBenefits: React.FC<Props> = ({ proposalId }) => {
  const totalValue = useSelector((state: RootState) => selectTotalPbiValue(state, proposalId, 25));
  const firstYearSmartValue = useSelector((state: RootState) => selectTotalPbiValue(state, proposalId, 1));
  const smartTerm = useSelector((state: RootState) => selectSMARTTerm(state, proposalId));
  const smartValue = useSelector((state: RootState) => selectSMARTValue(state, proposalId));
  const smartTermSystemProduction = useSelector((state: RootState) =>
    selectTotalProductionForSmartTerm(state, proposalId, smartTerm),
  );

  return (
    <BenefitCard
      title={`+${toFormattedPrice(totalValue)}`}
      subtitle="Massachusetts SMART Incentives"
      Description={() => (
        <p>
          Massachusetts awards monetary incentives to people generating solar through the{' '}
          <i>Solar Massachusetts Renewable Target (SMART)</i> program. Your system size qualifies you for{' '}
          <strong>{smartTerm} years</strong> of SMART incentives totaling{' '}
          <strong>{toFormattedPrice(firstYearSmartValue)}</strong>. Your SMART value is calculated by multiplying the
          state-determined fixed rate (<strong>${smartValue}</strong>) by the amount of energy your system produces over
          the {smartTerm} year term (
          <strong>{smartTermSystemProduction.toLocaleString('en', { maximumFractionDigits: 2 })} kWh</strong>). Your
          incentive payment is distributed monthly by mail or by direct deposit into your bank account free of charge.
        </p>
      )}
      Icon={() => <MassIcon width={100} />}
    />
  );
};

export default MassSMARTBenefits;
