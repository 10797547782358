import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      border: theme.palette.type === 'dark' ? 'none' : `solid 1px ${Colors.GreyThree}`,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      alignItems: 'center',
      transition: 'all 0.2s ease-out',
      hgroup: {
        display: 'flex',
        flexDirection: 'column',
      },
      '&:hover': {
        cursor: 'pointer',
        borderColor: 'transparent',
        boxShadow:
          '0px 2px 2px -3px rgba(0,0,0,0.2), 0px 4px 6px 1px rgba(0,0,0,0.14), 0px 2px 8px 2px rgba(0,0,0,0.12)',
        transform: 'scale(1.02)',
      },
    },
    rootSelected: {
      borderColor: theme.palette.type === 'dark' ? Colors.Background : Colors.SecondaryDark,
    },
    transparent: {
      opacity: theme.palette.type === 'dark' ? 0.35 : 0.3,
      '&:hover': {
        opacity: 1,
      },
    },
    title: {
      fontSize: '22px',
      fontWeight: 900,
      marginRight: theme.spacing(0.75),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.only('md')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
    label: {
      height: '18px',
      fontSize: '12px',
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 900,
    },
    iconContainer: {
      fontSize: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: theme.spacing(6),
      margin: theme.spacing(0, 1.5),
    },
    selected: {
      color: theme.palette.success.main,
    },
    unselected: {
      color: Colors.GreyThree,
    },
  }),
);
