import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      flexGrow: 1,
      maxWidth: '650px',
      marginBottom: theme.spacing(6),
      '& h5': {
        margin: theme.spacing(1, 'auto', 0),
        maxWidth: '475px',
      },
    },
    subtitle: {
      fontWeight: 700,
      color: Colors.GreyTwo,
    },
    answerOptionsContainer: {
      margin: theme.spacing(3, 0),
      width: '100%',
    },
    button: {
      width: '100%',
    },
  }),
);
