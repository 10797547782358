import { selectProposal, SelectProposalState } from '../../../../../../common/Proposals/selectors';
import System from '@solvana/proposal-tool-domain/dist/lib/System';
import StateIncentives from '@solvana/proposal-tool-domain/dist/lib/StateIncentives';
import { Massachusetts } from '@solvana/proposal-tool-domain/dist/lib/StateIncentives/Massachusetts/Massachusetts';

export const selectTotalPbiValue = (state: SelectProposalState, proposalId: number, horizonLength: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const incentives = StateIncentives.incentives(proposal, horizonLength);

  return Object.values(incentives.pbi).reduce((acc, incentiveForYear) => (acc += incentiveForYear), 0);
};

export const selectSMARTValue = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return proposal?.smart?.solarValue || 0;
};

export const selectSMARTTerm = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  return Massachusetts.getSMARTHorizonForSolarOption(solarOption);
};

export const selectTotalProductionForSmartTerm = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((so) => so.id === proposal.cart.solarOption);
  if (!solarOption) return 0;

  return System.totalProduction(solarOption, horizonLength);
};
