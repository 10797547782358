import React, { useState } from 'react';
import { CardContent, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ESSAdder } from '@solvana/proposal-tool-domain/dist/Proposal/ESSAdder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../RootState';
import { GoSolarPageParams } from '../../routes';
import { selectCart, selectCartUpdateError, selectIsUpdatingCart } from '../../../common/Proposals/selectors';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { updateCart } from '../../../common/Proposals/actions';
import { Cart } from '@solvana/proposal-tool-domain/dist/Proposal/Cart';
import SelectButton from '../../common/SelectButton';
import { setLearMoreItemForESSAdder } from '../../common/LearnMore/actions';
import PopCard from '../../../../components/PopCard';
import { selectHasEligibleFinanceOption } from './selectors';
import ESSAdderOption from './ESSAdderOption';
import { setShowEligibleESSAdderFinanceOptions } from '../ESSAdderFinanceOptions/actions';
import { selectCartESSAdder } from '../../../common/Proposals/Cart/selectors';
import { trackESSAdderRemovedFromCart, trackESSAdderSeeComponentsClicked } from '../../../../utils/analytics/track';

type Props = {
  essAdders: ESSAdder[];
};

const ESSAdderOptions: React.FC<Props> = ({ essAdders }) => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const selectedEssAdder = useSelector((state: RootState) => selectCartESSAdder(state, proposalId));
  const cart = useSelector((state: RootState) => selectCart(id, state)) as Cart;
  const hasEligibleFinanceOption = useSelector((state: RootState) => selectHasEligibleFinanceOption(state, proposalId));
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const cartUpdateError = useSelector(selectCartUpdateError);
  const dispatch = useDispatch();
  const [noneIsSelected, setNoneIsSelected] = useState(false);
  const hasSelection = Boolean(selectedEssAdder?.id) || noneIsSelected;

  const handleNoneClick = () => {
    if (!selectedEssAdder || !cart) {
      setNoneIsSelected(true);
      return;
    }

    const updatedCart = { ...cart, essAdder: null };

    updateCart(dispatch, id, updatedCart, () => setNoneIsSelected(true));
  };

  const handleSubmit = (essAdder: ESSAdder) => {
    if (!hasEligibleFinanceOption) {
      dispatch(setShowEligibleESSAdderFinanceOptions(true));
      return;
    }

    const updatedCart = { ...cart, essAdder: essAdder.id };
    updateCart(dispatch, id, updatedCart, () => setNoneIsSelected(false));
  };

  const handleLearnMore = (essAdder: ESSAdder) => {
    trackESSAdderSeeComponentsClicked({ proposalId, productId: essAdder.productId, price: essAdder.price });
    dispatch(setLearMoreItemForESSAdder({ id: essAdder.id, proposalId }));
  };

  return (
    <div aria-label="Energy Storage System" className={classes.root}>
      <Typography className={classes.sectionTitle} variant="h5">
        Energy Storage System
      </Typography>

      <List className={classes.optionContainer} aria-label="ESS Adder Options">
        <ListItem className={classes.listItem}>
          <PopCard isSelected={noneIsSelected} withFade={!noneIsSelected && hasSelection} className={classes.noneCard}>
            <CardContent className={clsx(classes.cardContent, { [classes.noneCardContainer]: true })}>
              <Typography className={clsx(classes.productName, { [classes.noneTitle]: true })} variant="h5">
                None
              </Typography>
              <SelectButton
                disabled={isUpdatingCart}
                onClick={() => {
                  trackESSAdderRemovedFromCart({ proposalId });
                  handleNoneClick();
                }}
                isSelected={noneIsSelected}
                isSubmitting={isUpdatingCart}
                selectedClassName={classes.noneSelectedContainer}
              >
                Select
              </SelectButton>
            </CardContent>
          </PopCard>
        </ListItem>

        {essAdders.map((essAdder) => (
          <ListItem className={classes.listItem} key={`ess-adder-${essAdder.id}`}>
            <ESSAdderOption
              onSubmit={() => handleSubmit(essAdder)}
              onLearnMore={() => handleLearnMore(essAdder)}
              essAdder={essAdder}
              proposalId={parseInt(id)}
              hasSelection={hasSelection}
            />
          </ListItem>
        ))}

        {cartUpdateError && (
          <Typography variant="subtitle2" color="primary">
            {cartUpdateError}
          </Typography>
        )}
      </List>
    </div>
  );
};

export default ESSAdderOptions;
