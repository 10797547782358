import React from 'react';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from '../../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { selectProposal } from '../../../../common/Proposals/selectors';
import { selectLearnMoreItem } from '../selectors';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import FAQ from './FAQ';
import { residentialFAQs } from './FAQ/residentialFAQs';
import { commercialFAQs } from './FAQ/commercialFAQs';

const LoanFinancingFAQ: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const learnMoreUI = useSelector(selectLearnMoreItem);
  if (!proposal || !learnMoreUI) {
    return null;
  }

  const isResidential = TaxEntity.Residential === proposal.tax.entity;

  const itc = ITC.percentage(proposal) * 100;

  if (isResidential) {
    return (
      <section className={classes.root}>
        <Typography className={classes.title} variant="h4">
          Two Examples of Loan Re-Amortization
        </Typography>
        <Typography variant="body1" className={classes.exampleParagraph}>
          Alice signed a loan agreement for solar. Her first payment occurred 60 days after her system was installed.
          The following year she had outstanding tax liabilities so she filed for the {itc}% federal tax credit (ITC).
          Her re-amortization occurred 18 months after the system install. Alice had applied her ITC savings towards the
          loan before month 18, so her monthly payments remained the same.
        </Typography>

        <Typography variant="body1" className={classes.exampleParagraph}>
          Bob also purchased solar with a loan. After he saved with his federal tax credit, however, Bob decided to put
          the savings towards a new car. At the time of re-amortization on month 18 Bob&apos;s monthly payment increased
          based on the loan terms.
        </Typography>

        <Typography className={classes.title} variant="h4">
          Advantages of a Solar Loan
        </Typography>
        <List className={classes.advantagesList}>
          <ListItem>
            <ListItemText>
              There is no lien on the customer&apos;s home or property. Instead, the customer gives the lender a
              security interest in the solar system. The lender will typically file a UCC-1 and a fixture filing, as
              applicable, to create a lien on the solar system.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Interest rate stays fixed for the lifetime of the loan regardless of whether the customer makes a {itc}%
              voluntary payment by month 18.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              There is no prepayment penalty. Customers are encouraged to pay off their loan early.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Any $1,500+ payment made, in addition to the minimum loan payment, reamortizes the loan payment.
            </ListItemText>
          </ListItem>
        </List>

        <Typography className={classes.title} variant="h4">
          Common Questions
        </Typography>
        <FAQ items={residentialFAQs} />
      </section>
    );
  }

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Common Questions
      </Typography>
      <FAQ items={commercialFAQs} />
    </section>
  );
};

export default LoanFinancingFAQ;
