import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectCartUpdateError, selectProposal } from '../../common/Proposals/selectors';
import { RootState } from '../../../RootState';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import FootNotes from '../common/FootNotes';
import AdderOptions from './AdderOptions';
import SelectButton from '../common/SelectButton';
import { GoSolarPageParams, toChooseFinancingPath, toChooseSolarOptionPath } from '../routes';
import Layout from '../common/Layout';
import PageTitle from '../common/PageTitle';
import { canViewCheckoutPages, resolveNextStep } from '../common/Layout/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import { selectProposalHasAdderOptions } from './selectors';
import { trackAdderOptionsPageViewed } from '../../../utils/analytics/track';

const ChooseYourAdders: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const history = useHistory();
  const cartUpdateError = useSelector(selectCartUpdateError);
  const hasAdderOptions = useSelector((state: RootState) => selectProposalHasAdderOptions(state, proposalId));

  useEffect(() => trackAdderOptionsPageViewed({ proposalId }), [proposalId]);

  if (!proposal) {
    return <Layout />;
  }

  if (!canViewCheckoutPages(proposal)) {
    return <Redirect to={resolveNextStep(proposal)} />;
  }

  if (!hasAdderOptions) {
    return <Redirect to={toChooseFinancingPath(`${proposal.id}`)} />;
  }

  return (
    <ScrollToTop>
      <Layout>
        <Container maxWidth="lg" className={classes.root}>
          <PageTitle previousPath={toChooseSolarOptionPath(proposal.id)}>
            Choose your <br />
            add-ons
          </PageTitle>

          {cartUpdateError && (
            <Typography className={classes.error} variant="subtitle1" color="primary">
              {cartUpdateError}
            </Typography>
          )}

          <Container className={classes.innerContainer} maxWidth="md">
            <AdderOptions proposal={proposal} />
            <SelectButton onClick={() => history.push(toChooseFinancingPath(id))} fullWidth>
              Next: Financing
            </SelectButton>
            <FootNotes />
          </Container>
        </Container>
      </Layout>
    </ScrollToTop>
  );
};

export default ChooseYourAdders;
