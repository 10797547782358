import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.paper,
    },
    solid: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
    detail: {
      stroke: theme.palette.type === 'dark' ? Colors.GreyThree : theme.palette.secondary.main,
    },
  }),
);

const defaultWidth = 50;

const SolarLoan: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 70">
      <title>{title || 'Solar Loan'}</title>
      <g fill="none" fillRule="evenodd">
        <path strokeWidth="2.21" strokeLinecap="round" strokeLinejoin="round" d="M31 8L2 30" />
        <path
          className={classes.detail}
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 15l-11 8"
        />
        <path strokeWidth="2.21" strokeLinecap="round" strokeLinejoin="round" d="M31 8l29 22" />
        <path
          className={classes.detail}
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31 15l11 8"
        />
        <path
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M60 19l-5 6M53 14l-5 6M48 10l-5 6M42 5l-5 6"
        />
        <path
          className={classes.highlight}
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M64 22L38 2"
        />
        <path
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 50h8v18H4zM13 54.538C15.174 52.846 17.571 52 20.194 52c3.933 0 5.464 1.659 9.789 2.538 4.325.88 11.017.274 11.017 2.552 0 2.279-.768 2.91-5.066 2.91h-5.951"
        />
        <path
          d="M12 67.026c1.756-1.648 3.205-2.471 4.349-2.471 1.715 0 8.117 3.445 16.082 3.445 5.31 0 12.52-3.304 21.63-9.913.939-.775 1.174-1.772.704-2.993-.47-1.22-1.798-1.42-3.987-.598-5.113 2.394-8.372 3.59-9.778 3.59"
          strokeWidth="2.21"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={classes.solid}
          d="M33.72 43.335v2.36H31.84v-2.37a7.56 7.56 0 0 1-4.204-1.985l1.463-1.675c1.168 1.06 2.608 1.56 3.816 1.56 1.228 0 2.082-.568 2.082-1.503 0-1.068-.864-1.483-2.64-2.002-2.323-.684-4.405-1.416-4.405-3.853 0-1.984 1.412-3.477 3.888-3.689v-2.484h1.878v2.581c1.28.24 2.619.809 3.542 1.512l-1.41 1.675c-1.137-.77-2.365-1.126-3.32-1.126-1.208 0-1.97.5-1.97 1.377 0 .915.914 1.32 2.498 1.8 2.7.791 4.578 1.465 4.578 4.036 0 2.09-1.493 3.506-3.918 3.786"
        />
      </g>
    </svg>
  );
};

export default SolarLoan;
