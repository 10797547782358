import React from 'react';
import { Link } from '@material-ui/core';
import { Address as AddressDomain } from '@solvana/rfa-domain';

type Props = {
  address: AddressDomain;
};

const AddressLinkToGoogleMaps: React.FC<Props> = ({ address }) => {
  const { address1, address2, city, zip, state } = address;

  const query = `${address1} ${address2}, ${city}, ${state}, ${zip}`;
  const googleMapLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;

  return (
    <Link href={googleMapLink} target="_blank">
      {address1} {address2}
      <br /> {city}, {state} {zip}
    </Link>
  );
};

export default AddressLinkToGoogleMaps;
