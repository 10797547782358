import * as React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm, { Props } from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';

const StripeCheckoutForm: React.FC<Props> = ({ customer, proposalId }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm customer={customer} proposalId={proposalId} />
    </Elements>
  );
};

export default StripeCheckoutForm;
