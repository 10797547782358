import { selectCartITCValue } from './selectCartITCValue';

import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { SolarOption } from '@solvana/proposal-tool-domain/dist/Proposal/SolarOption';
import { find, reduce } from 'ramda';
import { Adder } from '@solvana/proposal-tool-domain/dist/Proposal/Adder';
import { FinanceOption, isLoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { selectCartTotalNetFirstYearIncentives } from './selectCartTotalNetFirstYearIncentives';
import { selectCartMonthlyPaymentNetAllIncentivesOverTerm } from './selectCartMonthlyPaymentNetAllIncentivesOverTerm';
import { selectCartESSAdder } from './selectCartESSAdder';
import { selectCartMonthlyPaymentNetITC } from './selectCartMonthlyPaymentNetITC';
import { selectCartSolarOptionPriceNetFirstYearIncentives } from './selectCartSolarOptionPriceNetFirstYearIncentives';
import { selectCartESSAdderPriceNetFirstYearIncentives } from './selectCartESSAdderPriceNetFirstYearIncentives';
import { selectCartESSAdderMonthlyPaymentNetAllIncentivesOverTerm } from './selectCartESSAdderMonthlyPaymentNetAllIncentivesOverTerm';
import { selectIsAdderSelected } from './selectIsAdderSelected';
import { selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm } from './selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm';
import { selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm } from './selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm';

export const selectCartTotalFinancedPrice = (state: SelectProposalState, proposalId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return Pricing.cartTotal(proposal);
};

export const selectCartSolarOption = (state: SelectProposalState, proposalId: number): SolarOption | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return find((o) => o.id === proposal.cart.solarOption, proposal.solarOptions);
};

export const selectSelectedAdders = (state: SelectProposalState, proposalId: number): Adder[] => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return [];

  return reduce(
    (acc, sa) => {
      const adder = find((a) => a.id === sa.adderId, proposal.adders);
      return adder ? [...acc, adder] : acc;
    },
    [] as Adder[],
    proposal.cart.selectedAdders,
  );
};

export const selectCartFinanceOption = (state: SelectProposalState, proposalId: number): FinanceOption | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return proposal.financeOptions.find((fo) => fo.id === proposal.cart.financeOption);
};

export const selectHasMonthlyPayment = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;

  const financeOption = selectCartFinanceOption(state, proposalId);
  if (!financeOption) return false;

  return isLoanFinanceOption(financeOption.type);
};

export {
  selectCartAdderMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartESSAdder,
  selectCartESSAdderMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartESSAdderPriceNetFirstYearIncentives,
  selectCartITCValue,
  selectCartMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartMonthlyPaymentNetITC,
  selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm,
  selectCartSolarOptionPriceNetFirstYearIncentives,
  selectCartTotalNetFirstYearIncentives,
  selectIsAdderSelected,
};
