import React from 'react';
import * as yup from 'yup';
import { TextField as MUITextField } from 'formik-material-ui';
import { Auth } from 'aws-amplify';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { FormControl, LinearProgress, Typography } from '@material-ui/core';
import { Route as SignInRoute } from '../pg-sign-in/route';
import { Route as ResetPasswordRoute } from '../pg-reset-password/route';
import SubheaderWithLink from '../../common/SubheaderWithLink';
import AuthLayout from '../AuthLayout';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../styles';
import StyledButton from '../../common/StyledButton';

export const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
});

interface Params {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialValues: Params = { email: '' };

  const submitHandler = async ({ email }: Params, f: FormikHelpers<Params>) => {
    try {
      await Auth.forgotPassword(email);
      history.push(`${ResetPasswordRoute.Path}?email=${encodeURIComponent(email)}`);
    } catch (e) {
      f.setStatus({ error: e.message });
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
      {({ isValid, setFieldValue, setStatus, status, isSubmitting }) => {
        return (
          <AuthLayout
            title="Solvana | Forgot password"
            header="Forgot your password?"
            subHeader="We will help you reset it"
          >
            <Form>
              <FormControl className={classes.formControl}>
                <FastField
                  disabled={isSubmitting}
                  component={MUITextField}
                  className={classes.input}
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setStatus({ error: null });
                    setFieldValue('email', e.target.value);
                  }}
                  type="email"
                  label="Email"
                />
              </FormControl>
              {status && status.error && (
                <Typography variant="subtitle1" className={classes.error}>
                  {status.error}
                </Typography>
              )}
              {isSubmitting && <LinearProgress />}
              <div className={classes.submitContainer}>
                <StyledButton
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Send code
                </StyledButton>
              </div>
              <div className={classes.links}>
                <SubheaderWithLink to={SignInRoute.Path} subheader="Already have a Solvana account?" name="Sign in" />
                <SubheaderWithLink to={ResetPasswordRoute.Path} subheader="Have a code?" name="Reset password" />
              </div>
            </Form>
          </AuthLayout>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordPage;
