import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  size?: number;
};

const defaultSize = 50;

const FightsClimateChange: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
    >
      <title>Fights Climate Change</title>

      <g fill="none" fillRule="evenodd">
        <path d="M47.484 29.695c0 10.348-8.39 18.735-18.735 18.735-10.348 0-18.737-8.387-18.737-18.735 0-10.348 8.389-18.736 18.737-18.736 10.346 0 18.735 8.388 18.735 18.736" />
        <path
          d="M47.484 29.695c0 10.348-8.39 18.735-18.735 18.735-10.348 0-18.737-8.387-18.737-18.735 0-10.348 8.389-18.736 18.737-18.736 10.346 0 18.735 8.388 18.735 18.736z"
          strokeWidth="2.1"
        />
        <path
          d="M13.559 19.826s2.55 1.441 2.77 2.66c.223 1.22.667 2.107 1.664 3.215.998 1.108 4.212 4.655 4.212 4.655s2.77.998 1.996-.997c-.777-1.995-.555-2.217.553-2.217 1.109 0 2.218-.332 2.44-1.22.22-.887.775-1.773 1.884-2.66 1.108-.887 2.328-1.218 2.549-2.216.222-.997.444-1.662-.333-2.328-.775-.666-1.662-1.22-1.662-1.22s-1.108-.498-1.607.166c-.5.665-1.275 1.72-2.051 1.497-.776-.222-1.885-.776-1.94-1.33-.055-.555-.72-1.274.388-1.828 1.109-.555 1.774-1.053 3.215-.887 1.44.165 2.217.082 2.217-.194 0-.277.554-1.607 1.274-1.165.72.444 1.608 1.276 1.885 1.995.276.72.997 1.912 1.94 1.524.942-.387 2.438-2.825 4.323-2.825M31.738 48.258s-2.078-1.163-1.662-3.74c.415-2.578 1.163-3.16 1.247-4.656.082-1.497.415-2.328-.5-2.993s-2.16-1.746-2.16-3.325c0-1.58.082-2.578 1.744-2.66 1.664-.084 3.326.914 3.741.914.416 0 1.164.997 1.164 1.58 0 .581 1.082.664 1.663.748.582.083 2.66.415 2.578 1.662-.084 1.247-.75 3.16-.75 3.16s-.664.831-1.33.831c-.664 0-1.08.166-1.578.997-.5.832-1.414 2.411-1.995 2.827-.582.416-1.497 2.078-1.413 3.16.082 1.08-.75 1.495-.75 1.495zM45.538 21.822s-2.826-.665-2.743.998c.083 1.662.166 2.826-.665 3.99-.832 1.164-1.33 3.74-.25 4.656 1.081.914 1.995 1.413 3.16 1.496 1.163.083 1.745.416 1.745.416"
          strokeWidth="1.5"
        />
        <path
          d="M29.618 5.177A24.342 24.342 0 0 1 44.86 10.51M29.618 54.06c-13.499 0-24.44-10.942-24.44-24.441M48.558 14.167a24.344 24.344 0 0 1 5.502 15.452"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.304 49.088A28.761 28.761 0 0 1 .75 29.618C.75 13.674 13.675.75 29.618.75M45.864 53.486a28.735 28.735 0 0 1-16.245 5 28.743 28.743 0 0 1-14.314-3.791M38.165 2.036C49.936 5.679 58.486 16.65 58.486 29.619a28.74 28.74 0 0 1-3.483 13.76"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default FightsClimateChange;
