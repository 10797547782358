import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../RootState';
import { toFormattedPrice } from '../../../../../../../../utils/helpers';
import { selectTotalSolarSavings } from '../../../../../../../common/Proposals/SolarOptions/selectors';
import { selectTotalUtilityBill } from 'containers/CheckoutPages/pg-choose-your-financing/Financing/selectors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    negative: {
      color: theme.palette.primary.main,
    },
    positive: {
      color: theme.palette.success.main,
    },
  }),
);

type Props = {
  proposalId: number;
};
const ElectricBillBlurb: React.FC<Props> = ({ proposalId }) => {
  const classes = useStyles();
  const totalSolarSavings = useSelector((state: RootState) => selectTotalSolarSavings(state, proposalId, 1));
  const totalUtiltyBill = useSelector((state: RootState) => selectTotalUtilityBill(state, proposalId, 1));
  const lastYearsBill = useMemo(() => totalUtiltyBill * 0.89, [totalUtiltyBill]);

  return (
    <>
      <p>
        Based on the electric bills you provided you spent{' '}
        <b className={classes.negative}>{toFormattedPrice(lastYearsBill)}</b> on electricity over the last 12 months. Of
        that we can offset about <b className={classes.positive}>{toFormattedPrice(totalSolarSavings)}</b> and help
        reduce the remaining demand charges.
      </p>
    </>
  );
};

export default ElectricBillBlurb;
