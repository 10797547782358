import { toFormattedPrice } from '../../utils/helpers';

export const handleSignForValue = (value: number, flip?: boolean, ignoreSign?: boolean): string => {
  const absValue = Math.abs(value);
  if (value === 0) return toFormattedPrice(value);

  const isPositive = value > 0;

  if (ignoreSign) {
    return toFormattedPrice(absValue);
  }

  if (isPositive) {
    return flip ? `- ${toFormattedPrice(absValue)}` : `+ ${toFormattedPrice(absValue)}`;
  }

  return isPositive ? `+ ${toFormattedPrice(absValue)}` : `- ${toFormattedPrice(absValue)}`;
};
