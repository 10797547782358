import { Reducer } from 'redux';
import { Actions, setError, setIsFetching, setShowUtilityModal } from './actions';
import { GoSolarRoutes } from './routes';

type ProposalActions = ReturnType<typeof setIsFetching> &
  ReturnType<typeof setError> &
  ReturnType<typeof setShowUtilityModal>;

type State = {
  isFetching: boolean;
  error: string | null;
  currentStep: GoSolarRoutes;
  showUtilityModal: boolean;
};

const DEFAULT_STATE: State = {
  isFetching: false,
  error: null,
  currentStep: GoSolarRoutes.ChooseYourSolarOption,
  showUtilityModal: true,
};

const goSolarPageUI: Reducer<State, ProposalActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetIsFetching:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetShowUtilityModal:
      return { ...state, showUtilityModal: action.showModal };

    case Actions.SetError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default goSolarPageUI;
