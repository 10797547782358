import { Battery } from '@solvana/catalog-domain/dist/Battery';
import { Inverter } from '@solvana/catalog-domain/dist/Inverter';
import { find, reduce } from 'ramda';
import { ESSAdder } from '@solvana/proposal-tool-domain/dist/Proposal/ESSAdder';
import { ProposalsState } from '../../reducer';
import { BatteryCatalogState } from '../../../Catalog/Batteries/reducer';
import { InverterCatalogState } from '../../../Catalog/Inverters/reducer';
import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';
import { ITC } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/ITC';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import { selectESSAdderTotalStateIncentives } from './selectESSAdderTotalStateIncentives';
import { MACRS } from '@solvana/proposal-tool-domain/dist/lib/FederalIncentives/MACRS';
import { isLoanFinanceOption, LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import { MonthlyPaymentsResult } from '@solvana/proposal-tool-domain/dist/lib/Financing/monthlyPayments';

type ESSOption = {
  battery: Battery | undefined;
  inverters: Inverter[];
};

type CatalogItems = { [essAdderId: number]: ESSOption };

interface SelectESSOption {
  (
    essAdders: ESSAdder[],
    state: { batteryCatalog: BatteryCatalogState; inverterCatalog: InverterCatalogState },
  ): CatalogItems;
}

export const selectCatalogForESSAdders: SelectESSOption = (essAdders, state) => {
  return reduce(
    (acc, essAdder) => {
      const battery = find((e) => e.id === essAdder.productId, state.batteryCatalog.entities);

      const inverters =
        reduce(
          (collected, essInverter) => {
            const inverter = find((e) => e.id === essInverter.productId, state.inverterCatalog.entities);
            if (inverter) {
              collected.push(inverter);
            }

            return collected;
          },
          [] as Inverter[],
          essAdder.inverters,
        ) || [];

      return { ...acc, [essAdder.id]: { battery, inverters } };
    },
    {},
    essAdders,
  );
};

const defaultPhoto = '';

export const firstBatteryPhotoFor = (essAdder: ESSAdder, catalogItems?: CatalogItems): string => {
  if (!catalogItems) {
    return defaultPhoto;
  }

  const item = catalogItems[essAdder.id];

  if (!item || !item.battery || !(item.battery.photoUrls.length > 0)) {
    return defaultPhoto;
  }

  return item.battery.photoUrls[0];
};

export const resolveESSAdderName = (essAdder: ESSAdder, catalogItems?: CatalogItems): string => {
  if (!catalogItems) {
    return 'Battery Energy Storage System';
  }
  const item = catalogItems[essAdder.id];
  if (!item || !item.battery) {
    return 'Battery Energy Storage System';
  }

  return item.battery.model;
};

interface SelectESSAdderById {
  (essAdderOptionId: number, proposalId: number, state: { proposals: ProposalsState }): ESSAdder | undefined;
}

export const selectESSAdderById: SelectESSAdderById = (essAdderId, proposalId, state) => {
  const proposal = state.proposals.entities.find((p) => p.id === proposalId);
  if (!proposal) return undefined;

  return proposal.essAdders.find((ess) => ess.id === essAdderId);
};

export const selectEssAdderInverterCount = (ess: ESSAdder): number =>
  ess.inverters.reduce((acc, inverter) => acc + inverter.quantity, 0);

export const selectESSAdderITCValue = (state: SelectProposalState, proposalId: number, essAdderId: number): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const essAdder = proposal.essAdders.find((ess) => ess.id === essAdderId);
  if (!essAdder) return 0;

  const proposalWithESSAdderInCart = { ...proposal, cart: { ...proposal.cart, essAdder: essAdder.id } };
  const financedPrice = Pricing.essAdderPrice(proposalWithESSAdderInCart);

  return ITC.value(financedPrice, proposal);
};

export const selectESSAdderMACRSValue = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const essAdder = proposal.essAdders.find((ess) => ess.id === essAdderId);
  if (!essAdder) return 0;

  const proposalWithESSAdderInCart = { ...proposal, cart: { ...proposal.cart, essAdder: essAdder.id } };
  const financedPrice = Pricing.essAdderPrice(proposalWithESSAdderInCart);

  return MACRS.value(financedPrice, proposal);
};

export const selectESSAdderFinancedPrice = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithESSAdder = { ...proposal, cart: { ...proposal.cart, essAdder: essAdderId } };

  return Pricing.essAdderPrice(proposalWithESSAdder);
};

export const selectESSAdderFinancedPriceNetIncentives = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithESSAdder = { ...proposal, cart: { ...proposal.cart, essAdder: essAdderId } };

  return Pricing.essAdderPriceNetIncentives(proposalWithESSAdder, horizonLength);
};

export const selectMonthlyPaymentNetITCForESSAdder = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithESSAdder = {
    ...proposal,
    cart: { ...proposal.cart, essAdder: essAdderId },
  };

  const essEligibleLoanOptions = Financing.getESSAdderEligibleLoanOptions(proposal);
  const eligibleLoanOption = essEligibleLoanOptions.find((lo) => lo.id === proposal.cart.financeOption);

  if (eligibleLoanOption) {
    const result = Pricing.lowestMonthlyPaymentForESSAdder({
      ...proposalWithESSAdder,
      financeOptions: [eligibleLoanOption],
    });
    return result?.monthlyPayment || 0;
  }

  const lowest = Pricing.lowestMonthlyPaymentForESSAdder(proposalWithESSAdder);
  return lowest ? lowest.monthlyPayment : 0;
};

export const selectMonthlyPaymentNetAllIncentivesForESSAdder = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithESSAdder = {
    ...proposal,
    cart: { ...proposal.cart, essAdder: essAdderId },
  };

  const hasEligibleLoanFinanceOption = Pricing.hasSelectedEligibleESSAdderLoanOption(proposal);

  if (hasEligibleLoanFinanceOption) {
    return Pricing.avgMonthlyPaymentForESSAdderNetAllIncentives(proposalWithESSAdder);
  }

  const lowest = Pricing.lowestMonthlyPaymentForESSAdder(proposal);
  if (!lowest) return 0;

  return Pricing.avgMonthlyPaymentForESSAdderNetAllIncentives({
    ...proposalWithESSAdder,
    cart: { ...proposalWithESSAdder.cart, financeOption: lowest.financeOptionId },
  });
};

export const selectMonthlyPaymentsForESSAdder = (
  state: SelectProposalState,
  proposalId: number,
  essAdderId: number,
): MonthlyPaymentsResult | null => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return null;

  const essAdder = proposal.essAdders.find((ess) => ess.id === essAdderId);
  if (!essAdder) return null;

  const loanOption = proposal.financeOptions.find(
    (fo) => fo.id === proposal.cart.financeOption && isLoanFinanceOption(fo.type),
  );
  if (!loanOption) return null;

  return Financing.monthlyPayments(essAdder.price, loanOption as LoanFinanceOption);
};

export { selectESSAdderTotalStateIncentives };
