import React from 'react';
import { useStyles } from './styles';
import { LoanFinanceOption } from '@solvana/proposal-tool-domain/dist/FinanceOption';
import SelectButton from '../../../../common/SelectButton';
import { Button, List, ListItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUpdatingCart } from '../../../../../common/Proposals/selectors';
import { setLearMoreItemForFinanceBenefits } from '../../../../common/LearnMore/actions';
import { Proposal } from '@solvana/proposal-tool-domain/dist/Proposal';
import { toFormattedPrice } from '../../../../../../utils/helpers';
import { cashInHandForSolarOptions, selectIsCashFlowPositiveForSolarOptions } from '../common/selectors';
import { RootState } from '../../../../../../RootState';
import PopCard from '../../../../../../components/PopCard';
import FinancePartnerLogo from '../../../../../../components/FinancePartnerLogo';
import Financing from '@solvana/proposal-tool-domain/dist/lib/Financing';
import clsx from 'clsx';
import { RiBattery2ChargeLine } from 'react-icons/ri';
import CashFlowPositive from '../../../../../../components/SolvanaIcons/CashFlowPositive';
import ReAmortizationExplanation from './ReAmortizationExplanation';
import { selectCartMonthlyPaymentForLoanOption } from './selectors';
import { selectMonthlyPaymentNetIncentives } from '../../../../../common/Proposals/FinanceOptions/selectors';
import { selectHasStateIncentives } from '../../../../common/LearnMore/BenefitsSummary/selectors';
import { trackFinanceOptionLearnMoreClicked } from '../../../../../../utils/analytics/track';

type Props = {
  loanOption: LoanFinanceOption;
  onSubmit: () => any;
  proposal: Proposal;
  preventFade?: boolean;
};

const LoanOption: React.FC<Props> = ({ onSubmit, loanOption, proposal, preventFade }) => {
  const classes = useStyles()();
  const dispatch = useDispatch();
  const isUpdatingCart = useSelector(selectIsUpdatingCart);
  const isSelected = proposal.cart?.financeOption === loanOption.id;
  const isESSEligible = Financing.isEligibleESSAdderFinanceOption(loanOption);
  const isCashFlowPositive = useSelector((state: RootState) =>
    selectIsCashFlowPositiveForSolarOptions(state, proposal.id, loanOption.id),
  );
  const withFade = !preventFade && Boolean(proposal.cart?.financeOption && !isSelected);
  const cashInHand = cashInHandForSolarOptions(proposal, loanOption.id);
  const hasStateIncentives = useSelector((state: RootState) => selectHasStateIncentives(state, proposal.id));
  const monthlyPayment = useSelector((state: RootState) =>
    selectCartMonthlyPaymentForLoanOption(state, proposal.id, loanOption.id),
  );
  const monthlyPaymentNetIncentives = useSelector((state: RootState) =>
    selectMonthlyPaymentNetIncentives(state, proposal.id, loanOption.id),
  );

  return (
    <PopCard isSelected={isSelected} withFade={withFade} className={classes.root}>
      <div className={classes.topContainer}>
        <FinancePartnerLogo financeOption={loanOption} />

        <Typography className={classes.title} variant="h3">
          {loanOption.termInMonths / 12} Year Loan
        </Typography>
        <Typography className={classes.subtitle} variant="h4">
          {loanOption.rate}% APR
        </Typography>

        <hgroup className={classes.monthlyPaymentContainer}>
          <Typography variant="h5">
            {toFormattedPrice(monthlyPayment)}/mo<sup>*</sup>
          </Typography>

          {hasStateIncentives && (
            <Typography variant="body2">
              {`${toFormattedPrice(monthlyPaymentNetIncentives)}/mo. net incentives`}
            </Typography>
          )}
        </hgroup>

        <ReAmortizationExplanation proposal={proposal} loanOption={loanOption} sup="*" />

        <Typography className={classes.totalSavingsTitle} variant="h4">
          Cash in hand over 25 years <br /> for your cart selections can be up to
        </Typography>
        {cashInHand >= 0 ? (
          <Typography className={classes.totalSavings} variant="h4">
            +{toFormattedPrice(cashInHand)}
          </Typography>
        ) : (
          <Typography className={classes.cost} variant="h6">
            -{toFormattedPrice(Math.abs(cashInHand))}
          </Typography>
        )}
        <Button
          variant="contained"
          className={clsx(classes.seeHowButton, { [classes.seeHowButtonWithIconsBelow]: isESSEligible })}
          size="small"
          disableElevation
          color="primary"
          onClick={() => {
            trackFinanceOptionLearnMoreClicked({
              proposalId: proposal.id,
              termInMonths: loanOption.termInMonths,
              type: loanOption.type,
              rate: loanOption.rate,
            });
            dispatch(setLearMoreItemForFinanceBenefits({ id: loanOption.id, proposalId: proposal.id }));
          }}
        >
          See how
        </Button>
      </div>

      <div className={classes.bottomContainer}>
        <List>
          {isCashFlowPositive && (
            <ListItem className={classes.badgeTitleContainer}>
              <CashFlowPositive width={36} />
              <Typography variant="body2">Cash Flow Positive</Typography>
            </ListItem>
          )}

          {isESSEligible && (
            <ListItem className={classes.badgeTitleContainer}>
              <RiBattery2ChargeLine size={22} />
              <Typography variant="body2">Battery Compatible</Typography>
            </ListItem>
          )}
        </List>
        <SelectButton
          disabled={isUpdatingCart}
          isSubmitting={isUpdatingCart}
          isSelected={isSelected}
          buttonClassName={classes.button}
          onClick={onSubmit}
        >
          Select
        </SelectButton>
      </div>
    </PopCard>
  );
};

export default LoanOption;
