import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
    dollarSign: {
      fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 50;

const HomeValue: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 67">
      <title>Increased Home Value</title>

      <g fill="none" fillRule="evenodd">
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.053 32.405l34.12-31.352L69.29 32.405"
        />
        <path
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.43 33.635L35.173 9.66l26.74 23.975M55.84 19.763V9.661h-5.3v4.787"
        />
        <path strokeWidth="2.1" strokeLinecap="round" strokeLinejoin="round" d="M59.148 31.791v33.197H11.197V31.79" />
        <path
          className={classes.dollarSign}
          d="M36.946 49.833v2.804h-2.118v-2.815c-1.717-.24-3.366-1.007-4.74-2.358l1.65-1.99c1.316 1.258 2.94 1.852 4.302 1.852 1.385 0 2.347-.674 2.347-1.785 0-1.27-.974-1.762-2.976-2.38-2.619-.812-4.966-1.682-4.966-4.577 0-2.358 1.592-4.132 4.383-4.384V31.25h2.118v3.066c1.442.286 2.952.962 3.993 1.797l-1.59 1.99c-1.281-.915-2.667-1.338-3.743-1.338-1.362 0-2.22.595-2.22 1.637 0 1.087 1.03 1.568 2.816 2.14 3.044.938 5.162 1.74 5.162 4.795 0 2.482-1.684 4.165-4.418 4.497"
        />
      </g>
    </svg>
  );
};

export default HomeValue;
