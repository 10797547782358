import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacing(2, 2, 0),
      '& svg': {
        fill: theme.palette.success.main,
      },
      fontSize: theme.spacing(3),
    },
    checkboxNotSelected: {
      '& svg': {
        fill: Colors.GreyFour,
      },
    },
    body: {
      padding: theme.spacing(2, 2, 3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    answerText: {
      marginTop: theme.spacing(2),
      fontWeight: 700,
      lineHeight: '22px',
    },
  }),
);
