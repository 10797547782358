import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'RootState';
import { selectCustomerForId } from 'containers/common/Customers/selectors';
import { selectProposal } from 'containers/common/Proposals/selectors';
import { useParams } from 'react-router-dom';
import { GoSolarPageParams } from 'containers/CheckoutPages/routes';
import { toFullName } from '@solvana/proposal-tool-domain/dist/Customer';
import Address from 'containers/common/Address';

type Props = {};

const CustomerInformation: React.FC<Props> = () => {
  const classes = useStyles()();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id) || 0;

  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const customer = useSelector((state: RootState) => selectCustomerForId(proposal?.customerId || '0', state));

  if (!customer) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h6">{toFullName(customer)}</Typography>
      <Address address={customer.address} />
      {customer.phoneNumber !== '555-555-5555' && customer.phoneNumber !== '5555555555' && (
        <Typography variant="body1">{customer.phoneNumber}</Typography>
      )}
      <Typography variant="body1">{customer.email}</Typography>
    </div>
  );
};

export default CustomerInformation;
