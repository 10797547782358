import React, { useMemo } from 'react';
import { Advocate as TAdvocate } from '@solvana/proposal-tool-domain/dist/Advocate';
import { useStyles } from './styles';
import { Button, Typography } from '@material-ui/core';
import ContactInfo from '../../../../common/ContactInfo';

const CALENDY: { [k: string]: string } = {
  'apell@solvana.io': 'https://calendly.com/solvana/rfa-anthony',
  'mike@solvana.io': 'https://calendly.com/solvana/rfa-mike',
};

type Props = {
  advocate: TAdvocate;
};

const Advocate: React.FC<Props> = ({ advocate }) => {
  const classes = useStyles();

  const calendyLink = useMemo(() => {
    return CALENDY[advocate.email.toLowerCase()] || 'https://calendly.com/solvana/30min';
  }, [advocate.email]);

  return (
    <div className={classes.root}>
      <hgroup>
        <Typography variant="h5">Have Questions?</Typography>
        <Typography variant="subtitle2">Speak with your Advocate, {advocate.firstName}.</Typography>
        <Button
          variant="contained"
          className={classes.button}
          size="small"
          disableElevation
          color="primary"
          href={calendyLink}
          target="_blank"
        >
          Book a Time to Chat
        </Button>
      </hgroup>
      <ContactInfo user={advocate} />
    </div>
  );
};

export default Advocate;
