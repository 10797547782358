import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        width: '100%',
        border: theme.palette.type === 'dark' ? 'none' : `solid 1px ${Colors.GreyThree}`,
        borderRadius: theme.spacing(1),
        transition: 'all 0.1s ease-out',
        '&:hover': {
          borderColor: 'transparent',
          boxShadow:
            '0px 2px 2px -3px rgba(0,0,0,0.2), 0px 4px 6px 1px rgba(0,0,0,0.14), 0px 2px 8px 2px rgba(0,0,0,0.12)',
          transform: 'scale(1.01)',
        },
      },
      rootSelected: {
        borderColor: theme.palette.type === 'dark' ? Colors.Background : Colors.SecondaryDark,
      },
      pointer: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      transparent: {
        opacity: theme.palette.type === 'dark' ? 0.45 : 0.4,
        '&:hover': {
          opacity: 1,
        },
      },
    }),
  );
