import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from 'theme/Colors';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: theme.spacing(1),
        border: 'solid 1px',
        borderColor: Colors.SecondaryDark,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },
      title: {
        marginBottom: theme.spacing(3),
      },
      cartContentText: {
        padding: theme.spacing(2, 3),
        fontSize: '1rem',
        textAlign: 'right',
      },
      monthlyPayment: {
        margin: 0,
      },
      cartContentHeader: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(0),
        whiteSpace: 'normal',
        fontSize: '1.25rem',
        textAlign: 'left',
      },
      media: {
        width: '33%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      listItemBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        '& hgroup': {
          marginTop: theme.spacing(2),
          textAlign: 'right',
          '& h6': {
            fontWeight: 900,
          },
        },
      },
    }),
  );
