import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import PopCard from '../../../../components/PopCard';
import { RFAQuestion, RFASurveyAnswerChoice } from '../../common/rfaSurveyQuestions';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { RiCheckboxBlankCircleLine } from 'react-icons/ri';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../RootState';
import { selectIsAnswerSelected } from '../../selectors';

type Props = {
  option: RFASurveyAnswerChoice;
  question: RFAQuestion;
  onClick: () => void;
  withFade: boolean;
};

const AnswerCard: React.FC<Props> = ({ option, onClick, question, withFade }) => {
  const classes = useStyles();
  const isSelected = useSelector((state: RootState) => selectIsAnswerSelected(state, question, option.answerChoice));

  return (
    <PopCard className={classes.root} isSelected={isSelected} withFade={withFade && !isSelected} onClick={onClick}>
      <div className={clsx(classes.header, { [classes.checkboxNotSelected]: !isSelected })}>
        {isSelected ? <RiCheckboxCircleFill /> : <RiCheckboxBlankCircleLine />}
      </div>
      <div className={classes.body}>
        <option.icon width={option.iconWidth} />
        <Typography className={classes.answerText} variant="h6">
          {option.answerChoice}
        </Typography>
      </div>
    </PopCard>
  );
};

export default AnswerCard;
