import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, linearPrimaryGradient } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      '& li': {
        padding: theme.spacing(0, 0, 2),
      },
      '& li:last-child': {
        padding: 0,
      },
    },
    newProposalCard: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100px',
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : Colors.GreyFive,
      padding: theme.spacing(3),
      borderStyle: 'dashed !important',
      borderWidth: '1px !important',
    },
    name: {
      fontWeight: 900,
      marginBottom: theme.spacing(1),
    },
    address: {
      fontWeight: 700,
      lineHeight: '24px',
      marginBottom: theme.spacing(2),
    },
    timestamp: {
      color: Colors.GreyThree,
    },
    rfaBackground: {
      background: theme.palette.type === 'dark' ? theme.palette.info.dark : theme.palette.info.main,
    },
    proposalReadyBackground: {
      background: linearPrimaryGradient,
    },
    itemContainer: {
      width: '100%',
      display: 'flex',
      border: theme.palette.type === 'dark' ? 'none' : `solid 1px ${Colors.GreyThree}`,
      overflow: 'hidden',
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    leftContainer: {
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fill: '#fff',
      },
      width: '25%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    rightContainer: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      width: '75%',
      '& h5': {
        fontSize: '18px',
      },
      '& h6': {
        marginBottom: theme.spacing(1),
        lineHeight: '18px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
);
