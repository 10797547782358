import * as React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { LinearProgress, MenuItem, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Customer, schema } from '@solvana/proposal-tool-domain/dist/Customer';
import { STATE_VALUES } from '@solvana/state-domain/dist';
import { useStyles } from './styles';
import StyledButton from '../../StyledButton';

export interface CustomerSubmitHandler {
  (customer: Customer, actions: FormikHelpers<Customer>): any;
}

type Props = {
  customer: Customer;
  submitHandler: CustomerSubmitHandler;
  submitTitle?: string;
};

const stateOptions = STATE_VALUES.map((stateValue) => ({ value: stateValue, label: stateValue }));

const CustomerForm: React.FC<Props> = ({ customer, submitHandler, submitTitle }) => {
  const classes = useStyles();

  const textFieldFor = (name: string, disabled: boolean, label?: string) => (
    <Field
      disabled={disabled}
      component={TextField}
      className={classes.input}
      name={name}
      type="text"
      label={label || name}
    />
  );

  return (
    <Formik initialValues={{ ...customer }} validationSchema={schema} onSubmit={submitHandler}>
      {({ status, isValid, isSubmitting }) => (
        <Form>
          {textFieldFor('authUUID', true, 'authUUID')}
          {textFieldFor('companyName', isSubmitting, 'Company Name')}
          {textFieldFor('email', isSubmitting, 'Email')}
          {textFieldFor('firstName', isSubmitting, 'First Name')}
          {textFieldFor('lastName', isSubmitting, 'Last Name')}
          {textFieldFor('phoneNumber', isSubmitting, 'Phone')}
          {textFieldFor('address.address1', isSubmitting, 'Address1')}
          {textFieldFor('address.address2', isSubmitting, 'Address2')}
          {textFieldFor('address.city', isSubmitting, 'City')}

          <Field
            component={TextField}
            className={classes.input}
            type="text"
            name="address.state"
            label="State"
            select
            variant="standard"
            helperText="Please select A State"
            margin="normal"
            InputLabelProps={{ shrink: true }}
          >
            {stateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>

          {textFieldFor('address.zip', isSubmitting, 'Zip')}

          {isSubmitting && <LinearProgress />}

          {status && status.success && (
            <Typography variant="subtitle1" className={classes.success}>
              {status.success}
            </Typography>
          )}

          {status && status.error && (
            <Typography variant="subtitle1" className={classes.error}>
              {status.error}
            </Typography>
          )}

          <div className={classes.submitContainer}>
            <StyledButton
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid}
              type="submit"
            >
              {submitTitle || 'Submit'}
            </StyledButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerForm;
