import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: 'none',
    },
    highlight: {
      stroke: theme.palette.primary.main,
      fill: theme.palette.background.paper,
    },
  }),
);

const defaultWidth = 50;

const Squirrel: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 67">
      <title>{title || 'Squirrel'}</title>
      <g strokeWidth="2.1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M41.397 33.272c8.837 0 16 7.164 16 16 0 8.837-7.163 16-16 16h-27" />
        <path d="M30.397 43.272l-3.378 1.69a11.978 11.978 0 0 0-6.622 10.713v9.375M57.397 31.272s10 8 6 20-12 14-22 14" />
        <path d="M15.397 43.272s4-6 9-6v-10h-6.872c-2.482 0-4.198-2.478-3.327-4.8l4.199-11.2s5-8 12-3c5-8 9-6 9-6s2 6-3 10c2 4 0 9 0 9s5 5 5 11c1.976-7.902 5.728-13.877 12.738-13.998 5.674-.097 10.817 3.275 12.564 8.673 1.697 5.242.16 10.247-3.11 13.517" />
        <path d="M26.397 15.272a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        <path
          d="M3.73 46.392c.018.078.028.155.047.233.729 2.92 4.376 7.456 7.297 6.728 2.92-.729 4.009-6.446 3.279-9.367-.018-.077-.046-.15-.068-.227L3.73 46.392z"
          className={classes.highlight}
        />
        <path
          d="M15.675 43.412s-1.606-6.438-8.504-4.717C.274 40.416 2.34 46.74 2.34 46.74l13.335-3.327zM7.862 35.592s-1.035 1.724-.69 3.104"
          className={classes.highlight}
        />
      </g>
    </svg>
  );
};

export default Squirrel;
