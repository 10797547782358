import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    styledButton: {
      maxWidth: '200px',
    },
    subtext: {
      marginTop: theme.spacing(1),
    },
  }),
);
