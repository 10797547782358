import { Reducer } from 'redux';
import { Groups } from '../common/Group';
import { Actions, setGroups, setHasResolvedAuth, setIDTokenClaims, setIsAuthenticated, setLoggedOut } from './actions';

type ProjectAction = ReturnType<typeof setGroups> &
  ReturnType<typeof setIsAuthenticated> &
  ReturnType<typeof setHasResolvedAuth> &
  ReturnType<typeof setIDTokenClaims> &
  ReturnType<typeof setLoggedOut>;

export type CognitoUserProfile = {
  email: string;
  firstName?: string;
  lastName?: string;
  zipCode?: string;
};

const toCognitoUserProfile = (args: { [key: string]: any } | null): CognitoUserProfile | null => {
  if (!args) return null;

  return {
    email: args.email,
    firstName: args['custom:firstName'],
    lastName: args['custom:lastName'],
    zipCode: args['custom:zip'],
  };
};

export type AuthState = {
  groups: Groups[];
  isAuthenticated: boolean;
  hasResolved: boolean;
  cognitoUserProfile: CognitoUserProfile | null;
};

export type SelectAuthState = {
  auth: AuthState;
};

const DEFAULT_STATE: AuthState = {
  groups: [],
  isAuthenticated: false,
  hasResolved: false,
  cognitoUserProfile: null,
};

const auth: Reducer<AuthState, ProjectAction> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetGroups:
      return { ...state, groups: action.sessionUser?.groups || [] };

    case Actions.SetCognitoUserProfile:
      return { ...state, cognitoUserProfile: toCognitoUserProfile(action.idTokenClaims) };

    case Actions.SetHasResolved:
      return { ...state, hasResolved: action.hasResolved };

    case Actions.SetIsAuthenticated:
      return { ...state, isAuthenticated: action.isAuthenticated };

    case Actions.SetLoggedOut:
      return { ...state, groups: [], isAuthenticated: false };

    default:
      return state;
  }
};

export default auth;
