import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import StyledButton from '../../common/StyledButton';
import { RFAIntroductionRoute } from '../../pg-rfa/routes';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectHasResolvedRFAFetch, selectRFAEntities } from '../../pg-rfa/selectors';

const NextUp: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const RFAs = useSelector(selectRFAEntities);
  const hasResolvedRFAFetch = useSelector(selectHasResolvedRFAFetch);

  if (RFAs.length > 0 || !hasResolvedRFAFetch) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Next Up:
      </Typography>
      <StyledButton
        className={classes.button}
        color="primary"
        size="large"
        variant="contained"
        onClick={() => history.push(RFAIntroductionRoute.Path)}
      >
        Request a Proposal
      </StyledButton>
    </div>
  );
};

export default NextUp;
