import React, { useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { GoSolarPageParams, toCheckoutPath, toChooseBatteriesPath, toChooseAddersPath } from '../routes';
import SelectButton from '../common/SelectButton';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Layout from '../common/Layout';
import { RootState } from '../../../RootState';
import { useSelector } from 'react-redux';
import { selectProposal } from '../../common/Proposals/selectors';
import PageTitle from '../common/PageTitle';
import Financing from './Financing';
import { canViewFinancingPage, resolveNextStep } from '../common/Layout/selectors';
import ScrollToTop from '../../Auth/Routes/ScrollToTop';
import InstallPriceSummary from './InstallPriceSummary';
import { selectProposalHasBatterOptions } from '../pg-choose-your-batteries/selectors';
import FootNotes from '../common/FootNotes';
import { trackFinancingPageViewed } from '../../../utils/analytics/track';

const ChooseYourSavingsPage: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const history = useHistory();
  const proposal = useSelector((state: RootState) => selectProposal(state, proposalId));
  const hasBatteryOptions = useSelector((state: RootState) => selectProposalHasBatterOptions(state, proposalId));

  useEffect(() => proposal && trackFinancingPageViewed({ proposalId }), [proposalId]);

  if (!proposal) {
    return <Layout />;
  }

  if (!canViewFinancingPage(proposal)) {
    return <Redirect to={resolveNextStep(proposal)} />;
  }

  return (
    <ScrollToTop>
      <Layout>
        <div className={classes.root}>
          <Container maxWidth="lg">
            <PageTitle previousPath={toChooseAddersPath(id)}>Choose Financing</PageTitle>

            <Typography className={classes.subtitle} variant="body1">
              These benefits reflect the solar option and system customizations that you have selected. Please choose a
              finance option to view the total benefits.
            </Typography>

            <InstallPriceSummary proposal={proposal} />
            <Financing />
          </Container>

          <Container maxWidth="md">
            <SelectButton
              disabled={!proposal?.cart?.financeOption}
              onClick={() => {
                const path = hasBatteryOptions ? toChooseBatteriesPath(id) : toCheckoutPath(id);
                history.push(path);
              }}
              fullWidth
            >
              Next: {hasBatteryOptions ? 'Batteries' : 'Checkout'}
            </SelectButton>
            <FootNotes />
          </Container>
        </div>
      </Layout>
    </ScrollToTop>
  );
};

export default ChooseYourSavingsPage;
