import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { Field, useFormikContext } from 'formik';
import { TextField as MUITextField } from 'formik-material-ui';
import { TAX_ENTITY_VALUE_ARRAY, TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';

const taxEntityOptions = TAX_ENTITY_VALUE_ARRAY.map((entity) => ({ value: entity, label: entity }));

type Props = {
  onEntityChange: (entity: TaxEntity) => any;
};

const Tax: React.FC<Props> = ({ onEntityChange }) => {
  const classes = useStyles()();
  const { setFieldValue, isSubmitting, values } = useFormikContext<ProposalDTO>();

  const entity = values?.tax?.entity;
  const showRate = entity === TaxEntity.Commercial;

  const handleEntityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const entity = e.target.value as TaxEntity;
    setFieldValue('tax.entity', entity);

    if (entity !== TaxEntity.Commercial) {
      setFieldValue('tax.rate', 0);
    }

    onEntityChange(entity);
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (values?.tax?.entity === TaxEntity.Commercial) {
      setFieldValue('tax.rate', e.target.value);
      return;
    }
    setFieldValue('tax.rate', 0);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Tax
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Field
          component={MUITextField}
          className={classes.input}
          type="text"
          name="tax.entity"
          label="Tax Entity"
          select
          onChange={handleEntityChange}
          variant="standard"
          helperText="Please select a tax entity type"
          margin="normal"
          InputLabelProps={{ shrink: true }}
        >
          {taxEntityOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
        <TextField name={'tax.installationYear'} disabled={isSubmitting} label="Installation Year" type="number" />
        {showRate && (
          <TextField name="tax.rate" disabled={isSubmitting} onChange={handleRateChange} label="Rate" type="number" />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Tax;
