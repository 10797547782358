import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      input: {
        width: '100% !important',
        marginBottom: '30px',
      },
      formControl: {
        width: '100%',
      },
      itcCheckbox: {
        marginBottom: theme.spacing(1),
      },
      submitContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(3, 'auto', 0, 'auto'),
        maxWidth: '200px',
        '& button': {
          marginBottom: theme.spacing(3),
          width: '100%',
        },
      },
      modalBodyContainer: {
        width: '100%',
        padding: theme.spacing(3),
      },
      success: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
      },
      error: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
      },
      sectionTitle: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
      },
      accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
      },
      form: {
        width: '100%',
        margin: 'none',
      },
      option: {
        padding: theme.spacing(0, 1, 1, 1),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      addOption: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      },
    }),
  );
