import { selectProposal, SelectProposalState } from '../../common/Proposals/selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';

export const selectCashInHandTotalForSolarOptions = (
  state: SelectProposalState,
  proposalId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const proposalWithoutESSAdder = { ...proposal, cart: { ...proposal.cart, essAdder: null } };

  return Pricing.cashInHandTotal(proposalWithoutESSAdder, horizonLength);
};

export const selectProposalHasBatterOptions = (state: SelectProposalState, proposalId: number): boolean => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return false;
  return proposal.essAdders.length > 0;
};
