import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  size?: number;
};

const defaultSize = 50;

const NJIcon: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  return (
    <svg
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 65 65"
      className={classes.root}
    >
      <path
        d="M45,14.76A4.15,4.15,0,0,1,44.65,16c-.31,1.18-.64,2.34-.84,3.57-.09.6-.29.47-.63.83s-.27.57-.5.83c-.59.7-1.17.09-1.84.43-.49.25-.12,1.22-.42,1.74s-.91,1-.48,1.62,1.14,0,1.76.14,1,.66,1.61.35c0-.14.06-.35,0-.48,1.08,1.08.73,2,.5,3.44-.13.78-.08,1.56-.23,2.34s-.3,1.51-.46,2.27c-.31,1.43-.4,3-.61,4.41a12.82,12.82,0,0,1-.33,1.87,9.67,9.67,0,0,1-1.29,2,17.15,17.15,0,0,0-1.3,2.52,5.87,5.87,0,0,1-2.12,2.17,7.12,7.12,0,0,1-1,.78c-.69.33-.7.16-1.12.83a12.85,12.85,0,0,1-1.13,1.21c-.31.37-.25.59-.43,1a19.1,19.1,0,0,1-1.61,2.61,13.62,13.62,0,0,1-1.35,1.93c-.55.61-1.44.79-1.67,0a2.75,2.75,0,0,1,.47-1.83c.34-.64.89-1.17.79-1.94-.12-1-.93-.89-1.74-1.11a5,5,0,0,0-.93-.3c-.48,0-.31.22-.63.36-1.09.46-.42-.58-.89-1.2C26,48,25.3,48.16,24.88,48s-.38-.41-.64-.65a1.5,1.5,0,0,0-.48-.34c-.18-.05-.2.27-.35.2-.43-.18-.2-.27-.44-.54s-.91-.6-1.13-1-.16-.88-.62-1.14c-.3-.17-.71.18-.84-.49,0-.23.21-1.09.23-1.46.92-.17-.91-.87-.57-1.54,1-.12.81-.94,1.05-1.53a2.88,2.88,0,0,1,1.05-1.54c.68-.41.95-.78,1.76-.86a19.84,19.84,0,0,1,2.67-.56c0-.3,0-.6,0-.9,0-.58,0-.4.33-.88a2.26,2.26,0,0,1,1.71-1.15c.54-.14.55-.42,1-.7s.75-.25,1.15-.56.51-.55.91-.63.63.25.92-.12c.63-.81-1.43-2.14-1.8-2.61s-.53-1-.82-1.4c-.09-.1-.33-.08-.43-.2s0-.31-.12-.43c-.21-.28-.25-.43-.6-.61s-.94,0-1.17-.37.05-1.17,0-1.53c-.09-.6-.79-2.43-1.47-1.19-.75-.6-.6-3.39-.06-4.12.67.61,1.23-1,1.19-1.54.22,0,.46,0,.68,0,.07-.51-.26-.86-.46-1.32s-.14-1-.57-1.27c.2-.59.45-.58,1-.81a2,2,0,0,0,1-.86c.14-.21,0-.32.22-.54a4.72,4.72,0,0,1,.63-.36,1.84,1.84,0,0,0,.76-1.07c.26-.79.41-1.95,1.05-2.5.43-.37,1.78-1,2.34-.77L45,14.76Z"
        transform="translate(0 0)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.0999999046325684px"
      />
      <circle cx="32.5" cy="32.5" r="31.45" fill="none" strokeWidth="2.0999999046325684px" />
    </svg>
  );
};

export default NJIcon;
