import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { RiTruckLine } from 'react-icons/ri';
import { useStyles, verticalToHorizBreakpoint } from './styles';
import { Badge, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../RootState';
import { GoSolarPageParams } from '../../../routes';
import { useParams } from 'react-router-dom';
import { selectProductCount } from 'containers/common/Proposals/selectors';

type Props = {
  onClick: () => any;
};

const CartIcon: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles()();
  const theme = useTheme();
  const isUnderSmMediaSize = useMediaQuery(theme.breakpoints.down(verticalToHorizBreakpoint));
  const { id } = useParams<GoSolarPageParams>();
  const proposalId = parseInt(id);
  const productCount = useSelector((state: RootState) => selectProductCount(state, proposalId));

  return (
    <IconButton classes={{ root: classes.cartIconButton }} onClick={onClick}>
      {productCount ? (
        <Badge
          badgeContent={productCount}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: isUnderSmMediaSize ? 'left' : 'right',
          }}
        >
          <RiTruckLine />
        </Badge>
      ) : (
        <RiTruckLine />
      )}
    </IconButton>
  );
};

export default CartIcon;
