import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        padding: theme.spacing(4, 2, 6, 2),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      badgeTitleContainer: {
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        '& p': {
          fontWeight: 500,
          marginLeft: theme.spacing(1),
          color: Colors.GreyTwo,
        },
        '& svg': {
          color: Colors.GreyTwo,
          fill: Colors.GreyTwo,
          stroke: Colors.GreyTwo,
        },
      },
      topContainer: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
      },
      bottomContainer: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        '& ul': {
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
        },
        '& li': {
          padding: 0,
          marginBottom: theme.spacing(1),
        },
        '& li:last-child': {
          marginBottom: theme.spacing(3),
        },
      },
      rootSelected: {
        borderColor: theme.palette.type === 'dark' ? Colors.Background : Colors.SecondaryDark,
      },
      button: {
        '&:hover': {
          cursor: 'pointer !important',
        },
      },
      priceContainer: {
        marginTop: theme.spacing(3),
        '& sup': {
          marginLeft: 2,
        },
      },
      cartTotal: {
        fontSize: '13px',
        fontWeight: 700,
        color: Colors.GreyTwo,
        marginBottom: theme.spacing(4),
      },
      totalSavingsTitle: {
        marginTop: theme.spacing(4),
        fontSize: '13px',
        color: Colors.GreyTwo,
        fontWeight: 700,
      },
      totalSavings: {
        fontWeight: 900,
        fontSize: '22px',
        color: theme.palette.success.main,
      },
      loss: {
        fontWeight: 900,
        fontSize: '22px',
        color: theme.palette.primary.main,
      },
      seeHowButton: {
        textTransform: 'unset',
        margin: theme.spacing(1, 0, 0),
        padding: theme.spacing(0.25, 1.5),
      },
    }),
  );
