import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { verticalToHorizBreakpoint } from '../Cart/styles';
import { dockedWidthLG, dockedWidthMD, dockedWidthXL } from '../Cart/DockedView/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWidgetWrapper: {
      '& div.Papercups-toggleButtonContainer': {
        zIndex: theme.zIndex.drawer - 1,
        right: `calc(${dockedWidthXL} + 10px) !important`,
        [theme.breakpoints.down('lg')]: {
          right: `calc(${dockedWidthLG} + 10px) !important`,
        },
        [theme.breakpoints.down('md')]: {
          right: `calc(${dockedWidthMD} + 10px) !important`,
        },
        [theme.breakpoints.down('sm')]: {
          right: '20px !important',
          bottom: '104px',
        },
      },
    },
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(1),
    },
    smallHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(2, 1, 0, 2.5),
      height: '80px',
    },
    notFoundContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(1, dockedWidthXL, 1.5, 3),
      width: '100%',
      height: '80px',
      [theme.breakpoints.down('md')]: {
        paddingRight: dockedWidthMD,
        padding: theme.spacing(1, dockedWidthXL, 1.5, 2.5),
      },
      [theme.breakpoints.down(verticalToHorizBreakpoint)]: {
        padding: theme.spacing(0),
      },
    },
    brand: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '168px',
      '& svg': {
        height: '42px',
      },
      [theme.breakpoints.down('md')]: {
        '& svg': {
          height: '36px',
        },
      },
    },
    userMenu: {
      cursor: 'pointer',
      paddingRight: theme.spacing(2.5),
      width: '168px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    bodyContainer: {
      marginTop: theme.spacing(12),
      marginRight: dockedWidthXL,
      [theme.breakpoints.down('lg')]: {
        marginRight: dockedWidthLG,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: dockedWidthMD,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
        marginRight: 0,
      },
    },
    contactInfoWidgetContainer: {
      margin: theme.spacing(12, 0, 6),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0, 6),
      },
    },
  }),
);
