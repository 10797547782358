import React from 'react';
import { FormikHelpers } from 'formik';
import CustomerForm from '../../common/Customers/CustomerForm';
import { proposalToolApi } from '../../../lib/api/proposalToolApi';
import { setOneCustomer } from '../../common/Customers/actions';
import { Dispatch } from 'redux';
import { curry } from 'ramda';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { State } from '@solvana/state-domain';
import { Customer } from '@solvana/proposal-tool-domain/dist/Customer';

type Props = {
  customerDTO?: Customer;
  submitTitle?: string;
};

export const createCustomer = curry(
  (dispatch: Dispatch, customer, { setSubmitting, setValues, setStatus }: FormikHelpers<Customer>) => {
    setSubmitting(true);
    proposalToolApi
      .post('/v1/customers', { ...customer })
      .then((response) => {
        dispatch(setOneCustomer(response.data));
        setValues(response.data);
        setStatus({ success: 'Success.' });
        setSubmitting(false);
      })
      .catch((e) => {
        setStatus({ error: e.toString() });
        setSubmitting(false);
      });
  },
);

export const DEFAULT_CUSTOMER: Customer = {
  id: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: State.NJ,
    zip: '',
  },
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

const CreateCustomer: React.FC<Props> = ({ customerDTO, submitTitle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
      <div className={classes.root}>
        <CustomerForm customer={customerDTO || DEFAULT_CUSTOMER} submitHandler={createCustomer(dispatch)} submitTitle={submitTitle} />
      </div>
  );
};

export default CreateCustomer;
