import { selectProposal, SelectProposalState } from '../../selectors';
import Pricing from '@solvana/proposal-tool-domain/dist/lib/Pricing';

export const selectCartSolarOptionMonthlyPaymentNetAllIncentivesOverTerm = (
  state: SelectProposalState,
  proposalId: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  return Pricing.avgMonthlyPaymentForSolarOptionNetAllIncentives(proposal);
};
