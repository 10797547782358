import * as React from 'react';
import {
  ALotOfShadingWithTreeRemoval,
  ALotOfShadingNoTreeRemoval,
  CartPortCanopy,
  DetachedGarage,
  ElectricCar,
  GroundMount,
  NonElectricCar,
  NoShading,
  NoSnowfall,
  NoSquirrel,
  Pergola,
  Snowfall,
  SolarRoof,
  SomeShading,
  Squirrel,
} from '../../../components/SolvanaIcons/RFA';

import { RFAIconProps } from '../../../components/SolvanaIcons/RFA/types';
import { RFASurveyRoutes } from '../routes';
import RFAAddress from '../RFAAddress';
import RFASurvey from '../RFASurvey';
import RFAUtilityBills from '../RFAUtilityBills';
import SelfFinance from '../../../components/SolvanaIcons/RFA/SelfFinance';
import SolarLoan from '../../../components/SolvanaIcons/RFA/SolarLoan';
export type RFASurveyAnswerChoice = {
  answerChoice: string;
  icon: React.FC<RFAIconProps>;
  iconWidth: string;
};

export enum QuestionType {
  SelectOneOrMore = 'Select One Or More',
  SelectOne = 'Select One',
  Address = 'Address',
  UtilityBills = 'Utility Bills',
}

export enum RFAQuestion {
  Address = 'Where would you like to install solar?',
  Shading = 'Does the site have shading (ground or roof)?',
  ElectricCar = 'Do you have an electric car or plan to buy one in the next ten years?',
  Snowfall = 'Do you get a lot of snowfall each winter?',
  SmallAnimals = 'Do you have squirrels, birds, or other small animals that nest in or around your home?',
  InstallLocation = 'Which type of solar installation do you prefer? (Based on on-site conditions our engineering team will help make the best choice for you)',
  UtilityBills = 'Please grant access to review your utility history so that we can design options based on your energy demands and give you an accurate estimate on how much you will save with solar.',
  Financing = 'How do you plan to finance your solar options?',
}

export type RfaSurveyQuestions = {
  path: string;
  pathHandler: () => string;
  question: RFAQuestion;
  answerChoices: RFASurveyAnswerChoice[];
  questionType: QuestionType;
};

type SurveyStep = RfaSurveyQuestions & { component: React.FC };

const rfaSurveyQuestions: SurveyStep[] = [
  {
    path: RFASurveyRoutes.Address,
    pathHandler: () => RFASurveyRoutes.Address,
    questionType: QuestionType.Address,
    answerChoices: [],
    question: RFAQuestion.Address,
    component: RFAAddress,
  },
  {
    path: RFASurveyRoutes.InstallType,
    pathHandler: () => RFASurveyRoutes.InstallType,
    question: RFAQuestion.InstallLocation,
    questionType: QuestionType.SelectOneOrMore,
    component: RFASurvey,
    answerChoices: [
      {
        answerChoice: 'Roof',
        icon: SolarRoof,
        iconWidth: '80px',
      },
      {
        answerChoice: 'Ground mount',
        icon: GroundMount,
        iconWidth: '80px',
      },
      {
        answerChoice: 'Detached garage/barn',
        icon: DetachedGarage,
        iconWidth: '100px',
      },
      {
        answerChoice: 'Pergola',
        icon: Pergola,
        iconWidth: '72px',
      },
      {
        answerChoice: 'Carport canopy',
        icon: CartPortCanopy,
        iconWidth: '80px',
      },
    ],
  },
  {
    component: RFASurvey,
    path: RFASurveyRoutes.Shading,
    pathHandler: () => RFASurveyRoutes.Shading,
    question: RFAQuestion.Shading,
    questionType: QuestionType.SelectOne,
    answerChoices: [
      {
        answerChoice: 'No shading',
        icon: NoShading,
        iconWidth: '78px',
      },
      {
        answerChoice: 'Some shading',
        icon: SomeShading,
        iconWidth: '92px',
      },
      {
        answerChoice: 'A lot of shading (I am willing to remove trees)',
        icon: ALotOfShadingWithTreeRemoval,
        iconWidth: '120px',
      },
      {
        answerChoice: 'A lot of shading (I am not willing to remove trees)',
        icon: ALotOfShadingNoTreeRemoval,
        iconWidth: '108px',
      },
    ],
  },
  {
    component: RFASurvey,
    path: RFASurveyRoutes.ElectricCar,
    pathHandler: () => RFASurveyRoutes.ElectricCar,
    question: RFAQuestion.ElectricCar,
    questionType: QuestionType.SelectOne,
    answerChoices: [
      {
        answerChoice: 'Yes',
        icon: ElectricCar,
        iconWidth: '70px',
      },
      {
        answerChoice: 'No',
        icon: NonElectricCar,
        iconWidth: '70px',
      },
    ],
  },
  {
    component: RFASurvey,
    path: RFASurveyRoutes.Financing,
    pathHandler: () => RFASurveyRoutes.Financing,
    question: RFAQuestion.Financing,
    questionType: QuestionType.SelectOne,
    answerChoices: [
      {
        answerChoice: 'Self-Finance',
        icon: SelfFinance,
        iconWidth: '70px',
      },
      {
        answerChoice: 'Explore all Financing Options',
        icon: SolarLoan,
        iconWidth: '70px',
      },
    ],
  },
  {
    component: RFASurvey,
    path: RFASurveyRoutes.Snowfall,
    pathHandler: () => RFASurveyRoutes.Snowfall,
    question: RFAQuestion.Snowfall,
    questionType: QuestionType.SelectOne,
    answerChoices: [
      {
        answerChoice: 'Yes',
        icon: Snowfall,
        iconWidth: '70px',
      },
      {
        answerChoice: 'No',
        icon: NoSnowfall,
        iconWidth: '70px',
      },
    ],
  },
  {
    component: RFASurvey,
    path: RFASurveyRoutes.SmallAnimals,
    pathHandler: () => RFASurveyRoutes.SmallAnimals,
    question: RFAQuestion.SmallAnimals,
    questionType: QuestionType.SelectOne,
    answerChoices: [
      {
        answerChoice: 'Yes',
        icon: Squirrel,
        iconWidth: '80px',
      },
      {
        answerChoice: 'No',
        icon: NoSquirrel,
        iconWidth: '80px',
      },
    ],
  },
  {
    path: RFASurveyRoutes.UtilityBills,
    pathHandler: () => RFASurveyRoutes.UtilityBills,
    questionType: QuestionType.UtilityBills,
    answerChoices: [],
    question: RFAQuestion.UtilityBills,
    component: RFAUtilityBills,
  },
];

interface IHistory {
  push: (path: string) => any;
}

export const handleNextQuestionClick = (history: IHistory, index: number) => {
  const hasNextQuestion = index < rfaSurveyQuestions.length - 1;
  if (hasNextQuestion) {
    history.push(rfaSurveyQuestions[index + 1].path);
  }
};

export default rfaSurveyQuestions;
