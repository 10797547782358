import { Reducer } from 'redux';
import { Actions, setIsFetchingAdders, setAdders, setAddersFetchError } from './actions';
import { Adder } from '@solvana/catalog-domain/dist/Adder';

type AdderCatalogActions = ReturnType<typeof setIsFetchingAdders> &
  ReturnType<typeof setAddersFetchError> &
  ReturnType<typeof setAdders>;

export type AdderCatalogState = {
  isFetching: boolean;
  error: string | null;
  entities: Adder[];
};

export type SelectAdderCatalogState = {
  adderCatalog: AdderCatalogState;
};

const DEFAULT_STATE: AdderCatalogState = {
  isFetching: false,
  error: null,
  entities: [],
};

const adderCatalog: Reducer<AdderCatalogState, AdderCatalogActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetAdders:
      return { ...state, entities: action.adders };

    case Actions.SetIsFetchingAdders:
      return { ...state, isFetching: action.isFetching };

    case Actions.SetAddersFetchError:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default adderCatalog;
