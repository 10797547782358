import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fill: theme.palette.type === 'dark' ? Colors.GreyFour : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  width?: number;
};

const defaultWidth = 72;

const SolvanaMapPin: React.FC<Props> = ({ width }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={width || defaultWidth}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 214 259"
    >
      <path d="M106.4 163.8c31.3 0 56.8-25.5 56.8-56.8v-1.3-1.2c0-.4-.1-.8-.1-1.2 0-.4-.1-.8-.1-1.2 0-.4-.1-.7-.1-1.1-.1-.4-.1-.9-.2-1.3-.1-.3-.1-.6-.2-1-.1-.5-.2-.9-.2-1.4-.1-.3-.1-.5-.2-.8l-.3-1.5c-.1-.3-.2-.5-.2-.8-.1-.5-.3-1-.4-1.5-.1-.2-.1-.4-.2-.7-.2-.5-.3-1.1-.5-1.6-.1-.1-.1-.2-.2-.4-.2-.6-.5-1.2-.7-1.8 0-.1-.1-.2-.1-.3-.3-.6-.6-1.3-.8-1.9l-.1-.1c-.3-.7-.6-1.3-1-1.9-.1-.1-.1-.2-.2-.3-.3-.6-.7-1.2-1-1.7 0 0 0-.1-.1-.1-.8-1.3-1.6-2.5-2.5-3.8l-.1-.1c-3.7-4.9-8.3-9.1-13.6-12.3-2-1.2-4.1-2.3-6.2-3.2-.1 0-.2-.1-.3-.1-.6-.3-1.2-.5-1.9-.7-.7-.3-1.5-.5-2.2-.8-.3-.1-.5-.2-.8-.2-.5-.1-1-.3-1.5-.4-.2 0-.4-.1-.6-.1-.6-.1-1.2-.3-1.8-.4-.5-.1-.9-.2-1.4-.2-.3-.1-.7-.1-1-.2-.6-.1-1.2-.2-1.7-.2-.2 0-.5-.1-.7-.1-1.6-.2-3.3-.2-4.9-.2-1 0-2.1 0-3.1.1-.3 0-.6 0-.9.1-.8.1-1.6.1-2.4.3-.2 0-.5.1-.7.1-1 .2-2 .3-2.9.5-.2 0-.4.1-.6.1-.8.2-1.6.4-2.3.6-.3.1-.5.2-.8.2l-2.7.9h-.1c-.9.3-1.8.7-2.7 1.1-.2.1-.5.2-.7.3-.7.3-1.4.7-2.1 1l-.6.3c-.8.5-1.7.9-2.5 1.4-.2.1-.3.2-.5.3-.7.4-1.3.9-2 1.3-.2.2-.4.3-.7.5-.8.5-1.5 1.1-2.2 1.7l-.1.1c-.8.6-1.5 1.3-2.2 1.9-.2.2-.4.4-.6.5-.6.5-1.1 1.1-1.6 1.6l-.5.5c-.7.7-1.3 1.4-1.9 2.2-.1.2-.2.3-.3.5-.5.6-1 1.2-1.4 1.9-.2.2-.3.4-.5.7-.5.7-1 1.5-1.5 2.2 0 .1-.1.1-.1.2-.5.8-1 1.7-1.4 2.5 0 .1-.1.1-.1.2 7.3-11.2 19.9-18.5 34.2-18.5 22.5 0 40.9 18.3 40.9 40.9 0 27.2-22.2 49.4-49.4 49.4-1 0-2 0-3-.1-.3 0-.6-.1-.9-.1-.7-.1-1.4-.1-2.1-.2-.3 0-.6-.1-.9-.1-.7-.1-1.5-.2-2.2-.4-.2 0-.5-.1-.7-.1-.9-.2-1.9-.4-2.8-.6-.2-.1-.4-.1-.6-.2l-2.1-.6-.9-.3c-.6-.2-1.3-.4-1.9-.7-.3-.1-.5-.2-.8-.3-.8-.3-1.7-.7-2.5-1.1h-.1c-.8-.4-1.7-.8-2.5-1.3-.2-.1-.5-.3-.7-.4-.6-.3-1.2-.7-1.7-1-.3-.2-.5-.3-.8-.5-.6-.4-1.2-.8-1.7-1.2-.2-.1-.4-.3-.5-.4-.7-.6-1.5-1.1-2.2-1.7-.2-.1-.3-.2-.4-.4-.6-.5-1.1-1-1.7-1.5l-.6-.6-1.4-1.4-.6-.6c-.6-.6-1.1-1.3-1.7-1.9 0-.1-.1-.1-.1-.2-.6-.7-1.2-1.4-1.7-2.2-.2-.2-.3-.5-.5-.7-.4-.5-.8-1.1-1.1-1.6-.1-.1-.2-.3-.3-.4 9.4 17.8 28.3 30.1 50 30.1z" />
      <path d="M106.4 50.2c-31.3 0-56.8 25.5-56.8 56.8v2.5c0 .4.1.8.1 1.2 0 .4 0 .8.1 1.2 0 .4.1.7.1 1.1.1.4.1.9.2 1.3 0 .3.1.6.2 1 .1.5.2.9.2 1.4.1.3.1.6.2.8l.3 1.5c.1.3.2.5.2.8.1.5.3 1 .4 1.5 0 .2.1.3.2.5.2.6.4 1.2.6 1.7 0 .1.1.3.2.4.2.6.5 1.2.7 1.8 0 .1.1.2.2.4.3.6.5 1.2.8 1.8 0 .1.1.2.2.3l.9 1.8s0 .1.1.1c.3.6.7 1.3 1.1 1.9 0 0 0 .1.1.1 2.8 4.6 6.4 8.7 10.5 12.1 1.8 1.5 3.7 2.8 5.7 4 .7.4 1.3.8 2 1.1.7.4 1.4.7 2 1.1.1 0 .1 0 .1.1.7.3 1.3.6 2 .9.1.1.2.1.4.1.6.2 1.2.5 1.8.7.1 0 .2.1.3.1.7.2 1.3.5 2 .7h.1c.7.2 1.5.4 2.2.6.3.1.6.1.9.2l1.5.3c.5.1.9.2 1.4.2.3.1.7.1 1 .2.6.1 1.3.2 1.9.2.2 0 .3 0 .5.1 1.6.2 3.3.2 4.9.2 1 0 2.1 0 3.1-.1.3 0 .6 0 .9-.1l2.4-.3c.2 0 .5-.1.7-.1 1-.1 2-.3 2.9-.5.2-.1.4-.1.7-.2.8-.2 1.5-.4 2.3-.6.3-.1.5-.1.8-.2l2.7-.9h.1c.9-.3 1.8-.7 2.7-1.1.2-.1.5-.2.8-.3.7-.3 1.4-.7 2.1-1l.6-.3c.8-.4 1.7-.9 2.5-1.4.2-.1.3-.2.5-.3.7-.4 1.3-.9 1.9-1.3.2-.2.4-.3.7-.5.7-.5 1.5-1.1 2.2-1.7.1 0 .1-.1.1-.1.7-.6 1.5-1.2 2.2-1.9l.6-.6c.6-.5 1.1-1.1 1.6-1.6l.5-.5c.7-.7 1.3-1.4 1.9-2.2.1-.2.2-.3.4-.5.5-.6.9-1.2 1.4-1.9.2-.2.3-.5.5-.7.5-.7 1-1.5 1.4-2.2.1-.1.1-.1.1-.2.5-.8 1-1.7 1.4-2.5 0-.1.1-.1.1-.2-7.3 11.2-19.9 18.5-34.2 18.5-22.5 0-40.9-18.3-40.9-40.9 0-27.2 22.2-49.4 49.4-49.4 1 0 2 0 2.9.1.3 0 .6.1.9.1.7.1 1.4.1 2.1.2.3 0 .6.1 1 .1.7.1 1.4.2 2.2.4.2 0 .5.1.7.1.9.2 1.9.4 2.8.6.2.1.4.1.7.2l2.1.6.9.3c.6.2 1.2.4 1.9.7.3.1.6.2.8.3.8.3 1.6.7 2.4 1.1.1 0 .1.1.2.1.8.4 1.6.8 2.4 1.3.3.1.5.3.8.4.6.3 1.1.7 1.7 1 .3.2.5.3.8.5.6.4 1.1.8 1.7 1.2.2.2.4.3.6.4.7.5 1.4 1.1 2.1 1.7.2.1.3.3.5.4.5.5 1.1.9 1.6 1.4l.7.7c.5.4.9.9 1.4 1.4.2.2.4.4.6.7.6.6 1.1 1.2 1.6 1.8.1.1.1.2.2.2.6.7 1.2 1.4 1.7 2.2.2.2.3.4.5.7.4.5.8 1.1 1.1 1.7.1.1.2.3.3.4-9.8-17.7-28.7-30-50.5-30z" />
      <path d="M107.1 224.9L166 166c32.5-32.5 32.5-85.3 0-117.8s-85.3-32.5-117.8 0-32.5 85.3 0 117.8l58.9 58.9zm0 33.6l-75.7-75.7c-41.8-41.8-41.8-109.6 0-151.4s109.6-41.8 151.4 0c41.8 41.8 41.8 109.6 0 151.4l-75.7 75.7z" />
    </svg>
  );
};

export default SolvanaMapPin;
