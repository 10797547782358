import { selectProposal, SelectProposalState } from '../../selectors';
import { ESSAdder } from '@solvana/proposal-tool-domain/dist/Proposal/ESSAdder';
import { find } from 'ramda';

export const selectCartESSAdder = (state: SelectProposalState, proposalId: number): ESSAdder | undefined => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return undefined;

  return find((o) => o.id === proposal.cart.essAdder, proposal.essAdders);
};
