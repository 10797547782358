import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { isDarkMode } from '../../../../theme/helpers';

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
      },
      card: {
        width: '100%',
        display: 'flex',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      cardContent: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: theme.spacing(3),
        },
      },
      descriptionContainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1.5),
        },
      },
      description: {
        marginBottom: theme.spacing(1.25),
      },
      productName: {
        lineHeight: 1,
        fontWeight: 900,
        marginBottom: theme.spacing(1.25),
      },
      bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          alignItems: 'center',
        },
        '& hgroup': {
          margin: theme.spacing(0, 0, 2.5),
          borderRadius: theme.spacing(1),
        },
      },
      cashPriceGroup: {
        textAlign: 'right',
      },
      monthlyPriceGroup: {
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.secondary.dark : Colors.GreyFive,
        textAlign: 'left',
        width: '100%',
      },
      cartTotal: {
        paddingTop: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      totalPrice: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      price: {
        padding: 0,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      asterisk: {
        position: 'relative',
        top: '3px',
      },
      dagger: {
        fontSize: '12px',
        marginLeft: theme.spacing(0.5),
      },
      media: {
        width: '40%',
        height: 'unset',
        backgroundColor: isDarkMode(theme) ? theme.palette.secondary.dark : Colors.GreyFive,
        '& img': {
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            height: '300px',
            width: 'unset',
          },
        },
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          justifyContent: 'center',
          height: '300px',
          width: '100%',
        },
      },
      selectedButton: {
        justifyContent: 'flex-end !important',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center !important',
        },
      },
      error: {
        marginBottom: theme.spacing(1),
      },
    }),
  );
