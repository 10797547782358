import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';
import { selectInverterCatalog } from '../../../../../Catalog/Inverters/selectors';

type Props = {
  name: string;
  batteryCompatible?: boolean;
};

const InverterOptionSelect: React.FC<Props> = ({ name, batteryCompatible }) => {
  const classes = useStyles()();
  const { isSubmitting } = useFormikContext<ProposalDTO>();
  const inverterCatalog = useSelector(selectInverterCatalog);

  const selectOptions = batteryCompatible
    ? inverterCatalog.entities
        .filter((i) => i.batteryCompatible)
        .map((inverter) => ({
          label: `${inverter.brand} - ${inverter.model}`,
          value: inverter.id,
        }))
    : inverterCatalog.entities.map((inverter) => ({
        label: `${inverter.brand} - ${inverter.model}`,
        value: inverter.id,
      }));

  return (
    <Field
      component={TextField}
      className={classes.root}
      disabled={isSubmitting}
      type="text"
      name={name}
      label="Inverter"
      select
      variant="standard"
      helperText="Please select an Inverter"
      margin="normal"
      InputLabelProps={{ shrink: true }}
    >
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};

export default InverterOptionSelect;
