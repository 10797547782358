import React from 'react';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import { RiCloseCircleFill } from 'react-icons/ri';
import ListItem from '@material-ui/core/ListItem';
import { useStyles, verticalToHorizBreakpoint } from './styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CartIcon from '../CartIcon';
import TotalPrice from './TotalPrice';
import { RootState } from '../../../../../RootState';
import ProductThumbnail from './ProductThumbnail';
import { selectCartAddersData, selectCartESSAdderData, selectCartSolarOptionData } from '../selectors';
import { toFormattedPrice } from '../../../../../utils/helpers';

type Props = {
  proposalId: number;
  onToggle: () => any;
};

const DockedView: React.FC<Props> = ({ proposalId, onToggle }) => {
  const classes = useStyles()();
  const theme = useTheme();
  const isUnderSmMediaSize = useMediaQuery(theme.breakpoints.down(verticalToHorizBreakpoint));
  const selectedAdders = useSelector((state: RootState) => selectCartAddersData(state, proposalId));
  const solarOptionData = useSelector((state: RootState) => selectCartSolarOptionData(state, proposalId));
  const essAdder = useSelector((state: RootState) => selectCartESSAdderData(state, proposalId));

  return (
    <div className={classes.root}>
      {!isUnderSmMediaSize && (
        <div className={classes.cartIconContainer}>
          <CartIcon onClick={onToggle} />
        </div>
      )}

      <List className={classes.list}>
        {solarOptionData && (
          <ListItem className={classes.productListItem} onClick={onToggle}>
            <ProductThumbnail
              name={solarOptionData.title}
              imageURL={solarOptionData.imageUrl}
              formattedPrice={
                solarOptionData.monthlyPayment
                  ? `${toFormattedPrice(solarOptionData.monthlyPayment.initialPayment)}/mo.`
                  : toFormattedPrice(solarOptionData.price)
              }
            />
          </ListItem>
        )}

        {selectedAdders.map((adder) => (
          <ListItem key={`selected-adder-${adder.id}`} className={classes.productListItem} onClick={onToggle}>
            <ProductThumbnail
              name={adder.title}
              imageURL={adder.imageUrl}
              formattedPrice={
                adder.monthlyPayment
                  ? `${toFormattedPrice(adder.monthlyPayment.initialPayment)}/mo.`
                  : toFormattedPrice(adder.price)
              }
            />
          </ListItem>
        ))}

        {essAdder && (
          <ListItem className={classes.productListItem} onClick={onToggle}>
            <ProductThumbnail
              name={essAdder.title}
              imageURL={essAdder.imageUrl}
              formattedPrice={
                essAdder.monthlyPayment
                  ? `${toFormattedPrice(essAdder.monthlyPayment.initialPayment)}/mo.`
                  : toFormattedPrice(essAdder.price)
              }
            />
          </ListItem>
        )}
      </List>

      {isUnderSmMediaSize && (
        <IconButton size="medium" classes={{ root: classes.closeIconButton }} onClick={onToggle}>
          <RiCloseCircleFill />
        </IconButton>
      )}

      <div className={classes.total}>
        <TotalPrice />
      </div>
    </div>
  );
};

export default DockedView;
