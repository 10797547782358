import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../RootState';
import { selectITCRate } from '../../../../../../common/Proposals/selectors';
import { State } from '@solvana/state-domain';
import { selectCartTotal, selectCartTotalITCValue, selectCartTotalMACRSValue } from './selectors';
import { selectStateIncentives } from '../selectors';
import { selectSolarOptionsPriceNetFirstYear } from '../../../../../pg-choose-your-financing/Financing/FinanceOptions/common/selectors';
import { LineItem, LineItemTotal } from '../../../../../../../components/Accounting';
import SimpleDagger from '../../../../../../../components/SimpleDagger';

type Props = {
  proposalId: number;
  state: State;
};

const FirstYearCashFlow: React.FC<Props> = ({ proposalId, state }) => {
  const classes = useStyles();

  const stateIncentives = useSelector((state: RootState) => selectStateIncentives(state, proposalId, 1));
  const solarOptionsPrice = useSelector((state: RootState) => selectCartTotal(state, proposalId));
  const itcRate = useSelector((state: RootState) => selectITCRate(state, proposalId)) * 100;
  const itcValue = useSelector((state: RootState) => selectCartTotalITCValue(state, proposalId));
  const macrsValue = useSelector((state: RootState) => selectCartTotalMACRSValue(state, proposalId));
  const priceNetFirstYearIncentives = useSelector((state: RootState) =>
    selectSolarOptionsPriceNetFirstYear(state, proposalId),
  );

  return (
    <Card elevation={0} className={classes.card}>
      <div>
        <Typography className={classes.cardTitle} variant="h4">
          First Year Cash Flow
        </Typography>

        <LineItem title="Price" ignoreSign value={solarOptionsPrice} />

        {itcValue > 0 && (
          <LineItem
            title={`${itcRate}% Investment Tax Credit (ITC)`}
            flipSavingsSign
            value={itcValue}
            Sup={SimpleDagger}
          />
        )}

        {macrsValue > 0 && itcValue > 0 && (
          <LineItem title="MACRS Cash Value" flipSavingsSign value={macrsValue} Sup={SimpleDagger} />
        )}

        {State.NJ === state && (
          <LineItem
            title="First Year SRECs Value"
            flipSavingsSign
            value={stateIncentives.pbi[1] || 0}
            Sup={SimpleDagger}
          />
        )}

        {State.MA === state && (
          <LineItem
            title="First Year SMART Value"
            flipSavingsSign
            value={stateIncentives.pbi[1] || 0}
            Sup={SimpleDagger}
          />
        )}

        {stateIncentives.taxCredit > 0 && (
          <LineItem title="Tax Credit for First Year" value={stateIncentives.taxCredit} Sup={SimpleDagger} />
        )}
      </div>

      <LineItemTotal title="Price Net First Year Incentives" value={priceNetFirstYearIncentives} ignoreSign />
    </Card>
  );
};

export default FirstYearCashFlow;
