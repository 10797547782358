import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderColor: theme.palette.type === 'dark' ? '#fff' : 'inherit',
      borderTop: 'solid 1px',
      marginTop: theme.spacing(3),
    },
    title: {
      fontWeight: 900,
    },
    loss: {
      color: theme.palette.primary.main,
      fontWeight: 900,
    },
    savings: {
      color: theme.palette.success.main,
      fontWeight: 900,
    },
  }),
);
