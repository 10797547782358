import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 4, 0, 4),
      borderRadius: theme.spacing(1),
    },
    gridContainer: {
      display: 'flex',
      margin: 0,
      padding: 0,
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      '& li': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '156px',
        marginBottom: theme.spacing(4),
        '& svg': {
          marginBottom: theme.spacing(1),
        },
        '& h6': {
          margin: 0,
          padding: 0,
          lineHeight: '20px',
        },
      },
    },
    footerList: {
      borderTop: 'solid 1px',
      borderColor: Colors.GreyThree,
      marginBottom: theme.spacing(2),
      '& a': {
        color: Colors.GreyThree,
        textDecoration: 'underline',
      },
      '& h6': {
        color: Colors.GreyThree,
        fontSize: '14px',
      },
    },
  }),
);
