import { selectProposal, SelectProposalState } from '../../selectors';
import StateIncentives from '@solvana/proposal-tool-domain/dist/lib/StateIncentives';

export const selectSolarOptionTotalPBIs = (
  state: SelectProposalState,
  proposalId: number,
  solarOptionId: number,
  horizonLength: number,
): number => {
  const proposal = selectProposal(state, proposalId);
  if (!proposal) return 0;

  const solarOption = proposal.solarOptions.find((option) => option.id === solarOptionId);
  if (!solarOption) return 0;

  const proposalWithSolarOption = { ...proposal, cart: { solarOption: solarOptionId, selectedAdders: [] } };

  const pbi = StateIncentives.incentives(proposalWithSolarOption, horizonLength).pbi;

  let total = 0;
  for (let year = 1; year <= horizonLength; year += 1) {
    total += pbi[year] || 0;
  }

  return total;
};
