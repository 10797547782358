import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const baseSpanStyles = {
  width: '100%',
  backgroundSize: '150%',
  display: 'inline-block',
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
      color: '#fff',
      borderRadius: theme.spacing(0.5),
      overflow: 'hidden',
    },
    span: {
      ...baseSpanStyles,
      backgroundPositionY: theme.spacing(-5),
      background: 'url("../../../../../../solvana_circle.svg") no-repeat top;',
    },
    disabledSpan: {
      ...baseSpanStyles,
      backgroundPositionY: theme.spacing(-5),
      background: 'url("../../../../../../solvana_circle_gray.svg") no-repeat top;',
    },
    title: {
      padding: theme.spacing(1, 2),
      margin: 0,
    },
  }),
);

type Props = any;

const StyledButton: React.FC<Props> = ({ children, className, disabled, ...props }) => {
  const classes = useStyles();
  return (
    <Button className={clsx(classes.root, { [className || '']: true })} {...props} disabled={disabled} color="primary">
      <span className={disabled ? classes.disabledSpan : classes.span}>
        <p className={classes.title}>{children}</p>
      </span>
    </Button>
  );
};

export default StyledButton;
