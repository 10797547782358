import React, { useState } from 'react';
import AuthLayout from '../AuthLayout';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { TextField as MUITextField } from 'formik-material-ui';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import { FormControl, LinearProgress, Typography } from '@material-ui/core';
import SubheaderWithLink from '../../common/SubheaderWithLink';
import { Route as SignInRoute } from '../pg-sign-in/route';
import { useStyles } from '../styles';
import StyledButton from '../../common/StyledButton';
import { PASSWORD_REGEX } from '../../../utils/regex';
import { zipCodeApi } from '../../../lib/api/zipCodeApi';
import { useHistory } from 'react-router-dom';

export const schema = yup.object().shape({
  firstName: yup.string().trim().required().label('First Name'),
  lastName: yup.string().trim().required().label('Last Name'),
  email: yup.string().trim().required().label('Email'),
  password: yup
    .string()
    .matches(PASSWORD_REGEX, { message: 'Password does not match minimum requirement' })
    .required()
    .label('Password'),
  zip: yup.string().trim().required().length(5).label('Zip code'),
});

interface Params {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  zip: string;
}

const SignUpPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isZipValidated, setIsZipValidated] = useState(false);
  const [isCheckingZip, setIsCheckingZip] = useState(false);
  const [zipError, setZipError] = useState<string | null>(null);
  const initialValues: Params = { firstName: '', lastName: '', email: '', password: '', zip: '' };

  const checkZipCode = async (zip: string) => {
    setZipError(null);
    setIsCheckingZip(true);
    zipCodeApi
      .get(`/codes/${zip}`)
      .then((r) => {
        if (r.data.isSupported) {
          setIsCheckingZip(false);
          setIsZipValidated(true);

          return;
        }

        setZipError('It looks like we do not currently service your area :-(');
        setIsCheckingZip(false);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setZipError('It looks like we do not currently service your area :-(');
        } else {
          setZipError('Something went wrong.');
        }

        setIsCheckingZip(false);
      });
  };

  const submitHandler = async (p: Params, f: FormikHelpers<Params>) => {
    try {
      f.setStatus({ error: null });

      await Auth.signUp({
        username: p.email,
        password: p.password,
        attributes: {
          'custom:zip': p.zip,
          'custom:firstName': p.firstName,
          'custom:lastName': p.lastName,
        },
      });

      f.setStatus({
        success:
          'You should see a verification link in your email (it might be in your spam folder). You’ll need to verify your email to access your Solvana account.',
      });
    } catch (e) {
      f.setStatus({ error: e.message });
    }
  };

  const title = 'Solvana | Get a free solar proposal without annoying sales people.';

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitHandler}>
      {({ isValid, setFieldValue, errors, values, setStatus, status, isSubmitting }) => {
        if (isSubmitting) {
          return (
            <AuthLayout title={title} header="">
              <LinearProgress />
            </AuthLayout>
          );
        }

        if (status && status.success) {
          return (
            <>
              <AuthLayout title={title} header="">
                <Typography variant="h4" className={classes.success}>
                  Your account awaits...
                </Typography>
                <Typography variant="body1" className={classes.success}>
                  You should see a verification link in your email (it might be in your spam folder). You’ll need to
                  verify your email to access your Solvana account.
                </Typography>

                <StyledButton
                  size="large"
                  variant="contained"
                  onClick={() => history.push(SignInRoute.Path)}
                  color="primary"
                >
                  Sign in
                </StyledButton>
              </AuthLayout>
            </>
          );
        }

        return (
          <AuthLayout title={title} header="Get a free solar proposal" subHeader="Let's check if we service your area">
            <Form>
              {!isZipValidated && (
                <>
                  <FormControl className={classes.formControl}>
                    <FastField
                      disabled={isCheckingZip}
                      component={MUITextField}
                      className={classes.input}
                      variant="filled"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setZipError(null);
                        setStatus({ error: null });
                        setFieldValue('zip', e.target.value);
                      }}
                      name="zip"
                      type="text"
                      label="Zip Code"
                    />
                  </FormControl>

                  {zipError && (
                    <Typography variant="subtitle1" className={classes.error}>
                      {zipError}
                    </Typography>
                  )}

                  <div className={classes.submitContainer}>
                    <StyledButton
                      size="large"
                      variant="contained"
                      onClick={() => checkZipCode(values.zip)}
                      color="primary"
                      disabled={isCheckingZip || Boolean(zipError) || Boolean(errors.zip)}
                    >
                      Next
                    </StyledButton>
                  </div>
                </>
              )}

              {isZipValidated && (
                <>
                  <FormControl className={classes.formControl}>
                    <FastField
                      disabled={isSubmitting}
                      component={MUITextField}
                      className={classes.input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStatus({ error: null });
                        setFieldValue('firstName', e.target.value);
                      }}
                      name="firstName"
                      type="text"
                      label="First Name"
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <FastField
                      disabled={isSubmitting}
                      component={MUITextField}
                      className={classes.input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStatus({ error: null });
                        setFieldValue('lastName', e.target.value);
                      }}
                      name="lastName"
                      type="text"
                      label="Last Name"
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <FastField
                      disabled={isSubmitting}
                      component={MUITextField}
                      className={classes.input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStatus({ error: null });
                        setFieldValue('email', e.target.value);
                      }}
                      name="email"
                      type="email"
                      label="Email"
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <FastField
                      disabled={isSubmitting}
                      component={MUITextField}
                      className={classes.input}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStatus({ error: null });
                        setFieldValue('password', e.target.value);
                      }}
                      name="password"
                      type="password"
                      label="Password"
                    />
                  </FormControl>

                  <Typography variant="subtitle2" className={classes.hint}>
                    Password must be a minimum of 8 characters with at least ONE number, ONE capital letter, and ONE
                    special character (e.g. $, %, *).
                  </Typography>

                  {status && status.error && (
                    <Typography variant="subtitle1" className={classes.error}>
                      {status.error}
                    </Typography>
                  )}

                  <div className={classes.submitContainer}>
                    <StyledButton
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid}
                      type="submit"
                    >
                      Create my account
                    </StyledButton>
                  </div>
                </>
              )}
              <SubheaderWithLink to={SignInRoute.Path} subheader="Already have a Solvana account?" name="Sign in" />
            </Form>
          </AuthLayout>
        );
      }}
    </Formik>
  );
};

export default SignUpPage;
