import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
    },
  }),
);

type Props = {
  size?: number;
};

const defaultSize = 50;

const ElectricIcon: React.FC<Props> = ({ size }) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.root}
      width={size || defaultSize}
      height={size || defaultSize}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
    >
      <g strokeWidth="2.089" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M56.302 64.239H3.564a2.52 2.52 0 0 1-2.519-2.52V3.564a2.52 2.52 0 0 1 2.519-2.52h43.71a2.52 2.52 0 0 1 2.521 2.52V35.35" />
        <path d="M54.384 64.239h6.113a4.382 4.382 0 0 0 4.383-4.384V35.35H49.794v24.3a4.592 4.592 0 0 0 4.59 4.589zM13.736 32.642h21.919M13.736 40.694h21.919M13.736 48.747h21.919M28.672 7.905L19.02 17.56h9.652l-9.365 9.366" />
      </g>
    </svg>
  );
};

export default ElectricIcon;
