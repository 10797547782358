import { ESSAdderDTO } from '@solvana/proposal-tool-domain/dist/Proposal/ESSAdder';

export const defaultESSInverter = {
  productId: 2,
  quantity: 1,
};

export const defaultResidentialESSAdders: ESSAdderDTO[] = [
  {
    productId: 30,
    price: 6_298,
    quantity: 1,
    inverters: [{ productId: 15, quantity: 1 }],
  },
  {
    productId: 11,
    price: 17_468,
    quantity: 1,
    inverters: [{ ...defaultESSInverter }],
  },
  {
    productId: 19,
    price: 23_887,
    quantity: 1,
    inverters: [{ ...defaultESSInverter }],
  },
  {
    productId: 18,
    price: 32_635,
    quantity: 1,
    inverters: [{ ...defaultESSInverter }],
  },
  {
    productId: 26,
    price: 16_614,
    quantity: 1,
    inverters: [
      {
        productId: 11,
        quantity: 1,
      },
    ],
  },
  {
    productId: 27,
    price: 18_916,
    quantity: 1,
    inverters: [
      {
        productId: 11,
        quantity: 1,
      },
    ],
  },
  {
    productId: 28,
    price: 23_512,
    quantity: 1,
    inverters: [
      {
        productId: 11,
        quantity: 1,
      },
    ],
  },
];

export const defaultNewESSAdder = {
  quantity: 1,
  inverters: [{ ...defaultESSInverter }],
};
