import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../theme/Colors';
import { RFAIconProps } from '../types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      stroke: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      fill: theme.palette.background.default,
      strokeWidth: 2.1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      fillRule: 'evenodd',
    },
    highlight: {
      stroke: theme.palette.primary.main,
    },
  }),
);

const defaultWidth = 50;

const InverterSolArk: React.FC<RFAIconProps> = ({ width, title }) => {
  const classes = useStyles();
  return (
    <svg className={classes.root} width={width || defaultWidth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 78">
      <title>{title || 'SolArk Inverter'}</title>
      <g>
        <g transform="translate(0 .816)">
          <circle className={classes.highlight} cx="38" cy="38.184" r="36.95" />
          <path d="M11.05 53.95h57V4.234h-57zM11.05 71.95h57V54.234h-57z" />
        </g>
        <path className={classes.highlight} d="M22.05 39.766h35v-18h-35z" />
      </g>
    </svg>
  );
};

export default InverterSolArk;
