import { Reducer } from 'redux';
import { Actions, LearnMoreItem, setLearMoreItem } from './actions';

type LearnMoreActions = ReturnType<typeof setLearMoreItem>;

export type State = {
  item: LearnMoreItem | null;
};

const DEFAULT_STATE: State = {
  item: null,
};

const learnMoreUI: Reducer<State, LearnMoreActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetLearnMoreItem:
      return { ...state, item: action.item };

    default:
      return state;
  }
};

export default learnMoreUI;
