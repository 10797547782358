import React from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectLayoutUI } from '../../selectors';
import { setIsDarkMode } from '../../actions';
import { RiSunFill, RiMoonClearFill } from 'react-icons/ri';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../theme/Colors';
import clsx from 'clsx';
import { trackDarkModeSet, trackLightModeSet } from '../../../../../utils/analytics/track';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& svg': {
        fill: theme.palette.type === 'dark' ? '#fff' : Colors.SecondaryDark,
      },
    },
    light: {
      '& svg': {
        fill: '#fff',
      },
    },
  }),
);

type Props = {
  white?: boolean;
};

const DarkModeToggleButton: React.FC<Props> = ({ white }) => {
  const classes = useStyles();
  const { isDark } = useSelector(selectLayoutUI);
  const dispatch = useDispatch();

  const handleBrightnessChange = () => {
    isDark ? trackLightModeSet() : trackDarkModeSet();
    dispatch(setIsDarkMode(!isDark));
  };

  return (
    <IconButton
      className={clsx(classes.root, { [classes.light]: white })}
      onClick={handleBrightnessChange}
      size="small"
    >
      {isDark ? <RiSunFill /> : <RiMoonClearFill />}
    </IconButton>
  );
};

export default DarkModeToggleButton;
