import * as React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useStyles } from './styles';
import Utility from './Utility';
import Address from './Address';
import Smart from './Smart';
import Tax from './Tax';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import General from './General';
import ReactJson from 'react-json-view';
import FinanceOptions from './FinanceOptions';
import { resolveFinanceOptions } from './FinanceOptions/defaults';
import AdderOptions from './AdderOptions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchPanels } from '../../Catalog/Panels/actions';
import EssAdderOptions from './EssAdderOptions';
import SolarOptions from './SolarOptions';
import StyledButton from '../../StyledButton';
import { ProposalDTO, schema } from '@solvana/proposal-tool-domain/dist/Proposal';
import { TaxEntity } from '@solvana/proposal-tool-domain/dist/common/TaxEntity';
import { fetchBatteries } from '../../Catalog/Batteries/actions';
import { fetchAdders } from '../../Catalog/Adders/actions';
import { fetchInverters } from '../../Catalog/Inverters/actions';
import ModalView from '../../ModalView';

export interface ProposalSubmitHandler {
  (proposal: ProposalDTO, actions: FormikHelpers<ProposalDTO>): any;
}

type Props = {
  proposal: ProposalDTO;
  submitHandler: ProposalSubmitHandler;
};

const ProposalForm: React.FC<Props> = ({ proposal, submitHandler }) => {
  const [errorsForModal, setErrorsForModal] = useState<any>(null);
  const classes = useStyles()();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAdders(dispatch);
    fetchBatteries(dispatch);
    fetchInverters(dispatch);
    fetchPanels(dispatch);
  }, []);

  return (
    <>
      <ModalView wide open={Boolean(errorsForModal)} handleClose={() => setErrorsForModal(null)}>
        <div className={classes.modalBodyContainer}>
          {errorsForModal ? (
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              iconStyle="circle"
              theme="solarized"
              src={errorsForModal}
            />
          ) : (
            <Typography className={classes.success} variant="body1">
              Everything looks good!
            </Typography>
          )}
        </div>
      </ModalView>
      <Formik initialValues={{ ...proposal }} validationSchema={schema} onSubmit={submitHandler}>
        {({ setFieldValue, errors, isValid, status, isSubmitting }) => {
          const handleEntityChange = (entity: TaxEntity) => {
            setFieldValue('financeOptions', resolveFinanceOptions(entity));
          };

          if (status && status.success) {
            return (
              <Typography variant="subtitle1" className={classes.success}>
                {status.success}
              </Typography>
            );
          }

          return (
            <Form className={classes.form}>
              <General />
              <Address />
              <Smart />
              <Utility />
              <Tax onEntityChange={handleEntityChange} />
              <SolarOptions />
              <FinanceOptions />
              <EssAdderOptions />
              <AdderOptions />

              {isSubmitting && <LinearProgress />}

              {status && status.error && (
                <Typography variant="subtitle1" className={classes.error}>
                  {status.error}
                </Typography>
              )}

              <div className={classes.submitContainer}>
                <StyledButton
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Submit
                </StyledButton>

                <Button onClick={() => setErrorsForModal(errors)} size="large" variant="contained" color="secondary">
                  Validate
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProposalForm;
