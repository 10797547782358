import { combineReducers } from 'redux';
import adderCatalog from './containers/common/Catalog/Adders/reducer';
import advocates from './containers/common/Advocates/reducer';
import auth from './containers/Auth/reducer';
import batteryCatalog from './containers/common/Catalog/Batteries/reducer';
import cartUI from './containers/common/Proposals/ProposalForm/Cart/reducer';
import currentUserProfile from './containers/common/CurrentUserProfile/reducer';
import customers from './containers/common/Customers/reducer';
import essFinancingUI from './containers/CheckoutPages/pg-choose-your-batteries/ESSAdderFinanceOptions/reducer';
import goSolarPageUI from './containers/CheckoutPages/reducer';
import inverterCatalog from './containers/common/Catalog/Inverters/reducer';
import layoutUI from './containers/common/Layout/reducer';
import learnMoreUI from './containers/CheckoutPages/common/LearnMore/reducer';
import panelCatalog from './containers/common/Catalog/Panels/reducer';
import proposals from './containers/common/Proposals/reducer';
import rfa from './containers/pg-rfa/reducer';
import solarBenefitsUI from './containers/CheckoutPages/pg-choose-your-financing/reducer';
import customerRFAs from './containers/pg-customer/reducer';
import inviteCustomersUI from './containers/common/Proposals/ProposalsTable/InviteCustomerButton/reducers';

export const reducers = combineReducers({
  adderCatalog,
  advocates,
  auth,
  batteryCatalog,
  cartUI,
  currentUserProfile,
  customerRFAs,
  customers,
  essFinancingUI,
  goSolarPageUI,
  inverterCatalog,
  inviteCustomersUI,
  layoutUI,
  learnMoreUI,
  panelCatalog,
  proposals,
  rfa,
  solarBenefitsUI,
});

export default reducers;
