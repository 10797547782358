import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(3),
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    hgroup: {
      marginLeft: theme.spacing(4),
      '& h3': {
        fontSize: '38px',
      },
      '& h4': {
        fontSize: '20px',
      },
    },
    summary: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(4),
      },
    },
    details: {
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  }),
);
