import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import TextField from '../TextField';
import { useFormikContext } from 'formik';
import { State } from '@solvana/state-domain/dist';
import { ProposalDTO } from '@solvana/proposal-tool-domain/dist/Proposal';

const Smart: React.FC = () => {
  const classes = useStyles()();
  const { isSubmitting, values } = useFormikContext<ProposalDTO>();

  if (State.MA !== values?.address?.state) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Smart
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <TextField name={'smart.batteryValue'} disabled={isSubmitting} label="Battery Value" type="number" />
        <TextField name={'smart.solarValue'} disabled={isSubmitting} label="Solar Value" type="number" />
      </AccordionDetails>
    </Accordion>
  );
};

export default Smart;
