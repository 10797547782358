import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../../../theme/Colors';

export const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 'auto', 3, 'auto'),
        padding: theme.spacing(2),
      },
      firstRow: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          '& hgroup': {
            marginTop: theme.spacing(2),
          },
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        borderRadius: theme.spacing(1),
        width: '100%',
        '& svg': {
          width: '250px',
        },
      },
      buttonContainer: {
        minWidth: '180px',
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(2),
        },
      },
      button: {
        '&:hover': {
          cursor: 'pointer !important',
        },
      },
      subtitle: {
        fontSize: '13px',
        fontWeight: 700,
        color: Colors.GreyTwo,
      },
      monthlyPayment: {
        fontSize: '20px',
        fontWeight: 900,
      },
    }),
  );
