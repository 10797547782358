import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
    solarOptionContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    error: {
      margin: theme.spacing(3, 0),
    },
  }),
);
