import { State } from '@solvana/state-domain/dist';

export const solarSupportedStates = [
  State.NJ,
  State.TX,
  State.FL,
  State.MA,
  State.CA,
  State.CT,
  State.GA,
  State.NC,
  State.NM,
  State.NV,
  State.SC,
  State.TN,
  State.VA,
];
