import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    brand: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(1, 3, 1.5),
      height: '80px',
    },
    rightContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
  }),
);
